/* eslint-disable import/first */
import Vue from 'vue';

/**
 * PLUGIN INITIALIZATION
 */
import { ToastPlugin, BootstrapVue } from 'bootstrap-vue';
Vue.use(ToastPlugin);
import UniqueId from 'vue-unique-id';
Vue.use(UniqueId);

import Log from './libs/Log/plugins/Log';
Vue.use(Log);

import Config from './libs/Config/plugins/Config';
Vue.use(Config);

import ApiVue from './libs/Api/plugins/Api';
import endpoints from './config/endpoints';
Vue.use(ApiVue, {
  endpoints,
});

import OAuth2Vue from './libs/OAuth2/plugins/OAuth2';
Vue.use(OAuth2Vue, {
  config: {
    client_id: Config._getService().get('client_id'),
    client_secret: Config._getService().get('client_secret'),
  },
});

import StorageVue from './libs/Storage/plugins/Storage';
Vue.use(StorageVue);

import Router from 'vue-router';
Vue.use(Router);

/**
 * APP INITIALIZATION
 */
import App from './views/App.vue';
import routerGenerator from './libs/Router/router';
import routes from './config/routes';
import guards from './config/guards';

const router = routerGenerator({ routes }, [guards]);

Vue.config.productionTip = false;

const vm = new Vue({
  ...App,
  router,
}).$mount('#app');

(() => import(/* webpackChunkName: "css-bootstrap" */ './assets/scss/bootstrap.scss'))();
(() => import(/* webpackChunkName: "css-fontawesome" */ './assets/scss/fontawesome.scss'))();
(() => import(/* webpackChunkName: "css-style" */ './assets/scss/style.scss'))();

/**
 * GLOBAL COMPONENTS
 */

Vue.use(BootstrapVue);
import Popup from '@/views/components/Popup.vue';
import './registerServiceWorker';
Vue.component('Popup', Popup);
export default vm;
