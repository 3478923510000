<template>
  <div class="container-fluid p-0 w-auto m-4">
    <div class="pretty-card full-charts">
      <div class="row mb-4">
        <div class="col d-flex align-items-center">
          <h5 class="m-0">Previsione saldi</h5>
        </div>
        <div class="col-6 d-flex justify-content-end align-items-center mb-2">
          <label class="mr-3 mb-0" for="selector">
            Periodo
          </label>
          <div class="selector" id="selector">
            <button class="btn option" @click="setRangeDays(30)" :class="{ active: rangeDays === 30 }">
              30 giorni
            </button>
            <button class="btn option" @click="setRangeDays(90);" :class="{ active: rangeDays === 90 }">
              90 giorni
            </button>
          </div>
        </div>
      </div>
      <PromisesChart :banks="banksLegend" :series="promisesSeries" :todayDate="todayDate" class="mb-5" :rangeDays="rangeDays" :key="`promises_chart_${update}`"/>
      <div class="row mb-4">
        <div class="col d-flex align-items-center">
          <h5 class="m-0">Storico saldi</h5>
        </div>
        <div class="col-auto d-flex justify-content-end align-items-center mb-2">
          <label class="mr-3 mb-0" for="selector">
            Periodo
          </label>
          <div class="selector" id="selector">
            <button class="btn option" @click.prevent="setMaxRangeBalances('1y')" :class="{ active: maxRangeBalances === '1y' }">
              Ultimo anno
            </button>
            <button class="btn option" @click.prevent="setMaxRangeBalances('6m')" :class="{ active: maxRangeBalances === '6m' }">
              Ultimi 6 mesi
            </button>
            <button class="btn option" @click.prevent="setMaxRangeBalances('3m')" :class="{ active: maxRangeBalances === '3m' }">
              Ultimi 3 mesi
            </button>
          </div>
        </div>
      </div>
      <BalancesChart :banks="banksLegend" :series="balancesSeries" :todayDate="todayDate" :startDate="balancesStartDate" :maxRange="maxRangeBalances" :key="`balances_chart_${update}`" class="mb-5"/>

      <div class="row mb-4">
        <div class="col d-flex align-items-center">
          <h5 class="m-0">Storico entrate/uscite</h5>
        </div>
        <div class="col-6 d-flex justify-content-end align-items-center mb-2">
          <label class="mr-3 mb-0" for="selector">
            Raggruppa per
          </label>
          <div class="selector" id="selector">
            <button class="btn option" @click.prevent="setGroupBy('1w')" :class="{ active: groupBy === '1w' }">
              Settimana
            </button>
            <button class="btn option" @click.prevent="setGroupBy('1m')" :class="{ active: groupBy === '1m' }">
              Mese
            </button>
            <button class="btn option" @click.prevent="setGroupBy('3m')" :class="{ active: groupBy === '3m' }">
              Trimestre
            </button>
          </div>
        </div>
      </div>
      <BankSelect :banks="banks" :selectedItem="selectedBank" @update="updateSelectedBank" class="w-50 ml-auto"/>
      <InOutChart :series="inOutSeries" :groupBy="groupBy" :todayDate="todayDate" :startDate="inOutStartDate" :key="`in_out_chart_${update}`" class="mb-5"/>
    </div>
  </div>
</template>

<script>
import PromisesChart from '@/views/components/Charts/PromisesChart.vue';
import BalancesChart from '@/views/components/Charts/BalancesChart.vue';
import InOutChart from '@/views/components/Charts/InOutChart.vue';
import BankSelect from '@/views/components/BankSelect.vue';

import { toStandardDate, toFullHumanDate } from '@/helpers/dates.js';

export default {
  components: {
    PromisesChart,
    BalancesChart,
    InOutChart,
    BankSelect,
  },
  watch: {
    groupBy () {
      this.getCharts(true);
    },
  },
  data () {
    return {
      companyId: this.$oauth2.me.companies[0].id || null,
      banks: [],
      update: 0,
      errorMessage: '',

      /* promises balances chart */
      saldi: [],
      banksLegend: [],
      todayDate: null,
      rangeDays: 30, // 30, 90

      /* past balances chart */
      balances: [],
      balancesFrequency: '1w',
      maxRangeBalances: '6m', // 3m, 6m, 1y
      startDate: null,

      /* past in out chart */
      entrate: [],
      uscite: [],
      selectedBank: null,
      maxRangeInOut: '1y',
      inOutStartDate: '',
      groupBy: '1w', // 1w, 1m, 3m
    };
  },
  computed: {
    promisesSeries () {
      return this.saldi.map((obj, i) => {
        return {
          name: obj.alias || obj.name,
          type: 'line',
          smooth: true,
          data: this.getObjectValues(obj),
          itemStyle: {
            // to do color: obj.color, to bank colour
          },
          symbol: 'circle',
        };
      });
    },
    balancesSeries () {
      return this.balances.map((obj, i) => {
        return {
          name: obj.alias || obj.name,
          type: 'line',
          smooth: true,
          data: this.getBalancesValues(obj),
          itemStyle: {
            // to do color: obj.color, to bank colour
          },
          symbol: 'circle',
        };
      });
    },
    balancesStartDate () {
      if (this.maxRangeBalances === '1y' && this.startDate) {
        return this.startDate;
      }

      const date = new Date(this.startDate);
      date.setHours(0, 0, 0);

      if (this.maxRangeBalances === '6m' && this.startDate) {
        date.setMonth(date.getMonth() + 6);
        return date;
      }

      if (this.maxRangeBalances === '3m' && this.startDate) {
        date.setMonth(date.getMonth() + 9);
        return date;
      }

      return null;
    },
    inOutSeries () {
      if (this.selectedBank) {
        const bank = this.entrate.find((obj) => {
          if (obj.alias !== undefined) {
            return obj.alias === this.selectedBank.alias;
          } else {
            return obj.name === this.selectedBank.name;
          }
        });
        const bank2 = this.uscite.find((obj) => {
          if (obj.alias !== undefined) {
            return obj.alias === this.selectedBank.alias;
          } else {
            return obj.name === this.selectedBank.name;
          }
        });

        const inSeries = Object.assign({}, { name: 'Entrate', type: 'bar', data: bank?.values });
        const outSeries = Object.assign({}, { name: 'Uscite', type: 'bar', data: bank2?.values });

        return [inSeries, outSeries];
      } else {
        return [];
      }
    },
  },
  methods: {
    getCompanyInfo () {
      this.$api
        .getCompany(this.companyId)
        .then((res) => {
          this.banks = [...res.data.banks, { name: 'Totale banche', alias: 'Totale banche' }, { name: 'Totale tesoreria', alias: 'Totale tesoreria' }];
          const bank = this.banks?.find(obj => obj.name === 'Totale banche');
          this.selectedBank = bank;
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getObjectValues (obj) {
      return this.rangeDays === 30 ? obj.saldi.slice(0, 30) : obj.saldi;
    },
    getBalancesValues (obj) {
      if (this.maxRangeBalances === '1y') return obj.values;

      if (this.maxRangeBalances === '6m') {
        return obj.values.filter((obj, i) => (i >= 26));
      }

      if (this.maxRangeBalances === '3m') {
        return obj.values.filter((obj, i) => (i >= 39));
      }
    },
    getPromises () {
      this.$api.fetchSaldiDashboard(this.companyId)
        .then((res) => {
          this.$log.debug(res.data.items);
          this.saldi = res.data.items;
          this.todayDate = res.headers.date;
          this.banksLegend = this.saldi.map((obj) => obj.alias || obj.name);
          this.update++;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
          this.update++;
        });
    },
    getCharts (update = false) {
      this.loading = true;
      this.$api.fetchCharts(this.companyId, this.groupBy)
        .then((res) => {
          if (!update) {
            this.todayDate = res.headers.date;
            this.startDate = res.data.balancesFrom;
            this.balances = res.data.balances;
            this.update++;
          }
          this.inOutStartDate = res.data.inOutFrom;
          this.groupBy = res.data.inOutGroupBy;
          this.entrate = JSON.parse(JSON.stringify(res.data.in));
          this.uscite = JSON.parse(JSON.stringify(res.data.out));
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setRangeDays (num) {
      this.rangeDays = num;
    },
    setMaxRangeBalances (str) {
      this.maxRangeBalances = str;
    },
    setGroupBy (str) {
      if (!this.loading) {
        this.groupBy = str;
      }
    },
    toStandardDate (s) {
      return toStandardDate(s);
    },
    toFullHumanDate (s) {
      return toFullHumanDate(s);
    },
    updateSelectedBank (bank) {
      if (this.selectedBank !== bank) {
        this.selectedBank = JSON.parse(JSON.stringify(bank));
      }
    },
  },
  beforeMount () {
    this.getPromises();
    this.getCharts();
    this.getCompanyInfo();
  },
};
</script>

<style lang="scss">
.full-charts {
}
</style>
