<template>
  <div class="matching" ref="automaticMatching">
    <div v-if="isLoadingPage || isLoadingPage2">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid p-0 w-auto m-4">
      <h4 class="mb-2">Riconciliazione automatica</h4>

      <div class="container custom-container" v-if="movements?.length > 0">
        <div class="row">
          <div class="col">
            <h5 class="mb-3">Transazioni dai conti correnti</h5>
          </div>
        </div>

        <button
          v-if="userInStage"
          variant="warning"
          squared
          @click="downloadTransactionsDemo()"
        >
          Demo
        </button>

        <carousel-3d
          ref="carousel"
          :controls-visible="true"
          :height="300"
          :width="450"
          @before-slide-change="updatecurrentSlide"
          :key="`movements_${movements?.length}`"
        >
          <slide
            v-for="(slide, i) in slides"
            :index="i"
            :key="`slide_${i}`"
            :controls-visible="true"
            :class="[
              { 'custom-border': currentSlideIndex === i },
              `border-bank-variant-${movements[i].bankColour || 0}`,
            ]"
          >
            <div
              class="header"
              :class="`text-bank-variant-${
                movements[i].bankColour || 0
              } bank-variant-${movements[i].bankColour || 0}-light`"
            >
              {{ movements[i].bankName }}
            </div>
            <div class="body">
              <div class="row mb-2">
                <div class="col-6">
                  <div class="row">
                    <div class="col-12">
                      <label class="pretty-label-sm m-0"
                        >Data trasmissione</label
                      >
                    </div>
                    <div class="col-12 text-nowrap">
                      {{
                        movements[i].transmittedAt
                          ? toFormattedDate(movements[i].transmittedAt)
                          : null
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-12">
                      <label class="pretty-label-sm m-0">Data movimento</label>
                    </div>
                    <div class="col-12">
                      {{
                        movements[i].date
                          ? toFormattedDate(movements[i].date)
                          : null
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-6">
                  <div class="row">
                    <div class="col-12">
                      <label class="pretty-label-sm m-0">Tipo</label>
                    </div>
                    <div class="col-12 text-capitalize">
                      {{ movements[i].type | movement }}
                    </div>
                  </div>
                </div>
                <div class="col-6 text-right">
                  <label class="pretty-label-sm m-0">Importo</label>
                  <CellContent
                    :value="
                      movements[i].amount
                        ? parseFloat(movements[i].amount)
                        : null
                    "
                    class="d-flex justify-content-end m-0 p-0 border-0"
                  />
                </div>
              </div>
              <div class="row mb-2" v-if="movements[i].counterpart !== null">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <label class="pretty-label-sm m-0">Controparte</label>
                    </div>
                    <div class="col-12 text-nowrap">
                      {{ movements[i].counterpart }}
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="movements[i].purpose !== null || movements[i].counterpartPurpose !== null">
                <div
                  v-if="descriptionHover && currentSlideIndex == i"
                  class="row mb-2"
                  @mouseleave="descriptionHover = false"
                >
                  <div class="col-12">
                    <label class="pretty-label-sm m-0">Descrizione</label>
                  </div>
                  <div class="col-12 description">
                    {{ movements[i].description }}
                  </div>
                </div>

                <div v-else class="row mb-2">
                  <div class="col-6">
                    <div class="row" v-if="movements[i].purpose !== null">
                      <div class="col-12">
                        <label class="pretty-label-sm m-0">Causale</label>
                      </div>
                      <div class="col-12">
                        {{ movements[i].purpose }}
                      </div>
                      </div>
                      <div class="row" v-else>
                      <div class="col-12">
                      <label class="pretty-label-sm m-0">Controparte e Causale</label>
                    </div>
                    <div class="col-12 text-nowrap">
                      {{ movements[i].counterpartPurpose }}
                    </div>
                    </div>
                  </div>
                  <div class="col-6 text-right">
                    <b-button class="p-0" @mouseover="descriptionHover = true">
                      <span class="col-6">Descrizione</span>
                    </b-button>
                  </div>
                </div>
              </div>

              <div v-else class="row mb-2">
                <div class="col-12">
                  <label class="pretty-label-sm m-0">Descrizione</label>
                </div>
                <div class="col-12">
                  {{ movements[i].description }}
                </div>
              </div>
            </div>
          </slide>
        </carousel-3d>

        <div class="row mb-2">
          <div class="col d-flex align-items-center justify-content-center">
            <div class="current-slide">
              <div class="dark-box mr-1">
                {{ currentSlideIndex + 1 }}
              </div>
              di
              <div class="font-weight-bolder ml-1">
                {{ movements.length }}
              </div>
            </div>
          </div>
          <div
            class="
              col-12
              mt-3
              w-100
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <custom-button
              v-if="movements?.length > 0"
              class="custom-button"
              :isLoading="loading"
              :label="`Contabilizza ${
                selectedPromises?.length > 0 ? 'e associa moviment' : ''
              }${
                selectedPromises?.length > 1
                  ? 'i'
                  : selectedPromises?.length != 0
                  ? 'o'
                  : ''
              } ${
                selectedPromises?.length > 0
                  ? '(' + selectedPromises?.length + ')'
                  : ''
              }`"
              @click.prevent.native="onSubmit"
            />
          </div>
        </div>
      </div>

      <div class="container custom-container" v-else>
        <div class="row">
          <div class="col">
            <h5 class="mb-3">Transazioni dai conti correnti</h5>
          </div>
        </div>
        <p class="text-center custom-height">
          Non ci sono movimenti da riconciliare
        </p>
      </div>

      <div class="container h-100 p-0">
        <div class="row align-items-center ">
          <div class="col">
            <h5 class="mb-3">Movimenti in attesa</h5>
          </div>
          <div class="col d-flex justify-content-end">
            <p class="mb-3">
              Importo totale: {{ totalMovementsAmount.toLocaleString("de") }}
            </p>
          </div>
          <div class="col-5 d-flex flex-row justify-content-end">
          <b-form-group>
            <b-form-input
              id="search-input"
              v-model="promisesSearchInput"
              placeholder="Cerca..."
            ></b-form-input>
          </b-form-group>
        </div>
        </div>

        <div
          ref="movements"
          class="row custom-container-bottom"
          v-if="promises?.length > 0"
          :key="`promises_${promisesNoSuggested?.length}_${updating}`"
        >
          <div
            v-if="suggested"
            class="col-6 p-1"
            @click="updateSelected(suggested.id)"
            @keyup.enter="updateSelected(suggested.id)"
          >
            <PromiseCard
              :item="suggested"
              :isSelected="isSelected(suggested.id)"
              :level="matchAffinity"
              :key="`suggested_${currentSlideIndex}_${Boolean(suggested)}`"
            />
          </div>
          <div
            v-for="(promise, i) in promisesNoSuggested"
            :key="`card_${i}`"
            class="col-6 p-1"
            @click="updateSelected(promise.id)"
            @keyup.enter="updateSelected(promise.id)"
          >
            <PromiseCard :item="promise" :isSelected="isSelected(promise.id)" />
          </div>
        </div>
        <div v-else>
          <p class="text-center custom p-5">Non ci sono movimenti in attesa</p>
        </div>
      </div>
    </div>

    <Popup ref="popup" class="md">
      <template #fullContent> popup </template>
    </Popup>

    <Snackbar :text="snackbarText" ref="snackbar" />
  </div>
</template>

<script>
import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';
import chevronLeft from '@/assets/images/chevron-circle-left-solid.svg';
import chevronRight from '@/assets/images/chevron-circle-right-solid.svg';

import Button from '@/views/components/Button.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Popup from '@/views/components/Popup.vue';
import PromiseCard from '@/views/components/PromiseCard.vue';
import Snackbar from '@/views/components/Snackbar.vue';
import { Carousel3d, Slide } from 'vue-carousel-3d';

import { toFormattedDate } from '@/helpers/dates.js';
import formatMovementTypeFilter from '@/filters/formatMovementType';

export default {
  components: {
    'custom-button': Button,
    Carousel3d,
    Slide,
    CellContent,
    Loader,
    Popup,
    PromiseCard,
    Snackbar,
  },
  filters: {
    movement: formatMovementTypeFilter,
  },
  watch: {
    $route: function () {
      if (!this.isLoadingPage) {
        this.getPromises();
      }
    },
    suggested () {
      if (this.suggested) {
        this.setSelected(this.suggested.id);
      }
    },
    promisesSearchInput () {
      this.setFilters();
    },
  },
  computed: {
    userInStage () {
      const me = this.$oauth2.me;
      return (
        me !== undefined &&
        me.email !== undefined &&
        me.demo !== undefined &&
        me.demo
      );
    },
    promisesNoSuggested () {
      if (this.suggested) {
        return this.promises.filter(
          (promise) => promise.id !== this.suggested.id,
        );
      } else {
        return this.promises;
      }
    },
    matchAffinity () {
      return this.movements[this.currentSlideIndex]?.hintLevel || null;
    },
    totalMovementsAmount () {
      let total = 0;
      this.selectedPromises.map((obj) => {
        total = total + obj.amount;
      });
      return total;
    },
  },
  data () {
    return {
      companyId: this.$oauth2.me.companies[0].id || null,

      isLoadingPage: true,
      isLoadingPage2: true,
      updating: false,
      loadSuggested: true,
      loading: false,
      snackbarText: '',

      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
      chevronLeft: chevronLeft,
      chevronRight: chevronRight,

      movements: [],
      slides: 10,
      currentSlideIndex: 0,

      promises: [],
      selectedPromises: [],
      suggested: null,
      descriptionHover: false,

      promisesSearchInput: this.$route.query.search || null,
    };
  },
  methods: {
    setFilters: function () {
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { search: this.promisesSearchInput }),
      })
        .catch(() => {})
        .finally(() => {});
    },
    downloadTransactionsDemo () {
      this.$api.downloadTransactionsDemo(this.companyId).then(() => {
        this.getMovements();
        this.getPromises();
      });
    },
    getMovements () {
      this.$api
        .getOpenBankingMovements(this.companyId, { all: true })
        .then((res) => {
          const nextSlideIndex = 0;
          this.movements = res.data.movements || [];
          this.slides = this.movements.length;

          this.updatecurrentSlide(nextSlideIndex);
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.isLoadingPage = false;
          this.setSuggested();
        });
    },
    getPromises () {
      const filters = this.$route.query;
      this.$api
        .fetchMatchingPromisesDetails(this.companyId, filters, { all: true })
        .then((res) => {
          this.promises = res.data.promises || [];
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.isLoadingPage2 = false;
          this.setSuggested();
        });
    },
    isSelected (promiseId) {
      return Boolean(this.selectedPromises.find((obj) => obj.id === promiseId));
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    setSelected (promiseId) {
      const promise = this.promises.find((obj) => obj.id === promiseId);
      this.$log.debug('this.promises');
      this.$log.debug(this.promises);
      if (this.selectedPromises.find(element => element.id === promiseId)) {
        return;
      }
      this.selectedPromises.unshift(promise);
      this.$log.debug('01', promiseId);
      this.$log.debug('02', promise.id);
    },
    removeSelectedPromises () {
      this.selectedPromises = [];
    },
    updateSelected (promiseId) {
      if (this.isSelected(promiseId)) {
        // remove from array
        const foundIndex = this.selectedPromises.findIndex(
          (obj) => obj.id === promiseId,
        );
        this.selectedPromises.splice(foundIndex, 1);
      } else {
        // add to array
        const promise = this.promises.find((obj) => obj.id === promiseId);
        if (!this.selectedPromises.includes(promise)) {
          this.selectedPromises.unshift(promise);
        }
      }
    },
    updatecurrentSlide (i) {
      this.removeSelectedPromises();
      this.currentSlideIndex = i;
      this.setSuggested();
    },
    moveCarousel (back = false) {
      if (back) {
        const i =
          this.currentSlideIndex === 0
            ? this.movements.length
              ? this.movements?.length - 1
              : this.currentSlideIndex
            : this.currentSlideIndex - 1;
        this.$refs.carousel.goSlide(i);
      } else {
        this.$refs.carousel.goSlide(this.currentSlideIndex + 1);
      }
    },
    onKeyDown ($event) {
      // this.$log.debug($event);
      if (this.movements.length > 0) {
        if ($event.key === 'ArrowLeft') {
          this.moveCarousel(true);
        }
        if ($event.key === 'ArrowRight') {
          this.moveCarousel();
        }
      }
    },
    onSubmit () {
      this.updating = true;
      this.loading = true;
      const selectedPromiseIds = this.selectedPromises.map(
        (promise) => promise.id,
      );
      this.$api
        .createMatch(
          this.companyId,
          selectedPromiseIds,
          this.movements[this.currentSlideIndex].id,
        )
        .then((res) => {
          this.getMovements();
          this.getPromises();
          this.snackbarText = 'Movimento riconciliato correttamente';
          this.removeSelectedPromises();
          this.$refs.snackbar.openSnackbar();
          this.$root.$emit('updateNotifications');
          this.promisesSearchInput = '';
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.loading = false;
          this.updating = false;
        });
    },
    setSuggested () {
      this.$log.debug('1');
      this.loadSuggested = true;
      this.suggested = null;
      const id = this.movements[this.currentSlideIndex]?.hintPromiseId;
      if (id) {
        this.$log.debug('2');
        let foundPromise = this.promises.filter((promise) => promise.id === id);
        this.$log.debug(foundPromise);
        if (!foundPromise.length) {
          this.$log.debug('3');
          this.promises.unshift(
            this.movements[this.currentSlideIndex].hintPromise,
          );
          foundPromise = this.promises.filter((promise) => promise.id === id);
        }
        this.suggested = foundPromise[0] || null;
      }
      this.loadSuggested = false;
    },
  },
  mounted () {
    this.getMovements();
    this.getPromises();
    document.addEventListener('keyup', this.onKeyDown);
  },
};
</script>

<style lang="scss">
$height-header: 40px;

.matching {
  background: $primary-lighter;
  max-height: 100vh;
  overflow: hidden;
  .custom-container {
    max-height: auto;
    overflow: hidden;
  }
  .custom-container-bottom {
    height: auto;
    max-height: 35vh;
    overflow: scroll;
    overflow-x: hidden;
  }
  .custom-height {
    height: 400px !important; // height of carousel
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .carousel-3d-container {
    overflow: visible;
  }
  .carousel-3d-slide {
    background: white;
    color: $primary;
    border-radius: 10px;
    box-shadow: $box-shadow;
    margin-bottom: 30px;
    border: none;
    &.left-1 {
      transform: translateX(-300px) translateZ(-120px) rotateY(-27deg) !important;
      box-shadow: none;
      filter: blur(0.03rem);
      &::before {
        content: "";
        display: block;
        position: absolute;
        margin: 0;
        background: #eff0f4;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        opacity: 0.2;
      }
    }
    &.right-1 {
      transform: translateX(300px) translateZ(-120px) rotateY(27deg) !important;
      box-shadow: none;
      filter: blur(0.03rem);
      &::before {
        content: "";
        display: block;
        position: absolute;
        margin: 0;
        background: #eff0f4;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        opacity: 0.2;
      }
    }
    &.left-2 {
      transform: translateX(-540px) translateZ(-340px) rotateY(-50deg) !important;
      box-shadow: none;
      filter: blur(0.05rem);
      &::before {
        content: "";
        display: block;
        position: absolute;
        margin: 0;
        background: #eff0f4;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        opacity: 0.5;
      }
    }
    &.right-2 {
      transform: translateX(540px) translateZ(-340px) rotateY(50deg) !important;
      box-shadow: none;
      filter: blur(0.05rem);
      &::before {
        content: "";
        display: block;
        position: absolute;
        margin: 0;
        background: #eff0f4;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        opacity: 0.5;
      }
    }
  }

  .fade {
    position: sticky;
    bottom: 0;
    height: 120px;
    background: linear-gradient(
      180deg,
      rgba(239, 240, 244, 0) 0%,
      #eff0f4 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    transition: all 0.5 ease-in;
    &.opaque > * {
      opacity: 0 !important;
      transition: all 0.5 ease-in;
    }
  }
  .custom-button {
    position: relative;
    margin: auto;
    pointer-events: auto;
    transition: all 0.5 ease-in;
  }

  .prev {
    span {
      display: none;
    }
    &::after {
      content: "";
      display: block;
      background: url(~@/assets/images/chevron-circle-left-solid.svg) no-repeat;
      height: 30px;
      width: 30px;
      opacity: 0.4;
    }
    &:hover::after {
      opacity: 1;
    }
  }

  .next {
    span {
      display: none;
    }
    &::after {
      content: "";
      display: block;
      background: url(~@/assets/images/chevron-circle-right-solid.svg) no-repeat;
      height: 30px;
      width: 30px;
      opacity: 0.4;
    }
    &:hover::after {
      opacity: 1;
    }
  }

  .current-slide {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $primary;
    opacity: 0.8;
    font-size: 14px;
  }

  .dark-box {
    padding: 1px 7px;
    min-width: 23px;
    text-align: center;
    background: $primary;
    color: white;
    font-weight: 600;
    border-radius: 5px;
  }
  .header {
    height: $height-header;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding: 8px;
    border-radius: 10px 10px 0 0;
  }
  .body {
    box-sizing: border-box;
    height: calc(100% - $height-header);
    padding: 15px;
    font-size: 15px;
    overflow: hidden;
  }
}
</style>
