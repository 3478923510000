<template>
    <div class="confirm-user-subscription">
      <div class="container-fluid">
        <div class="row mt-5">
          <div class="col-12 d-flex justify-content-center">
            <div class="logo">
              <img :src="logo" alt="NinaFin logo">
            </div>
          </div>
        </div>
        <div class="pretty-card my-4 text-center">

          <div class="row mt-2">
            <div v-if="isLoadingPage">
              <Loader/>
            </div>
            <div v-else-if="showError" style="margin: auto;">
              <img :src="errorImg" alt="" class="mb-4">
              <h4 class="text-center mb-4">C'è un problema :(</h4>
              <p class="text-center mb-4">
                Il link è scaduto o è già stato usato.
              </p>
              <router-link :to="{ name: 'login'}" tag="button" class="btn btn-fin">
                Vai al login
              </router-link>
            </div>
            <div v-else style="margin: auto;">
              <img :src="successImg" alt="" class="mb-4">
              <h4 class="text-center mb-4">Complimenti!</h4>
              <p class="text-center mb-4">
                Il suo account è ora attivo.
              </p>
              <router-link :to="{ name: 'login'}" tag="button" class="btn btn-fin">
                Vai al login
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Loader from '@/views/components/Layout/Loading.vue';
import logo from '@/assets/images/fin-big-logo.svg';
import successImg from '@/assets/images/account-activation-done.svg';
import errorImg from '@/assets/images/account-activation-error.svg';

export default {
  components: {
    Loader,
  },
  data () {
    return {
      showError: false,
      isLoadingPage: true,
      loading: false,
      logo: logo,
      successImg: successImg,
      errorImg: errorImg,
    };
  },
  methods: {
    confirmUserSubscription () {
      this.isLoadingPage = true;
      this.$api.confirmUserSubscription(this.$route.params.email, this.$route.params.token)
        .then((res) => {
          this.$log.debug(res.data);
        })
        .catch((e) => {
          this.showError = true;
          this.$log.error(e);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
  },
  mounted () {
    this.confirmUserSubscription();
  },
};
</script>
<style lang="scss">
  .confirm-user-subscription {
    background-image: url(~@/assets/images/fin-bg.png);
    background-position: center;
    background-color: $primary-lighter;
    align-items: center;
    .container-fluid {
      max-width: 968px;
    }
  }
</style>
