<template>
  <div class="recovery-form">
    <form v-if="!emailConfirmed" @submit.prevent="onSubmit" class="text-left">
      <h2 class="h4 mb-4">Recupera la tua password</h2>
      <p class="mb-4">Ti manderemo un link per reimpostare la tua password</p>

      <b-form-group
        class="mb-4"
        id="input-group-email"
        label="Indirizzo email"
        label-for="input-email"
        :class="{ invalid: !(!showEmailError || isValidEmail) }"
      >
        <b-form-input
          id="input-email"
          v-model="recoveryEmail"
          @blur="setFeedbackVisibility"
          type="email"
        ></b-form-input>
        <b-form-invalid-feedback :state="!showEmailError || isValidEmail">
          <i class="fas fa-exclamation-circle"></i>
          L'indirizzo email inserito non è valido
        </b-form-invalid-feedback>
        <b-form-valid-feedback :state="!showEmailError || isValidEmail">
          <i class="fas fa-exclamation-circle"></i>
          L'indirizzo email inserito non è valido
        </b-form-valid-feedback>
      </b-form-group>

      <custom-button
        label="Recupera"
        class="w-100"
        @click.prevent.native="onSubmit"
        type="submit"
        :isLoading="loading"
      />

      <ErrorCard v-if="errorMessage">
        <template #message>
          {{ errorMessage }}
        </template>
      </ErrorCard>
    </form>

    <div v-if="emailConfirmed">
      <!--todo-->
      <div class="text-left message">
        <h2 class="mb-3">Controlla la tua e-mail</h2>
        <div class="subtitle m-0">
          Abbiamo mandato un'email con un link per resettare la tua password!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorCard from '@/views/components/ErrorCard.vue';
import Button from '@/views/components/Button.vue';

import { BFormGroup, BFormInput, BFormInvalidFeedback, BFormValidFeedback } from 'bootstrap-vue';

export default {
  components: {
    ErrorCard,
    'custom-button': Button,
    'b-form-group': BFormGroup,
    'b-form-input': BFormInput,
    'b-form-invalid-feedback': BFormInvalidFeedback,
    'b-form-valid-feedback': BFormValidFeedback,
  },
  props: {
    email: String,
  },
  data () {
    return {
      errorMessage: '',
      showEmailError: false,
      invalidInput: false,
      emailConfirmed: false,
      loading: false,
    };
  },
  computed: {
    recoveryEmail: {
      get () {
        return this.email;
      },
      set (newEmailValue) {
        this.$emit('email', newEmailValue);
      },
    },
    isValidEmail () {
      return this.validateEmail();
    },
  },
  methods: {
    validateEmail: function () {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.recoveryEmail);
    },
    onSubmit: function () {
      this.invalidInput = false;
      if (this.isValidEmail) {
        this.loading = true;

        this.$api
          .getChangePasswordToken(this.email)
          .then(() => {
            this.emailConfirmed = true;
            this.loading = false;
          })
          .catch((e) => {
            this.$log.error(e);
            if (e.response.status === 404) {
              this.showEmailError = false;
              this.errorMessage = 'Email non trovata';
              this.loading = false;
            } else {
              this.errorMessage = 'Qualcosa è andato storto, riprova tra poco.';
              this.emailConfirmed = false;
              this.showEmailError = false;
              this.loading = false;
            }
          });
      } else {
        this.showEmailError = true;
        this.invalidInput = true;
        this.emailConfirmed = false;
      }
    },
    setFeedbackVisibility: function () {
      if (this.recoveryEmail !== '' && this.recoveryEmail !== null) this.showEmailError = true;
    },
  },
};
</script>
