<template>
  <div class="in-out-chart">
    <v-chart :options="barChart" :autoresize="true" class="w-100" :key="`in_out_${update}`"/>
  </div>
</template>

<script>
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
// import { toFullHumanDate } from '@/helpers/dates.js';

export default {
  props: {
    series: Array,
    todayDate: String,
    startDate: String,
  },
  components: {
    'v-chart': ECharts,
  },
  watch: {
    series: {
      handler () {
        this.barChart.dataset.source = this.series;
      },
      deep: true,
    },
  },
  data () {
    return {
      update: 0,

      barRChart: {
        color: [
          '#00D26D',
          '#FD5050',
        ],
        backgroundColor: '#ffffff',
        line: {
          symbolSize: 4,
          symbol: 'circle',
          smooth: false,
        },
        tooltip: {
          // className: 'custom-tooltip',
          trigger: 'axis',
          backgroundColor: '#fff',
          // shadowOffsetX: 1,
          // shadowColor: '#2C3576',
          // shadowOffsetY: 2,
          borderWidth: 1,
          borderRadius: 10,
          padding: 15,
          textStyle: {
            color: '#2C3576',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: 14,
          },
          toolbox: {
            feature: {
              show: true,
              orient: 'horizontal',
              left: 'right',
              top: 'top',
              backgroundColor: 'transparent',
              borderColor: '#ccc',
              borderRadius: 0,
              borderWidth: 0,
              padding: 5,
              itemSize: 15,
              itemGap: 8,
              fontSize: 15,
              showTitle: true,
              saveAsImage: {},
            },
          },
        },
        legend: {
          data: [],
          left: 150,
          align: 'right',
          itemGap: 40,
          textStyle: {
            color: '#2C3576',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: 14,
          },
          emphasis: {
            focus: 'series',
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.1)',
            },
          },
        },
        grid: {
          left: '1%',
          right: '2%',
          containLabel: true,
        },
        xAxis: { type: 'category' },
        yAxis: {},
        series: [{ type: 'bar' }, { type: 'bar' }]
      },

      barChart: {
        color: [
          '#FF9357',
          '#5855FF',
        ],
        legend: {},
        tooltip: {},
        dataset: {
          dimensions: ['period', 'Inizio', 'Fine'],
          source: []
        },
        xAxis: { type: 'category' },
        yAxis: {},
        series: [{ type: 'bar' }, { type: 'bar' }]
      },
    };
  },
  mounted () {
    this.barChart.dataset.source = this.series;
  },
};
</script>
