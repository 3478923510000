<template>
<div v-if="brainScore !== null" class="brain-score">
    <!-- <div class="title">BrainScore&reg;</div> -->
    <div class="title">Score</div>
    <div class="battery-container">
      <div class="battery" :class="bgColour"></div>
      <div class="bar" :class="[bgColour, level]"></div>
    </div>
    <div class="score" :class="textColour">
      {{brainScore}}%
    </div>
</div>
</template>

<script>
export default {
  props: {
    brainScore: Number,
    colour: [Number, String],
  },
  computed: {
    bgColour () {
      return this.colour ? `bank-variant-${this.colour}` : 'primary';
    },
    textColour () {
      return this.colour ? `text-bank-variant-${this.colour}` : 'text-primary';
    },
    level () {
      if (this.brainScore >= 95) {
        return 'maximum';
      } else if (this.brainScore >= 80) {
        return 'very-high';
      } else if (this.brainScore >= 60) {
        return 'high';
      } else if (this.brainScore >= 50) {
        return 'medium';
      } else if (this.brainScore >= 20) {
        return 'low';
      } else if (this.brainScore >= 10) {
        return 'very-low';
      } else {
        return 'minimum';
      }
    },
  },
};
</script>

<style lang="scss">
.brain-score {
    font-weight: 600;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    .title {
      font-size: 16px;
      color: $primary;
    }
    .score {
      width: 30px;
      margin: auto;
    }
    .battery-container {
        position: relative;
        margin: 0px 5px;
        width: 68px;
        height: 18px;
        .battery {
          border-radius: 20px;
          width: 100%;
          height: 100%;
          opacity: 0.25;
        }
         .bar {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 4px;
            height: 12px;
            border-radius: 20px;
            transition: all 0.5s ease-in-out;
            &.minimum {
              width: 6px;
            }
            &.very-low {
              width: 10px;
            }
            &.low {
              width: 20px;
            }
            &.medium {
              width: 30px;
            }
            &.high {
              width: 40px;
            }
            &.very-high {
              width: 50px;
            }
            &.maximum {
              width: 60px;
            }
        }
    }
}
</style>
