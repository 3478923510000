<template>
  <div class="filters">
    <!--FILTRI-->
    <b-dropdown right variant="primary" class="api-filters" :key="`filters_${update}`">
      <template #button-content>
        <div class="d-flex justify-content-center align-items-center">
          <i class="fas fa-sliders-h mr-2"></i>
          <span class="pr-3 text-uppercase">Filtri</span>
          <div class="noti-badge-dark">
            {{countFilters}}
          </div>
        </div>
      </template>

      <div class="container-fluid">
        <div class="row d-flex justify-content-between mt-2">
          <div class="col">
            <div class="font-weight-bolder text-uppercase text-primary">
              Filtri
            </div>
          </div>
          <div class="col">
            <div class="text-right" @click="resetFilters()" role="button">
              Pulisci filtri
            </div>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <div class="row">
          <div class="col-12">
            <b-form-group>
              <label for="search-input">Cerca</label>
              <b-form-input
                id="search-input"
                v-model="filters.searchInput"
                placeholder="Numero fattura, fornitore o descrizione"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <b-form-group>
              <label for="checkbox-group">Saldo</label>
              <b-form-checkbox-group
                id="checkbox-group"
                v-model="filters.selectedTypes"
                :options="types"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <label class="p-0 m-0">Importo</label>
            <div class="row">
              <div class="col">
                <b-form-group class="w-100">
                  <CurrencyInput id="min-amount" v-model="filters.minAmount" />
                  <label for="min-amount">Min</label>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group class="w-100">
                  <CurrencyInput id="max-amount" v-model="filters.maxAmount" />
                  <label for="max-amount">Max</label>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdown, BFormCheckboxGroup, BFormGroup, BFormInput } from 'bootstrap-vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';

export default {
  components: {
    BDropdown,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    CurrencyInput,
  },
  data () {
    return {
      update: 0,

      types: [
        { text: 'Saldate', value: 'saldata' },
        { text: 'Da saldare', value: 'da saldare' },
      ],

      filters: {
        selectedTypes: this.$route.query.types || [],
        searchInput: this.$route.query.search || undefined,
        minAmount: this.$route.query.minAmount || undefined,
        maxAmount: this.$route.query.maxAmount || undefined,
      },
    };
  },
  watch: {
    watchedFilters: function () {
      this.setFilters();
    },
  },
  computed: {
    watchedFilters () {
      return this.filters.selectedTypes + this.filters.minAmount + this.filters.maxAmount + this.filters.searchInput;
    },
    countFilters: function () {
      return this.filters.selectedTypes?.length + Boolean(this.filters.searchInput) + Boolean(this.filters.minAmount) + Boolean(this.filters.maxAmount);
    },
  },
  methods: {
    resetFilters: function () {
      this.filters.searchInput = undefined;
      this.filters.selectedTypes = [];
      this.filters.minAmount = undefined;
      this.filters.maxAmount = undefined;
      this.update++;
    },
    setFilters: function () {
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { search: this.filters.searchInput, types: this.filters.selectedTypes, minAmount: this.filters.minAmount, maxAmount: this.filters.maxAmount }),
      })
        .catch(() => {});
    },
    /*
    updateFilters () {
      this.filters.searchInput = this.$route.query.search ? this.$route.query.search : undefined;
      this.filters.selectedTypes = this.$route.query.types ? this.$route.query.types : [];
      this.filters.minAmount = this.$route.query.minAmount ? this.$route.query.minAmount : undefined;
      this.filters.maxAmount = this.$route.query.maxAmount ? this.$route.query.maxAmount : undefined;
    },
    */
  },
  mounted () {
    // this.updateFilters();
  },
};
</script>

<style lang="scss">
.filters {
  background: $primary;
  border-radius: 10px;
  max-width: 200px;
  .custom-checkbox {
    margin: 5px 30px 10px 0px;
    label {
      font-weight: 400;
      font-size: 16px;
    }
  }
}
.dropdown.api-filters {
  .dropdown-toggle {
    padding: 8px 10px;
    letter-spacing: 1px;
    font-size: 14px!important;
    .noti-badge-dark {
      background: rgba($primary-light, 0.3);
      height: 20px;
      min-width: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      border-radius: 5px;
    }

    &:hover {
      border-color: $primary-dark !important;
    }
  }

  button::after {
    display: none;
  }

  .form-group {
    margin: 10px 0px;
    position: relative;
  }

  .dropdown-menu {
    min-width: 500px;
    border-radius: 10px;
    margin-top: 10px;

    fieldset {
      legend {
        text-align: center;
        color: $primary;
        opacity: 0.8;
        font-weight: 700;
      }
    }

    .filter-group {
      display: flex;

      .custom-control {
        margin-right: 35px;
        display: inline-flex;

        &:last-child {
          margin-right: 0;
        }

        .custom-control-label {
          font-weight: 400;
          line-height: 1;
        }

        input {
          margin-right: 10px;
        }
      }

      &.date {
        > div {
          width: 100%;
        }
      }

      &.column-group {
        .custom-control {
          margin-right: 0;
          margin-bottom: 10px;
          display: block;

          &:last-child {
          margin-bottom: 0;
        }
        }
      }
    }

    .b-form-tags {
      border-radius: 10px;
      padding: 10px;

      input {
        font-size: 16px;
      }

      .b-form-tags-list {
        .badge {
          text-transform: uppercase;
          color: $primary;
          background: rgba(39, 36, 83, 0.15);
          font-size: 14px;
          font-weight: 700;
          padding: 5px 7px;
          border-radius: 5px;
          letter-spacing: 1px;

          button {
            border: none;
            background: none;
            font-weight: 500;
            margin: 0;
            margin-left: 5px;
            padding: 0;
            align-self: center;
          }
        }
      }
    }
  }
}
</style>
