<template>
  <div class="users-settings">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h5 class="mb-0">Utenti</h5>
      <button class="btn btn-fin btn-primary" @click="togglePopup('upsertUser')">
        Aggiungi utente
      </button>
    </div>
    <div v-if="users?.length > 0" class="row">
      <div v-for="(user, i) in users" :key="`${i}_${user}_${update}`" class="col-4 mb-4">
          <div class="box px-0 primary h-100" :class="{'current-user': user.role === 'owner'}">
            <div class="container-fluid">
              <div class="row">
                <div class="mb-3" :class="[user.role === 'owner' ? 'col-12' : 'col-6']">
                  <label class="w-100 mb-1">Utente</label>
                  <span>
                    {{user.name ? user.name : '-'}} {{user.surname}}
                  </span>

                  <span class="light-tag" v-if="user.role === 'owner'">
                    Tu
                  </span>
                </div>
                <div class="col-6" v-if="user.role !== 'owner'">
                  <div class="buttons" v-if="user.role !== 'owner'">
                    <div class="d-flex justify-content-end">
                      <button class="btn btn-primary btn-fin-square mr-2" @click="selectedUser = user; togglePopup('upsertUser')"><i class="fas fa-pen"></i></button>
                      <button class="btn btn-danger btn-fin-square" @click="selectedUser = user; togglePopup('deleteUser')"><i class="fas fa-trash"></i></button>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label class="w-100 mb-1">E-mail</label>
                  <span>
                    {{user.email}}
                  </span>
                </div>
                <div class="col-12 mb-3">
                  <label class="w-100 mb-1">Ruolo</label>
                  {{getRole(user.role)}}
                </div>
                <div class="col-12">
                  <label class="w-100 mb-1">Stato</label>
                  <div :class="{'light-tag': !user.active }">
                    {{user.active ? 'Attivo' : 'In attesa di conferma'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>

    <Popup class="lg" @beforeClosing="selectedUser = null" @closePopup="togglePopup('upsertUser')" ref="upsertUser">
      <template #fullContent>
        <UpsertCompanyUser
          :options="options"
          @onSaved="onSavedUser"
          :user="selectedUser"
          :companyId="companyId"
        />
      </template>
    </Popup>

    <Popup @beforeClosing="selectedUser = null; error = null;" ref="deleteUser" class="md" :errorMessage="error">
        <template #title>Sei sicuro di voler eliminare?</template>
        <template #text>
          <span v-if="selectedUser.active">
            L'utente {{selectedUser.name}} {{selectedUser.surname}} {{selectedUser.email}} verrà eliminato.
          </span>
          <span v-else>
            L'invito per {{selectedUser.email}} verrà eliminato.
          </span>
        </template>
        <template #button>
            <custom-button
            :isLoading="loading"
            label="Elimina"
            class="w-100 mt-3"
            @click.prevent.native="selectedUser.active ? deleteUser() : deleteUserReservation()"
            />
        </template>
    </Popup>
  </div>
</template>

<script>
import Button from '@/views/components/Button.vue';
import Popup from '@/views/components/Popup.vue';
import UpsertCompanyUser from '@/views/components/Forms/Profile/UpsertCompanyUser.vue';
import md5 from 'md5';

export default {
  props: {
    users: Array,
  },
  components: {
    UpsertCompanyUser,
    'custom-button': Button,
    Popup,
  },
  computed: {
    changes () {
      // to do
      return true;
    },
    isValidForm () {
      return this.changes;
    },
  },
  watch: {
    users (oldVal, newVal) {
      this.oldUsers = JSON.parse(JSON.stringify(oldVal));
    },
  },
  data () {
    return {
      companyId: this.$oauth2.me.companies[0].id || null,
      loading: false,
      snackbarText: '',
      error: '',
      update: 0,

      oldUsers: [],

      selectedUser: null,
      options: [
        { text: 'Proprietario', value: 'owner', disabled: true },
        { text: 'Manager', value: 'manager' },
        { text: 'Visitatore', value: 'visitor' },
      ],
      form: {
        email: '',
        role: '',
      },

      showErrorEmail: false,
      showErrorPermissions: false,
    };
  },
  methods: {
    onSavedUser (msg) {
      this.$log.debug(msg);
      this.togglePopup('upsertUser');
      this.$emit('openSnackbar', msg);
      this.$emit('getCompany');
    },
    deleteUserReservation () {
      this.loading = true;
      this.$log.debug(this.selectedUser);
      this.$api.declinesRegisterUser(md5(this.selectedUser.email), this.selectedUser.token)
        .then(() => {
          this.$emit('getCompany');
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
          this.togglePopup('deleteUser');
        });
    },
    deleteUser () {
      this.loading = true;
      this.$log.debug(this.selectedUser);
      this.$api.deleteCompanyUser(this.companyId, this.selectedUser.id)
        .then(() => {
          this.$emit('getCompany');
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
          this.togglePopup('deleteUser');
        });
    },
    getRole (role) {
      if (role === 'owner') return 'Proprietario';
      if (role === 'manager') return 'Manager';
      if (role === 'visitor') return 'Visitatore';
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
  },
};
</script>

<style lang="scss">
.light-tag {
  border: none;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  padding: 1px 7px;
  margin: 0;
  gap: 10px;
  border-radius: 5px;
  width: fit-content;
  background: $primary-mid;
  color: white;
  opacity: 1;
  border: none;
  white-space: nowrap;
  cursor: default;
}

.flex-gap {
  --gap: 10px;
  display: inline-flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));
  & > * {
    margin: var(--gap) 0 0 var(--gap);
  }
}

.darker-bg {
  background: rgba($primary-light, 0.7)!important;
}

.users-settings {
  .dropdown {
    .dropdown-menu {
        border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
          outline: none;
      }
      .red > * {
        color: $danger !important;
      }
      .dropdown-item {
          color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
            outline: none;
        }
        &:active {
            background: $primary-light;
        }
      }
    }
  }
}

.current-user {
  border: 2px solid rgba($primary, .15);
}
</style>
