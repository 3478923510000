<template>
  <main class="page-password-change page--v-center">
    <ResetPasswordChangeForm class="card-body" />
  </main>
</template>

<script>

export default {
  name: 'page-password-change',
  components: {
    ResetPasswordChangeForm: () => import('../../components/Account/ResetPasswordChangeForm'),
  },
};

</script>
