import $localStorage from '../services/localStorage';
import $sessionStorage from '../services/sessionStorage';

let useSessionStorage = false;

export default {
  install (Vue, options) {
    const calcOptions = Object.assign({ useSessionStorage: false }, options);
    useSessionStorage = calcOptions.useSessionStorage;

    Vue.localStorage = $localStorage;
    Vue.sessionStorage = $sessionStorage;
    Vue.storage = calcOptions.useSessionStorage ? Vue.sessionStorage : Vue.localStorage;

    Object.defineProperties(Vue.prototype, {
      $localStorage: {
        get: () => Vue.localStorage,
      },
      $sessionStorage: {
        get: () => Vue.sessionStorage,
      },
      $storage: {
        get: () => Vue.storage,
      },
    });
  },
  _getService: () => useSessionStorage ? $sessionStorage : $localStorage,
};
