<template>
  <div class="tax-box-form">
    <h5 class="mb-4">Cassetto fiscale</h5>
    <div v-if="taxBoxHasBeenSetup" class="text-center">
      <p>Il cassetto fiscale è stato configurato correttamente!</p>
      <p class="mb-4">Puoi cancellare il collegamento con il cassetto fiscale in qualsiasi momento.
        I tuoi dati forniti in fase di setup verranno eliminati in modo definitivo.
      </p>
      <div class="row mt-5">
        <div class="col-6 ml-auto">
          <custom-button
            :isLoading="loading"
            label="Elimina associazione"
            class="w-100 "
            @click.prevent.native="$refs['unlinkTaxBox'].togglePopup()"
            />
        </div>
      </div>
    </div>
    <div v-else>
      <p class="mb-4">Guarda il <span @click="$refs['taxBoxTutorial'].togglePopup()" style="font-weight: bold; cursor: pointer;">tutorial</span> per recuperare il pin richiesto nel form.</p>
      <div class="row">
        <div class="col-6">
          <b-form-group
            class="px-0"
            id="input-group-fiscalCode"
            label="Codice Fiscale"
            label-for="input-fiscalCode"
            :class="{ invalid: !isValidFiscalCode && showErrorFiscalCode }"
          >
            <b-form-input
              id="input-fiscalCode"
              v-model="form.fiscalCode"
              type="text"
              @input="resetError()"
              @blur="showErrorFiscalCode = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidFiscalCode || !showErrorFiscalCode }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un codice fiscale valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            class="mb-4 px-0"
            id="input-group-pin"
            label="Pin"
            label-for="input-pin"
            :class="{ invalid: !isValidPin && showErrorPin }"
          >
            <b-form-input
              id="input-pin"
              v-model="form.pin"
              @input="resetError()"
              type="text"
              autocomplete="off"
              @blur="showErrorPin = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidPin || !showErrorPin }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un pin valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group
            class="mb-4 px-0"
            id="input-group-password"
            label="Password"
            label-for="input-password"
            :class="{ invalid: !isValidPassword && showErrorPassword }"
          >
            <b-form-input
              id="input-password"
              v-model="form.password"
              type="password"
              autocomplete="off"
              @input="resetError()"
              @blur="showErrorPassword = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidPassword || !showErrorPassword }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una password valida
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4 ml-auto">
          <custom-button
            :isLoading="loading"
            :class="{disabled: !isValidForm}"
            label="Invia"
            :size="setup ? 'md' : ''"
            class="w-100"
            @click.prevent.native="onSubmit"
          />
        </div>
      </div>

      <Popup ref="taxBoxTutorial" class="xl">
        <template #fullContent>
          <video-player :options="videoOptions" class="px-5 py-4"/>
          <div class="row justify-content-md-center">
            <div class="col-8 mb-3 demo-player">
            </div>
          </div>
        </template>
      </Popup>

    </div>

    <Popup ref="successPopup" class="md">
      <template #title> Cassetto Fiscale Attivato! </template>
      <template #text>
        <p>È in corso l'importazione delle fatture dal cassetto fiscale.<br>
          Aggiorna la pagina fra qualche minuto per vedere le fatture caricate nella sezione dedicata
        </p>
      </template>
      <template #button>
        <custom-button
          :isLoading="loading"
          label="Ok"
          class="w-100 mt-4"
          @click.prevent.native="$refs['successPopup'].close()"
        />
      </template>
    </Popup>

    <Popup ref="unlinkTaxBox" class="md" :errorMessage="errorMessage" @beforeClosing="errorMessage = ''">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
          Il pin e la password forniti in precedenza verranno eliminati in modo permanente.
      </template>
      <template #button>
        <custom-button
          :isLoading="loading"
          label="Elimina"
          class="w-100 mt-4"
          @click.prevent.native="unlinkTaxBox()"
        />
      </template>
    </Popup>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled } from '@/helpers/formValidation.js';
import successImg from '@/assets/images/illustration.svg';

import Button from '@/views/components/Button.vue';
import Popup from '@/views/components/Popup.vue';

import VideoPlayer from '@/libs/VideoPlayer/VideoPlayer.vue';

export default {
  props: {
    companyId: String,
    setup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    'custom-button': Button,
    Popup,
    VideoPlayer,
  },
  data () {
    return {
      loading: false,
      successImg: successImg,

      showErrorFiscalCode: false,
      showErrorPin: false,
      showErrorPassword: false,

      taxBoxHasBeenSetup: false,
      form: {
        fiscalCode: '',
        pin: '',
        password: '',
      },
      errorMessage: '',

      videoOptions: {
        pictureInPictureToggle: false,
        volumePanel: false,
        autoplay: false,
        loop: true,
        controls: true,
        fluid: true,
        language: 'it',
        sources: [
          {
            src: 'video/tutorial-cassetto-fiscale2.mp4',
            type: 'video/mp4',
          },
        ],
      },
    };
  },
  computed: {
    isValidForm () {
      return this.isValidFiscalCode && this.isValidPin && this.isValidPassword;
    },
    isValidFiscalCode () {
      return isFilled(this.form.fiscalCode);
    },
    isValidPin () {
      return isFilled(this.form.pin);// && isNumber(this.form.pin);
    },
    isValidPassword () {
      return isFilled(this.form.password);
    },
  },
  methods: {
    unlinkTaxBox () {
      this.loading = true;
      this.$api.deleteFlowPayOnboarding(this.companyId)
        .then(() => {
          this.$refs.unlinkTaxBox.close();
          this.$emit('openSnackbar', 'Cassetto Fiscale Eliminato!');
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.loading = false;
          this.getFlowPayOnboardedFlag();
        });
    },
    getFlowPayOnboardedFlag () {
      // this.loading = true;
      this.$api.getCompany(this.companyId)
        .then((res) => {
          const company = res.data;
          this.taxBoxHasBeenSetup = company.flowPayOnboarded;
          this.$emit('completed', true);
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetError () {
      this.errorMessage = '';
    },
    onSubmit () {
      this.resetError();
      if (this.isValidForm) {
        this.loading = true;
        this.submit();
      } else {
        this.loading = true;
        if (!this.isValidFiscalCode) {
          this.showErrorFiscalCode = true;
        } else if (!this.isValidPin) {
          this.showErrorPin = true;
        } else if (!this.isValidPassword) {
          this.showErrorPassword = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    submit () {
      this.$api.flowPayOnboarding(this.companyId, this.form.fiscalCode, this.form.pin, this.form.password)
        .then((res) => {
          this.$log.debug(res);
          this.getFlowPayOnboardedFlag();
          this.$emit('openSnackbar', 'Cassetto Fiscale Attivato!');
          this.$refs.successPopup.togglePopup();
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted () {
    this.getFlowPayOnboardedFlag();
  },
};
</script>

<style lang="scss">
.tax-box-form  {
  .demo-player {
    max-width: 900px;
  }
  .video-player-popup {
    .popup-bg {
    &.md {
      .card {
        max-width: 1600px!important;
      }
    }
    &.lg {
      .card {
        max-width: 1600px!important;
      }
    }
    &.xl {
      .card {
        max-width: 1600px!important;
      }
    }
  }
  }
}
</style>
