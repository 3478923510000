<template>
  <div class="in-out-chart">
    <v-chart :options="barChart" :autoresize="true" class="w-100" :key="`in_out_${update}`"/>
  </div>
</template>

<script>
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import { toFullHumanDate } from '@/helpers/dates.js';

export default {
  props: {
    series: Array,
    groupBy: {
      type: String,
      default: '1w',
    },
    todayDate: String,
    startDate: String,
  },
  components: {
    'v-chart': ECharts,
  },
  watch: {
    series: {
      handler () {
        this.getXAxis();
        this.barChart.series = this.series;
      },
      deep: true,
    },
  },
  computed: {
    selectedBank () {
      return this.banks.find(obj => obj.id === this.selectedBankId);
    },
  },
  data () {
    return {
      update: 0,

      barChart: {
        color: [
          '#00D26D',
          '#FD5050',
        ],
        backgroundColor: '#ffffff',
        line: {
          symbolSize: 4,
          symbol: 'circle',
          smooth: false,
        },
        tooltip: {
          // className: 'custom-tooltip',
          trigger: 'axis',
          backgroundColor: '#fff',
          // shadowOffsetX: 1,
          // shadowColor: '#2C3576',
          // shadowOffsetY: 2,
          borderWidth: 1,
          borderRadius: 10,
          padding: 15,
          textStyle: {
            color: '#2C3576',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: 14,
          },
          toolbox: {
            feature: {
              show: true,
              orient: 'horizontal',
              left: 'right',
              top: 'top',
              backgroundColor: 'transparent',
              borderColor: '#ccc',
              borderRadius: 0,
              borderWidth: 0,
              padding: 5,
              itemSize: 15,
              itemGap: 8,
              fontSize: 15,
              showTitle: true,
              saveAsImage: {},
            },
          },
        },
        legend: {
          data: [],
          left: 150,
          align: 'right',
          itemGap: 40,
          textStyle: {
            color: '#2C3576',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: 14,
          },
          emphasis: {
            focus: 'series',
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.1)',
            },
          },
        },
        grid: {
          left: '1%',
          right: '2%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: [],
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
          },
        },
        series: [],
      },

    };
  },
  methods: {
    getDate (startDate, weeks) {
      const from = new Date(startDate);
      from.setHours(0, 0, 0);

      const y = new Date(from.setDate(from.getDate(from) + weeks * 7));
      y.setHours(0, 0, 0);
      return toFullHumanDate(y);
    },
    getXAxis () {
      const months = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
      const startDate = new Date(this.startDate);
      const startMonth = startDate.getMonth();
      let array = [];

      if (this.groupBy === '1w') {
        for (let i = 0; i < 52; i++) {
          array.push(this.getDate(startDate, i));
        }
      }

      if (this.groupBy === '1m') {
        array = months.map((obj, i) => months[i]);
        const items = array.splice(0, startMonth);
        array = array.concat(items);
      }

      if (this.groupBy === '3m') {
        const mod = 3;
        const remainder = startMonth % mod;
        const trimestri = months.map((obj, i) => `${months[i]}-${months[(i + mod - 1) % 12]}`);
        array = trimestri.filter((obj, i) => {
          if (i >= startMonth) return i % mod === remainder;
        });
        const array2 = trimestri.filter((obj, i) => {
          if (startMonth > i) return i % mod === remainder;
        });
        array = array.concat(array2);
      }

      this.barChart.xAxis.data = array;
      this.update++;
    },
  },
  mounted () {
    this.getXAxis();
    this.barChart.legend.data = this.banks;
    this.barChart.series = this.series;
  },
};
</script>
