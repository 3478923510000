import environmentVars from '@/config/env.js';

export default {
  get (val) {
    if (environmentVars[val]) {
      return environmentVars[val];
    }
    return null;
  },
};
