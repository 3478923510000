<template>
  <div class="layout-login">
    <div class="d-flex align-items-center justify-content-center flex-grow-1 flex-column">
      <div class="login-wrapper d-flex flex-column flex-grow-1 align-items-center">
        <div
          class="
            flex-grow-1
            d-flex
            align-items-center
            justify-content-center
            logo-wrapper
          "
        >
          <img
            :src="logo"
          />
        </div>
        <div
          class="d-none form-wrapper flex-shrink-1 d-flex justify-content-center"
        >
          <div class="form bg-white p-4">
            <router-view class="flex-grow-1 w-100 m-0 p-0" />
          </div>
        </div>
        <div class="flex-grow-1 pt-4 d-flex justify-content-center">
        </div>
      </div>
      <FinFooter />
    </div>
  </div>
</template>

<script>
// import rolesMixin from '@/mixins/roles.js';
import logo from '@/assets/images/nina-fin-logo-new.svg';

import FinFooter from '@/views/components/Layout/FinFooter.vue';

export default {
  components: {
    FinFooter,
  },
  // mixins: [rolesMixin],
  computed: {
    links () {
      return [];
    },
  },
  data () {
    return {
      logo: logo,
    };
  },
};
</script>

<style lang="scss">
$background-image: "~@/assets/images/fin-bg.png";
$background-image-2: "~@/assets/images/fin-bg-2.jpg";

$padding: 90px;

.layout-login {
  background-image: url(#{$background-image});
  background-position: center;
  background-color: $primary-lighter;

  .login-wrapper {
    // width: 100%;
    // max-width: 700px;
  }

  .form-wrapper {
    position: relative;

    .form {
      border-radius: 20px;
      box-shadow: $box-shadow;
      z-index: 3;
      width: 550px;
    }
  }

  .logo-wrapper {
    z-index: 4;
  }
}
</style>
