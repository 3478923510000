<template>
    <div class="stripe-response-from-server">
      <div class="container-fluid">
        <div class="row mt-5">
          <div class="col-12 d-flex justify-content-center">
            <div class="logo">
              <img :src="logo" alt="NinaFin logo">
            </div>
          </div>
        </div>
        <div class="pretty-card my-4 text-center">

          <div class="row mt-2">
            <div v-if="showError" style="margin: auto;">
              <img :src="errorImg" alt="" class="mb-4">
              <h4 class="text-center mb-4">C'è stato un problema :(</h4>
              <p class="text-center mb-4">
                Il pagamento non è andato a buon fine. Riprovare più tardi.
              </p>
              <router-link :to="{ name: 'checkout' }" tag="button" class="btn btn-fin">
                Vai al modulo di pagamento
              </router-link>
            </div>
            <div v-else style="margin: auto;">
              <img :src="successImg" alt="" class="mb-4">
              <h4 class="text-center mb-4">Complimenti!</h4>
              <p class="text-center mb-4">
                Il pagamento è andato a buon fine!
              </p>
              <div>
                <custom-button
                  class="btn btn-fin"
                  :isLoading="loading"
                  label="Torna all'app"
                  @click.prevent.native="goToNinafin"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import logo from '@/assets/images/nina-fin-logo-new.svg';
import successImg from '@/assets/images/account-activation-done.svg';
import errorImg from '@/assets/images/account-activation-error.svg';
import Button from '@/views/components/Button.vue';

export default {
  components: {
    'custom-button': Button,
  },
  props: {
    id: String,
  },
  data () {
    return {
      showError: null,
      logo: logo,
      successImg: successImg,
      errorImg: errorImg,
      loading: false,
    };
  },
  computed: {
    hasBeenSetup () {
      return this.$oauth2.me.companies[0].hasBeenSetup;
    },
  },
  methods: {
    goToNinafin () {
      this.loading = true;
      this.$oauth2.getMe(true)
        .finally(() => {
          this.loading = false;
          this.$router.replace({ name: 'dashboard' });
        });
    },
  },
  mounted () {
    this.waitForActions = this.$route.query.wait ? (this.$route.query.wait === 'true') : false;
    this.showError = this.$route.query.error ? (this.$route.query.error === 'true') : true;
  },
};
</script>
<style lang="scss">
  .stripe-response-from-server {
    background-image: url(~@/assets/images/fin-bg.png);
    background-position: center;
    background-color: $primary-lighter;
    align-items: center;
    .container-fluid {
      max-width: 968px;
    }
  }
</style>
