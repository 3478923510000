<template>
  <div class="checkout-form">
    <div class="custom-loader-container" v-if="loading && !isLoadingPage">
      <Loader2 class="custom-loader" />
    </div>
    <div v-if="isLoadingPage">
      <Loader />
    </div>
    <div class="container-fluid w-auto">
      <div class="row mt-5">
        <div class="col-12 d-flex justify-content-center">
          <div class="logo">
            <img :src="isWhitelabel ? customLogo : logo" alt="Company logo" :key="`logo_${isWhitelabel}`" @click="$router.push({ name: 'dashboard' })" class="clickable"/>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-4">
          <div class="pretty-card">
            <h4 class="mb-4">Il tuo ordine</h4>

            {{ selectedPeriod?.label }}
            <p>
              (scadrà il
              {{
                selectedAmounts !== null
                  ? toFormattedDate(selectedAmounts.expireAt)
                  : null
              }}
              )
            </p>
            <div class="">
              Imponibile :
              {{
                selectedAmounts !== null
                  ? selectedAmounts.taxable.toLocaleString("de", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : null
              }}
              €
            </div>
            <div class="">
              Iva {{null !== selectedAmounts ? selectedAmounts.iva : null}}% :
              {{
                selectedAmounts !== null
                  ? selectedAmounts.ivaAmount.toLocaleString("de", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : null
              }}
              €
            </div>
            <div class="">
              <b>
                Totale :
                {{
                  selectedAmounts !== null                   ? selectedAmounts.total.toLocaleString("de", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : null
                }}
                €
              </b>
            </div>
          </div>
          <div class="pretty-card py-3" v-if="hasCompany">
            <router-link :to="{ name: 'dashboard' }" class="logout">
              <i class="fas fa-arrow-left pr-2"></i>
              Torna alla panoramica
            </router-link>
          </div>
          <div class="pretty-card py-3">
            <router-link :to="{ name: 'logout' }" class="logout">
              <i class="fas fa-sign-out-alt pr-2"></i>
              Log out
            </router-link>
          </div>
        </div>
        <div class="col-8">
          <div class="pretty-card">
            <div>
              <form
                ref="editInstalment"
                @submit.prevent="onSubmit"
                class="text-start"
                novalidate
              >
                <h5 class="mb-4">Dati fatturazione</h5>
                <div class="row">
                  <div class="col-4">
                    <b-form-group
                      id="input-group-denomination"
                      label="Denominazione*"
                      label-for="input-denomination"
                      :class="{
                        invalid: !isValidDenomination && showErrorDenomination,
                      }"
                    >
                      <b-form-input
                        id="input-denomination"
                        v-model="form.denominazione"
                        type="text"
                        @blur="showErrorDenomination = true"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="false"
                        :class="{
                          invisible:
                            isValidDenomination || !showErrorDenomination,
                        }"
                      >
                        <i class="fas fa-exclamation-circle"></i> Inserisci una
                        denominazione valida
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      id="input-group-fiscal-code"
                      label="Codice Fiscale*"
                      label-for="input-fiscal-code"
                      :class="{
                        invalid: !isValidFiscalCode && showErrorFiscalCode,
                      }"
                    >
                      <b-form-input
                        id="input-fiscal-code"
                        v-model="form.codice_fiscale"
                        type="text"
                        @blur="showErrorFiscalCode = true"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="false"
                        :class="{
                          invisible: isValidFiscalCode || !showErrorFiscalCode,
                        }"
                      >
                        <i class="fas fa-exclamation-circle"></i> Inserisci un
                        codice fiscale valido
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      id="input-group-iva"
                      label="Partita IVA*"
                      label-for="input-iva"
                      :class="{ invalid: !isValidIva && showErrorIva }"
                    >
                      <b-form-input
                        id="input-iva"
                        v-model="form.piva"
                        type="text"
                        @blur="showErrorIva = true"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="false"
                        :class="{ invisible: isValidIva || !showErrorIva }"
                      >
                        <i class="fas fa-exclamation-circle"></i> Inserisci una
                        partita IVA valida
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <b-form-group
                      id="input-group-address"
                      label="Indirizzo*"
                      label-for="input-address"
                      :class="{ invalid: !isValidAddress && showErrorAddress }"
                    >
                      <b-form-input
                        id="input-address"
                        v-model="form.indirizzo"
                        type="text"
                        @blur="showErrorAddress = true"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="false"
                        :class="{
                          invisible: isValidAddress || !showErrorAddress,
                        }"
                      >
                        <i class="fas fa-exclamation-circle"></i> Inserisci un
                        indirizzo valido
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col-8">
                        <b-form-group
                          id="input-group-municipality"
                          label="Comune*"
                          label-for="input-municipality"
                          :class="{
                            invalid: !isValidCity && showErrorCity,
                          }"
                        >
                          <vue-select
                            v-model="form.comune"
                            ref="select1"
                            :options="suggestions"
                            label="name"
                            @search:blur="showErrorCity = true"
                            @search="fetchSuggestions"
                            :clearable="false"
                          >
                            <template #selected-option="item">
                              {{ item.name }}
                              <div v-if="item.province">
                                ({{ item.province }})
                              </div>
                            </template>
                            <template #no-options>
                              <div class="text-left py-2 ml-3">
                                {{
                                  searchValueMunicipality &&
                                  searchValueMunicipality.length > 1
                                    ? "Nessun comune trovato"
                                    : "Inizia a scrivere..."
                                }}
                              </div>
                            </template>
                          </vue-select>
                          <b-form-invalid-feedback
                            :state="false"
                            :class="{
                              invisible: isValidCity || !showErrorCity,
                            }"
                          >
                            <i class="fas fa-exclamation-circle"></i> Inserisci
                            un comune valido
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-4">
                        <b-form-group
                          class="m-0"
                          id="input-group-cap"
                          label="CAP*"
                          label-for="input-cap"
                          :class="{ invalid: !isValidCap && showErrorCap }"
                        >
                          <b-form-input
                            id="input-cap"
                            v-model="form.cap"
                            type="text"
                            @blur="showErrorCap = true"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            :state="false"
                            :class="{ invisible: isValidCap || !showErrorCap }"
                          >
                            <i class="fas fa-exclamation-circle"></i> Inserisci
                            un CAP valido
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <b-form-group
                      id="input-group-pec"
                      label="PEC*"
                      label-for="input-pec"
                      :class="{
                        invalid:
                          (!isValidPec && showErrorPec) ||
                          (!isValidPecOrSdi && showErrorPecOrSdi),
                      }"
                    >
                      <b-form-input
                        id="input-pec"
                        v-model="form.pec"
                        type="text"
                        @blur="showErrorPec = true"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="false"
                        :class="{
                          invisible:
                            (isValidPec || !showErrorPec) &&
                            (isValidPecOrSdi || !showErrorPecOrSdi),
                        }"
                      >
                        <i class="fas fa-exclamation-circle"></i>
                        {{
                          !isValidPecOrSdi
                            ? "Inserisci PEC o codice SDI"
                            : "Inserisci un PEC valido"
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div
                    class="col-1 d-flex justify-content-center align-items-center opaque mb-3"
                  >
                    oppure
                  </div>
                  <div class="col-5">
                    <b-form-group
                      id="input-group-fiscal-code"
                      label="Codice SDI*"
                      label-for="input-fiscal-code"
                      :class="{
                        invalid:
                          (!isValidSdi && showErrorSdi) ||
                          (!isValidPecOrSdi && showErrorPecOrSdi),
                      }"
                    >
                      <b-form-input
                        id="input-fiscal-code"
                        v-model="form.codice_identificativo_sdi"
                        type="text"
                        @blur="showErrorSdi = true"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="false"
                        :class="{ invisible: isValidSdi || !showErrorSdi }"
                      >
                        <i class="fas fa-exclamation-circle"></i> Inserisci un
                        codice SDI di 7 caratteri valido
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>

            <b-form-checkbox v-if="false" v-model="showPromotionalCode" :disabled="disablePromotionalCodeInput" class="mb-3">
              Ho un codice promozionale
            </b-form-checkbox>

            <div class="row" v-if="false" v-show="showPromotionalCode">
              <b-input-group
                class="col-6"
                id="promotional-code"
                label="Codice promozionale"
                label-for="input-promotional-code"
                :class="disablePromotionalCodeInput ? 'disabled' : ''"
                :state="isAValidPromotionalCode"
              >
                <b-form-input
                  id="input-promotional-code"
                  v-model="form.promotionalCode"
                  :disabled="disablePromotionalCodeInput"
                  type="text"
                  :state="isAValidPromotionalCode"
                  @update="checkPromotionalCode()"
                  @blur="checkPromotionalCode()"
                  debounce="500"
                ></b-form-input>

                  <b-input-group-append>
                    <div class="loading-input" v-if="loadingPromotionalCodeResponse">
                      <i class="fas fa-spinner fa-spin"></i>
                    </div>
                  </b-input-group-append>

                  <b-form-invalid-feedback
                    :state="isAValidPromotionalCode"
                  >
                    <i class="fas fa-exclamation-circle"></i> {{promotionalCodeErrorMsg}}
                  </b-form-invalid-feedback>

                  <b-form-valid-feedback
                    :state="isAValidPromotionalCode"
                     style="visibility: visible"
                  >
                    <i class="fas fa-check-circle"></i> Il codice inserito è valido
                    <!--
                      TO DO
                      Hai diritto ad un periodo di prova gratuita di X giorni + aggiunt
                    -->
                  </b-form-valid-feedback>

              </b-input-group>
            </div>

                <h5 class="mt-4 mb-4">Seleziona il tipo di licenza</h5>
                <div class="row">
                  <div v-for="(item, i) in periods" :key="`item_${i}`" class="col">
                    <button class="pretty-card clickable position-relative text-left pt-4 m-0 h-100" :class="[{ 'selected': item.days === selectedPeriod.days }, {'first-choice': item.days === 365}]" @click="selectPeriod(item)" @keydown.enter="selectPeriod(item)" tabindex="0">
                      <!--div v-if="item.days === 365" class="tag custom-tag">
                        risparmia 30%
                      </div-->
                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="title text-left mb-2">
                            {{ item?.label }}
                            <div class="radio">
                              <i v-if="selectedPeriod === item" class="fas fa-check-circle"></i>
                              <i v-else class="far fa-circle"></i>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="description opaque mb-3">
                            Licenza valida {{ item.days === 365 ? '12 mesi' : '30 giorni' }}
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="d-inline font-weight-600">
                            {{ item?.taxable ? item.taxable.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null }}
                          </div>
                           €/{{ item.days === 365 ? 'anno' : 'mese' }}

                           <small v-if="item.days === 365" class="opaque">
                            ({{ item?.taxable ? (item.taxable/12).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null }}
                            €/mese)
                          </small>
                          <small class="d-block opaque">
                            Pagamento in un'unica rata
                          </small>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>

                <h5 class="mt-5 mb-4">Inserisci i dati della carta</h5>
                <div class="row">
                  <div class="col-6">
                    <StripeCard
                      v-model="validCreditCard"
                      cardId="card-element"
                      class="w-100"
                      @init="getStripe"
                      @cardInit="getCard"
                    />
                    <b-form-invalid-feedback
                      :state="false"
                      :class="{
                        invisible: isValidCreditCard || !showErrorCreditCard,
                      }"
                    >
                      <i class="fas fa-exclamation-circle"></i> Controlla i dati
                      della carta
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mb-2 mt-2">
                    <b-form-checkbox
                      v-model="autoRenewal"
                      name="check-button"
                      switch
                    >
                      Rinnovo automatico
                      <b>{{ autoRenewal ? "Attivo" : "Non attivo" }}</b>
                    </b-form-checkbox>
                  </div>
                  <div class="col-12 opaque">
                    <span
                      >Abbonamento valido fino al:
                      {{
                        nextExpirationDate
                          ? toFormattedDate(nextExpirationDate)
                          : nextExpirationDate
                      }}</span
                    >
                  </div>
                </div>
              </form>
            </div>

            <custom-button
              class="w-100 mt-5"
              :class="{ disabled: !isValidForm }"
              :isLoading="loading"
              :label="`Paga ${amountToPay} €`"
              @click.prevent.native="onSubmit"
            />

            <div class="mt-3 text-center opaque">
              <i class="fas fa-lock"></i> Pagamento sicuro con Stripe
            </div>

            <ErrorCard v-if="errorMessage">
              <template #message>
                {{ errorMessage }}
              </template>
            </ErrorCard>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import StripeCard from '@/views/components/Stripe/StripeCard';
import logo from '@/assets/images/nina-fin-logo-new.svg';

import Button from '@/views/components/Button.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Loader2 from '@/views/components/Layout/Spinner.vue';
import Select from 'vue-select';

import { BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import {
  isFilled,
  isCap,
  isIva,
  isFiscalCode,
  isAlphanumeric,
  isNumber,
  isEmail,
} from '@/helpers/formValidation.js';
import { toFormattedDate } from '@/helpers/dates.js';

export default {
  components: {
    StripeCard,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    'custom-button': Button,
    ErrorCard,
    Loader,
    Loader2,
    'vue-select': Select,
  },
  data () {
    return {
      me: this.$oauth2.me,
      errorMessage: null,
      promotionalCodeErrorMsg: null,
      isLoadingPage: true,
      loading: false,
      logo: logo,
      customLogo: null,

      showErrorFirstName: false,
      showErrorLastName: false,
      showErrorEmail: false,
      showErrorPassword: false,
      showErrorPasswordConfirm: false,

      showErrorDenomination: false,
      showErrorFiscalCode: false,
      showErrorIva: false,
      showErrorAddress: false,
      showErrorCity: false,
      showErrorCap: false,
      showErrorPec: false,
      showErrorSdi: false,
      showErrorPecOrSdi: false,
      showErrorCreditCard: false,

      suggestions: [],
      periods: [],

      selectedPeriod: null,

      oldForm: null,
      // Form
      form: {
        denominazione: '',
        codice_fiscale: '',
        piva: '',
        indirizzo: '',
        comune: '',
        cap: '',
        pec: '',
        codice_identificativo_sdi: '',
      },
      autoRenewal: true,

      // StripeCard
      validCreditCard: false,
      stripe: null,
      cardElement: null,
      paying: false,
      paymentStatus: false,
      paymentError: false,
      payment: null,

      taxableAmount: null,
      ivaAmount: null,
      nextExpirationDate: null,

      priceOptions: [],

      companyId: this.$oauth2.me?.companies[0]?.id || null, // todo modifica

      showPromotionalCode: Boolean(this.$route.query.promotionalCode),
      isAValidPromotionalCode: null,
      loadingPromotionalCodeResponse: false,
      disablePromotionalCodeInput: this.$route.query?.promotionalCode?.length > 0,
    };
  },
  watch: {
    fiscalCode () {
      if (this.isNumber(this.fiscalCode[0])) {
        this.form.piva = this.form.codice_fiscale;
      }
    },
  },
  computed: {
    selectedNextExpirationDate () {
      var date = new Date();
      date.setDate(date.getDate() + this.selectedPeriod.days);
      return date;
    },
    selectedAmounts () {
      return this.selectedPeriod ? this.periods.find(el => el.days === this.selectedPeriod.days) : null;
    },
    paymentAmount: {
      get () {
        return this.selectedAmounts ? this.selectedAmounts.total : null;
      },
      set (newVal) {
        return newVal;
      },
    },
    isValidCreditCard () {
      return this.validCreditCard;
    },
    isValidPromotionalCodeField () {
      return this.showPromotionalCode && this.isAValidPromotionalCode;
    },
    fiscalCode () {
      return this.form.codice_fiscale ?? 0;
    },
    isValidForm () {
      return (
        this.isValidDenomination &&
        this.isValidFiscalCode &&
        this.isValidIva &&
        this.isValidAddress &&
        this.isValidCity &&
        this.isValidCap &&
        this.isValidPecOrSdi &&
        this.isValidPec &&
        this.isValidSdi &&
        this.isValidCreditCard
      );
    },
    isValidDenomination () {
      return isFilled(this.form.denominazione);
    },
    isValidFiscalCode () {
      return (
        isFilled(this.form.codice_fiscale) &&
        (isFiscalCode(this.form.codice_fiscale) ||
          isIva(this.form.codice_fiscale))
      );
    },
    isValidIva () {
      return isFilled(this.form.piva) && isIva(this.form.piva);
    },
    isValidAddress () {
      return isFilled(this.form.indirizzo);
    },
    isValidCity () {
      return isFilled(this.form.comune) && isFilled(this.form.comune.name);
    },
    isValidCap () {
      return isCap(this.form.cap);
    },
    isValidPec () {
      return !isFilled(this.form.pec) || isEmail(this.form.pec);
    },
    isValidSdi () {
      return (
        !isFilled(this.form.codice_identificativo_sdi) ||
        (this.form.codice_identificativo_sdi.length === 7 &&
          isAlphanumeric(this.form.codice_identificativo_sdi))
      );
    },
    searchValueMunicipality () {
      return this.$refs.select1._data.search;
    },
    isValidPecOrSdi () {
      return (
        isFilled(this.form.pec) || isFilled(this.form.codice_identificativo_sdi)
      );
    },
    hasCompany () {
      return this.me?.companies ? this.me?.companies?.length > 0 : false;
    },
    isWhitelabel () {
      return this.hasCompany && this.me?.companies[0]?.theme !== undefined && this.me?.companies[0]?.theme !== null;
    },
    amountToPay () {
      return this.paymentAmount ? this.paymentAmount.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null;
    },
  },
  methods: {
    checkPromotionalCode () {
      this.isAValidPromotionalCode = null;
      this.loadingPromotionalCodeResponse = true;
      this.$api.checkPromotionalCode(this.form.promotionalCode)
        .then((res) => {
          this.isAValidPromotionalCode = true;
          this.$log.debug(res.data);
          this.promotionalCodeErrorMsg = '';
        })
        .catch((e) => {
          this.$log.debug(e.response.data);
          this.isAValidPromotionalCode = false;
          this.promotionalCodeErrorMsg = e.response.data?.error;
        })
        .finally(() => {
          this.loadingPromotionalCodeResponse = false;
        });
    },
    validate () {
      return this.isValidCreditCard;
    },
    getStripe (res) {
      if (res.error) {
        this.$log.error(res.error);
        return;
      }
      this.stripe = res.stripe;
    },
    getCard (res) {
      if (res.error) {
        this.$log.error(res.error);
        return;
      }
      this.$log.debug('card created');
      this.$log.debug(res.card);
      this.cardElement = res.card;
    },
    handleServerResponse (response) {
      if (response.error) {
        this.$log.debug('handle server response error');
        this.errorMessage = "Errore nel pagamento. Riprova o contatta l'assistenza se il problema persiste";

        this.paying = false;
        this.paymentError = true;
        this.$router.replace({
          name: 'checkout-response',
          query: { error: 'true' },
        });
        return;
      }

      if (response.requiresAction) {
        this.$log.debug('response requires action');

        this.stripe
          .handleCardAction(response.paymentIntentClientSecret)
          .then((result) => this.handleStripeJsResult(result))
          .catch(() => {
            this.$log.debug('handle card action error');
            this.errorMessage = "Errore nel pagamento. Riprova o contatta l'assistenza se il problema persiste";

            this.paying = false;
            this.paymentError = true;
            this.$router.replace({
              name: 'checkout-response',
              query: { error: 'true' },
            });
          });

        return;
      }

      this.$log.debug('checkout');

      this.paymentStatus = true;
      this.paying = false;
      this.payment = response;

      this.$router.replace({
        name: 'checkout-response',
        query: { error: 'false' },
        replace: true,
      });
    },
    handleStripeJsResult (result) {
      if (result.error) {
        this.paying = false;
        this.paymentError = true;
        this.$log.debug('handle stripe js error');
        this.errorMessage = "Errore nel pagamento. Riprova o contatta l'assistenza se il problema persiste";
        return;
      }

      this.loading = true;
      this.$api
        .buyALicense(
          this.$oauth2.me.id,
          this.paymentAmount,
          this.selectedPeriod.days,
          this.nextExpirationDate,
          result?.paymentIntent?.id || null,
          null,
          this.form,
          this.autoRenewal,
          this.companyId,
        )
        .then(
          (res) => {
            this.handleServerResponse(res.data);
          },
          // () => {
          //   this.$log.debug('payment success??');
          //   this.paying = false;
          //   this.paymentError = true;
          // },
        )
        .catch((e) => {
          this.$log.debug('buy a license error');
          this.$log.debug(e.response?.data?.exception?.message);
          // this.errorMessage = e.response?.data?.exception?.message;
          this.errorMessage = e.response.data.msg;

          // this.$router.replace({
          //   name: 'checkout-response',
          //   query: { error: 'true' },
          // });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit () {
      if (this.isValidForm) {
        this.paying = true;
        this.paymentError = false;

        this.stripe
          .createPaymentMethod({
            type: 'card',
            card: this.cardElement,
          })
          .then((res) => {
            this.$log.debug('call stripe handler');
            this.$log.debug(res);
            this.stripePaymentMethodHandler(res);
          })
          .catch(() => {
            this.errorMessage = "Qualcosa è andato storto. Riprova o contatta l'assistenza";
            this.paymentError = true;
            this.paying = false;
          });
      } else {
        if (!this.isValidDenomination) {
          this.showErrorDenomination = true;
        } else if (!this.isValidFiscalCode) {
          this.showErrorFiscalCode = true;
        } else if (!this.isValidIva) {
          this.showErrorIva = true;
        } else if (!this.isValidAddress) {
          this.showErrorAddress = true;
        } else if (!this.isValidCity) {
          this.showErrorCity = true;
        } else if (!this.isValidCap) {
          this.showErrorCap = true;
        } else if (!this.isValidPecOrSdi) {
          this.showErrorPecOrSdi = true;
        }
      }
    },
    stripePaymentMethodHandler (result) {
      if (result.error) {
        this.paying = false;
        this.paymentError = true;
        this.$log.debug('error in payment method handler');
        this.errorMessage = "Qualcosa è andato storto. Riprova o contatta l'assistenza";
        return;
      }

      this.$log.debug('stripe payment  ' + result);
      this.errorMessage = '';
      this.loading = true;

      if (this.isValidForm) {
        this.loading = true;

        this.$log.debug('Checkout payment - amount: ' + this.paymentAmount);
        this.$log.debug(typeof this.paymentAmount);

        this.$api
          .buyALicense(
            this.$oauth2.me.id,
            this.paymentAmount,
            this.selectedPeriod.days,
            this.nextExpirationDate,
            null,
            result?.paymentMethod?.id || null,
            this.form,
            this.autoRenewal,
            this.companyId,
          )
          .then((res) => {
            this.$log.debug('call handleServerResponse');
            this.$log.debug(res.data);
            this.handleServerResponse(res.data);
          })
          .catch((e) => {
            this.$log.debug('buy a license error');
            this.$log.debug(e.response?.data?.exception?.message);

            // this.errorMessage = e.response?.data?.exception?.message;
            this.errorMessage = e.response.data.msg;

            // this.$router.replace({
            //   name: 'checkout-response',
            //   query: { error: 'true' },
            // });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!this.isValidDenomination) {
          this.showErrorDenomination = true;
        } else if (!this.isValidFiscalCode) {
          this.showErrorFiscalCode = true;
        } else if (!this.isValidIva) {
          this.showErrorIva = true;
        } else if (!this.isValidAddress) {
          this.showErrorAddress = true;
        } else if (!this.isValidCity) {
          this.showErrorCity = true;
        } else if (!this.isValidCap) {
          this.showErrorCap = true;
        } else if (!this.isValidPec) {
          this.showErrorPec = true;
        } else if (!this.isValidSdi) {
          this.showErrorSdi = true;
        }

        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    fetchBillingData () {
      this.$api
        .getBillingInformations(this.companyId)
        .then((res) => {
          this.$log.debug(res.data.billingAddress);
          this.paymentAmount = res.data.nextPaymentAmount;
          this.taxableAmount = res.data.taxableAmount;
          this.ivaAmount = res.data.ivaAmount;
          this.nextExpirationDate = res.data.nextExpirationDate;
          this.autoRenewal = res.data.autoRenewal;
          if (res.data.billingAddress) {
            this.form = res.data.billingAddress;
            this.oldForm = JSON.parse(JSON.stringify(res.data.billingAddress));
          }
          this.periods = res.data.periods;
          this.defaultSelectedPeriodDays = res.data.defaultSelectedPeriodDays;
          this.selectedPeriod = this.periods.find(el => el.days === this.defaultSelectedPeriodDays);
          this.update++;
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
    fetchSuggestions () {
      const searchValue = this.$refs.select1._data.search;
      if (searchValue.length > 1) {
        this.$api.fetchSuggestions(searchValue)
          .then((res) => {
            this.suggestions = res.data.towns;
          })
          .catch((e) => {
            this.$log.error(e);
            this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          });
      }
    },
    selectPeriod (item) {
      this.selectedPeriod = item;
    },
    isNumber (d) {
      return isNumber(d);
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
  },
  beforeMount () {
    /* to do sostitiuire con isNotAdmin
    const me = this.$oauth2.me;
    if (!me?.product || !me?.product?.expired) {
      this.$log.debug('not expired');
      this.$router.replace('dashboard');
    }
    */
    this.fetchBillingData();
    this.customLogo = this.$oauth2.me?.companies[0]?.theme?.bigLogoUrl;
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'checkout-response' && to.name !== 'login') {
      this.$log.debug(to.name);
      if (confirm('Stai lasciando la pagina senza salvare. Vuoi continuare?') === true) {
        return next();
      } else {
        return next(false);
      }
    }

    return next();
  },
};
</script>

<style lang="scss">
.checkout {
  background-image: url(~@/assets/images/fin-bg.png);
  background-position: center;
  background-color: $primary-lighter;
  align-items: center;
  .container-fluid {
    max-width: 1200px;
    position: relative;
  }
  .stepper {
    counter-reset: counter;
    .step {
      counter-increment: counter;
      transition: all 0.5s ease-in-out;
      cursor: pointer;
      display: flex;
      justify-content: left;
      align-items: center;
      &.active {
        .name {
          font-size: 16px !important;
        }
        .dot {
          transform: scale(1.2);
          &::before {
            content: counter(counter) !important;
            font-size: 14px;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      &:nth-of-type(1)::after {
        opacity: 0;
      }
      &::after {
        content: "";
        height: 20px;
        width: 2px;
        border-radius: 1px;
        display: block;
        position: relative;
        background: $primary;
        bottom: 30px;
        left: 13px;
        order: -1;
      }
      .dot {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 24px;
        min-width: 24px;
        height: 24px;
        z-index: 1;
        font-weight: 700;
        transform: all 0.5s ease-in;
        margin-bottom: 3px;
        &::before {
          content: counter(counter);
          position: absolute;
          z-index: -1;
          background: $primary;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .name {
        color: $primary;
        margin-left: 10px;
        font-weight: 600;
      }
      &.disabled {
        opacity: 0.4;
        transition: opacity 0.5s ease-in-out;
        cursor: default;
        .dot {
          font-size: 12px;
          &::before {
            height: 24px;
            width: 24px;
          }
        }
        .name {
          font-size: 13px;
        }
      }
      &.completed {
        .dot {
          font-size: 12px;
          &::before {
            content: "";
            display: block;
            background: $primary;
          }
          &::after {
            content: url("~@/assets/images/check-icon.svg") !important;
            position: relative;
            top: 2px;
            transform: scale(0.7);
          }
        }
        .name {
          font-size: 13px;
        }
      }
    }
  }
  .logout {
    font-size: 14px;
    font-weight: 500;
    &:hover {
      text-decoration: none;
      font-weight: 600;
    }
  }
}

.selected {
  outline: 3px solid $accent!important;
  border-radius: 15px;
  .radio {
    color: $accent;
  }
}

.radio {
  font-size: 22px;
  position: absolute;
  top: -6px;
  right: 20px;
}

.custom-tag {
  position: absolute;
  bottom: -2px;
  right: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  background: $primary;
}

.first-choice::before {
  content: 'il più popolare';
  position: absolute;
  top: 0px; // same as height
  height: 25px;
  left: 0;
  background: $accent;
  color: white;
  width: 100%;
  border: 2px solid $accent!important;
  border-radius: 10px 10px 0px 0px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-form {
  .custom-loader-container {
    position: absolute;
    background: rgba(75, 75, 75, 0.26);
    z-index: 10;
    width: 100%;
    height: 100%;
    .custom-loader {
      position: relative;
      left: 50%;
      top: 50%;
    }
  }
  .text-red > * {
    color: $danger !important;
  }
  background-image: url(~@/assets/images/fin-bg.png);
  background-position: center;
  background-color: $primary-lighter;
  align-items: center;
  .container-fluid {
    max-width: 1200px;
  }
}

</style>
