import $axios from '@/libs/OAuth2/services/axios.js';
import md5 from 'md5';

const emailToMd5 = (email) => md5(email.toString().toLowerCase());

export default {
  /* Fake Call
  *  param: res [bool]
  *  return: resolved promise if resolve is true, rejected promise if resolve is false
  */
  fakeCall: (res) => {
    if (res) {
      return new Promise((resolve) => {
        setTimeout(() => resolve('fake ok created'), 1000);
      });
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error('fake error created')), 1000);
    });
  },

  getStatus: () => $axios.get('/status'),

  // Password recovery
  getChangePasswordToken: (email) =>
    $axios.post(`/users/${emailToMd5(email)}/changePasswordTokens`),
  changePasswordWithToken: (email, token, password) =>
    $axios.post(`/users/${email}/password`, { token, password }),
  changePassword: (currentPassword, newPassword, passwordConfirm) =>
    $axios.post('/users/password', {
      currentPassword: currentPassword,
      newPassword: newPassword,
      passwordConfirm: passwordConfirm,
    }),

  // My profile
  getMyProfile: () => $axios.get('/profile'),
  updateMyProfile: (user) => $axios.put('/profile', user),
  changeMyPassword: (password, newPassword) =>
    $axios.post('/profile/password', { password, newPassword }),

  /* FIN */
  // Setup
  getSetup: () => $axios.get('/fin/setup'),
  updateSetup: (form, isCompleted) =>
    $axios.put('/fin/setup', { form, isCompleted }),
  fetchSuggestions: (search) =>
    $axios.get(`/geo/towns/autocomplete?search=${search}`),
  fetchAtecoList: (search) =>
    $axios.get(`/ateco/autocomplete?search=${search}`),
  createCompany: (
    name,
    vat,
    fiscalCode,
    legalAddress,
    legalProvince,
    legalCity,
    legalCAP,
    operationalAddress,
    operationalProvince,
    operationalCity,
    operationalCAP,
  ) =>
    $axios.post('/fin/companies', {
      name,
      vat,
      fiscalCode,
      legalAddress,
      legalProvince,
      legalCity,
      legalCAP,
      operationalAddress,
      operationalProvince,
      operationalCity,
      operationalCAP,
    }),

  // Company
  getCompany: (id) => $axios.get(`/fin/companies/${id}`),

  // Bank Accounts
  createBank: (
    companyId,
    iban,
    name,
    balance,
    fido,
    colour,
    isMain,
    transferFees,
    bankDetailId,
    fabrickId = null,
    consentExpireAt = null,
  ) =>
    $axios.post(`/fin/companies/${companyId}/banks`, {
      iban,
      name,
      balance,
      fido,
      colour,
      isMain,
      transferFees,
      bankDetailId,
      fabrickId,
      consentExpireAt,
    }),
  fetchBanksList: () => $axios.get('/fin/bank-details'),
  updateBank: (id, iban, name, balance, fido, colour, isMain, transferFees, fabrickId = null) =>
    $axios.put(`/fin/banks/${id}`, {
      iban,
      name,
      balance,
      fido,
      colour,
      isMain,
      transferFees,
      fabrickId,
    }),

  // Dashboard
  fetchSaldiDashboard: (companyId) =>
    $axios.get(`/fin/companies/${companyId}/balances`),
  fetchImpegniDashboard: (companyId) =>
    $axios.get(`/fin/companies/${companyId}/promisesums`),

  // Graphs
  fetchCharts: (companyId, inOutGroupBy) =>
    $axios.get(`/fin/companies/${companyId}/graphs?inOutGroupBy=${inOutGroupBy}`),

  // Promises (Movimenti in attesa)
  createPromise: (
    companyId,
    counterpart,
    amount,
    date,
    description,
    type = null,
    bankId,
    otherData = null,
  ) =>
    $axios.post(`/fin/companies/${companyId}/promises`, {
      amount,
      counterpart,
      date,
      description,
      type,
      bankId,
      ...otherData,
    }),
  updatePromise: (id, amount, date, description, type, bankId) =>
    $axios.put(`/fin/promises/${id}`, {
      amount,
      date,
      description,
      type,
      bankId,
    }),
  deletePromise: (id) => $axios.delete(`/fin/promises/${id}`),

  // Recurring transfers
  getRecurringTransfers: (companyId, filters) =>
    $axios.get(`/fin/companies/${companyId}/recurring-transfers`, { params: filters }),
  createRecurringTransfer: (companyId, counterpart, amount, firstExpirationDate, lastExpirationDate, description, bankId, frequency, lastOfMonth, addMonthToDescription, id = null) =>
    $axios.post(`/fin/companies/${companyId}/recurring-transfers`, {
      counterpart,
      amount,
      firstExpirationDate,
      lastExpirationDate,
      description,
      bankId,
      frequency,
      lastOfMonth,
      addMonthToDescription,
      id,
    }),
  deleteRecurringTransfer: (id) =>
    $axios.delete(`/fin/recurring-transfers/${id}`),

  // Movements
  createMovement: (companyId, amount, date, description, type, bankId) =>
    $axios.post(`/fin/companies/${companyId}/movements`, {
      amount,
      date,
      description,
      type,
      bankId,
    }),
  updateMovement: (id, amount, date, description, type, bankId) =>
    $axios.put(`/fin/movements/${id}`, {
      amount,
      date,
      description,
      type,
      bankId,
    }),
  deleteMovement: (movementId) => $axios.delete(`/fin/movements/${movementId}`),

  // Fabrick Onboarding
  fabrickCheckOnBoarded: () =>
    $axios.get('/fabrick/onboard'),
  fabrickUpdateAggregation: (companyId = null) =>
    $axios.get(`fin/companies/${companyId}/fabrick/update-aggregation`),
  fabrickOnboarding: (form) =>
    $axios.post('fabrick/onboarding', { form }),
  getOpenBankingListAccounts: (companyId = null) =>
    $axios.get(`fin/companies/${companyId}/fabrick/accounts`),

  // Riconciliazione con Fabrick
  getOpenBankingMovements: (companyId, filters) =>
    $axios.get(`/fin/companies/${companyId}/openbanking-movements`, {
      params: filters,
    }),
  createMatch: (companyId, promiseIds, movementId) =>
    $axios.post(`/fin/companies/${companyId}/match`, {
      promiseIds,
      movementId,
    }),

  // fabrick fake
  importFromFabrick: (companyId) =>
    $axios.post(`/fin/companies/${companyId}/fabrick-fake`),

  // Prima Nota
  getAccountedMovements: (companyId, filters) =>
    $axios.get(`/fin/companies/${companyId}/movements`, { params: filters }),
  getPrimeEntry: (companyId, filters) =>
    $axios.get(`/fin/companies/${companyId}/primanota`, { params: filters }),
  getManualAccountedMovements: (companyId, filters) =>
    $axios.get(`/fin/companies/${companyId}/movements/manual-accounted`, { params: filters }),
  deleteMatch: (movementId) => $axios.delete(`/fin/matches/${movementId}`),
  downloadPrimeEntry: (companyId, filters, type) =>
    $axios.get(`/fin/companies/${companyId}/primanota/download`, {
      responseType: 'blob',
      params: { ...filters, type },
    }),

  // Riconciliazione manuale
  fetchPromisesDetails: (companyId, filters) =>
    $axios.get(`/fin/companies/${companyId}/promises`, { params: filters }),
  fetchMatchingPromisesDetails: (companyId, filters) =>
    $axios.get(`/fin/companies/${companyId}/matching-promises`, {
      params: filters,
    }),
  createAccountedMovement: (
    companyId,
    amount,
    date,
    description,
    type,
    bankId,
    promiseId = null,
    rettificaAmount,
  ) =>
    $axios.post(`/fin/companies/${companyId}/movements/accounted`, {
      amount,
      date,
      description,
      type,
      bankId,
      promiseId,
      rettificaAmount,
    }),

  // Scadenzario
  getListScadenzario: (companyId, filters, sign) =>
    $axios.get(`/fin/companies/${companyId}/scadenzario?sign=${sign}`, {
      params: filters,
    }), // ?sign=in/out
  downloadScadenzarioAttivo: (companyId, filters, type) =>
    $axios.get(`/fin/companies/${companyId}/scadenzario/download?sign=in`, {
      responseType: 'blob',
      params: { ...filters, type },
    }),
  downloadScadenzarioPassivo: (companyId, filters, type) =>
    $axios.get(`/fin/companies/${companyId}/scadenzario/download?sign=out`, {
      responseType: 'blob',
      params: { ...filters, type },
    }),

  // Finanziamenti
  getListLoans: (companyId) =>
    $axios.get(`/fin/companies/${companyId}/finanziamenti`),
  fetchLoan: (id) => $axios.get(`/fin/finanziamenti/${id}`),
  fetchLoanInstalments: (id) => $axios.get(`/fin/finanziamenti/${id}/rate`),
  deleteLoan: (id) => $axios.delete(`/fin/finanziamenti/${id}`),
  generateInstalmentLoan: (piano) => $axios.get('/fin/rate', { params: piano }),
  createLoan: (
    companyId,
    label,
    bankId,
    isPaid,
    isCalculated,
    importo,
    paidDate,
    frequenzaRate,
    tasso,
    tipoPianoId,
    nbRate,
    primaScadenza,
    nbRatePreammortamento,
    primaScadenzaPreammortamento,
    fineMese,
    iva,
    maxiRataIniziale,
    maxiRataFinale,
    rate,
    id = null,
  ) =>
    $axios.post(`/fin/companies/${companyId}/finanziamenti`, {
      label,
      bankId,
      isPaid,
      isCalculated,
      importo,
      paidDate,
      frequenzaRate,
      tasso,
      tipoPianoId,
      nbRate,
      primaScadenza,
      nbRatePreammortamento,
      primaScadenzaPreammortamento,
      fineMese,
      iva,
      maxiRataIniziale,
      maxiRataFinale,
      rate,
      id,
    }),
  updateLoan: (id, nRata, edit, result, tasso = null) =>
    $axios.put(`/fin/finanziamenti/${id}`, { nRata, edit, result, tasso }),
  setPaidInstalmentLoan: (id, nRata, isPaid = true) =>
    $axios.patch(`/fin/finanziamenti/${id}/rate`, { nRata, isPaid }),

  // Fatture passive
  getListInvoices: (companyId, filters, sign) =>
    $axios.get(`/fin/companies/${companyId}/fatture?sign=${sign}`, {
      params: filters,
    }), // ?sign=in/out
  downloadPdfInvoice: (id) => $axios.get(`/fin/pdf/fatture/${id}`, {
    responseType: 'blob',
  }),
  fetchInvoice: (id) => $axios.get(`/fin/fatture/${id}`),
  deleteInvoice: (id) => $axios.delete(`/fin/fatture/${id}`),
  createInvoice: (
    companyId,
    number,
    denomination,
    vat,
    fiscalCode,
    address,
    city,
    cap,
    province,
    state,
    taxable, // imponibile
    iva,
    total,
    date,
    withholding,
    withholdingBankId,
    sign,
    rate,
    leftAmount,
    id = null,
  ) =>
    $axios.post(`/fin/companies/${companyId}/fatture`, {
      number,
      denomination,
      vat,
      fiscalCode,
      address,
      city,
      cap,
      province,
      state,
      taxable,
      iva,
      total,
      date,
      withholding,
      withholdingBankId,
      sign,
      rate,
      leftAmount,
      id,
    }),
  uploadXmlInvoice: (companyId, xml, sign) =>
    $axios.post(`/fin/companies/${companyId}/fatture/xml/${sign}`, xml),
  uploadZipInvoice: (companyId, zip, sign) =>
    $axios.post(`/fin/companies/${companyId}/fatture/zip/${sign}`, zip, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  confirmInvoice: (id, isPaid) => $axios.post(`/fin/fatture/${id}`, { isPaid }),
  setInstalmentInvoiceAsPaid: (id, nRata, isPaid = true) =>
    $axios.patch(`/fin/fatture/${id}/rate`, { nRata, isPaid }),

  // Registrazione e pagamenti
  createNewUser: (form) => $axios.post('/users', form),
  confirmUserSubscription: (emailMd5, token) =>
    $axios.post(`/users/${emailMd5}/confirmation/${token}`),
  buyALicense: (userId, paymentAmount, paymentDays, nextExpirationDate, paymentIntentId, paymentMethodId, form, autoRenewal, companyId = null) =>
    $axios.post('/checkout', {
      userId: userId,
      paymentAmount: paymentAmount,
      paymentDays: paymentDays,
      nextExpirationDate: nextExpirationDate,
      paymentIntentId: paymentIntentId,
      paymentMethodId: paymentMethodId,
      form: form,
      autoRenewal: autoRenewal,
      companyId,
    }),
  getBillingInformations: (companyId = null) => $axios.get(`/billing?companyId=${companyId}`),
  editBillingInformations: (form, autoRenewal, companyId = null, periodRenewal = null) => $axios.post('/billing', { form, autoRenewal, companyId, periodRenewal }),

  // Payments
  pay: ({
    id,
    quantity,
    paymentMethodId,
    paymentIntentId,
  }) => $axios.post('/pay', {
    id,
    quantity,
    paymentMethodId,
    paymentIntentId,
  }),

  // Personale
  getSalary: (companyId) => $axios.get(`/fin/companies/${companyId}/salary`),
  updateSalary: (
    companyId,
    amountCumulated,
    day,
    salaryMonths,
    f24Amount,
    bankId,
    f24BankId,
    employees,
  ) =>
    $axios.put(`/fin/companies/${companyId}/salary`, {
      amountCumulated,
      day,
      salaryMonths,
      f24Amount,
      bankId,
      f24BankId,
      employees,
    }),
  updateEmployeeCurrentSalary: (employeeId, salary) => $axios.put(`/fin/employees/${employeeId}/salary`, { salary }),

  // Settings
  getCompanySettings: (companyId) =>
    $axios.get(`/fin/companies/${companyId}/settings/company`),
  updateCompanySettings: (companyId, form) =>
    $axios.patch(`/fin/companies/${companyId}/settings/company`, { form }),
  getEmployeesSettings: (companyId) =>
    $axios.get(`/fin/companies/${companyId}/settings/personnel`),
  updateEmployeesSettings: (companyId, form) =>
    $axios.patch(`/fin/companies/${companyId}/settings/personnel`, { form }),
  updateCashSettings: (companyId, cashBalance) =>
    $axios.patch(`/fin/companies/${companyId}/settings/cash`, { cashBalance }),
  deleteBankAccount: (id) => $axios.delete(`/fin/settings/banks/${id}`),
  setIVA: (companyId, form) => $axios.post(`/fin/companies/${companyId}/iva`, { form }),
  setPeriodicityIVA: (companyId, periodicity) => $axios.patch(`/fin/companies/${companyId}/iva/periodicity`, periodicity),

  // Ticket
  createNewTicket: (companyId, priority = 10, subject, description) =>
    $axios.post(`/companies/${companyId}/tickets`, { priority, subject, description }),

  // Other Users
  // createCompanyUser: (companyId, name, surname, email, role, id = null) =>
  //   $axios.patch(`/fin/companies/${companyId}/users`, { name, surname, email, role, id }),
  // deleteCompanyUser: (id) =>
  //   $axios.patch(`/fin/users/${id}`),

  // Registration
  reserveUser: reservedUser => $axios.post('/users/reservation', reservedUser),
  getUserReservation: (email, token) => $axios.get(`/users/${email}/reservation/${token}`),
  registerUser: (email, token, user) => {
    let body = {};
    if (user?.name && user?.surname && user?.password) {
      body = user;
    }
    return $axios.post(`/users/${email}/reservation/${token}`, body);
  },
  declinesRegisterUser: (email, token) => $axios.delete(`/users/${email}/reservation/${token}`),
  deleteCompanyUser: (companyId, userId) => $axios.delete(`/companies/${companyId}/users/${userId}`),
  checkPromotionalCode: (promotionalCode) => $axios.get(`/promotional?promotionalCode=${promotionalCode}`),

  // Demo
  resetCompany: (companyId) =>
    $axios.post(`/fin/companies/${companyId}/demo/reset`),
  downloadFattureDemo: (companyId, sign) =>
    $axios.post(`/fin/companies/${companyId}/demo/download-fatture/${sign}`),
  downloadTransactionsDemo: (companyId) =>
    $axios.post(`/fin/companies/${companyId}/demo/download-transactions`),

  // FlowPay
  flowPayOnboarding: (companyId, fiscalCode, pin, password) =>
    $axios.post(`/fin/companies/${companyId}/flowpay/onboarding`, { fiscalCode, pin, password }),
  deleteFlowPayOnboarding: (companyId) =>
    $axios.delete(`/fin/companies/${companyId}/flowpay/onboarding`),

  // Notifications
  getNotifications: (companyId, filters) =>
    $axios.get(`/companies/${companyId}/notifications`, {
      params: filters,
    }),
  deleteNotification: (id) => $axios.delete(`/notifications/${id}`),
  verifyNotification: (id) => $axios.get(`/notifications/${id}/verify`),
  editNotification: (id, hasBeenRead) => $axios.patch(`/notifications/${id}`, { hasBeenRead }),

  // IVA Manager
  getIVA: (companyId) => $axios.get(`/fin/companies/${companyId}/iva`),
  getDatesIVA: (periodicity) => $axios.get(`/fin/iva-dates?periodicity=${periodicity}`),
  updateIVA: (companyId, form) => $axios.patch(`/fin/companies/${companyId}/iva`, { form }),
  recalculateIVA: (companyId, form) => $axios.post(`/fin/companies/${companyId}/iva/recalculate`, { form }),
  getIVADates: (periodicity) => $axios.get('/fin/iva-dates', periodicity)
};
