<template>
  <form @submit.prevent="onSubmit" class="azienda text-start" novalidate>
    <h5 class="mb-3">Dati azienda</h5>
    <div class="row">
      <div class="col">
        <b-form-group
          class="mb-4"
          id="input-group-business-name"
          label="Ragione sociale*"
          label-for="input-business-name"
          :class="{ invalid: !isValidBusinessName && showErrorBusinessName }"
        >
          <b-form-input
            id="input-business-name"
            v-model="form.businessName"
            type="text"
            @blur="showErrorBusinessName = true"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="false"
            :class="{
              invisible: isValidBusinessName || !showErrorBusinessName,
            }"
          >
            <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="col">
        <b-form-group
          class="mb-4"
          id="input-group-fiscal-code"
          label="Codice fiscale*"
          label-for="input-fiscal-code"
          :class="{ invalid: !isValidFiscalCode && showErrorFiscalCode }"
        >
          <b-form-input
            id="input-fiscal-code"
            v-model="form.fiscalCode"
            type="text"
            @blur="showErrorFiscalCode = true"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="false"
            :class="{ invisible: isValidFiscalCode || !showErrorFiscalCode }"
          >
            <i class="fas fa-exclamation-circle"></i> Inserisci un codice
            fiscale valido
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <b-form-group
          class="mb-4"
          id="input-group-iva"
          label="Partita IVA*"
          label-for="input-iva"
          :class="{ invalid: !isValidIva && showErrorIva }"
        >
          <b-form-input
            id="input-iva"
            v-model="form.iva"
            type="text"
            @blur="showErrorIva = true"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="false"
            :class="{ invisible: isValidIva || !showErrorIva }"
          >
            <i class="fas fa-exclamation-circle"></i> Inserisci una partita IVA
            valida
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group
          class="mb-4"
          id="input-group-ateco"
          label="Ateco"
          label-for="input-ateco"
          :class="{ invalid: !isValidAteco && showErrorAteco }"
        >
          <vue-select
            class="mw-100"
            id="input-ateco"
            v-model="form.ateco"
            ref="selectAteco"
            :options="atecoSuggestions"
            label="name"
            @search:blur="showErrorAteco = true"
            @search="fetchAtecoList"
            @change="autocomplete(1)"
            :clearable="false"
          >
            <template #selected-option="item">
              {{ item.name }}
            </template>
            <template #no-options>
              <div class="text-left py-2 ml-3">
                {{
                  searchValueAteco && searchValueAteco.length > 1
                    ? "Nessun codice trovato"
                    : "Inizia a scrivere..."
                }}
              </div>
            </template>
          </vue-select>
          <b-form-invalid-feedback
            :state="false"
            :class="{ invisible: isValidAteco || !showErrorAteco }"
          >
            <i class="fas fa-exclamation-circle"></i> Inserisci un codice Ateco
            valido
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="business-card">
          <div class="title">
            <h4>Sede legale</h4>
          </div>
          <div class="row flex-column card-content">
            <div class="col">
              <b-form-group
                class="mb-4"
                id="input-group-address"
                label="Indirizzo*"
                label-for="input-address"
                :class="{ invalid: !isValidAddress && showErrorAddress }"
              >
                <b-form-input
                  id="input-address"
                  v-model="form.address"
                  type="text"
                  @blur="showErrorAddress = true"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="false"
                  :class="{ invisible: isValidAddress || !showErrorAddress }"
                >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un
                  indirizzo valido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-8">
                  <b-form-group
                    class="mb-4"
                    id="input-group-municipality"
                    label="Comune*"
                    label-for="input-municipality"
                    :class="{
                      invalid: !isValidMunicipality && showErrorMunicipality,
                    }"
                  >
                    <vue-select
                      v-model="form.municipality"
                      ref="select1"
                      :options="suggestions"
                      label="name"
                      @search:blur="showErrorMunicipality = true"
                      @search="fetchSuggestions(1)"
                      @change="autocomplete(1)"
                      :clearable="false"
                    >
                      <template #selected-option="item">
                        {{ item.name }}
                        <div v-if="item.province">
                          ({{ item.province }})
                        </div>
                      </template>
                      <template #no-options>
                        <div class="text-left py-2 ml-3">
                          {{
                            searchValueMunicipality &&
                            searchValueMunicipality.length > 1
                              ? "Nessun comune trovato"
                              : "Inizia a scrivere..."
                          }}
                        </div>
                      </template>
                    </vue-select>
                    <b-form-invalid-feedback
                      :state="false"
                      :class="{
                        invisible:
                          isValidMunicipality || !showErrorMunicipality,
                      }"
                    >
                      <i class="fas fa-exclamation-circle"></i> Inserisci un
                      comune valido
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group
                    class="m-0"
                    id="input-group-cap"
                    label="CAP*"
                    label-for="input-cap"
                    :class="{ invalid: !isValidCap && showErrorCap }"
                  >
                    <b-form-input
                      id="input-cap"
                      v-model="form.cap"
                      type="text"
                      @blur="showErrorCap = true"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :state="false"
                      :class="{ invisible: isValidCap || !showErrorCap }"
                    >
                      <i class="fas fa-exclamation-circle"></i> Inserisci un CAP
                      valido
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div @click.prevent="copyInfo" class="link" title="Copia dati">
        <i class="fas fa-copy"></i>
      </div>
      <div class="col">
        <div class="business-card">
          <div class="title">
            <h4>Sede operativa</h4>
          </div>
          <div class="row flex-column card-content">
            <div class="col">
              <b-form-group
                class="mb-4"
                id="input-group-address"
                label="Indirizzo*"
                label-for="input-address"
                :class="{ invalid: !isValidAddress2 && showErrorAddress2 }"
              >
                <b-form-input
                  id="input-address"
                  v-model="form.address2"
                  type="text"
                  @blur="showErrorAddress2 = true"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="false"
                  :class="{ invisible: isValidAddress2 || !showErrorAddress2 }"
                >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un
                  indirizzo valido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-8">
                  <b-form-group
                    id="input-group-municipality2"
                    label="Comune*"
                    label-for="input-municipality2"
                    class="mb-4"
                    :class="{
                      invalid: !isValidMunicipality2 && showErrorMunicipality2,
                    }"
                  >
                    <vue-select
                      v-model="form.municipality2"
                      ref="select2"
                      :options="suggestions2"
                      label="name"
                      @search="fetchSuggestions(2)"
                      @search:blur="showErrorMunicipality2 = true"
                      @change="autocomplete(2)"
                      :clearable="false"
                      @update="autocomplete(1)"
                    >
                      <template #selected-option="item">
                        {{ item.name }} ({{ item.province }})
                      </template>
                      <template #no-options>
                        <div class="text-left py-2 ml-3">
                          {{
                            searchValueMunicipality2 &&
                            searchValueMunicipality2.length > 1
                              ? "Nessun comune trovato"
                              : "Inizia a scrivere..."
                          }}
                        </div>
                      </template>
                    </vue-select>
                    <b-form-invalid-feedback
                      :state="false"
                      :class="{
                        invisible:
                          isValidMunicipality2 || !showErrorMunicipality2,
                      }"
                    >
                      <i class="fas fa-exclamation-circle"></i> Inserisci un
                      comune valido
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group
                    id="input-group-cap2"
                    class="m-0"
                    label="CAP*"
                    label-for="input-cap2"
                    :class="{ invalid: !isValidCap2 && showErrorCap2 }"
                  >
                    <b-form-input
                      id="input-cap2"
                      v-model="form.cap2"
                      type="text"
                      @blur="showErrorCap2 = true"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :state="false"
                      :class="{ invisible: isValidCap2 || !showErrorCap2 }"
                    >
                      <i class="fas fa-exclamation-circle"></i> Inserisci un CAP
                      valido
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-6 ml-auto">
        <custom-button
          :isLoading="loading"
          :class="{disabled: !isValidForm}"
          label="Avanti"
          class="w-100"
          @click.prevent.native="onSubmit"
        />
      </div>
    </div>

    <ErrorCard v-if="errorMessage">
      <template #message>
        {{ errorMessage }}
      </template>
    </ErrorCard>
  </form>
</template>

<script>
import { BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import Button from '@/views/components/Button.vue';
import {
  isNumber,
  isFilled,
  isIva,
  isCap,
  isOnlyLettersAndSpaces,
  isFiscalCode,
} from '@/helpers/formValidation.js';
import Select from 'vue-select';

export default {
  props: {
    propForm: {
      type: Object,
      required: true,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    'custom-button': Button,
    ErrorCard,
    'vue-select': Select,
  },
  watch: {
    fiscalCode (fiscalCode) {
      if (fiscalCode !== null && this.isNumber(fiscalCode[0])) {
        this.form.iva = this.form.fiscalCode;
      }
    },
  },
  data () {
    return {
      me: this.$oauth2.me,

      loading: false,
      errorMessage: '',
      showErrorBusinessName: false,
      showErrorIva: false,
      showErrorFiscalCode: false,
      showErrorAteco: false,
      showErrorAddress: false,
      showErrorProvince: false,
      showErrorMunicipality: false,
      showErrorCap: false,
      showErrorAddress2: false,
      showErrorProvince2: false,
      showErrorMunicipality2: false,
      showErrorCap2: false,

      suggestions: [],
      suggestions2: [],
      atecoSuggestions: [],

      form: JSON.parse(JSON.stringify(this.propForm)),
    };
  },
  computed: {
    fiscalCode () {
      return this.form.fiscalCode;
    },
    isValidForm () {
      return this.isValidBusinessName && this.isValidIva && this.isValidFiscalCode && this.isValidAteco &&
        this.isValidAddress && this.isValidProvince && this.isValidMunicipality && this.isValidCap &&
        this.isValidAddress2 && this.isValidProvince2 && this.isValidMunicipality2 && this.isValidCap2;
    },
    isValidBusinessName () {
      return isFilled(this.form.businessName);
    },
    isValidIva () {
      return isFilled(this.form.iva) && isIva(this.form.iva);
    },
    isValidFiscalCode () {
      return (
        isFilled(this.form.fiscalCode) && isFiscalCode(this.form.fiscalCode)
      );
    },
    isValidAteco () {
      return true;
    },
    isValidAddress () {
      return isFilled(this.form.address);
    },
    isValidProvince () {
      return (
        isFilled(this.form.province) &&
        isOnlyLettersAndSpaces(this.form.province)
      );
    },
    isValidMunicipality () {
      return isFilled(this.form.municipality);
    },
    isValidCap () {
      return isFilled(this.form.cap) && isCap(this.form.cap);
    },
    isValidAddress2 () {
      return isFilled(this.form.address2);
    },
    isValidProvince2 () {
      return (
        isFilled(this.form.province2) &&
        isOnlyLettersAndSpaces(this.form.province2)
      );
    },
    isValidMunicipality2 () {
      return isFilled(this.form.municipality2);
    },
    isValidCap2 () {
      return isFilled(this.form.cap2) && isCap(this.form.cap2);
    },
    searchValueMunicipality () {
      return this.$refs.select1._data.search;
    },
    searchValueMunicipality2 () {
      return this.$refs.select2._data.search;
    },
    searchValueAteco () {
      return this.$refs.selectAteco._data.search;
    },
  },
  methods: {
    autocomplete (option) {
      if (option === 1) {
        this.form.province = this.form.municipality.province;
      } else if (option === 2) {
        this.form.province2 = this.form.municipality2.province;
      }
    },
    copyInfo () {
      this.form.address2 = this.form.address;
      this.form.province2 = this.form.province;
      this.form.municipality2 = this.form.municipality;
      this.form.cap2 = this.form.cap;
      this.showErrorAddress2 = true;
      this.showErrorProvince2 = true;
      this.showErrorMunicipality2 = true;
      this.showErrorCap2 = true;
    },
    fetchSuggestions (option = 1) {
      const searchValue =
        option === 1
          ? this.$refs.select1._data.search
          : this.$refs.select2._data.search;
      if (searchValue.length > 1) {
        this.$api.fetchSuggestions(searchValue)
          .then((res) => {
            if (option === 1) {
              this.suggestions = res.data.towns;
            } else if (option === 2) {
              this.suggestions2 = res.data.towns;
            }
          })
          .catch((e) => {
            this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          });
      }
    },
    fetchAtecoList () {
      const searchValue = this.$refs.selectAteco._data.search;
      if (searchValue.length > 1) {
        this.$api.fetchAtecoList(searchValue)
          .then((res) => {
            this.atecoSuggestions = res.data.ateco;
            this.$log.debug(res.data);
          })
          .catch((e) => {
            this.$log.error(e);
            this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          });
      }
    },
    isNumber (d) {
      return isNumber(d);
    },
    onSubmit () {
      if (this.isValidForm) {
        this.$emit('saveData', this.form);
      } else {
        this.loading = true;
        if (!this.isValidBusinessName) {
          this.showErrorBusinessName = true;
        } else if (!this.isValidFiscalCode) {
          this.showErrorFiscalCode = true;
        } else if (!this.isValidIva) {
          this.showErrorIva = true;
        } else if (!this.isValidAteco) {
          this.showErrorAteco = true;
        } else if (!this.isValidAddress) {
          this.showErrorAddress = true;
        } else if (!this.isValidProvince) {
          this.showErrorProvince = true;
        } else if (!this.isValidMunicipality) {
          this.showErrorMunicipality = true;
        } else if (!this.isValidCap) {
          this.showErrorCap = true;
        } else if (!this.isValidAddress2) {
          this.showErrorAddress2 = true;
        } else if (!this.isValidProvince2) {
          this.showErrorProvince2 = true;
        } else if (!this.isValidMunicipality2) {
          this.showErrorMunicipality2 = true;
        } else if (!this.isValidCap2) {
          this.showErrorCap2 = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
  },
  mounted () {
    this.fetchAtecoList();
    const form = this.me?.product?.billingAddress;
    if (form) {
      this.form.businessName = this.form.businessName ? this.form.businessName : form.denominazione;
      this.form.fiscalCode = this.form.fiscalCode ? this.form.fiscalCode : form.codice_fiscale;
      this.form.iva = this.form.iva ? this.form.iva : form.piva;
      this.form.address = this.form.address ? this.form.address : form.indirizzo;
      this.form.municipality = this.form.municipality ? this.form.municipality : form.comune;
      this.form.cap = this.form.cap ? this.form.cap : form.cap;
    }
  },
};
</script>

<style lang="scss">
.azienda {
  .link {
    margin: auto;
    background: $primary-mid;
    color: white;
    height: 23px;
    width: 23px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.1s ease-in;
    &:hover {
      background: rgba($primary, 0.6);
      transition: all 0.1s ease-in;
    }
  }
  .business-card {
    .title {
      background: $primary-lighter;
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      border: 1px solid $primary-lighter;
      h4 {
        margin: 5px;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
      }
    }
    border-radius: 10px;
    background: $fin-white;
    .card-content {
      padding: 15px;
    }
  }
}
</style>
