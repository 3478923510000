import $config from '../../Config/services/config';

export default namedStorage => {
  const storage = {
    get: (key, defaultValue) => namedStorage.getItem($config.get('STORAGE_KEY') + '-' + key) || defaultValue,
    put: (key, value) => namedStorage.setItem($config.get('STORAGE_KEY') + '-' + key, value),
    delete: (key) => namedStorage.removeItem($config.get('STORAGE_KEY') + '-' + key),
  };

  storage.getInt = (key, defaultValue) => parseInt(storage.get(key, defaultValue));
  storage.getFloat = (key, defaultValue) => parseFloat(storage.get(key, defaultValue));
  storage.getBool = (key, defaultValue) => !!storage.get(key, defaultValue);

  return storage;
};
