<template>
    <td :class="[{red: isNegative}, isValue(valueWithDecimals) ? 'selectable' : 'not-clickable']" @click="$emit('click')" class="cell">
      {{ valueWithDecimals }}
    </td>
</template>

<script>
export default {
  props: {
    value: Number,
  },
  computed: {
    valueWithDecimals () {
      return this.value ? this.value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : this.value;
    },
    isNegative () {
      return this.value < 0;
    },
  },
  methods: {
    isValue (value) {
      return value !== null && value !== undefined && value !== '';
    },
  },
};
</script>
