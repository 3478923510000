<template>
  <div class="bg-fin" ref="loans" :id="`notification_${notification?.notificationId}`">
    <div class="container-fluid p-0 w-auto m-4">
        <div class="d-flex mb-4">
          <router-link :to="{ name: 'archive'}">
            <h4 class="breadcrumb">Avvisi</h4>
          </router-link>
          <h4 class="m-0">{{notification?.title}}</h4>
        </div>

        <div class="notifications" v-if="notification">
          <div class="row">
            <div class="col-12 mb-4">
              <span class="date">{{toFullHumanDate(notification?.createdAt)}}</span>
            </div>
            <div class="col-12">
              {{notification?.body}}
            </div>
            <div class="col-12 text-center">
              <a v-if="notification?.actionLink" :href="notification?.actionLink" tag="button" class="btn btn-primary">{{notification?.actionTitle ? notification?.actionTitle : 'Vai'}}</a>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { toFullHumanDate } from '@/helpers/dates.js';

export default {
  props: {
    notification: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data () {
    return {
      errorMessage: '',
    };
  },
  methods: {
    toFullHumanDate (date) {
      return toFullHumanDate(date);
    },
    setNotificationAsRead (id, read = true) {
      this.$api.editNotification(id, read)
        .then((res) => {
          this.$root.$emit('updateNotifications');
          this.$log.debug(res);
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        });
    },
  },
  mounted () {
    if (this.notification === null) {
      this.$router.go(-1);
    } else {
      this.setNotificationAsRead(this.notification?.notificationId);
    }
  },
  beforeUpdate () {
    if (this.notification === null) {
      this.$router.go(-1);
    } else {
      this.setNotificationAsRead(this.notification?.notificationId);
    }
  },
};
</script>

<style lang="scss">
.date {
  font-weight: 600;
  font-size: 14px;
}
.notifications {
  background: $white;
  border-radius: 10px;
  padding: 30px;
}
</style>
