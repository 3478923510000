<template>
  <div class="settings">
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid p-0 w-auto m-4">
        <h4 class="mb-5">Impostazioni</h4>

        <div class="pretty-card">
          <div class="tabs">
            <button v-for="tab in tabs" :key="tab.name" class="btn tab" :class="{ active: currentTab.label === tab.label }" @click="setView(tab)">{{ tab.label }}</button>
          </div>
            <KeepAlive>
              <component :is="currentTab.name" :companyId="companyId" @setError="setError" @openSnackbar="openSnackbar"></component>
            </KeepAlive>

            <ErrorCard v-if="errorMessage">
              <template #message>
                {{ errorMessage }}
              </template>
            </ErrorCard>
        </div>
    </div>

    <Snackbar :text="snackbarText" ref="snackbar"/>
  </div>
</template>

<script>
import SettingsCash from '@/views/components/Settings/SettingsCash';
import SettingsBankAccounts from '@/views/components/Settings/SettingsBankAccounts';
import SettingsCompany from '@/views/components/Settings/SettingsCompany';
import SettingsEmployees from '@/views/components/Settings/SettingsEmployees';
import SettingsIVA from '@/views/components/Settings/SettingsIVA';
import TaxBoxForm from '@/views/components/Settings/TaxBoxForm';

import ErrorCard from '@/views/components/ErrorCard.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  components: {
    ErrorCard,
    SettingsCash,
    SettingsCompany,
    SettingsBankAccounts,
    SettingsEmployees,
    SettingsIVA,
    TaxBoxForm,
    Loader,
    Snackbar,
  },
  data () {
    return {
      companyId: this.$oauth2.me.companies[0].id || null,
      isLoadingPage: false,
      view: 0,
      snackbarText: '',
      admin: false,
      visitor: false,
      errorMessage: '',

      defaultTabs: [
        { name: 'SettingsCompany', label: 'Azienda' },
        { name: 'SettingsBankAccounts', label: 'Conti correnti', admin: true },
        { name: 'SettingsCash', label: 'Cassa' },
        { name: 'SettingsEmployees', label: 'Personale', admin: true },
        { name: 'TaxBoxForm', label: 'Cassetto fiscale', admin: true },
        { name: 'SettingsIVA', label: 'Periodicità IVA', admin: true },
      ],
      currentTab: { name: 'SettingsCompany', label: 'Azienda' },
    };
  },
  computed: {
    tabs () {
      if (this.admin) {
        return this.defaultTabs;
      }
      return this.defaultTabs.filter(obj => !obj.admin);
    },
    page () {
      return this.$route.params.page;
    }
  },
  methods: {
    getRoles () {
      const role = this.$oauth2.me.companies[0].role;
      if (role === 'owner') {
        this.admin = true;
      } else if (role === 'visitor') {
        this.visitor = true;
      }
    },
    openSnackbar (text) {
      this.snackbarText = text;
      this.$refs.snackbar.openSnackbar();
    },
    setView (tab) {
      this.currentTab = tab;
      this.setError('');
      sessionStorage.setItem('lastVisitedPage', this.currentTab.name);
    },
    setError (s) {
      this.errorMessage = s;
    },
  },
  created () {
    this.getRoles();

    if (this.page) {
      const found = this.tabs.find(obj => obj.name === this.page);
      this.currentTab = found;
      sessionStorage.setItem('lastVisitedPage', this.currentTab.name);
      return;
    }

    const lastVisitedPageName = sessionStorage.getItem('lastVisitedPage');

    if (lastVisitedPageName) {
      const found = this.tabs.find(obj => obj.name === lastVisitedPageName);
      if (found) {
        this.currentTab = found;
      }
    }
  },
  beforeDestroy (to, from) {
    sessionStorage.removeItem('lastVisitedPage');
  },
};
</script>

<style lang="scss">
.settings {
  background: $primary-lighter;
  .pretty-card {
    position: relative;
  }
}
.tabs {
  position: absolute;
  top: -35px;
  .tab {
      border: none;
      border-radius: 10px 10px 0px 0px;
      text-transform: uppercase;
      padding: 6px 12px;
      font-weight: 700;
      background: transparent;
      color: $primary-mid;
      overflow: visible;
      z-index: -1;
      &.active, &.selected  {
        color: $primary;
        background: white;
        border: none;
      }
  }
}
</style>
