<template>
  <component
    :is="tag"
    :type="tag === 'button' ? type : 'null'"
    class="btn"
    :class="[variantClass, sizeClass, loadingClass]"
    :to="to"
    :href="href"
    :disabled="isLoading"
  >
    <!-- <template v-if="!isLoading">
      {{ label }}
    </template>
    <div class="d-flex justify-content-center" v-else>
      <LoadingDots />
    </div> -->
    <span>{{ label }}</span>
    <div class="dots d-flex justify-content-center align-items-center" v-if="isLoading">
      <LoadingDots />
    </div>
  </component>
</template>

<script>
import LoadingDots from '@/views/components/LoadingDots.vue';

export default {
  components: {
    LoadingDots,
  },
  props: {
    tag: {
      // button, a, router-link
      type: String,
      default: 'button',
      validator (value) {
        return ['button', 'a', 'router-link'].includes(value);
      },
    },
    type: {
      // button, submit
      type: String,
      default: 'button',
    },
    variant: {
      // primary, secondary, danger, ecc
      type: String,
      default: 'primary',
    },
    size: {
      // sm, lg, md
      type: String,
      default: null,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Label',
    },
    to: {
      // with router-link's
      type: Object,
    },
    href: {
      // with a's
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    variantClass () {
      return `btn-${this.outline ? 'outline-' : ''}${this.variant}`;
    },
    sizeClass () {
      return this.size ? `btn-${this.size}` : '';
    },
    loadingClass () {
      return this.isLoading ? 'loading' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  position: relative;
  &-light {
    @include button-variant(
      $white,
      $white,
      rgba($white, 0.9),
      rgba($white, 0.9),
      rgba($white, 0.9),
      rgba($white, 0.9)
    );
    color: $primary !important;
  }

  &-md {
    padding: 0.625rem 1rem;
    font-size: 1rem;
  }

  .dots {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.loading {
    span {
      opacity: 0;
    }
  }
}
</style>
