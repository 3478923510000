<template>
  <div class="profile" ref="profileWrapper">
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid p-0 w-auto m-4">
      <h4 class="mb-5">Profilo utente</h4>
      <div class="pretty-card">
        <div class="tabs">
          <button @click.prevent="setView(0)" class="tab" :class="{active: view === 0 }">Utente</button>
          <button v-if="admin" @click.prevent="setView(1)" class="tab" :class="{active: view === 1 }">Dati fatturazione</button>
          <button v-if="admin" @click.prevent="setView(2)" class="tab" :class="{active: view === 2 }">Altri utenti</button>
          <button @click.prevent="setView(3)" class="tab" :class="{active: view === 3 }">Cambio password</button>
        </div>
        <UserProfile :key="`info_${update}`" v-show="view === 0" @openSnackbar="openSnackbar" @setError="setError" :banks="banks"/>
        <Billing :key="`billing_${update}`" v-if="admin" v-show="view === 1" @openSnackbar="openSnackbar" @setError="setError" :companyId="companyId"/>
        <UsersSettings :key="`users_${update}`" v-if="admin" v-show="view === 2" @openSnackbar="openSnackbar" @setError="setError" @getCompany="getCompanyInfo" :users="users" />
        <UserPassword :key="`password_${update}`" v-show="view === 3" @openSnackbar="openSnackbar" @setError="setError" />

        <ErrorCard v-if="errorMessage">
            <template #message >
            {{ errorMessage }}
            </template>
        </ErrorCard>
      </div>
    </div>

    <Snackbar ref="snackbar" :text="snackbarText" />
  </div>
</template>

<script>
import UserPassword from '@/views/components/Profile/UserPassword.vue';
import UserProfile from '@/views/components/Profile/UserProfile.vue';
import UsersSettings from '@/views/components/Profile/UsersSettings.vue';
import Billing from '@/views/components/Profile/Billing.vue';

import ErrorCard from '@/views/components/ErrorCard.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  components: {
    Billing,
    ErrorCard,
    Loader,
    Snackbar,
    UserPassword,
    UserProfile,
    UsersSettings,
  },
  data () {
    return {
      visitor: false,
      admin: false,
      view: 0,

      companyId: this.$oauth2.me.companies[0].id || null,
      isLoadingPage: false,
      snackbarText: '',
      errorMessage: '',
      update: 0,

      users: null,
      banks: null,
    };
  },
  methods: {
    getCompanyInfo () {
      this.$api
        .getCompany(this.companyId)
        .then((res) => {
          this.users = res.data.users;
          this.banks = res.data.banks.filter((obj) => obj.consentExpireAt !== null);
          // this.banks = res.data.banks.filter((obj) => obj.fabrickId !== null);
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getRoles () {
      const role = this.$oauth2.me.companies[0].role;
      if (role === 'owner') {
        this.admin = true;
      } else if (role === 'visitor') {
        this.visitor = true;
      }
    },
    openSnackbar (text) {
      this.snackbarText = text;
      this.$refs.snackbar.openSnackbar();
    },
    setError (text) {
      this.errorMessage = text;
    },
    setView (i) {
      this.errorMessage = '';
      this.view = i;
      const tags = ['profilo', 'dati-fatturazione'];
      this.$router.push({ hash: tags[i] })
        .catch(() => {});
    },
  },
  beforeMount () {
    this.getRoles();
    this.getCompanyInfo();

    if (this.page) {
      this.setView(this.page);
    } else {
      this.setView(0);
    }
  },
};
</script>

<style lang="scss">
.profile {
  background: $primary-lighter;
  .pretty-card {
    position: relative;
  }
  .tabs {
    position: absolute;
    top: -35px;
    .tab {
        border: none;
        border-radius: 10px 10px 0px 0px;
        text-transform: uppercase;
        padding: 6px 12px;
        font-weight: 700;
        background: transparent;
        color: $primary-mid;
        overflow: visible;
        z-index: -1;
        &.active, &.selected  {
          color: $primary;
          background: white;
          border: none;
        }
    }
  }
}
</style>
