<template>
  <div class="instalments-table">
      <b-table
        id="instalmentsTable"
        ref="instalmentsTable"
        :key="`instalments_table_${instalments}`"
        class="shadow"
        :items="instalments"
        :fields="!isManual ? fields : fields2"
        :busy.sync="isBusy"
        hover
        show-empty
        >
          <template #cell(date)="row">
              {{ toFormattedDate(row.item.date) }}
          </template>
          <template #cell(quotaInteressi)="row">
            <CellContent
              :value="row.item.quotaInteressi ? parseFloat(row.item.quotaInteressi) : null"
              class="d-flex justify-content-end align-items-center m-0 p-0 border-0 w-100"
            />
          </template>
          <template #cell(quotaCapitale)="row">
            <CellContent
              :value="row.item.quotaCapitale ? parseFloat(row.item.quotaCapitale) : null"
              class="d-flex justify-content-end align-items-center m-0 p-0 border-0 w-100"
            />
          </template>
          <template #cell(debitoResiduo)="row">
            <CellContent
              :value="row.item.debitoResiduo ? parseFloat(row.item.debitoResiduo) : null"
              class="d-flex justify-content-end align-items-center m-0 p-0 border-0 w-100"
            />
          </template>
          <template #cell(tasso)="row">
            <CellContent
              :value="row.item.tasso ? parseFloat(row.item.tasso) : null"
              class="d-flex justify-content-end align-items-center m-0 p-0 border-0 w-100"
            />
          </template>
          <template #cell(pre)="row">
              {{ (row.item.pre) ? 'P' : 'A' }}
          </template>
          <template #cell(isPaid)="row">
            <div class="d-flex align-items-center justify-content-center">
              <div v-if="row.item.isPaid" class="m-auto paid custom">Pagata</div>
              <button v-else class="to-pay" @click.prevent="setInstalmentAsPaid(row.item.nRata)">Segna come pagata</button>
            </div>
          </template>
          <template #cell(amount)="row">
            <CellContent
              :value="row.item.amount ? parseFloat(row.item.amount) : null"
              class="d-flex justify-content-end align-items-center m-0 p-0 border-0 w-100"
            />
          </template>
          <template #cell(actions)="row">
              <b-dropdown
              class="text-right"
              size="sm"
              id="dropdown-1"
              right
              variant="link"
              toggle-class="text-decoration-none"
              v-if="row.item.isPaid || !nextInstalmentsPaid(row.item.nRata)"
              >
                  <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item v-if="!isManual && !row.item.isPaid && !nextInstalmentsPaid(row.item.nRata)" @click="togglePopup('editRatePopup'); selectedRow = row.item;">Modifica tasso</b-dropdown-item>
                  <b-dropdown-item v-if="!row.item.isPaid && !nextInstalmentsPaid(row.item.nRata)" @click="togglePopup('deleteInstalmentPopup'); selectedRow = row.item;">Elimina rata</b-dropdown-item>
                  <b-dropdown-item v-if="!row.item.isPaid && !nextInstalmentsPaid(row.item.nRata)" @click="togglePopup('deleteAndAddInstalmentPopup'); selectedRow = row.item;">Elimina rata e aggiungi rata alla fine</b-dropdown-item>
                  <b-dropdown-item v-if="!isManual && !row.item.isPaid && !nextInstalmentsPaid(row.item.nRata)" @click="togglePopup('editInterestPopup'); selectedRow = row.item;">Solo interessi</b-dropdown-item>
                  <b-dropdown-item v-if="!isManual && !row.item.isPaid && !nextInstalmentsPaid(row.item.nRata)" @click="togglePopup('editAndAddInterestPopup'); selectedRow = row.item;">Solo interessi e aggiungi rata alla fine</b-dropdown-item>
                  <b-dropdown-item v-if="row.item.isPaid" @click="togglePopup('setAsNotPaid'); selectedRow = row.item;">Segna come non pagata</b-dropdown-item>
              </b-dropdown>
          </template>
          <template #empty>
              <div class="d-flex justify-content-center align-items-center py-2">
                {{ instalments === null ? 'Caricamento' : 'Nessuna rata trovata'}}
              </div>
          </template>
      </b-table>
      <!-- to do modificare testi -->
      <Popup @beforeClosing="beforeClosing" ref="editRatePopup" class="md" :errorMessage="error">
          <template #title>Modifica tasso</template>
          <template #text>
              <div class="row mb-4">
                  <div class="col-6">
                      <div class="d-flex flex-column">
                          <label for="">Numero rata</label>
                          {{selectedRow.nRata}}
                      </div>
                  </div>
                  <div class="col-6">
                      <div class="d-flex flex-column">
                          <label>Ultima modifica</label>
                          {{toFormattedDate(selectedRow.date)}}
                      </div>
                  </div>
              </div>
          </template>
          <template #content>
              <b-form-group
                  class="mb-3"
                  id="input-group-rate"
                  label="Tasso"
                  label-for="input-rate"
                  :class="{ invalid: !isValidRate && showErrorRate }"
              >
                  <CurrencyInput id="input-rate" v-model="rate" @blur="showErrorRate = true" />
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidRate || !showErrorRate }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un tasso in percentuale valido
                  </b-form-invalid-feedback>
              </b-form-group>
          </template>
          <template #button>
              <custom-button
              :isLoading="loading"
              label="Modifica"
              class="w-100 mt-4"
              @click.prevent.native="onEditRate"
              />
          </template>
      </Popup>
      <Popup @beforeClosing="beforeClosing" ref="deleteInstalmentPopup" class="md" :errorMessage="error">
          <template #title> Sei sicuro di voler eliminare? </template>
          <template #text>La rata n. {{selectedRow.nRata}} di {{(parseFloat(selectedRow.amount)).toFixed(2)}} € sarà rimossa.</template>
          <template #button>
              <custom-button
              :isLoading="loading"
              label="Elimina"
              class="w-100 mt-4"
              @click.prevent.native="updateLoan('delete', 'recalculate', null)"
              />
          </template>
      </Popup>
      <Popup @beforeClosing="beforeClosing" ref="deleteAndAddInstalmentPopup" class="md" :errorMessage="error">
          <template #title> Sei sicuro di voler eliminare? </template>
          <template #text>
              La rata n. {{selectedRow.nRata}} di {{(parseFloat(selectedRow.amount)).toFixed(2)}} € sarà rimossa.<br/>
              Una nuova rata verrà aggiunta alla fine.
          </template>
          <template #button>
              <custom-button
              :isLoading="loading"
              label="Elimina e aggiungi nuova"
              class="w-100 mt-4"
              @click.prevent.native="updateLoan('delete', 'shift', null)"
              />
          </template>
      </Popup>
      <Popup @beforeClosing="beforeClosing" ref="editInterestPopup" class="md" :errorMessage="error">
          <template #title>Ricalcola rata</template>
          <template #text>
              L'importo della rata n. {{selectedRow.nRata}} sarà modificato e comprenderà solo gli interessi.<br/>
              Le rate successive verranno ricalcolate.
          </template>
          <template #button>
              <custom-button
              :isLoading="loading"
              label="Conferma"
              class="w-100 mt-4"
              @click.prevent.native="updateLoan('interests', 'recalculate', null)"
              />
          </template>
      </Popup>
      <Popup @beforeClosing="beforeClosing" ref="editAndAddInterestPopup" class="md" :errorMessage="error">
          <template #title>Ricalcola rata</template>
          <template #text>
              L'importo della rata n. {{selectedRow.nRata}} sarà modificato e comprenderà solo gli interessi.<br/>
              Le rate successive verranno ricalcolate.<br/>
              Verrà aggiunta una nuova rata alla fine.</template>
          <template #button>
              <custom-button
              :isLoading="loading"
              label="Conferma"
              class="w-100 mt-4"
              @click.prevent.native="updateLoan('interests', 'shift', null)"
              />
          </template>
      </Popup>
      <Popup @beforeClosing="beforeClosing" ref="setAsNotPaid" class="md" :errorMessage="error">
          <template #title>Segna rata come non pagata</template>
          <template #text>
              Verrà ricreato un movimento in attesa associato alla rata n. {{selectedRow.nRata}} di {{toCurrency(selectedRow.amount)}}.<br/>
          </template>
          <template #button>
              <custom-button
              :isLoading="loading"
              label="Conferma"
              class="w-100 mt-4"
              @click.prevent.native="setInstalmentAsPaid(selectedRow.nRata, false)"
              />
          </template>
      </Popup>  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BTable } from 'bootstrap-vue';
import { toFormattedDate } from '@/helpers/dates.js';
import { isFilled, toCurrency } from '@/helpers/formValidation.js';

import Button from '@/views/components/Button.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import Popup from '@/views/components/Popup.vue';

export default {
  props: {
    instalments: Array,
    loanId: String,
    isManual: {
      type: Boolean,
      default: false,
    },
    isLoadingInstalments: Boolean,
  },
  components: {
    'custom-button': Button,
    CellContent,
    CurrencyInput,
    Popup,
    BTable,
    BDropdown,
    BDropdownItem,
  },
  watch: {
    selectedRow () {
      if (this.selectedRow) {
        this.rate = this.selectedRow.tasso;
      }
    },
  },
  data () {
    return {
      fields: [
        { key: 'nRata', label: 'Rata', sortable: false },
        { key: 'pre', label: 'Tipo rata', sortable: false },
        { key: 'date', label: 'Scadenza', sortable: false },
        { key: 'quotaInteressi', label: 'Quota interessi', sortable: false, class: 'text-right' },
        { key: 'quotaCapitale', label: 'Quota capitale', sortable: false, class: 'text-right' },
        { key: 'amount', label: 'Importo rata', sortable: false, class: 'text-right' },
        { key: 'debitoResiduo', label: 'Debito residuo', sortable: false, class: 'text-right' },
        { key: 'tasso', label: "Tasso d'interesse %", sortable: false, class: 'text-right' },
        { key: 'isPaid', label: '', sortable: false },
        { key: 'actions', label: '', sortable: false },
      ],
      fields2: [
        { key: 'nRata', label: 'Rata', sortable: false },
        { key: 'pre', label: 'Tipo rata', sortable: false },
        { key: 'date', label: 'Scadenza', sortable: false },
        { key: 'amount', label: 'Importo rata', sortable: false, class: 'text-right' },
        { key: 'isPaid', label: '', sortable: false },
        { key: 'actions', label: '', sortable: false },
      ],
      loading: false,
      error: '',
      selectedRow: null,
      rate: null,
      showErrorRate: false,
    };
  },
  computed: {
    isBusy () {
      return this.instalments === null || this.isLoadingInstalments;
    },
    isValidRate () {
      return isFilled(this.rate);
    },
  },
  methods: {
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    toCurrency (s) {
      return toCurrency(s);
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    onEditRate () {
      if (this.isValidRate) {
        this.loading = true;
        this.updateLoan('tasso', null, (parseFloat(this.rate)).toFixed(2));
      } else {
        this.showErrorRate = true;
      }
    },
    updateLoan (edit, result, tasso) {
      this.$log.debug(this.loanId);
      this.$log.debug(this.selectedRow);
      this.loading = true;
      const nRata = this.selectedRow.nRata;
      this.$api.updateLoan(this.loanId, nRata, edit, result, parseFloat(tasso))
        .then(() => {
          this.error = '';
          this.closeAll(); // to do change in close opened popup
          this.$emit('reloadData');
          this.$emit('openSnackbar', 'Finanziamento aggiornato correttamente');
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    beforeClosing () {
      this.loading = false;
      this.error = '';
      this.showErrorRate = false;
    },
    closeAll () {
    // to do improve
      this.$refs.editRatePopup.close();
      this.$refs.deleteInstalmentPopup.close();
      this.$refs.deleteAndAddInstalmentPopup.close();
      this.$refs.editInterestPopup.close();
      this.$refs.editAndAddInterestPopup.close();
    },
    setInstalmentAsPaid (nRata, bool = true) {
      this.loading = true;
      this.$api.setPaidInstalmentLoan(this.loanId, nRata, bool)
        .then((res) => {
          this.$log.debug(res.data);
          this.$emit('reloadData');
          this.$emit('openSnackbar', 'Rata aggiornata correttamente');
        })
        .catch((e) => {
          this.$log.error(e);
          const errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          this.$emit('openSnackbar', errorMessage, 'danger');
        })
        .finally(() => {
          this.loading = false;
          this.$refs.setAsNotPaid.close();
        });
    },
    nextInstalmentsPaid (nRata) {
      return this.instalments.find(obj => (obj.nRata >= nRata && obj.isPaid === true));
    },
  },
};
</script>

<style lang="scss">
.instalments-table {
  .paid.custom {
    background: $primary-lighter;
    color: $primary;
    opacity: 1;
    cursor: default;
  }
  .to-pay {
    border: none;
    background: $primary;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    padding: 1px 7px;
    margin: 0;
    gap: 10px;
    border-radius: 5px;
    width: fit-content;
  }
}
</style>
