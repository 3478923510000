<template>
  <div class="manual-matching" ref="manualMatching">
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid p-0 w-auto m-4">

      <h4 class="mb-4">Riconciliazione manuale</h4>

      <div class="row p-0 m-0 box d-flex justify-content-between align-items-center no-gutters" :class="rowColour">
        <div class="col-5">
          <BankSelect class="mb-0" ref="bankSelect" key="bankSelect" :banks="allBanks" @update="updateBank" :selectedItem="selectedBank"/>
        </div>
        <div class="col-7">
          <div class="row d-flex justify-content-end p-0 mx-3" v-if="selectedBank ? selectedBank.alias !== 'Tutte' : false">
            <div class="col d-flex flex-column box-sm" :class="rowColourLighter">
                <label class="text-uppercase text-nowrap" for="">Saldo contabile</label>
                <CellContent
                  :value="accountBalance ? parseFloat(accountBalance) : null"
                  class="d-flex justify-content-end m-0 p-0 border-0"
                />
            </div>
            <div class="col ml-3 d-flex flex-column box-sm" :class="rowColourLighter">
                <label class="text-uppercase  text-nowrap" for="">Saldo disponibile</label>
                <CellContent
                  :value="availableBalance ? parseFloat(availableBalance) : null"
                  class="d-flex justify-content-end m-0 p-0 border-0"
                />
            </div>
            <div class="col ml-3 d-flex flex-column box-sm" :class="rowColourLighter">
                <label class="text-uppercase text-nowrap" for="">fido</label>
                <CellContent
                  :value="fido ? parseFloat(fido) : null"
                  class="d-flex justify-content-end m-0 p-0 border-0"
                />
            </div>
            <div class="d-none">
              <div class="col ml-3 d-flex flex-column box-sm" :class="rowColourLighter">
                <label class="text-uppercase text-nowrap" for=""> Conto anticipi</label>
                <CellContent
                  :value="advances ? parseFloat(advances) : null"
                  class="d-flex justify-content-end m-0 p-0 border-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row my-4">
        <div class="col-8 d-flex flex-column">
          <div class="row flex-column flex-grow-1">
            <div class="col flex-grow-1 mb-3 d-flex flex-column">
              <div class="table-wrapper flex-grow-1" style="position: relative; overflow: hidden; background:white;">
                <div class="table-title w-100">
                  movimenti contabilizzati di recente
                </div>
                <b-table
                  sticky-header="400px"
                  id="table"
                  ref="table"
                  :items="movements"
                  :fields="fields"
                  no-local-sorting
                  :busy.sync="isBusy"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  hover
                  show-empty
                  style="position: absolute; width: 100%; max-height: calc(100% - 28px); top: 28px;"
                >
                  <template #cell(bankName)="row">
                    <div :title="row.item.bankName" :class="`text-bank-variant-${row.item.bankColour ? row.item.bankColour : 0}`" class="font-weight-600">
                      {{ row.item.bankName }}
                    </div>
                  </template>
                  <template #cell(date)="row">
                    {{ row.item.date ? toFormattedDate(row.item.date) : row.item.date }}
                  </template>
                  <template #cell(type)="row">
                    {{ row.item.type | movement | capitalizeFirstLetter }}
                  </template>
                  <template #cell(amount)="row">
                    <CellContent
                      :value="parseFloat(row.item.amount)"
                      class="d-flex justify-content-end m-0 p-0 border-0"
                    />
                  </template>
                  <template #empty>
                    <div class="d-flex justify-content-center align-items-center py-2">
                      Nessun movimento trovato
                    </div>
                  </template>
                </b-table>
              </div>

            </div>
            <div class="col flex-grow-1 mt-3 d-flex flex-column">

              <div class="table-wrapper flex-grow-1" style="position: relative; overflow: hidden; background:white;">
                <div class="table-title w-100">
                  movimenti attesi
                </div>
                <b-table
                  class="clickable-rows"
                  sticky-header="500px"
                  id="table2"
                  ref="table2"
                  :items="promises"
                  :fields="fields2"
                  :busy.sync="isBusy2"
                  show-empty
                  :tbody-tr-class="setColour"
                  @row-clicked="bindItem"
                  style="position: absolute; width: 100%; max-height: calc(100% - 28px); top: 28px;"
                >
                  <template #cell(bankName)="row">
                    <b-popover v-if="row.item.bank?.fabrickId" :target="`line_${row.index}`" placement="bottom" triggers="hover focus">
                      <div class="popover-alert">
                        Per contabilizzare questo movimento usa la <b>Riconciliazione automatica</b>
                      </div>
                    </b-popover>
                    <div :title="row.item.bankName" :class="`text-bank-variant-${row.item.bankColour ? row.item.bankColour : 0}`" class="font-weight-600" :id="`line_${row.index}`" @mouseover="hovered = true" @mouseleave="hovered = false" data-toggle="popover" data-trigger="hover">
                      <i v-if="row.item.bank.fabrickId" class="fas fa-exclamation-triangle orange"></i>
                      {{ row.item.bankName }}
                    </div>
                  </template>
                  <template #cell(date)="row">
                    {{ toFormattedDate(row.item.date) }}
                  </template>
                  <template #cell(type)="row">
                    {{ row.item.type | movement | capitalizeFirstLetter }}
                  </template>
                  <template #cell(amount)="row">
                    <CellContent
                      :value="parseFloat(row.item.amount)"
                      class="d-flex justify-content-end m-0 p-0 border-0"
                    />
                  </template>
                  <template #cell(description)="row">
                    <div :title="row.item.description">
                      {{ row.item.description }}
                    </div>
                  </template>
                  <template #cell(actions)="row">
                    <div class="d-flex actions">
                      <button v-if="selectedRow !== row.item" class="icon" @click="selectedRow=row.item; autoFill()" title="Espandi">
                        <i class="fas fa-file-import mw-icon"></i>
                      </button>
                      <button v-else class="icon" @click="selectedRow=null; revertFill()" title="Annulla">
                        <i class="fas fa-file-excel mw-icon"></i>
                      </button>
                      <button class="icon ml-2" @click="selectedRow=row.item; togglePopup('promiseDelete')" title="Elimina"><i class="fas fa-trash"></i></button>
                    </div>
                  </template>
                  <template #empty>
                    <div class="d-flex justify-content-center align-items-center py-2">
                      Nessun movimento in attesa trovato
                    </div>
                  </template>
                </b-table>
              </div>

            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="pretty-card mb-0" :class="[rowColourLighter2, textColour2]">
            <AccountMovement ref="accountMovement" :banks="banks" :form="formData" @updateColours="updateColours2" :rettificaAmount="rettificaAmount" @accountMovement="accountMovement"/>
          </div>
        </div>
      </div>

    </div> <!-- container -->

    <!-- popup edit -->
    <Popup ref="movementEditor" class="md">
      <template #fullContent>
        <EditMovement :obj="selectedRow" :banks="banks" @save="onEdit" :descriptionOnly="true"/>
      </template>
    </Popup>

    <Popup ref="promiseDelete" class="md">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
        Il movimento di <b>{{ toCurrency(selectedRow.amount) }}</b> € verrà rimosso dai movimenti in attesa.
      </template>
      <template #button>
        <custom-button
          :isLoading="loading"
          label="Elimina"
          class="w-100 mt-4"
          @click.prevent.native="deletePromise(selectedRow.id)"
        />
      </template>
    </Popup>

    <Popup ref="conflict" class="md">
      <template #title>Rettifica movimento</template>
      <template #text>
        Il {{selectedRow.type === 'prelievo' ? 'prelievo' : 'pagamento'}} di <b>{{ toCurrency(selectedRow.amount) }}</b> € porterà il saldo di cassa in negativo.
        <b>Verrà generato un movimento per riportare il saldo di cassa in positivo.</b>
          <b-form-group
            class="mt-4 mb-2"
            id="input-group-rettifica-amount"
            label="Importo del movimento di rettifica"
            label-for="input-rettifica-amount"
            :class="{ invalid: !isValidRettificaAmount && showErrorRettificaAmount }"
          >
            <CurrencyInput id="input-rettifica-amount" v-model="rettificaAmount" @blur="showErrorRettificaAmount = true" />
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidRettificaAmount || !showErrorRettificaAmount }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo superiore a {{toCurrency(minRettificaAmount)}} €
            </b-form-invalid-feedback>
          </b-form-group>
      </template>
      <template #button>
        <custom-button
          :isLoading="loadingPopup"
          label="Conferma"
          class="w-100 mt-3"
          @click.prevent.native="confirmMatch"
        />
      </template>
    </Popup>

    <Snackbar :text="snackbarText" ref="snackbar" />
  </div>
</template>

<script>
import formatMovementTypeFilter from '@/filters/formatMovementType';
import capitalizeFirstLetter from '@/filters/capitalizeFirstLetter';

import { BTable, BFormGroup, BPopover } from 'bootstrap-vue';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';
// to do handle broken img links

import AccountMovement from '@/views/components/Forms/Movements/AccountMovement.vue';
import Button from '@/views/components/Button.vue';
import BankSelect from '@/views/components/BankSelect.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import EditMovement from '@/views/components/Forms/Movements/EditMovement.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Popup from '@/views/components/Popup.vue';
import Snackbar from '@/views/components/Snackbar.vue';
import { toCurrency, isFilled } from '@/helpers/formValidation.js';

export default {
  components: {
    BTable,
    BFormGroup,
    BPopover,
    AccountMovement,
    BankSelect,
    'custom-button': Button,
    CellContent,
    CurrencyInput,
    EditMovement,
    Loader,
    Popup,
    Snackbar,
  },
  filters: {
    movement: formatMovementTypeFilter,
    capitalizeFirstLetter: capitalizeFirstLetter,
  },
  data () {
    return {
      banks: [],
      allBanks: [],
      errorMessage: '',
      companyId: this.$oauth2.me.companies[0].id || null,
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
      fields: [
        {
          key: 'bankName',
          label: 'Banca',
          class: 'first-column',
          sortable: false,
        },
        {
          key: 'date',
          label: 'Data',
          class: '',
          sortable: false,
        },
        { key: 'type', label: 'Tipo', sortable: false },
        { key: 'amount', label: 'Importo', sortable: false },
        {
          key: 'description',
          label: 'Descrizione',
          class: ' description',
          sortable: false,
        },
      ],
      fields2: [
        {
          key: 'bankName',
          label: 'Banca',
          class: 'first-column',
          sortable: false,
        },
        {
          key: 'date',
          label: 'Data',
          class: '',
          sortable: false,
        },
        {
          key: 'type',
          label: 'Tipo',
          class: 'description',
          sortable: false,
        },
        { key: 'amount', label: 'Importo', sortable: false },
        {
          key: 'description',
          label: 'Descrizione',
          class: 'description',
          sortable: false,
        },
        { key: 'actions', label: '', sortable: false },
      ],
      movements: [],
      promises: [],

      // Feedbacks
      showPopup: true,
      snackbarText: '',
      loading: false,
      loadingPopup: false,
      isLoadingMovements: true,
      isLoadingPromises: true,

      startDate: this.$route.query.startDate || null,
      endDate: this.$route.query.endDate || null,
      maxEndDate: null,

      // Table
      isBusy: false,
      isBusy2: false,
      sortDesc: Boolean(this.$route.query.sortDesc) || true,
      sortBy: this.$route.query.sortBy || 'date',

      // Top bar
      selectedBank: null,
      fido: null,
      advances: null,
      accountBalance: null,
      availableBalance: null,

      rowColour: null,
      rowColourLighter: null,
      textColour: null,

      // Form
      rowColour2: null,
      rowColourLighter2: null,
      textColour2: null,
      formData: null,

      // Promises
      selectedRow: null,

      // Rettifica
      rettificaAmount: null,
      minRettificaAmount: null,
      showErrorRettificaAmount: false,
    };
  },
  computed: {
    bankId: function () {
      return this.$route.query.bankId;
    },
    watchedSorting () {
      return this.sortBy + this.sortDesc;
    },
    isLoadingPage () {
      return this.isLoadingMovements && this.isLoadingPromises;
    },
    isValidRettificaAmount () {
      return isFilled(this.rettificaAmount) && (this.rettificaAmount >= this.minRettificaAmount);
    },
  },
  watch: {
    $route: function () {
      if (!this.isLoadingPage) {
        this.getMovements();
        this.getPromises();
      }
    },
    selectedBank: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            bankId: this.selectedBank.id || undefined,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        })
        .finally(() => {
          this.fido = this.selectedBank.fido;
          this.advances = this.selectedBank.conto_anticipi;
          this.accountBalance = this.selectedBank.balance;
          this.availableBalance = this.fido + this.advances + this.accountBalance;
          this.updateColours();
        });
    },
    watchedSorting: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            sortDesc: this.sortDesc,
            sortBy: this.sortBy,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
  },
  methods: {
    accountMovement (obj) {
      // this.$log.debug(`OBJ: ${JSON.stringify(obj)}`)
      // this.$api.createAccountedMovement(Object.assign(obj, { companyId: this.companyId }))
      this.$api.createAccountedMovement(this.companyId, parseFloat(obj.amount), obj.date, obj.description, obj.type, obj.bankId, obj.promiseId, this.rettificaAmount)
        .then(() => {
          this.snackbarText = 'Movimento contabilizzato correttamente';
          this.$refs.accountMovement.success();
          this.formData = null;
          this.$refs.snackbar.openSnackbar();
          this.getMovements();
          this.getPromises();
          this.rettificaAmount = null;
          this.minRettificaAmount = null;
          this.$refs.conflict.close();
        })
        .catch((e) => {
          if (e.response.status === 409) {
            this.togglePopup('conflict');
            this.rettificaAmount = e.response.data.rettificaAmount;
            this.minRettificaAmount = this.rettificaAmount;
            this.$refs.accountMovement.stopLoading();
          } else {
            this.$refs.accountMovement.failure();
          }
        })
        .finally(() => {
          this.loadingPopup = false;
        });
    },
    bindItem (item) {
      this.$log.debug(item);
      if (this.selectedRow !== item) {
        this.selectedRow = item;
        this.autoFill();
      } else {
        this.selectedRow = null;
        this.revertFill();
      }
    },
    confirmMatch () {
      this.$refs.accountMovement.confirm();
      this.loadingPopup = true;
    },
    canBeRestored (item) {
      const date = new Date(item.date);
      const today = new Date();
      let diff = today.getTime() - date.getTime();
      diff = Math.floor(diff / (1000 * 3600 * 24));
      return diff <= 15;
    },
    deletePromise (id) {
      this.loading = true;
      this.$api.deletePromise(id)
        .then(() => {
          this.togglePopup('promiseDelete');
          this.snackbarText = 'Movimento eliminato correttamente';
          this.$refs.snackbar.openSnackbar();
          this.getPromises();
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.isBusy = false;
          this.updateColours();
          this.loading = false;
        });
    },
    autoFill () {
      this.formData = this.selectedRow;
      this.$refs.accountMovement.setForm();
    },
    revertFill () {
      this.formData = null;
      this.$refs.accountMovement.resetForm();
    },
    getCompanyInfo () {
      this.$api
        .getCompany(this.companyId)
        .then((res) => {
          // this.$log.debug(res.data);
          this.banks = res.data.banks.filter(bank => bank.fabrickId === null);
          this.allBanks = [{ alias: 'Tutte' }, ...res.data.banks.filter(bank => bank.fabrickId === null)];
          this.selectedBank = this.searchInBanks();
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        });
    },
    getMovements () {
      this.isBusy = true;
      const filters = this.$route.query;
      this.$api
        .getManualAccountedMovements(this.companyId, filters)
        .then((res) => {
          this.$log.debug(res.data);
          const movements = res.data.movements.filter((obj) => obj.id);
          this.movements = movements.slice(0, 10) || [];
          this.$log.debug(this.movements);
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.isBusy = false;
          this.updateColours();
          this.isLoadingMovements = false;
        });
    },
    getPromises () {
      this.isBusy2 = true;
      this.$api
        // .fetchPromisesDetails(this.companyId, { all: true })
        .fetchMatchingPromisesDetails(this.companyId, { all: true })
        .then((res) => {
          this.$log.debug(res.data);
          this.promises = res.data.promises || [];
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.isBusy2 = false;
          this.updateColours();
          this.isLoadingPromises = false;
        });
    },
    replaceImg (e) {
      e.target.src = bankLogoDefault;
    },
    setColour (item) {
      return this.selectedRow === item ? this.rowColour2 : null;
    },
    searchInBanks (id) {
      return this.allBanks.find((bank) => bank.id === id || bank.id === this.bankId);
    },
    setDates () {
      const today = new Date();
      const diff = today.getTime() - 30 * 3600 * 1000 * 24;
      const firstDay = new Date(diff);
      this.endDate = today.getFullYear().toString() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
      this.startDate = firstDay.getFullYear().toString() + '-' + ('0' + (firstDay.getMonth() + 1)).slice(-2) + '-' + ('0' + firstDay.getDate()).slice(-2);
    },
    toFormattedDate (date) {
      const x = new Date(date);
      return (
        ('0' + x.getDate()).slice(-2) +
        '/' +
        ('0' + (x.getMonth() + 1)).slice(-2) +
        '/' +
        x.getFullYear().toString()
      );
    },
    toCurrency (amount) {
      return toCurrency(amount);
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    updateBank (bank) {
      this.selectedBank = bank;
    },
    updateColours () {
      this.rowColour = this.selectedBank?.colour
        ? `bank-variant-${this.selectedBank.colour}-light`
        : 'primary-light';
      this.rowColourLighter = this.selectedBank?.colour
        ? `bank-variant-${this.selectedBank.colour}-lighter`
        : 'primary-lighter';
      this.textColour = this.selectedBank?.colour
        ? `text-bank-variant-${this.selectedBank.colour}`
        : 'text-primary';
    },
    updateColours2 (colourNum) {
      this.rowColour2 = colourNum
        ? `bank-variant-${colourNum}-light`
        : 'primary-light';
      this.rowColourLighter2 = colourNum
        ? `bank-variant-${colourNum}-lighter`
        : 'primary-lighter';
      this.textColour2 = colourNum
        ? `text-bank-variant-${colourNum}`
        : 'text-primary';
    },
  },
  mounted () {
    this.$root.$on('updateMovements', () => {
      this.getCompanyInfo();
      this.getMovements();
      this.getPromises();
    });
    this.setDates();
    this.getCompanyInfo();
    this.getMovements();
    this.getPromises();
  },
};
</script>

<style lang="scss">
.no-bg {
  background: transparent !important;
}

.manual-matching {
  background: $primary-lighter;

  .pretty-card {
    transition: background-color 0.5s;
  }

  .mw-icon {
    width: 20px;
    text-align: right;
  }

  .icon {
    background: none!important;
    border: none;
    color: $primary;
  }

  .box-sm {
    text-align: right;
    max-width: fit-content;
    padding: 6px 10px;
    border-radius: 10px;
    transition: all 0.5s;
    color: #292E4D;
    filter: brightness(102%);
    label {
      text-align: right;
      font-weight: 700;
      font-size: 14px;
      opacity: 0.9;
    }
  }

  // row settings
  .dropdown {
    button {
      // no caret
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
        outline: none;
      }
      .red > * {
        color: $danger !important;
      }
      .dropdown-item {
        color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
          outline: none;
        }
        &:active {
          background: $primary-light;
        }
      }
    }
  }

  .table-wrapper {
    border-radius: 10px;
    box-shadow: $box-shadow;
  }

  .table-title {
    text-align: center;
    text-transform: uppercase;
    background: $primary;
    padding: 3px;
    opacity: 0.75;
    color: white;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.05em;
    border-radius: 10px 10px 0px 0px;
  }

  table {
    background: white;
    text-overflow: ellipsis;
    font-size: 16px;
    margin: 0;
    border-radius: 10px;
      th {
        border: none;
        text-transform: uppercase;
        font-size: 14px;
        color: rgba($primary, 0.75)!important;
        font-weight: 600;
      }
      thead {
        border: none !important;
        background: $fin-white!important;
        border-radius: 10px;
        th {
          padding: 23px 15px;
        }
      }
    /*
    .first-column {
      max-width: 160px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .description {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    td {
      padding: 6px 15px;
      .actions {
        max-width: 60px;
      }
      .amount {
        text-align: right;
      }
    }
    */
  }

  .table {
    min-height: 100%;
    thead {
      th {
        &:first-child {
          border-top-left-radius: 0!important;
        }

        &:last-child {
          border-top-right-radius: 0!important;
        }
      }
    }
  }
}
</style>
