<template>
    <div class="confirm-user-subscription">
      <div v-if="isLoadingPage">
        <Loader/>
      </div>
      <div v-else class="container-fluid">
        <div class="row mt-5">
          <div class="col-12 d-flex justify-content-center">
            <div class="logo">
              <img :src="logo" alt="NinaFin logo">
            </div>
          </div>
        </div>
        <!--to do rivedere condizioni v-if-->
        <div class="pretty-card my-4 text-center">
          <div v-if="role === 'owner'" class="row mt-2">
            <div v-if="showError" style="margin: auto;">
              <img :src="errorImg" alt="" class="mb-4">
              <h4 class="text-center mb-4">C'è un problema :(</h4>
              <p class="text-center mb-4">
                Il link è scaduto o è già stato usato.
              </p>
              <router-link :to="{ name: 'login'}" tag="button" class="btn btn-fin">
                Vai al login
              </router-link>
            </div>
            <div v-else style="margin: auto;">
              <img :src="successImg" alt="" class="mb-4">
              <h4 class="text-center mb-4">Complimenti!</h4>
              <p class="text-center mb-4">
                Il tuo account è ora attivo.
              </p>
              <router-link :to="{ name: 'login'}" tag="button" class="btn btn-fin">
                Vai al login
              </router-link>
            </div>
          </div>
          <div v-else>
            <div v-if="!subscriptionSuccess">
              <h4>Benvenuto su NinaFin!</h4>
              <p>Hai ottenuto i permessi di {{getRole(role)}} per l'account {{company.name}}. Completa la registrazione per iniziare.</p>
              <div class="row">
                <div class="col-6">
                  <b-form-group
                      class="mb-3"
                      id="input-group-name"
                      label="Nome"
                      label-for="input-name"
                      :class="{ invalid: !isValidName && showErrorName }"
                  >
                      <b-form-input
                      id="input-name"
                      v-model="name"
                      type="text"
                      @blur="showErrorName = true"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="false" :class="{ invisible: isValidName || !showErrorName }" >
                          <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido
                      </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                      class="mb-3"
                      id="input-group-surname"
                      label="Cognome"
                      label-for="input-surname"
                      :class="{ invalid: !isValidSurname && showErrorSurname }"
                  >
                      <b-form-input
                      id="input-surname"
                      v-model="surname"
                      type="text"
                      @blur="showErrorSurname = true"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="false" :class="{ invisible: isValidSurname || !showErrorSurname }" >
                          <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido
                      </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <b-form-group
                    id="input-password"
                    label="Password"
                    label-for="input-password"
                    :class="{ invalid: !isValidPassword && showErrorPassword }"
                  >
                    <b-input-group>
                      <b-form-input
                        id="input-iva"
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        @blur="showErrorPassword = true"
                      ></b-form-input>
                      <b-input-group-append>
                        <div class="eye" role="button" @click="showPassword = !showPassword">
                            <i v-if="!showPassword" class="fas fa-eye"></i>
                            <i v-if="showPassword" class="fas fa-eye-slash"></i>
                        </div>
                      </b-input-group-append>
                      <b-form-invalid-feedback
                        :state="false"
                        :class="{ invisible: isValidPassword || !showErrorPassword }"
                      >
                      <i class="fas fa-exclamation-circle"></i>
                        La password deve essere lunga almeno 6 caratteri e deve
                        contenere almeno una maiuscola, una minuscola e un
                        numero.
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="input-passwordConfirm"
                    label="Ripeti password"
                    label-for="input-passwordConfirm"
                    :class="{ invalid: !isValidPassword2 && showErrorPassword2 }"
                  >
                    <b-input-group>
                      <b-form-input
                        id="input-passwordConfirm"
                        v-model="password2"
                        :type="showPassword ? 'text' : 'password'"
                        @blur="showErrorPassword2 = true"
                      ></b-form-input>
                      <b-input-group-append>
                        <div class="eye" role="button" @click="showPassword = !showPassword">
                            <i v-if="!showPassword" class="fas fa-eye"></i>
                            <i v-if="showPassword" class="fas fa-eye-slash"></i>
                        </div>
                      </b-input-group-append>
                      <b-form-invalid-feedback
                        :state="false"
                        :class="{ invisible: isValidPassword2 || !showErrorPassword2 }"
                      >
                        <i class="fas fa-exclamation-circle"></i> Le due password devono coincidere
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mb-3 text-left">
                  <b-form-checkbox v-model="privacyPolicy" name="input-last-of-month">
                    Ho letto e accetto la
                    <a :href="privacyPolicyLink" target="blank">privacy policy</a>.
                  </b-form-checkbox>
                </div>
                <div class="col-12 mb-3 text-left">
                  <b-form-checkbox v-model="termsOfUse" name="input-last-of-month">
                    Ho letto e accetto i
                    <a :href="termsOfUseLink" target="blank">termini di utilizzo</a>
                    di NinaFin.
                  </b-form-checkbox>
                </div>
              </div>

              <custom-button
                :isLoading="loading"
                :class="{'disabled': !isValidForm}"
                label="Avanti"
                class="w-100 mt-4"
                @click.prevent.native="onSubmit"
              />
            </div>
            <div v-else>
              <img :src="successImg" alt="" class="mb-4">
              <h4 class="text-center mb-4">Complimenti!</h4>
              <p class="text-center mb-4">
                Il tuo account è ora attivo.
              </p>
              <router-link :to="{ name: 'login'}" tag="button" class="btn btn-fin">
                Vai al login
              </router-link>
            </div>
          </div>

          <ErrorCard v-if="errorMessage">
            <template #message >
              {{ errorMessage }}
            </template>
          </ErrorCard>
        </div>
      </div>
    </div>
</template>
<script>
import Loader from '@/views/components/Layout/Loading.vue';
import logo from '@/assets/images/fin-big-logo.svg';
import successImg from '@/assets/images/account-activation-done.svg';
import errorImg from '@/assets/images/account-activation-error.svg';
import Button from '@/views/components/Button.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';

import { BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BFormInvalidFeedback, BFormCheckbox } from 'bootstrap-vue';
import { isFilled } from '@/helpers/formValidation.js';

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BInputGroupAppend,
    'custom-button': Button,
    ErrorCard,
    Loader,
  },
  data () {
    return {
      showError: false,
      isLoadingPage: true,
      loading: false,
      logo: logo,
      successImg: successImg,
      errorImg: errorImg,
      showPassword: false,
      errorMessage: '',
      subscriptionSuccess: false,

      privacyPolicyLink: 'https://ninafin.it/wp-content/themes/ninafin/assets/pdf/privacy-policy.pdf',
      termsOfUseLink: 'https://ninafin.it/wp-content/themes/ninafin/assets/pdf/condizioni-utilizzo.pdf',

      showErrorName: false,
      showErrorSurname: false,
      showErrorPassword: false,
      showErrorPassword2: false,

      role: null,
      email: null,
      company: null,
      name: '',
      surname: '',
      password: '',
      password2: '',
      privacyPolicy: false,
      termsOfUse: false,
    };
  },
  computed: {
    isValidForm () {
      return this.isValidName && this.isValidSurname && this.isValidPassword && this.isValidPassword2 && this.privacyPolicy && this.termsOfUse;
    },
    isValidName () {
      return isFilled(this.name);
    },
    isValidSurname () {
      return isFilled(this.surname);
    },
    isValidPassword () {
      const re = /(?=^.{6,}$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      return isFilled(this.password) && re.test(this.password);
    },
    isValidPassword2 () {
      return (this.password === this.password2);
    },
  },
  methods: {
    confirmUserSubscription () {
      this.isLoadingPage = true;
      this.$api.confirmUserSubscription(this.$route.params.email, this.$route.params.token)
        .then((res) => {
          this.$log.debug(res.data);
        })
        .catch((e) => {
          this.showError = true;
          this.$log.error(e);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
    getUserReservation () {
      this.isLoadingPage = true;
      this.$api.getUserReservation(this.$route.params.email, this.$route.params.token)
        .then((res) => {
          this.$log.debug(res.data);
          this.company = res.data.role.company;
          this.role = res.data.role.name;
          this.email = res.data.email;
          if (this.role === 'owner') {
            this.confirmUserSubscription();
          }
        })
        .catch((e) => {
          this.showError = true;
          this.$log.error(e);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
    getRole (role) {
      if (role === 'owner') return 'proprietario';
      if (role === 'manager') return 'manager';
      if (role === 'visitor') return 'visitatore';
    },
    onSubmit () {
      this.errorMessage = '';
      if (this.isValidForm) {
        this.loading = true;
        const user = Object.assign({}, { name: this.name, surname: this.surname, password: this.password });
        this.$log.debug(user);
        this.$api.registerUser(this.$route.params.email, this.$route.params.token, user)
          .then((res) => {
            this.$log.debug(res.data);
            this.subscriptionSuccess = true;
          })
          .catch((e) => {
            this.showError = true;
            this.$log.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!this.isValidName) {
          this.showErrorName = true;
        } else if (!this.isValidSurname) {
          this.showErrorSurname = true;
        } else if (!this.isValidPassword) {
          this.showErrorPassword = true;
        } else if (!this.isValidPassword2) {
          this.showErrorPassword2 = true;
        } else if (!this.privacyPolicy || !this.termsOfUse) {
          this.errorMessage = 'Devi accettare i termini di utilizzo e la privacy policy per proseguire.';
        }
      }
    },
  },
  mounted () {
    this.getUserReservation();
  },
};
</script>
<style lang="scss">
  .confirm-user-subscription {
    background-image: url(~@/assets/images/fin-bg.png);
    background-position: center;
    background-color: $primary-lighter;
    align-items: center;
    .container-fluid {
      max-width: 968px;
    }
  }
  .eye {
    position: relative;
    top: 3px;
  }
</style>
