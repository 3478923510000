<template>
  <div class="setup">
    <div v-if="isLoadingPage">
      <Loader />
    </div>
    <div class="container-fluid">
      <div class="row mt-5">
        <div class="col-12 d-flex justify-content-center">
          <div class="logo">
            <img :src="logo" alt="NinaFin logo">
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-3">
          <div class="pretty-card">
            <div class="stepper" :class="{ 'disabled': currentStep.step === 6 }">
              <div v-for="(step, i) in steps" :key="step.name" @click="goToPage(i)" class="step" :class="{'disabled': currentStep.step < i, 'completed': steps[i]?.completed && currentStep.step !== i, 'active': currentStep.step === i}">
                <div class="dot"></div>
                <div class="name">{{step.label}}</div>
              </div>
            </div>
          </div>
          <div class="pretty-card py-3">
            <router-link :to="{ name: 'logout'}" class="logout">
              <i class="fas fa-sign-out-alt pr-2"></i>
              Log out
            </router-link>
          </div>
        </div>
        <div class="col-9">
          <div class="pretty-card">
            <div v-show="!formHasLoaded" class="custom-loader-container">
              <Loader2 class="custom-loader" />
            </div>

            <KeepAlive>
              <component :is="currentStep.name" v-if="formHasLoaded" :propForm="form" :banks="banks" :setupBanks="userBanks" @changePage="changePage" @saveData="saveData" @updateLoans="updateLoans" @openSnackbar="openSnackbar" @setTaxFormAsCompleted="setTaxFormAsCompleted"></component>
            </KeepAlive>

            <ErrorCard v-if="errorMessage">
                <template #message >
                {{ errorMessage }}
                </template>
            </ErrorCard>
          </div>

        </div>
      </div>
    </div>

    <Snackbar :text="snackbarText" ref="snackbarLoans" class="up"/>
  </div>
</template>

<script>
import SetupAzienda from '@/views/components/Setup/SetupAzienda.vue';
import SetupContiCorrenti from '@/views/components/Setup/SetupContiCorrenti.vue';
import SetupDipendenti from '@/views/components/Setup/SetupDipendenti.vue';
import SetupIVA from '@/views/components/Setup/SetupIVA.vue';
import SetupFinanziamenti from '@/views/components/Setup/SetupFinanziamenti.vue';
import SetupCassettoFiscale from '@/views/components/Setup/SetupCassettoFiscale.vue';
import SetupCompleted from '@/views/components/Setup/SetupCompleted.vue';

import ErrorCard from '@/views/components/ErrorCard.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Loader2 from '@/views/components/Layout/Spinner.vue';
import logo from '@/assets/images/nina-fin-logo-new.svg';
import img from '@/assets/images/illustration.svg';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  components: {
    SetupAzienda,
    SetupContiCorrenti,
    SetupDipendenti,
    SetupIVA,
    SetupFinanziamenti,
    SetupCassettoFiscale,
    SetupCompleted,
    ErrorCard,
    Loader,
    Loader2,
    Snackbar,
  },
  data () {
    return {
      isLoadingPage: true,
      formHasLoaded: false,
      loading: false,
      snackbarText: '',
      errorMessage: '',

      logo: logo,
      img: img,
      banks: [],
      usersBanks: [],

      steps: [
        { step: 0, label: 'Dati Azienda', name: 'SetupAzienda', completed: false },
        { step: 1, label: 'Conti Correnti', name: 'SetupContiCorrenti', completed: false },
        { step: 2, label: 'Personale', name: 'SetupDipendenti', completed: false },
        { step: 3, label: 'Finanziamenti', name: 'SetupFinanziamenti', completed: false },
        // { step: 4, label: 'Cassetto Fiscale', name: 'SetupCassettoFiscale', completed: false },
        // { step: 5, label: 'Periodicità IVA', name: 'SetupIVA', completed: false },
        { step: 4, label: 'Setup Completato!', name: 'SetupCompleted', completed: false },
      ],
      currentStep: {},
      skipIVA: true,

      form: {
        step: 0,
        // Step 1
        businessName: '',
        iva: '',
        fiscalCode: '',
        ateco: '',
        address: '',
        province: '',
        municipality: '',
        cap: null,
        address2: '',
        province2: '',
        municipality2: '',
        cap2: null,
        // Step 2
        bankAccounts: [],
        cash: null,
        // Step 3
        f24: null,
        f24BankAccount: null,
        payday: null,
        salary: null, // defaultView
        numberOfMonths: null, // defaultView
        employees: [],
        salariesBankAccount: null,
        mode: 'detailed', // 'detailed' or 'cumulative'
        // Step 4
        loans: [],
      },
    };
  },
  computed: {
    userBanks () {
      /*
      if (this.banks && this.form.bankAccounts) {
        return this.banks.filter(bank => {
          return this.form.bankAccounts.find(account => account.bankId === bank.id);
        });
      } else return [];
      */
      return this.form.bankAccounts;
    },
    formIsEmpty: {
      get () {
        return this.form ? Object.keys(this.form).length === 0 : null;
      },
      set (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    getMe () {
      this.$oauth2.getMe(true);
    },
    changePage (back = false) {
      this.errorMessage = '';
      return back ? this.goToPreviousStep() : this.goToNextStep();
    },
    getBanks () {
      this.$api.fetchBanksList()
        .then((res) => {
          this.banks = res.data.bankDetails;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        });
    },
    getSetup () {
      this.$api.getSetup()
        .then((res) => {
          const newForm = res.data.form;
          if (newForm && Object.keys(res.data.form).length > 0) {
            this.form = newForm;
            this.$log.debug(this.form.step);

            const i = this.form.step ? this.form.step : 0;
            this.currentStep = this.steps[i];
            this.$log.debug('form.step ' + JSON.stringify(this.currentStep));

            this.steps.map(obj => {
              if (obj.step <= this.currentStep.step) {
                obj.completed = true;
              }
            });
          }
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.formHasLoaded = true;
        });
    },
    goToPage (i) {
      this.$log.debug('goToPage: ' + i);
      if (this.steps[i]?.completed) {
        this.currentStep = this.steps[i];
        this.errorMessage = '';
      }
    },
    goToPreviousStep () {
      const i = this.currentStep?.step;
      this.currentStep = this.steps[i - 1];
    },
    goToNextStep () {
      this.$log.debug('next step')
      const i = this.currentStep?.step + 1;
      this.currentStep = this.steps[i];
    },
    openSnackbar (text) {
      this.snackbarText = text;
      this.$refs.snackbarLoans.openSnackbar();
    },
    saveData (form) {
      let isCompleted = false;

      if (this.$route.query.fabrickonboardingsuccess !== undefined) {
        this.$router.replace({ query: null });
      }
      this.form = Object.assign(this.form, form);
      this.formHasLoaded = false;

      // if current step is the next to last one (the last one with fields to fill) set setup as completed
      const lastStepToFill = this.steps.length - 2;
      if (this.currentStep.step === lastStepToFill) {
        isCompleted = true;
        this.$log.debug('setup completed');
      }

      this.form.step = this.currentStep.step + 1;

      this.$api.updateSetup(this.form, isCompleted)
        .then((res) => {
          this.changePage();
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.getSetup(); // updates formHasLoaded
        });
    },
    setTaxFormAsCompleted (isCompleted = false) {
      this.skipIVA = !isCompleted;
    },
    updateLoans (form) {
      this.form = Object.assign(this.form, form);
      // this.formHasLoaded = false;
      this.$api.updateSetup(this.form)
        .then((res) => {
          this.$log.debug(res.data);
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          // this.getSetup(); // updates formHasLoaded
        });
    },
  },
  created () {
    const me = this.$oauth2.me;
    this.currentStep = this.steps[0];

    if (!me?.product || me?.product?.expired) {
      if (me?.companies[0]?.role === 'owner') {
        this.$router.replace('checkout');
      } else {
        this.$router.replace('checkout');
      }
    } else if (me?.companies[0]?.hasBeenSetup) {
      this.$router.replace('dashboard');
    } else {
      this.getBanks();
      this.getSetup();
      this.isLoadingPage = false;
    }
  },
  watch: {
    currentStep: function (newStep) {
      // update me object after company creation
      const lastStepIndex = this.steps.length - 1;
      if (newStep.step === lastStepIndex) {
        this.getMe();
      }
      // Skip IVA step if the previous step is not completed
      if (this.currentStep.name === 'SetupIVA' && this.skipIVA) {
        this.$log.debug('skip IVA')
        this.saveData();
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'logout') {
      this.$log.debug(to.name);
      if (confirm('Stai lasciando la pagina senza salvare. Vuoi continuare?') === true) {
        return next();
      } else {
        return next(false);
      }
    }

    return next();
  },
};
</script>

<style lang="scss">
.setup {
  // to do change to svg pattern
  background-image: url(~@/assets/images/fin-bg.png);
  background-position: center;
  background-color: $primary-lighter;
  align-items: center;
  .container-fluid {
    max-width: 1200px;
    position: relative;
  }
  .custom-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }
  .stepper {
    counter-reset: counter;
    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
    .step {
      counter-increment: counter;
      transition: all 0.5s ease-in-out;
      cursor: pointer;
      display: flex;
      justify-content: left;
      align-items: center;
      &.active {
        .name {
          font-size: 16px!important;
        }
        .dot {
          transform: scale(1.2);
          &::before {
            content: counter(counter)!important;
            font-size: 14px;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      &:nth-of-type(1)::after {
        opacity: 0;
      }
      &::after {
        content: '';
        height: 20px;
        width: 2px;
        border-radius: 1px;
        display: block;
        position: relative;
        background: $primary;
        bottom: 30px;
        left: 13px;
        order: -1;
      }
      .dot {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 24px;
        min-width: 24px;
        height: 24px;
        z-index: 1;
        font-weight: 700;
        transform: all 0.5s ease-in;
        margin-bottom: 3px;
        &::before {
          content: counter(counter);
          position: absolute;
          z-index: -1;
          background: $primary;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .name {
        color: $primary;
        margin-left: 10px;
        font-weight: 600;
      }
      &.disabled {
        opacity: 0.4;
        transition: opacity 0.5s ease-in-out;
        cursor: default;
        .dot{
          font-size: 12px;
          &::before {
            height: 24px;
            width: 24px;
          }
        }
        .name {
          font-size: 13px;
        }
      }
      &.completed {
        .dot {
          font-size: 12px;
          &::before {
            content: '';
            display: block;
            background: $primary;
          }
          &::after {
            content: url('~@/assets/images/check-icon.svg')!important;
            position: relative;
            top: 2px;
            transform: scale(0.7);
          }
        }
        .name {
          font-size: 13px;
        }
      }
    }
  }
  .logout {
    font-size: 14px;
    font-weight: 500;
    &:hover {
      text-decoration: none;
      font-weight: 600;
    }
  }
}
</style>
