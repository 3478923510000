import log from '../services/log';

export default {
  install (Vue) {
    Vue.log = log;

    Object.defineProperties(Vue.prototype, {
      $log: {
        get: () => Vue.log,
      },
    });
  },
  _getService: () => log,
};
