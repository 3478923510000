<template>
  <AwlLoader :imagePath="img" animated fitViewport imageClasses="logo"></AwlLoader>
</template>

<script>
import img from '@/assets/images/nina-fin-logo-new.svg';

export default {
  components: {
    AwlLoader: () => import('../../../libs/Loader/components/AwlBouncingLoader'),
  },
  data () {
    return {
      img: img,
    };
  }
};
</script>

<style lang="scss">

.awl-loader {
  background: #fff;
  .logo {
    max-width: 85px;
  }
}

</style>
