<template>
  <div>
    <h5 class="mb-4">Cambio password</h5>
    <form @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-4">
            <b-form-group
                id="input-current-password"
                label="Password attuale"
                label-for="input-current-password"
                :class="{ invalid: showErrorCurrentPassword }"
            >
                <b-input-group>
                    <b-form-input
                    id="input-current-password"
                    @blur="showErrorCurrentPassword = false"
                    v-model="form.currentPassword"
                    :type="showPassword ? 'text' : 'password'"
                    ></b-form-input>
                    <b-form-invalid-feedback
                    :state="false"
                    :class="{ invisible: isValidCurrentPassword || !showErrorCurrentPassword }"
                    >
                    <i class="fas fa-exclamation-circle"></i>
                    Il campo password non può essere vuoto
                    </b-form-invalid-feedback>
                    <b-input-group-append>
                        <div class="eye m-1" role="button" @click="showPassword = !showPassword">
                            <i v-if="!showPassword" class="fas fa-eye"></i>
                            <i v-if="showPassword" class="fas fa-eye-slash"></i>
                        </div>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>

        </div>
        <div class="col-4">
        <b-form-group
          class="mb-4"
          id="input-new-password"
          label="Password"
          label-for="input-password"
          :class="{ invalid: !isValidNewPassword && showErrorNewPassword }"
        >
            <b-input-group>
                <b-form-input
                    id="input-new-password"
                    v-model="form.newPassword"
                    :type="showPassword2 ? 'text' : 'password'"
                    @blur="showErrorNewPassword = true"
                ></b-form-input>
                <b-form-invalid-feedback
                    :state="false"
                    :class="{ invisible: isValidNewPassword || !showErrorNewPassword }"
                >
                <i class="fas fa-exclamation-circle"></i>
                    La password deve essere lunga almeno 6 caratteri e deve
                    contenere almeno una maiuscola, una minuscola e un
                    numero.
                </b-form-invalid-feedback>
                    <b-input-group-append>
                        <div class="eye m-1" role="button" @click="showPassword2 = !showPassword2">
                            <i v-if="!showPassword2" class="fas fa-eye"></i>
                            <i v-if="showPassword2" class="fas fa-eye-slash"></i>
                        </div>
                    </b-input-group-append>
            </b-input-group>
        </b-form-group>
      </div>
        <div class="col-4">
          <b-form-group
            class="mb-4"
            id="input-passwordConfirm"
            label="Ripeti password"
            label-for="input-passwordConfirm"
            :class="{ invalid: !isValidRepeatPassword && showErrorPasswordConfirm }"
          >
            <b-input-group>
                <b-form-input
                id="input-passwordConfirm"
                v-model="form.repeatPassword"
                :type="showPassword2 ? 'text' : 'password'"
                @blur="showErrorPasswordConfirm = true"
                ></b-form-input>
                <b-form-invalid-feedback
                :state="false"
                :class="{ invisible: isValidRepeatPassword || !showErrorPasswordConfirm }"
                >
                <i class="fas fa-exclamation-circle"></i> Le due password devono coincidere
                </b-form-invalid-feedback>
                <b-input-group-append>
                    <div class="eye m-1" role="button" @click="showPassword2 = !showPassword2">
                        <i v-if="!showPassword2" class="fas fa-eye"></i>
                        <i v-if="showPassword2" class="fas fa-eye-slash"></i>
                    </div>
                </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </div>

      <div class="row d-flex justify-content-end">
        <div class="col-4">
          <custom-button
            :isLoading="loading"
            :disabled="!isValidForm"
            label="Salva"
            class="w-100"
            @click.prevent.native="onSubmit"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Button from '@/views/components/Button.vue';
import { isFilled, isSafe } from '@/helpers/formValidation.js';

export default {
  components: {
    'custom-button': Button,
  },
  data () {
    return {
      loading: false,
      form: {
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
      },
      showErrorCurrentPassword: false,
      showErrorNewPassword: false,
      showErrorPasswordConfirm: false,

      showPassword: false,
      showPassword2: false,
    };
  },
  computed: {
    isValidForm () {
      return this.isValidCurrentPassword && this.isValidNewPassword && this.isValidRepeatPassword;
    },
    isValidCurrentPassword: function () {
      return isFilled(this.form?.currentPassword);
    },
    isValidNewPassword () {
      const re = /(?=^.{6,}$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      return isFilled(this.form.newPassword) && isSafe(this.form.newPassword) && re.test(this.form.newPassword);
    },
    isValidRepeatPassword () {
      return (this.form.newPassword === this.form.repeatPassword);
    },
  },
  methods: {
    onSubmit () {
      this.$emit('setError', '');
      if (this.isValidForm) {
        this.$log.debug(this.form);
        this.loading = true;
        this.$api.changePassword(this.form.currentPassword, this.form.newPassword, this.form.repeatPassword)
          .then((res) => {
            this.$log.debug(res.data);
            this.$emit('openSnackbar', 'Password cambiata con successo');
            this.form = {};
            this.showErrorCurrentPassword = false;
            this.showErrorNewPassword = false;
            this.showErrorPasswordConfirm = false;
          })
          .catch((e) => {
            const error = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto, riprova o contatta l'assistenza se il problema persiste";
            this.$emit('setError', error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$emit('setError', 'Controlla i dati immessi');
      }
    },
  },
};
</script>

<style>

</style>
