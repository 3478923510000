<template>
  <div class="registration-form d-flex flex-column">
    <div v-if="isLoadingPage">
      <Loader />
    </div>
    <div class="container-fluid flex-grow-1">
      <div class="row mt-5">
        <div class="col-12 d-flex justify-content-center">
          <div class="logo">
            <div class="custom-height" v-show="isLoadingLogo"></div>
            <img :src="customLogo ? customLogo : logo" alt="NinaFin logo" height="64" v-show="!isLoadingLogo">
          </div>
        </div>
      </div>
      <div class="pretty-card mt-5">
        <div v-if="showRegistrationForm">
          <h2 class="h4 mb-4">Crea un nuovo account</h2>
          <div>
            <form
            @keydown.enter="onSubmit"
            @submit.prevent="onSubmit"
            class="text-start" novalidate>
              <div class="row">
                <div class="col-4">
                  <b-form-group
                    id="input-group-firstName"
                    label="Nome"
                    label-for="input-firstName"
                    :class="{ invalid: !isValidFirstName && showErrorFirstName }"
                  >
                    <b-form-input
                      id="input-firstName"
                      v-model="form.firstName"
                      type="text"
                      @blur="showErrorFirstName = true"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :state="false"
                      :class="{
                        invisible: isValidFirstName || !showErrorFirstName,
                      }"
                    >
                      <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group
                    class="mb-4"
                    id="input-group-lastName"
                    label="Cognome"
                    label-for="input-lastName"
                    :class="{ invalid: !isValidLastName && showErrorLastName }"
                  >
                    <b-form-input
                      id="input-lastName"
                      v-model="form.lastName"
                      type="text"
                      @blur="showErrorLastName = true"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :state="false"
                      :class="{ invisible: isValidLastName || !showErrorLastName }"
                    >
                      <i class="fas fa-exclamation-circle"></i> Inserisci un cognome valido
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group
                    class="mb-4"
                    id="input-group-email"
                    label="Email"
                    label-for="input-email"
                    :class="{ invalid: !isValidEmail && showErrorEmail }"
                  >
                    <b-form-input
                      id="input-iva"
                      v-model="form.email"
                      type="email"
                      @blur="showErrorEmail = true"
                      autocomplete="off"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :state="false"
                      :class="{ invisible: isValidEmail || !showErrorEmail }"
                    >
                      <i class="fas fa-exclamation-circle"></i> Inserisci una email valida
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <b-form-group
                    id="input-password"
                    label="Password"
                    label-for="input-password"
                    autocomplete="off"
                    :class="{ invalid: !isValidPassword && showErrorPassword }"
                  >
                    <b-input-group>
                      <b-form-input
                        id="input-password"
                        v-model="form.password"
                        :type="showPassword ? 'text' : 'password'"
                        @blur="showErrorPassword = true"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="false"
                        :class="{ invisible: isValidPassword || !showErrorPassword }"
                      >
                      <i class="fas fa-exclamation-circle"></i>
                        La password deve essere lunga almeno 6 caratteri e deve
                        contenere almeno una maiuscola, una minuscola e un
                        numero.
                      </b-form-invalid-feedback>
                      <b-input-group-append>
                        <div class="eye" role="button" @click="showPassword = !showPassword">
                            <i v-if="!showPassword" class="fas fa-eye"></i>
                            <i v-if="showPassword" class="fas fa-eye-slash"></i>
                        </div>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    class="mb-4"
                    id="input-passwordConfirm"
                    label="Ripeti password"
                    label-for="input-passwordConfirm"
                    :class="{ invalid: !isValidRepeatPassword && showErrorPasswordConfirm }"
                  >
                    <b-input-group>
                      <b-form-input
                        id="input-passwordConfirm"
                        v-model="form.passwordConfirm"
                        :type="showPassword ? 'text' : 'password'"
                        @blur="showErrorPasswordConfirm = true"
                        autocomplete="off"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="false"
                        :class="{ invisible: isValidRepeatPassword || !showErrorPasswordConfirm }"
                      >
                        <i class="fas fa-exclamation-circle"></i> Le due password devono coincidere
                      </b-form-invalid-feedback>
                      <b-input-group-append>
                        <div class="eye" role="button" @click="showPassword = !showPassword">
                            <i v-if="!showPassword" class="fas fa-eye"></i>
                            <i v-if="showPassword" class="fas fa-eye-slash"></i>
                        </div>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
            </form>

            <b-form-checkbox v-model="termsHasBeenAccepted" @blur="showErrorPrivacy = true;" class="mb-3">
              <div :class="{ red: !termsHasBeenAccepted && showErrorPrivacy }">
                Ho preso visione della <a href="https://ninafin.it/wp-content/themes/ninafin/assets/pdf/privacy-policy.pdf" target="blank" class="font-weight-bold" :class="{ red: !termsHasBeenAccepted && showErrorPrivacy }">privacy policy</a> e dei <a href="https://ninafin.it/wp-content/themes/ninafin/assets/pdf/condizioni-utilizzo.pdf" target="blank" class="font-weight-bold" :class="{ red: !termsHasBeenAccepted && showErrorPrivacy }">termini e condizioni di utilizzo</a> del sito.
              </div>
            </b-form-checkbox>

            <b-form-checkbox v-model="showPromotionalCode" class="mb-3" v-if="!isWhiteLabel">
              Ho un codice promozionale
            </b-form-checkbox>

            <div class="row" v-show="showPromotionalCode" v-if="!isWhiteLabel">
              <b-input-group
                class="col-6"
                id="promotional-code"
                label="Codice promozionale"
                label-for="input-promotional-code"
                :state="isAValidPromotionalCode"
              >
                <b-form-input
                  id="input-promotional-code"
                  v-model="form.promotionalCode"
                  type="text"
                  :state="isAValidPromotionalCode"
                  @update="checkPromotionalCode()"
                  @blur="checkPromotionalCode()"
                  debounce="500"
                ></b-form-input>

                  <b-input-group-append>
                    <div class="loading-input" v-if="loadingPromotionalCodeResponse">
                      <i class="fas fa-spinner fa-spin"></i>
                    </div>
                  </b-input-group-append>

                  <b-form-invalid-feedback
                    :state="isAValidPromotionalCode"
                  >
                    <i class="fas fa-exclamation-circle"></i> {{promotionalCodeErrorMsg}}
                  </b-form-invalid-feedback>

                  <b-form-valid-feedback
                    :state="isAValidPromotionalCode"
                     style="visibility: visible"
                  >
                    <i class="fas fa-check-circle"></i> Il codice inserito è valido
                  </b-form-valid-feedback>

              </b-input-group>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-4 ml-auto">
              <custom-button
                :isLoading="loading"
                class="w-100"
                :class="{disabled: !isValidForm}"
                label="Registrati"
                @click.prevent.native="onSubmit"
              />
            </div>
          </div>

        </div>
        <div v-else class="text-center">
          <div v-if="showRegistrationError" style="margin: auto;">
              <img :src="errorImg" alt="" class="mb-4">
              <h4 class="text-center mb-4">C'è stato un problema :(</h4>
              <p class="text-center mb-4">
                Prova a registrarti in un secondo momento oppure contatta l'assistenza.
              </p>
              <router-link :to="{ name: 'login'}" tag="button" class="btn btn-fin">
                Vai al login
              </router-link>
            </div>
            <div v-else style="margin: auto;">
              <img :src="successImg" alt="" class="mb-4">
              <h4 class="text-center mb-4">Verifica il tuo indirizzo e-mail</h4>
              <p class="text-center mb-4">
                Un'email è stata inviata a {{ form.email }}. Clicca sul link nell'email per confermare la registrazione.
              </p>
              <router-link :to="{ name: 'login'}" tag="button" class="btn btn-fin">
                Vai al login
              </router-link>
            </div>
        </div>
        <ErrorCard v-if="errorFromServer">
          <template #message>
            {{ errorFromServer }}
          </template>
        </ErrorCard>
      </div>

      <div class="row">
        <div class="col text-center">
          <router-link :to="{ name: 'login' }">
            Hai già un account? <b>Clicca qui!</b>
          </router-link>
        </div>
      </div>

    </div>
    <FinFooter />
  </div>
</template>

<script>
import logo from '@/assets/images/nina-fin-logo-new.svg';
import img from '@/assets/images/illustration.svg';
import successImg from '@/assets/images/account-activation-done.svg';
import errorImg from '@/assets/images/account-activation-error.svg';

import { BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroup, BInputGroupAppend } from 'bootstrap-vue';
import Button from '@/views/components/Button.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import FinFooter from '@/views/components/Layout/FinFooter.vue';

import { isFilled } from '@/helpers/formValidation.js';

import $config from '@/libs/Config/services/config.js';
import { load } from 'recaptcha-v3';

export default {
  components: {
    Loader,
    'custom-button': Button,
    ErrorCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormInvalidFeedback,
    BInputGroupAppend,
    FinFooter,
  },
  data () {
    return {
      successImg: successImg,
      errorImg: errorImg,
      showRegistrationForm: true,
      showRegistrationError: true,
      errorFromServer: null,

      isLoadingPage: true,
      isLoadingLogo: true,

      isWhiteLabel: null,

      loading: false,
      logo: logo,
      customLogo: null,
      img: img,
      showPassword: false,
      showPromotionalCode: Boolean(this.$route.query.promotionalCode),

      showErrorFirstName: false,
      showErrorLastName: false,
      showErrorEmail: false,
      showErrorPassword: false,
      showErrorPasswordConfirm: false,
      showErrorPrivacy: false,
      isAValidPromotionalCode: null,
      promotionalCodeErrorMsg: '',
      loadingPromotionalCodeResponse: false,

      termsHasBeenAccepted: false,

      form: {
        // Step 1
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirm: '',
        promotionalCode: this.$route.query.promotionalCode ? this.$route.query.promotionalCode : '',
        recaptchaV3Token: '',
      },
      disablePromotionalCodeInput: this.$route.query?.promotionalCode?.length > 0,
    };
  },
  computed: {
    isValidForm () {
      return this.termsHasBeenAccepted && this.isValidFirstName && this.isValidLastName && this.isValidEmail && this.isValidPassword && this.isValidRepeatPassword && this.isValidPromotionalCodeField;
    },
    isValidFirstName () {
      return isFilled(this.form.firstName);
    },
    isValidLastName () {
      return isFilled(this.form.lastName);
    },
    isValidPromotionalCodeField () {
      return this.showPromotionalCode ? this.isAValidPromotionalCode : true;
    },
    isValidEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return isFilled(this.form.email) && re.test(this.form.email);
    },
    isValidPassword () {
      const re = /(?=^.{6,}$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      return isFilled(this.form.password) && re.test(this.form.password);
    },
    isValidRepeatPassword () {
      return (this.form.password === this.form.passwordConfirm);
    },
    formIsEmpty: {
      get () {
        return Object.keys(this.form).length === 0;
      },
      set (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    onSubmit: function () {
      this.errorFromServer = null;
      if (this.isValidForm) {
        this.onNext();
      } else {
        this.loading = true;
        if (!this.isValidFirstName) {
          this.showErrorFirstName = true;
        } else if (!this.isValidLastName) {
          this.showErrorLastName = true;
        } else if (!this.isValidEmail) {
          this.showErrorEmail = true;
        } else if (!this.isValidPassword) {
          this.showErrorPassword = true;
        } else if (!this.isValidRepeatPassword) {
          this.showErrorPasswordConfirm = true;
        } else if (!this.termsHasBeenAccepted) {
          this.showErrorPrivacy = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 100);
      }
    },
    checkPromotionalCode () {
      this.promotionalCodeErrorMsg = '';
      this.isAValidPromotionalCode = null;
      this.loadingPromotionalCodeResponse = true;
      this.$api.checkPromotionalCode(this.form.promotionalCode)
        .then((res) => {
          this.$log.debug(res.data);
          this.isAValidPromotionalCode = true;

          this.customLogo = res.data.theme?.bigLogoUrl;
          this.isWhiteLabel = Boolean(res.data.theme);
        })
        .catch((e) => {
          this.$log.debug(e.response.data);
          this.isAValidPromotionalCode = false;
          this.promotionalCodeErrorMsg = e.response.data?.error;
        })
        .finally(() => {
          this.loadingPromotionalCodeResponse = false;
          this.isLoadingLogo = false;
        });
    },
    makeCaptchaInvisible () {
      const grecaptcha = document.querySelector('.grecaptcha-badge');
      if (grecaptcha) {
        grecaptcha.style.display = 'none';
      }
    },
    onNext () {
      if (!this.showPromotionalCode) {
        this.form.promotionalCode = '';
      }
      this.$log.debug('Sending new registration');
      this.isLoadingPage = true;

      load($config.get('recaptchav3_sitekey')).then((recaptcha) => {
        recaptcha.execute('login').then((recaptchaV3Token) => {
          this.form.recaptchaV3Token = recaptchaV3Token;
          this.$api.createNewUser(this.form)
            .then((res) => {
              this.$log.debug(res.data);
              this.showRegistrationError = false;
              this.makeCaptchaInvisible();
            })
            .catch((e) => {
              this.showRegistrationError = true;
              this.errorFromServer = e.response.data?.error;
              this.$log.error(e);
            })
            .finally(() => {
              this.showRegistrationForm = false;
              this.isLoadingPage = false;
            });
        });
      });
    },
  },
  mounted () {
    this.isLoadingPage = false;
    if (this.$route.query.promotionalCode) {
      this.checkPromotionalCode();
    } else {
      this.isLoadingLogo = false;
    }

    load($config.get('recaptchav3_sitekey'));
  },
};
</script>

<style lang="scss">
.registration-form {
  .text-red > * {
    color: $danger !important;
  }
  background-image: url(~@/assets/images/fin-bg.png);
  background-position: center;
  background-color: $primary-lighter;
  align-items: center;

  .container-fluid {
    max-width: 1200px;
  }

  .eye {
    position: relative;
    top: 3px;
  }

  .loading-input{
    position: relative;
    top: 3px;
    right: 20px;
  }
}
</style>
