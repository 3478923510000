<template>
<div class="edit-instalments">
    <form
      ref="editInstalment"
      @submit.prevent="onSubmit"
      class="text-start"
      novalidate
      >
      <h5 class="mb-4">Dati fatturazione</h5>

      <div class="row">
          <div class="col-4">
              <b-form-group
                  id="input-group-denomination"
                  label="Denominazione"
                  label-for="input-denomination"
                  :class="{ invalid: !isValidDenomination && showErrorDenomination }"
              >
                  <b-form-input
                  id="input-denomination"
                  v-model="form.denominazione"
                  type="text"
                  @blur="showErrorDenomination = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidDenomination || !showErrorDenomination }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci una denominazione valida
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
          <div class="col-4">
              <b-form-group
                  id="input-group-fiscal-code"
                  label="Codice Fiscale"
                  label-for="input-fiscal-code"
                  :class="{ invalid: !isValidFiscalCode && showErrorFiscalCode }"
              >
                  <b-form-input
                  id="input-fiscal-code"
                  v-model="form.codice_fiscale"
                  type="text"
                  @blur="showErrorFiscalCode = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidFiscalCode || !showErrorFiscalCode }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un codice fiscale valido
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
          <div class="col-4">
              <b-form-group
                  id="input-group-iva"
                  label="Partita IVA"
                  label-for="input-iva"
                  :class="{ invalid: !isValidIva && showErrorIva }"
              >
                  <b-form-input
                  id="input-iva"
                  v-model="form.piva"
                  type="text"
                  @blur="showErrorIva = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidIva || !showErrorIva }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci una partita IVA valida
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group
            id="input-group-address"
            label="Indirizzo"
            label-for="input-address"
            :class="{ invalid: !isValidAddress && showErrorAddress }"
          >
            <b-form-input
              id="input-address"
              v-model="form.indirizzo"
              type="text"
              @blur="showErrorAddress = true"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="false"
              :class="{ invisible: isValidAddress || !showErrorAddress }"
            >
              <i class="fas fa-exclamation-circle"></i> Inserisci un
              indirizzo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col">
          <div class="row">
            <div class="col-8">
              <b-form-group
                id="input-group-municipality"
                label="Comune"
                label-for="input-municipality"
                :class="{
                  invalid: !isValidCity && showErrorCity,
                }"
              >
                <vue-select
                  v-model="form.comune"
                  ref="select1"
                  :options="suggestions"
                  label="name"
                  @search:blur="showErrorCity = true"
                  @search="fetchSuggestions"
                  :clearable="false"
                >
                  <template #selected-option="item">
                    {{ item.name }} {{ item.province ? `(${item.province})` : '' }}
                  </template>
                  <template #no-options>
                    <div class="text-left py-2 ml-3">
                      {{
                        searchValueMunicipality &&
                        searchValueMunicipality.length > 1
                          ? "Nessun comune trovato"
                          : "Inizia a scrivere..."
                      }}
                    </div>
                  </template>
                </vue-select>
                <b-form-invalid-feedback
                  :state="false"
                  :class="{
                    invisible:
                      isValidCity || !showErrorCity,
                  }"
                >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un
                  comune valido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group
                class="m-0"
                id="input-group-cap"
                label="CAP"
                label-for="input-cap"
                :class="{ invalid: !isValidCap && showErrorCap }"
              >
                <b-form-input
                  id="input-cap"
                  v-model="form.cap"
                  type="text"
                  @blur="showErrorCap = true"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="false"
                  :class="{ invisible: isValidCap || !showErrorCap }"
                >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un CAP
                  valido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-6">
              <b-form-group
                  id="input-group-pec"
                  label="PEC"
                  label-for="input-pec"
                  :class="{ invalid: (!isValidPec && showErrorPec) || (!isValidPecOrSdi && showErrorPecOrSdi) }"
              >
                  <b-form-input
                  id="input-pec"
                  v-model="form.pec"
                  type="text"
                  @blur="showErrorPec = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: (isValidPec || !showErrorPec) && (isValidPecOrSdi || !showErrorPecOrSdi) }" >
                    <i class="fas fa-exclamation-circle"></i> {{ !isValidPecOrSdi ? 'Inserisci PEC o codice SDI' : 'Inserisci un PEC valido' }}
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
          <div class="col-1 d-flex justify-content-center align-items-center opaque mb-3">
          oppure
          </div>
          <div class="col-5">
              <b-form-group
                  id="input-group-fiscal-code"
                  label="Codice SDI"
                  label-for="input-fiscal-code"
                  :class="{ invalid: (!isValidSdi && showErrorSdi) || (!isValidPecOrSdi && showErrorPecOrSdi) }"
              >
                  <b-form-input
                  id="input-fiscal-code"
                  v-model="form.codice_identificativo_sdi"
                  type="text"
                  @blur="showErrorSdi = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidSdi || !showErrorSdi }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un codice SDI di 7 caratteri valido
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
      </div>

      <div class="row d-flex justify-content-end mt-4">
        <!--
        <div class="col">
          <div class="row">
            <div class="col-12 mb-2">
              <b-form-checkbox v-model="autoRenewal" name="check-button" switch @click="editAutorenewal">
                Rinnovo automatico <b>{{ autoRenewal ? 'Attivo' : 'Non attivo' }}</b>
              </b-form-checkbox>
            </div>
            <div class="col-12 opaque">
              <span>Prossima scadenza: {{nextExpirationDate ? toFormattedDate(nextExpirationDate) : nextExpirationDate}}</span>
            </div>
          </div>
        </div>
        -->
        <div class="col-4">
          <custom-button
            :isLoading="loading"
            :class="{disabled: !isValidForm || !changes}"
            label="Salva"
            class="w-100"
            @click.prevent.native="onSubmit"
          />
        </div>
      </div>

      <ErrorCard v-if="errorMessage">
        <template #message >
          {{ errorMessage }}
        </template>
      </ErrorCard>
    </form>
</div>
</template>

<script>
import Button from '@/views/components/Button.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import Select from 'vue-select';
import { BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isCap, isIva, isFiscalCode, isAlphanumeric, isEmail } from '@/helpers/formValidation.js';
import { toFormattedDate } from '@/helpers/dates.js';

export default {
  props: {
    banks: Array,
    companyId: String,
    instalment: Object,
  },
  components: {
    ErrorCard,
    'custom-button': Button,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    'vue-select': Select,
  },
  data () {
    return {
      loading: false,
      errorMessage: '',

      showErrorDenomination: false,
      showErrorFiscalCode: false,
      showErrorIva: false,
      showErrorAddress: false,
      showErrorCity: false,
      showErrorCap: false,
      showErrorPec: false,
      showErrorSdi: false,
      showErrorPecOrSdi: false,

      suggestions: [],

      oldForm: null,
      // Form
      form: {
        denominazione: '',
        codice_fiscale: '',
        piva: '',
        indirizzo: '',
        comune: '',
        cap: '',
        pec: '',
        codice_identificativo_sdi: '',
      },
      nextExpirationDate: null,
      oldAutoRenewal: null,
      autoRenewal: null,
    };
  },
  computed: {
    isValidForm () {
      return this.isValidDenomination && this.isValidFiscalCode && this.isValidIva && this.isValidAddress &&
        this.isValidCity && this.isValidCap && this.isValidPecOrSdi && this.isValidPec && this.isValidSdi;
    },
    isValidDenomination () {
      return isFilled(this.form.denominazione);
    },
    isValidFiscalCode () {
      return isFilled(this.form.codice_fiscale) && (isFiscalCode(this.form.codice_fiscale) || isIva(this.form.codice_fiscale));
    },
    isValidIva () {
      return isFilled(this.form.piva) && isIva(this.form.piva);
    },
    isValidAddress () {
      return isFilled(this.form.indirizzo);
    },
    isValidCity () {
      return isFilled(this.form.comune) && isFilled(this.form.comune.name);
    },
    isValidCap () {
      return isFilled(this.form.cap) && isCap(this.form.cap);
    },
    isValidPec () {
      return !isFilled(this.form.pec) || isEmail(this.form.pec);
    },
    isValidSdi () {
      return !isFilled(this.form.codice_identificativo_sdi) || (this.form.codice_identificativo_sdi.length === 7 && isAlphanumeric(this.form.codice_identificativo_sdi));
    },
    isValidPecOrSdi () {
      return isFilled(this.form.pec) || isFilled(this.form.codice_identificativo_sdi);
    },
    searchValueMunicipality () {
      return this.$refs.select1._data.search;
    },
    changes () {
      return JSON.stringify(this.form) !== JSON.stringify(this.oldForm) || this.oldAutoRenewal !== this.autoRenewal;
    },
  },
  methods: {
    getBillingData () {
      this.$api.getBillingInformations(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          if (res.data.billingAddress) {
            this.form = res.data.billingAddress;
            this.oldForm = JSON.parse(JSON.stringify(res.data.billingAddress));
            this.nextExpirationDate = res.data.nextExpirationDate || null;
            this.oldAutoRenewal = res.data.autoRenewal;
            this.autoRenewal = res.data.autoRenewal;
            this.$root.$emit('setExpiration', res.data.expirationDate);
            this.$root.$emit('setAutoRenewal', res.data.autoRenewal);
            this.$root.$emit('setPeriodRenewal', res.data.periodRenewal);
            this.$root.$emit('setRenewalEnabled', res.data.renewalEnabled);
          }
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        });
    },
    fetchSuggestions () {
      const searchValue = this.$refs.select1._data.search;
      if (searchValue.length > 1) {
        this.$api.fetchSuggestions(searchValue)
          .then((res) => {
            this.suggestions = res.data.towns;
          });
      }
    },
    onSubmit: function () {
      this.errorMessage = '';
      this.loading = true;
      if (this.isValidForm && this.changes) {
        this.$api.editBillingInformations(this.form, this.autoRenewal, this.companyId)
          .then((res) => {
            if (res.data.billingAddress) {
              this.form = res.data.billingAddress;
              this.oldForm = JSON.parse(JSON.stringify(res.data.billingAddress));
              this.oldAutoRenewal = res.data.autoRenewal;
              this.autoRenewal = res.data.autoRenewal;
              this.$emit('openSnackbar', 'Modifiche salvate');
            }
            this.$emit('openSnackbar', 'Informazioni aggiornate correttamente');
            this.oldAutoRenewal = this.autoRenewal;
            this.oldForm = JSON.parse(JSON.stringify(this.form));
          })
          .catch((e) => {
            this.$log.error(e);
            this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!this.isValidDenomination) {
          this.showErrorDenomination = true;
        } else if (!this.isValidFiscalCode) {
          this.showErrorFiscalCode = true;
        } else if (!this.isValidIva) {
          this.showErrorIva = true;
        } else if (!this.isValidAddress) {
          this.showErrorAddress = true;
        } else if (!this.isValidCity) {
          this.showErrorCity = true;
        } else if (!this.isValidCap) {
          this.showErrorCap = true;
        } else if (!this.isValidPecOrSdi) {
          this.showErrorPecOrSdi = true;
        }

        setTimeout(() => {
          this.loading = false;
        }, 100);
      }
    },
    editAutorenewal: function () {
      this.errorMessage = '';
      this.$api.editBillingInformations(this.form, this.autoRenewal, this.companyId)
        .then((res) => {
          this.$emit('openSnackbar', 'Informazioni aggiornate correttamente');
          this.oldAutoRenewal = this.autoRenewal;
          this.oldForm = JSON.parse(JSON.stringify(this.form));
        })
        .catch((e) => {
          this.autoRenewal = !this.autoRenewal;
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
  },
  beforeMount () {
    this.getBillingData();
  },
};
</script>

<style lang="scss">
</style>
