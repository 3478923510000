import formIdPropsMixin from './formIdProps';
import formInputPropsMixin from './formInputProps';

export default {
  mixins: [formIdPropsMixin, formInputPropsMixin],
  props: {
    placeholder: {
      type: String,
      default: null,
    },
  },
};
