<template>
  <div class="setup-employees">
      <h5 class="mb-3">
        F24 Personale
      </h5>
      <div class="row">
        <div class="col-6">
          <b-form-group
            class="mb-4 box px-0"
            id="input-group-f24"
            label="Importo"
            label-for="input-f24"
            :class="{ invalid: !isValidF24 && showErrorF24 }"
          >
            <CurrencyInput v-model="form.f24Amount" @blur="showErrorF24 = true" :key="`f24_${update}`"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidF24 || !showErrorF24 }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
                class="mb-2"
                id="input-group-bank"
                :class="{ invalid: !isValidBank && showErrorBank }"
            >
              <BankSelect ref="bankSelect" :banks="banks" :selectedItem="form.f24Bank" @update="updateBank" @search:blur="showErrorBank = true" />
              <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBank || !showErrorBank }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci una banca
              </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-6 d-flex align-items-center">
          <h5>
            Stipendi personale
          </h5>

        </div>
        <div class="col-6 d-flex justify-content-end align-items-center mb-2">
          <label class="mr-3 mb-0" for="selector">
            Modalità
          </label>
          <div class="selector" id="selector">
            <button class="btn option" @click="setView('detailed')" :class="{ active: view === 'detailed' }">
              Dettagliata
            </button>
            <button class="btn option" @click="setView('cumulative')" :class="{ active: view === 'cumulative' }">
              Cumulata
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group
                class="mb-2"
                id="input-group-bank2"
                :class="{ invalid: !isValidBank2 && showErrorBank2 }"
            >
              <BankSelect ref="bankSelect" :banks="banks" :selectedItem="form.bank" @update="updateBank2" @search:blur="showErrorBank2 = true" />

              <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBank2 || !showErrorBank2 }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci una banca
              </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            class="mb-4 box px-0"
            id="input-group-payday"
            label="Giorno pagamento stipendi"
            label-for="input-payday"
            :class="{ invalid: !isValidPayday && showErrorPayday }"
          >
            <b-form-input
              id="input-payday"
              v-model="form.day"
              type="number"
              @blur="showErrorPayday = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidPayday || !showErrorPayday }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un giorno del mese valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div v-if="form.mode === 'detailed'" :key="`view1_${update}`">
          <div class="table-wrapper mb-4">
            <b-table id="table" ref="table" :items="form.employees" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" hover show-empty>
              <template #cell(salary)="row">
                {{toCurrency(row.item.salary)}}
              </template>
              <template #cell(actions)="row">
                <b-dropdown size="sm" :id="`dropdown-${row.item.index}`" right variant="link" toggle-class="text-decoration-none">
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item @click="selectedRow = row.item; togglePopup('upsertEmployeePopup');">Modifica</b-dropdown-item>
                  <b-dropdown-item @click="selectedRow = row.item; togglePopup('deleteEmployeePopup');" class="text-red">Elimina</b-dropdown-item>
                </b-dropdown>
              </template>
              <template #empty>
                <div class="d-flex justify-content-center align-items-center py-2">
                  Nessun dipendente aggiunto
                </div>
              </template>
            </b-table>
          </div>

          <div class="row">
            <div class="col d-flex justify-content-end">
              <button @click="selectedRow = null; togglePopup('upsertEmployeePopup');" class="btn btn-fin">
                Aggiungi dipendente
              </button>
            </div>
          </div>
      </div>

      <div v-else class="row" :key="`view2_${update}`">
        <div class="col-6">
          <b-form-group
            class="mb-4"
            id="input-group-salary"
            label="Stipendio netto totale"
            label-for="input-salary"
            :class="{ invalid: !isValidSalary && showErrorSalary }"
          >
            <CurrencyInput id="input-salary" v-model="form.amountCumulated" @blur="showErrorSalary = true" :key="`salary_${update}`"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidSalary || !showErrorSalary }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci uno stipendio valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            class="mb-3"
            id="input-group-months"
            label="Numero di mensilità"
            label-for="input-months"
            :class="{ invalid: !isValidNumberOfMonths && showErrorNumberOfMonths }"
          >
            <b-form-select
              id="input-months"
              v-model="form.salaryMonths"
              @blur="showErrorNumberOfMonths = true"
              :options="[12, 13, 14]"
            ></b-form-select>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidNumberOfMonths || !showErrorNumberOfMonths }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un numero di mesi valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-6 ml-auto">
          <custom-button
            :isLoading="loading"
            :class="{disabled: !isValidForm}"
            label="Salva"
            class="w-100"
            @click.prevent.native="onSubmit"
          />
        </div>
      </div>

      <Popup ref="upsertEmployeePopup" class="md">
        <template #fullContent>
          <UpsertEmployee @save="upsertEmployee" :employee="selectedRow"/>
        </template>
      </Popup>

      <Popup ref="deleteEmployeePopup" class="md">
        <template #title> Sei sicuro di voler eliminare? </template>
        <template #text>
          Il dipendente <b>{{ selectedRow.name }} </b> sarà rimosso dall'elenco.
        </template>
        <template #button>
          <custom-button
            label="Elimina"
            class="w-100 mt-4"
            @click.prevent.native="onDelete"
          />
        </template>
      </Popup>

  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormInvalidFeedback, BTable } from 'bootstrap-vue';
import { isFilled, isCurrency, isNumber, toCurrency } from '@/helpers/formValidation.js';

import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import UpsertEmployee from '@/views/components/Setup/UpsertEmployee.vue';
import Popup from '@/views/components/Popup.vue';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    companyId: String,
  },
  components: {
    BankSelect,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTable,
    'custom-button': Button,
    CurrencyInput,
    UpsertEmployee,
    Popup,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
      loading: false,
      update: 0,

      showErrorF24: false,
      showErrorBank: false,
      showErrorBank2: false,
      showErrorPayday: false,
      showErrorSalary: false,
      showErrorNumberOfMonths: false,

      view: this.form?.mode ? this.form?.mode : 'detailed',
      selectedRow: null,

      fields: [
        { key: 'name', label: 'Nome', sortable: true },
        { key: 'months', label: 'Mensilità', sortable: true },
        { key: 'salary', label: 'Stipendio netto', sortable: true },
        { key: 'actions', label: ' ' },
      ],
      sortBy: null,
      sortDesc: true,

      banks: [],
      form: {},
    };
  },
  computed: {
    isValidForm () {
      return this.isValidF24 && this.isValidBank && this.isValidBank2 && this.isValidPayday && (this.view || (this.isValidSalary && this.isValidNumberOfMonths));
    },
    isValidF24 () {
      return isCurrency(this.form.f24Amount) && isFilled(this.form.f24Amount);
    },
    isValidBank () {
      return isFilled(this.form.f24Bank);
    },
    isValidBank2 () {
      return isFilled(this.form.bank);
    },
    isValidPayday () {
      return isFilled(this.form.day) && isNumber(this.form.day) && this.form.day <= 31;
    },
    isValidSalary () {
      return isCurrency(this.form.amountCumulated) && isFilled(this.form.amountCumulated);
    },
    isValidNumberOfMonths () {
      return isFilled(this.form.salaryMonths) && isNumber(this.form.salaryMonths) && this.form.salaryMonths <= 14;
    },
    arrayOfEmployees () {
      return this.form?.employees ? this.form?.employees : [];
    },
  },
  methods: {
    getCompanyInfo () {
      this.$api.getCompany(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          const company = res.data;
          this.banks = company.banks;
          this.banks = company.banks.filter(obj => !obj.isCash);
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.update++;
        });
    },
    getSettings () {
      this.$api.getEmployeesSettings(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          this.form = res.data.salary;
          this.setView(res.data.salary.mode); // to do
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.update++;
        });
    },
    updateBank (bank) {
      this.form.f24Bank = bank;
    },
    updateBank2 (bank) {
      this.form.bank = bank;
    },
    upsertEmployee (obj) {
      if (this.selectedRow) {
        const i = this.form.employees.findIndex(employee => this.selectedRow === employee);
        Object.assign(this.form.employees[i], obj);
      } else {
        if (this.form.employees === undefined) {
          this.form.employees = [];
        }
        this.form.employees.push(obj);
      }

      this.togglePopup('upsertEmployeePopup');
      this.selectedRow = null;
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    onDelete () {
      const i = this.form.employees.findIndex(employee => this.selectedRow === employee);
      this.form.employees.splice(i, 1);

      this.togglePopup('deleteEmployeePopup');
      this.selectedRow = null;
    },
    onSubmit () {
      if (this.isValidForm) {
        this.loading = true;
        this.saveData();
      } else {
        this.loading = true;
        if (!this.isValidF24) {
          this.showErrorF24 = true;
        } else if (!this.isValidBank) {
          this.showErrorBank = true;
        } else if (!this.isValidBank2) {
          this.showErrorBank2 = true;
        } else if (!this.isValidPayday) {
          this.showErrorPayday = true;
        } else if (this.view === 'cumulative') {
          if (!this.isValidSalary) {
            this.showErrorSalary = true;
          } else if (!this.isValidNumberOfMonths) {
            this.showErrorNumberOfMonths = true;
          }
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    saveData () {
      this.$emit('setError', '');
      this.$log.debug(this.form);
      this.$api.updateEmployeesSettings(this.companyId, this.form)
        .then((res) => {
          this.$log.debug(res);
          this.snackbarText = 'Informazioni aggiornate';
          this.$emit('openSnackbar', this.snackbarText);
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setView (type) {
      this.view = type;
      this.form.mode = type;
      // this.update++;
    },
    toCurrency (amount) {
      return toCurrency(amount);
    },
  },
  created () {
    this.getCompanyInfo();
    this.getSettings();
  },
};
</script>

<style lang="scss">
.setup-employees  {

  .selector {
    background: $primary-lighter;
    padding: 3px;
    border-radius: 12px;
    .option {
      border: none;
      border-radius: 10px;
      padding: 5px 15px;
      color: $primary;
      opacity: 0.7;
      font-weight: 600;
      font-size: 14px;
      &.active {
        background: white;
        opacity: 1;
      }
    }
  }

  .table-wrapper {
    border-radius: 10px;
    box-shadow: $box-shadow;
    height: auto;
  }

  table {
    background: white;
    text-overflow: ellipsis;
    font-size: 16px;
    margin: 0;
    border-radius: 10px;
    th {
      border: none;
      text-transform: uppercase;
      font-size: 14px;
      color: rgba($primary, 0.75);
      font-weight: 600;
    }
    thead {
      border: none!important;
      background: $fin-white;
      th {
        padding: 23px 15px;
        &:nth-last-child(2) {
          text-align: center;
        }
      }
    }
    td:nth-last-child(2) {
      text-align: right;
    }
    td:nth-last-child(1) {
      width: 80px;
      text-align: right;
    }
  }

  // row settings
  .dropdown {
    button {
      // no caret
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
        outline: none;
      }
      .text-red > * {
        color: $danger!important;
      }
      .dropdown-item {
        color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
          outline: none;
        }
        &:active {
          background: $primary-light;
        }
      }
    }
  }
}
</style>
