import Router from 'vue-router';

const routerGenerator = (config = {}, beforeEach = []) => {
  const options = {
    ...config,
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior (to, from, savedPosition) {
      if (to.meta.disableScroll) return;
      // https://router.vuejs.org/guide/advanced/scroll-behavior.html
      if (to.hash) {
        return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' });
      }

      if (savedPosition) {
        return window.scrollTo({ top: savedPosition.y, behavior: 'smooth' });
      }

      return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    linkActiveClass: 'active',
  };
  router = new Router(options);

  if (beforeEach.length) {
    for (const el of beforeEach) {
      router.beforeEach(el);
    }
  }

  return router;
};

export default routerGenerator;

export let router = null;
