<template>
  <div class="employees" ref="employees">
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid p-0 w-auto m-4">
      <h4 class="mb-4">Gestione personale</h4>

      <div class="pretty-card">
        <h5>F24 Personale</h5>
        <div class="row">
          <div class="col-2 box">
            <label for="expected-amount">Importo atteso</label>
            <CellContent
              id="expected-amount"
              :value="expectedAmountF24 ? parseFloat(expectedAmountF24) : null"
              class="py-2"
            />
          </div>
          <div class="col-5 box">
            <b-form-group
              class="mb-3"
              id="input-group-amount-f24"
              :label="`Importo F24 ${f24Date}`"
              label-for="input-amount-f24"
              :class="{
                invalid: !isValidNewAmountF24 && showErrorNewAmountF24,
              }"
            >
              <CurrencyInput
                :class="{
                  invalid: !isValidNewAmountF24 && showErrorNewAmountF24,
                }"
                :key="`f24-input-${loading}`"
                id="input-amount-f24"
                v-model="newAmountF24"
                @blur="showErrorNewAmountF24 = true"
              />
              <b-form-invalid-feedback
                :state="false"
                :class="{
                  invisible: isValidNewAmountF24 || !showErrorNewAmountF24,
                }"
              >
                <i class="fas fa-exclamation-circle"></i> Inserisci un importo
                valido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-5">
            <BankSelect
              :banks="banks"
              :selectedItem="newBankF24"
              @search:blur="showErrorBankF24 = true"
              @update="updateBankF24"
              :class="{ invalid: !isValidBankF24 && showErrorBankF24 }"
            />
            <b-form-invalid-feedback
              :state="false"
              :class="{ invisible: isValidBankF24 || !showErrorBankF24 }"
            >
              <i class="fas fa-exclamation-circle"></i> Inserisci una banca
            </b-form-invalid-feedback>
          </div>
        </div>

        <h5 class="mb-4">Stipendi personale</h5>

        <div class="row">
          <div class="col-2 box" v-if="cumulativeAmount">
            <label for="expected-amount-salary">Importo atteso</label>
            <CellContent
              id="expected-amount"
              :value="
                expectedAmountSalary ? parseFloat(expectedAmountSalary) : null
              "
              class="py-2"
            />
          </div>
          <div class="col-5 box" v-if="cumulativeAmount">
            <b-form-group
              class="mb-3"
              id="input-group-amount-Salary"
              :label="`Importo stipendi ${salaryDate}`"
              label-for="input-amount-Salary"
              :class="{
                invalid: !isValidNewAmountSalary && showErrorNewAmountSalary,
              }"
            >
              <CurrencyInput
                :class="{
                  invalid: !isValidNewAmountSalary && showErrorNewAmountSalary,
                }"
                :key="`salary-input-${loading}`"
                id="input-amount-Salary"
                v-model="newAmountSalary"
                @blur="showErrorNewAmountSalary = true"
              />
              <b-form-invalid-feedback
                :state="false"
                :class="{
                  invisible:
                    isValidNewAmountSalary || !showErrorNewAmountSalary,
                }"
              >
                <i class="fas fa-exclamation-circle"></i> Inserisci un importo
                valido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-7" v-if="!cumulativeAmount">
            <div v-if="employees.length > 0" class="scrollable">
              <div
                v-for="(employee, i) in employees"
                :key="`employee_${i}`"
                class="box primary mb-3 pb-0"
              >
                <h5 class="mb-3">{{ employee.name }}</h5>
                <div class="row">
                  <div class="col-4">
                    <label for="expected-amount-salary">Importo atteso</label>
                    <CellContent
                      id="expected-amount"
                      :value="
                        employee.salary ? parseFloat(employee.salary) : null
                      "
                      class="py-2"
                    />
                  </div>
                  <div class="col-8">
                    <b-form-group
                      class="mb-3"
                      :id="`input-group-amount-employee-${i}`"
                      :label="`Importo stipendi ${getStringDate(
                        employee?.nextPayment
                      )}`"
                      :label-for="`input-amount-employee-${i}`"
                    >
                      <CurrencyInput
                        :id="`input-amount-employee-${i}`"
                        :key="`currency-input-${i}-${isUpdating}`"
                        @input="setEmployeeSalary($event, i)"
                      />
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="mb-4">
                Devi aggiungere dei dipendenti per poter usare questa
                funzionalità.
              </div>
              <router-link
                :to="{
                  name: 'settings',
                  params: { page: 'SettingsEmployees' },
                }"
                tag="button"
                class="btn btn-fin"
              >
                Configura
              </router-link>
            </div>
          </div>
          <div class="col-5">
            <BankSelect
              :banks="banks"
              :selectedItem="bankSalary"
              @search:blur="showErrorBankSalary = true"
              @update="updateBankSalary"
              :class="{ invalid: !isValidBankSalary && showErrorBankSalary }"
            />
            <b-form-invalid-feedback
              :state="false"
              :class="{ invisible: isValidBankSalary || !showErrorBankSalary }"
            >
              <i class="fas fa-exclamation-circle"></i> Inserisci una banca
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="row d-flex justify-content-end">
          <div class="col-4">
            <custom-button
              :isLoading="loading"
              :disabled="!changes"
              label="Salva"
              class="w-100 mt-4"
              @click.prevent.native="onSubmit"
            />
          </div>
        </div>

        <ErrorCard v-if="errorMessage" class="mb-3">
          <template #message >
          {{ errorMessage }}
          </template>
        </ErrorCard>
      </div>

      <Snackbar ref="snackbar" :text="snackbarText" />
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled } from '@/helpers/formValidation.js';

import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import Loader from '@/views/components/Layout/Loading.vue';
// import Popup from '@/views/components/Popup.vue';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    BankSelect,
    'custom-button': Button,
    CellContent,
    CurrencyInput,
    ErrorCard,
    Loader,
    // Popup,
    Snackbar,
  },
  data () {
    return {
      banks: [],
      companyId: this.$oauth2.me.companies[0].id || null,

      errorMessage: '',
      isLoadingPage: true,
      isUpdating: false,
      loading: false,
      snackbarText: '',

      employees: [],
      newEmployees: [],
      mode: null,

      expectedAmountF24: null,
      newAmountF24: null,
      bankF24: null,
      newBankF24: null,

      expectedAmountSalary: null,
      newAmountSalary: null,
      bankSalary: null,
      newBankSalary: null,

      showErrorNewAmountF24: false,
      showErrorBankF24: false,
      showErrorNewAmountSalary: false,
      showErrorBankSalary: false,

      f24Date: '',
      salaryDate: '',
    };
  },
  computed: {
    isValidBankF24 () {
      return isFilled(this.bankF24);
    },
    isValidBankSalary () {
      return isFilled(this.bankSalary);
    },
    isValidNewAmountF24 () {
      // return isFilled(this.newAmountF24);
      return true;
    },
    isValidNewAmountSalary () {
      // return isFilled(this.newAmountSalary);
      return true;
    },
    cumulativeAmount () {
      return this.mode === 'cumulative';
    },
    changes () {
      return (
        isFilled(this.newAmountF24) ||
        isFilled(this.newAmountSalary) ||
        this.newSalaries ||
        this.bankF24 !== this.newBankF24 ||
        this.bankSalary !== this.newBankSalary
      );
    },
    newSalaries () {
      return Boolean(
        this.newEmployees.find(
          (employee, i) => employee.salary !== null && this.employees[i].salary !== employee.salary,
        ),
      );
    },
  },
  methods: {
    getData () {
      this.$api
        .getSalary(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          const data = res.data.salary;
          this.expectedAmountF24 = data.f24Amount;
          this.bankF24 = data.f24Bank;
          this.newBankF24 = data.f24Bank;
          this.expectedAmountSalary = data.amountCumulated;
          this.bankSalary = data.bank;
          this.newBankSalary = data.bank;
          this.employees = data.employees;
          this.mode = data.mode;
          this.newEmployees = JSON.parse(JSON.stringify(data.employees));
          this.salaryDate = this.getStringDate(data.cumulatedNextPayment);
          this.f24Date = this.getStringDate(data.f24NextPayment);
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg
            ? e.response.data.msg
            : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
    getStringDate (date) {
      if (date === null) {
        return '';
      }
      // date format MM-YYYY
      const month = parseInt(date.slice(0, 2));
      const year = date.slice(3, date.length);

      const months = [
        'gennaio',
        'febbraio',
        'marzo',
        'aprile',
        'maggio',
        'giugno',
        'luglio',
        'agosto',
        'settembre',
        'ottobre',
        'novembre',
        'dicembre',
      ];
      return `${months[month - 1]}  ${year}`;
    },
    getCompanyInfo () {
      this.$api
        .getCompany(this.companyId)
        .then((res) => {
          this.banks = res.data.banks;
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    updateBankF24 (bank) {
      this.newBankF24 = bank;
    },
    updateBankSalary (bank) {
      this.newBankSalary = bank;
    },
    onSubmit () {
      this.loading = true;
      this.$api
        .updateSalary(
          this.companyId,
          this.newAmountSalary,
          null,
          null,
          this.newAmountF24,
          this.newBankSalary.id,
          this.newBankF24.id,
          this.newEmployees,
        )
        .then((res) => {
          this.$log.debug(res.data);
          this.snackbarText = 'Salvataggio completato';
          this.$refs.snackbar.openSnackbar();
          this.isUpdating = true;
          this.employees = res.data.employees;

          this.newAmountF24 = null;
          this.newAmountSalary = null;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg
            ? e.response.data.msg
            : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
          this.isUpdating = false;
          this.getData();
        });
    },
    setEmployeeSalary (newSalary, i) {
      this.newEmployees[i].salary = newSalary;
      return null;
    },
  },
  mounted () {
    this.getCompanyInfo();
    this.getData();
  },
};
</script>

<style lang="scss">
.employees {
  background: $primary-lighter;
  .box.primary {
    background: $primary-lighter;
  }
  .scrollable {
    max-height: 80vh !important;
    overflow: scroll;
    overflow-x: hidden;
    padding-bottom: 60px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: +1;
      background: linear-gradient(transparent, white);
      max-width: 100%;
      height: 80px;
    }
  }
}
</style>
