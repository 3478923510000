<template>
  <p v-if="labelIsFake" class="form-label fake-label" :class="{ 'sr-only': hideLabel }">
    <slot name="label" :label="label" :notRequired="notRequired">
      <slot :label="label">
        {{ label }}
      </slot>
      <span v-if="!notRequired">*</span>
    </slot>
  </p>
  <label v-else class="form-label" :class="{ 'sr-only': hideLabel }" :for="inputId">
    <slot name="label" :label="label" :notRequired="notRequired">
      <slot :label="label">
        {{ label }}
      </slot>
      <span v-if="!notRequired">*</span>
    </slot>
  </label>
</template>

<script>

import formIdMixin from './Inputs/mixins/formId';
import formLabelMixin from './Inputs/mixins/formLabel';

export default {
  mixins: [formIdMixin, formLabelMixin],
};

</script>
