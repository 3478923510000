<template>
  <div class="bg-fin" ref="loans">
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid p-0 w-auto m-4">
        <h4 class="mb-4">Avvisi</h4>

        <div class="notifications">
          <div v-if="isBusy" class="custom-loader-container">
            <Loader2 />
          </div>

          <div v-else>
            <div v-if="importantNotifications?.length === 0 && notifications?.length === 0" class="text-center py-4">
              Non ci sono avvisi
            </div>

            <div class="gap">
              <h5 v-if="importantNotifications?.length > 0" class="mb-3">Importanti</h5>
              <div v-for="(notification, i) in importantNotifications" :key="`notification_${i}_${update}`">
                  <div class="row">
                      <div class="col">
                          <div class="notification" :class="notification?.hasBeenRead ? 'read' : 'to-read'" @click="goToDetails(notification)">
                              <img class="notification-icon" :src="notification?.icon ? notification?.icon : infoIcon">
                              <div class="notification-text">
                                  <b>
                                    {{notification.title}}
                                  </b>
                                  {{notification.text}}
                                  <span class="notification-timestamp w-100">{{getTimeSince(notification.createdAt)}}</span>
                              </div>
                          </div>
                      </div>
                      <div class="col-auto p-1 d-flex align-items-center">
                          <b-dropdown
                              class="text-right"
                              size="sm"
                              :id="`dropdown_${i}`"
                              right
                              variant="link"
                              toggle-class="text-decoration-none"
                          >
                              <template #button-content>
                                  <i class="fas fa-ellipsis-v p-2"></i>
                              </template>
                              <b-dropdown-item v-if="true" @click="setNotificationAsRead(notification.notificationId, false)">Segna come non letto</b-dropdown-item>
                              <b-dropdown-item v-if="notification.canBeDeleted" @click="togglePopup('deleteNotification'); selectedItem = notification;">Elimina</b-dropdown-item>
                          </b-dropdown>
                      </div>
                  </div>
              </div>
            </div>

            <h5 v-if="notifications?.length > 0" class="mb-3" :class="{'mt-4': importantNotifications?.length > 0}">Altri</h5>
            <div class="gap">
              <div v-for="(notification, i) in notifications" :key="`notification_${i}_${update}`">
                  <div class="row">
                      <div class="col">
                          <div class="notification" :class="notification?.hasBeenRead ? 'read' : 'to-read'" @click="goToDetails(notification)">
                              <img class="notification-icon" :src="notification?.icon ? notification?.icon : infoIcon">
                              <div class="notification-text">
                                  <b>
                                    {{notification.title}}
                                  </b>
                                  {{notification.text}}
                                  <span class="notification-timestamp w-100">{{getTimeSince(notification.createdAt)}}</span>
                              </div>
                          </div>
                      </div>
                      <div class="col-auto p-1 d-flex align-items-center">
                          <b-dropdown
                              class="text-right"
                              size="sm"
                              :id="`dropdown_${i}`"
                              right
                              variant="link"
                              toggle-class="text-decoration-none"
                          >
                              <template #button-content>
                                  <i class="fas fa-ellipsis-v p-2"></i>
                              </template>
                              <b-dropdown-item v-if="true" @click="setNotificationAsRead(notification.notificationId, false)">Segna come non letto</b-dropdown-item>
                              <b-dropdown-item v-if="notification.canBeDeleted" @click="togglePopup('deleteNotification'); selectedItem = notification;">Elimina</b-dropdown-item>
                          </b-dropdown>
                      </div>
                  </div>
              </div>
            </div>

          </div>
        </div>

      <div class="row d-flex justify-content-between mt-4">
        <div class="col-4 d-flex align-items-center">
          <b-form-group
            label="Elementi per pagina"
            label-for="perPageOptions"
            class="page-items d-flex align-items-center mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageOptions"
              class="form-control ml-3"
              :options="perPageOptions"
            />
          </b-form-group>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-center">
          <b-pagination
            v-model="currentPage"
            aria-controls="table"
            :per-page="perPage"
            :total-rows="totalItems"
            class="mb-0"
            first-number
            last-number
          >
            <template #prev-text><i class="fas fa-chevron-left"></i></template>
            <template #next-text><i class="fas fa-chevron-right"></i></template>
          </b-pagination>
        </div>
        <div class="col-4">
        </div>
      </div>

    </div>

    <Popup @beforeClosing="errorMessage = '';" ref="deleteNotification" class="md" :errorMessage="errorMessage">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
        Non potrai ripristinare questa notifica.
      </template>
      <template #button>
        <custom-button
          :isLoading="loading"
          label="Elimina"
          class="w-100 mt-4"
          @click.prevent.native="onDelete"
        />
      </template>
    </Popup>

    <Snackbar :text="snackbarText" ref="snackbarLoans" class="move-up"/>
  </div>
</template>

<script>
import { toFormattedDate } from '@/helpers/dates.js';
import { BDropdown, BDropdownItem, BFormSelect, BPagination } from 'bootstrap-vue';

import Button from '@/views/components/Button.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Popup from '@/views/components/Popup.vue';
import Snackbar from '@/views/components/Snackbar.vue';
import Loader2 from '@/views/components/Layout/Spinner.vue';

import infoIcon from '@/assets/images/info_icon.svg';

export default {
  name: 'loans',
  components: {
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    'custom-button': Button,
    Loader,
    Loader2,
    Popup,
    Snackbar,
  },
  data () {
    return {
      update: 0,
      companyId: this.$oauth2.me.companies[0].id || null,
      infoIcon: infoIcon,

      isBusy: true,
      currentPage: this.$route.query.page || 1,
      perPage: this.$route.query.perPage || 10,
      perPageOptions: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
      totalItems: null,

      notifications: [],
      importantNotifications: [],

      errorMessage: null,
      showPopup: true,
      snackbarText: '',
      loading: false,
      isLoadingPage: true,
      selectedRowItem: null,
    };
  },
  watch: {
    $route: function () {
      this.getNotifications();
    },
    perPage: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            perPage: this.perPage,
          }),
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.scrollToTop();
        });
    },
    currentPage: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            page: this.currentPage,
          }),
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.scrollToTop();
        });
    },

  },
  methods: {
    getCompanyInfo () {
      this.$api
        .getCompany(this.companyId)
        .then((res) => {
          this.banks = res.data.banks;
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getNotifications () {
      this.isBusy = true;
      const filters = this.$route.query;

      this.$api.getNotifications(this.companyId, filters)
        .then((res) => {
          this.$log.debug(res);
          this.notifications = res.data.notifications.filter((obj) => obj.category === 'n' && !obj.isImportant);
          this.importantNotifications = res.data.notifications.filter((obj) => obj.category === 'n' && obj.isImportant);
          this.warnings = res.data.warning;
          this.update++;
          // this.$root.$emit('updateNotifications', this.warnings);

          this.totalItems = res.data.metadata['total-items'];
          this.perPage = res.data.metadata['per-page'];
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.isBusy = false;
          this.isLoadingPage = false;
        });
    },
    goToDetails (notification) {
      this.$router.push({ name: 'notificationDetails', params: { notification: notification } });
    },
    onDelete () {
      this.loading = true;
      this.$api
        .deleteNotification(this.selectedItem.notificationId)
        .then(() => {
          this.togglePopup('deleteNotification');
          this.snackbarText = 'Notifica eliminata correttamente';
          this.$refs.snackbarLoans.openSnackbar();
          this.getNotifications();
        })
        .catch((e) => {
          this.$log.debug(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    getTimeSince (datetime) {
      const timestamp = new Date(datetime);
      const now = new Date();
      const secondsPast = (now.getTime() - timestamp.getTime()) / 1000;
      if (secondsPast < 60) {
        return parseInt(secondsPast) + ' secondi fa';
      }
      if (secondsPast < 3600) {
        return parseInt(secondsPast / 60) + ' minuti fa';
      }
      if (secondsPast <= 86400) {
        return parseInt(secondsPast / 3600) + ' ore fa';
      }
      if (secondsPast > 86400) {
        const day = timestamp.getDate();
        const month = timestamp.toDateString().match(/ [a-zA-Z]*/)[0].replace(' ', '');
        const year = timestamp.getFullYear() === now.getFullYear() ? '' : ' ' + timestamp.getFullYear();
        return day + ' ' + month + year;
      }
    },
    setNotificationAsRead (id, read = true) {
      this.$api.editNotification(id, read)
        .then((res) => {
          this.$log.debug(res);
          this.getNotifications();
          if (read === false) {
            this.snackbarText = 'Avviso modificato correttamente';
            this.$refs.snackbarLoans.openSnackbar();
          }
          this.$root.$emit('updateNotifications');
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        });
    },
  },
  mounted () {
    this.getCompanyInfo();
    this.getNotifications();
  },
};
</script>

<style lang="scss">
.notifications {

  background: $white;
  border-radius: 10px;
  padding: 30px;

  .custom-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }

  .dropdown {
    .dropdown-menu {
        border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
          outline: none;
      }
      .red > * {
        color: $danger !important;
      }
      .dropdown-item {
          color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
            outline: none;
        }
        &:active {
            background: $primary-light;
        }
      }
    }
  }

  .dropdown-toggle {
    padding: 0;
    margin: 0;
    &::after {
        display: none;
    }
  }

}
</style>
