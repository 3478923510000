<template>
  <p class="form-feedback-message"><slot /></p>
</template>

<script>

export default {
  name: 'form-form-input-feedback',
};

</script>
