<template>
  <div class="layout-dashboard">
    <div class="d-flex h-100">
      <Sidebar />

      <div class="fin-wrapper flex-grow-1 overflow-auto d-flex h-100 flex-column">
        <Warnings />
        <router-view class="flex-grow-1" />
        <FinFooter />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/components/Sidebar.vue';
import FinFooter from '@/views/components/Layout/FinFooter.vue';
import Warnings from '@/views/components/Warnings.vue';

export default {
  name: 'LayoutDashboard',
  components: {
    Warnings,
    Sidebar,
    FinFooter,
  },
  data () {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.fin-wrapper {
  background: $background;
}
</style>
