<template>
<div>
    <div class="row">
      <div class="col-3">
        <b-form-group
          id="input-group-day"
          label-for="input-day"
          @blur="$emit('blur')"
        >
          <b-form-select id="input-day" v-model="day" :options="days" @input="setDay" placeholder="01"></b-form-select>
        </b-form-group>
      </div>
      <div class="col-5">
        <b-form-group
          id="input-group-month"
          label-for="input-month"
          @blur="$emit('blur')"
        >
          <b-form-select id="input-month" v-model="month" :options="months" @input="setMonth"></b-form-select>
        </b-form-group>
      </div>
      <div class="col-4">
        <b-form-group
          id="input-group-year"
          label-for="input-year"
          @blur="$emit('blur')"
        >
          <b-form-select id="input-year" v-model="year" :options="years" @input="setYear"></b-form-select>
        </b-form-group>
      </div>
    </div>
</div>
</template>

<script>
import { BFormGroup, BFormSelect } from 'bootstrap-vue';
import { isFilled } from '@/helpers/formValidation.js';

export default {
  props: {
    value: [Date, String, Number],
  },
  components: {
    BFormGroup,
    BFormSelect,
  },
  computed: {
    isValidDate () {
      return true;
    },
    isValidForm () {
      return isFilled(this.day) && isFilled(this.month) && isFilled(this.year);
    },
  },
  data () {
    return {
      date: this.value ? new Date(this.value) : new Date(),

      day: null,
      month: null,
      year: null,

      days: [],
      months: [],
      years: [],
    };
  },
  watch: {
    date () {
      this.setValue();
    },
    day () {
      if (this.isValidForm) {
        this.checkDate();
      }
    },
    month () {
      if (this.isValidForm) {
        this.checkDate();
      }
    },
    year () {
      if (this.isValidForm) {
        this.checkDate();
      }
    },
  },
  methods: {
    checkDate () {
      const date = new Date(this.date);
      this.day = date.getDate();
      const i = date.getMonth();
      this.month = this.months[i];
      this.year = date.getFullYear();
      this.setValue();
    },
    generateDays () {
      for (let i = 1; i <= 31; i++) {
        this.days.push(i);
      }
    },
    generateMonths () {
      this.months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
    },
    generateYears () {
      const date = new Date();
      const currentYear = date.getFullYear();
      for (let i = 1900; i <= currentYear; i++) {
        this.years.unshift(i);
      }
    },
    setValue () {
      this.$emit('setValue', this.date);
    },
    setDay () {
      this.date.setDate(this.day);
    },
    setMonth () {
      const i = this.months.indexOf(this.month);
      this.date.setMonth(i);
    },
    setYear () {
      this.date.setYear(this.year);
    },
  },
  mounted () {
    this.generateDays();
    this.generateMonths();
    this.generateYears();
  },
};
</script>

<style lang="scss">
$height-of-row: 55px;
$font-family-titles: sans-serif !default;

.dateofbirthpicker {
  position: relative;
  font-family: $font-family-titles;
  label {
    font-size: 14px;
  }
  .vc-container {
    width: 350px!important;
    overflow: hidden!important;
  }
  .vc-highlight {
    background: $primary!important;
  }
  .prepend {
    border-radius: 10px 0px 0px 10px!important;
    z-index: +1;
    &:active, &.selected {
      background: rgba($primary-mid, 0.6)!important;
    }
  }
  .bar.style {
    background: red!important;
  }
  .custom-border {
    border-radius: 0px 10px 10px 0px!important;
  }
}
</style>
