<template>
  <div>
    <Alert status="primary" fontSize="lg">
        <b>Attenzione!</b> Il credito o il debito IVA previsto per il periodo in corso, mese o trimestre, potrebbe non essere indicativo della situazione reale. Una stima corretta necessita di almeno un periodo completo di flusso costante di informazioni.
    </Alert>

    <div class="row my-4">
        <div class="col">
            <h5 class="mb-3">Periodicità IVA</h5>
        </div>
        <div class="col-6 d-flex justify-content-end align-items-center mb-2">
          <label class="mr-3 mb-0" for="selector">
            Modalità
          </label>
          <div class="selector" id="selector">
            <button class="btn option" @click="setView('month')" :class="{ 'active': iva.periodicity === 'month' }">
              Mensile
            </button>
            <button class="btn option" @click="setView('quarter')" :class="{ 'active': iva.periodicity === 'quarter' }">
              Trimestrale
            </button>
          </div>
        </div>
    </div>

    <div class="row">
        <div class="col-4" v-if="showDebt">
          <b-form-group
            id="input-group-debt"
            label="Debito IVA periodo precedente"
            :description="labelExpiration"
            label-for="input-debt"
            :class="{ 'invalid': !isValidDebt && showErrorDebt }"
          >
            <CurrencyInput v-model="iva.debt" @blur="showErrorDebt = true" id="input-debt" :key="`debt_${update}`"/>
            <b-form-invalid-feedback :state="false" :class="{ 'invisible': isValidDebt || !showErrorDebt }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            id="input-group-credit"
            :label="labelCredit"
            label-for="input-credit"
            :class="{ 'invalid': !isValidCredit && showErrorCredit }"
          >
            <CurrencyInput v-model="iva.credit" @blur="showErrorCredit = true" id="input-credit" :key="`credit_${update}`"/>
            <b-form-invalid-feedback :state="false" :class="{ 'invisible': isValidCredit || !showErrorCredit }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-4">
          <b-form-group
            id="input-group-balance"
            :label="labelBalance"
            label-for="input-balance"
            :class="{ 'invalid': !isValidBalance && showErrorBalance }"
          >
            <CurrencyInput v-model="iva.balance" @blur="showErrorBalance = true" id="input-balance" :key="`balance_${update}`"/>
            <b-form-invalid-feedback :state="false" :class="{ 'invisible': isValidBalance || !showErrorBalance }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
    </div>

    <div class="row mt-4">
      <div class="col-8">
        <BankSelect ref="bankSelect" label="Banca" :banks="banks" :selectedItem="bank" @update="updateBank"/>
      </div>
    </div>

    <div class="row mt-5">
        <div class="col-6">
        <custom-button
          label="Indietro"
          class="w-100 btn-secondary"
          @click.prevent.native="$emit('changePage', -1)"
        />
        </div>
        <div class="col-6">
          <custom-button
            :isLoading="loading"
            :label="isValidForm ? 'Avanti' : 'Salta'"
            class="w-100"
            @click.prevent.native="onSubmit"
          />
        </div>
      </div>

  </div>
</template>

<script>
import { BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue';
// import { isFilled, isCurrency } from '@/helpers/formValidation.js';
import { toFormattedDate } from '@/helpers/dates.js';

import Alert from '@/libs/Feedback/components/Alert.vue';
import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';

export default {
  props: {
    propForm: {
      type: Object,
      required: true,
    },
    setupBanks: Array,
  },
  components: {
    Alert,
    BankSelect,
    BFormGroup,
    BFormInvalidFeedback,
    'custom-button': Button,
    CurrencyInput,
  },
  data () {
    return {
      loading: false,
      update: 0,

      bank: null,

      expirationDate: null,
      creditStartDate: null,

      showErrorDebt: false,
      showErrorCredit: false,
      showErrorBalance: false,

      iva: {
        debt: null,
        credit: null,
        balance: null,
        periodicity: 'month',
        bank: null,
      },
    };
  },
  computed: {
    banks: {
      get () {
        if (this.setupBanks.length > 0) return this.setupBanks;
        return [];
      },
      set (newValue) {
        return newValue;
      },
    },
    isValidForm () {
      // return (!this.showDebt || this.isValidDebt) && this.isValidCredit && this.isValidBalance;
      return true;
    },
    isValidDebt () {
      // return isFilled(this.iva.debt) && isCurrency(this.iva.debt);
      return true;
    },
    isValidCredit () {
      // return isFilled(this.iva.credit) && isCurrency(this.iva.credit);
      return true;
    },
    isValidBalance () {
      // return isFilled(this.iva.balance) && isCurrency(this.iva.balance);
      return true;
    },
    labelExpiration () {
      /*
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (this.iva.periodicity === 'month' && today.getDate() <= 16) {
        const date = new Date(today.getFullYear(), today.getMonth(), 16);
        return `In scadenza il ${this.toFormattedDate(date)}`;
      } else if (this.iva.periodicity === 'quarter') {
        const quarterDates = [
          { start: new Date(today.getFullYear(), 0, 1), end: new Date(today.getFullYear(), 2, 16) }, // 01/01 - 16/03
          { start: new Date(today.getFullYear(), 3, 1), end: new Date(today.getFullYear(), 4, 16) }, // 01/04 - 16/05
          { start: new Date(today.getFullYear(), 5, 1), end: new Date(today.getFullYear(), 7, 20) }, // 01/06 - 20/08
          { start: new Date(today.getFullYear(), 8, 1), end: new Date(today.getFullYear(), 10, 16) }, // 01/09 - 16/11
        ];

        for (const quarter of quarterDates) {
          if (today >= quarter.start && today <= quarter.end) {
            return `In scadenza il ${this.toFormattedDate(quarter.end)}`;
          }
        }
      }
      */

      return `In scadenza il ${this.toFormattedDate(this.previousExpirationDate)}`;
    },
    showDebt: {
      get () {
        return this.labelExpiration !== '';
      },
      set (newVal) {
        return newVal;
      },
    },
    labelCredit () {
      /*
      const today = new Date();
      if (this.iva.periodicity === 'month') {
        const date = new Date(today.getFullYear(), today.getMonth(), 1);
        return `Credito IVA al ${this.toFormattedDate(date)}`;
      }
      if (this.iva.periodicity === 'quarter') {
        const quarterDates = [
          { start: new Date(today.getFullYear(), 0, 1), end: new Date(today.getFullYear(), 2, 31) }, // 01/01 - 31/03
          { start: new Date(today.getFullYear(), 3, 1), end: new Date(today.getFullYear(), 4, 31) }, // 01/04 - 31/05
          { start: new Date(today.getFullYear(), 5, 1), end: new Date(today.getFullYear(), 8, 30) }, // 01/06 - 30/09
          { start: new Date(today.getFullYear(), 9, 1), end: new Date(today.getFullYear(), 11, 31) }, // 01/10 - 16/11
        ];

        for (const quarter of quarterDates) {
          if (today >= quarter.start && today <= quarter.end) {
            return `Credito IVA al ${this.toFormattedDate(quarter.start)}`;
          }
        }
      }
      */

      return `Credito IVA al ${this.toFormattedDate(this.creditStartDate)}`;
    },
    labelBalance () {
      const date = new Date();
      const year = date.getFullYear() - 1;
      return 'Saldo IVA ' + year;
    },
  },
  methods: {
    setView (type) {
      this.iva.periodicity = type;
      this.getExpirationDate();
    },
    onSubmit () {
      const form = Object.assign(this.propForm, { ivaSettings: this.iva });
      this.$emit('saveData', form);
      /*
      if (this.isValidForm) {
        this.$emit('saveData', this.form);
      } else {
        this.loading = true;
        if (!this.isValidDebt) {
          this.showErrorDebt = true;
        } else if (!this.isValidCredit) {
          this.showErrorCredit = true;
        } else if (!this.isValidBalance) {
          this.showErrorBalance = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
      */
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    getExpirationDate () {
      this.$api.getDatesIVA(this.iva.periodicity)
        .then((res) => {
          this.expirationDate = res.data.previousExpirationDate;
          this.creditStartDate = res.data.creditStartDate;
          this.showDebt = !!this.expirationDate;
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    updateBank (bank) {
      this.iva.bank = bank;
    },
  },
  mounted () {
    this.iva = Object.assign(this.iva, this.propForm.ivaSettings);
    this.getExpirationDate();
  },
};
</script>

<style>

</style>
