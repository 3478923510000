<template>
  <div class="invoice-wrapper" ref="wrapper" :key="`invoice_${invoice ? invoice.id : 'new'}`">
    <h4 class="mb-4">{{ invoice ? (confirmInvoice ? 'Conferma fattura' : 'Modifica fattura') : `Aggiungi fattura ${fatturePassive ? 'passiva' : 'attiva'}` }}</h4>
    <upsert-invoice-1 v-show="isFirstPage" :fatturePassive="fatturePassive" :banks="banks" :propInvoice="form" @updatePage="updatePage"/>
    <upsert-invoice-2 v-show="!isFirstPage" :fatturePassive="fatturePassive" :banks="banks" :propInvoice="form" :confirmInvoice="confirmInvoice" @updatePage="updatePage" @save="onSave" @openAlert="openAlert"/>

    <ErrorCard v-if="errorMessage">
      <template #message >
      {{ errorMessage }}
      </template>
    </ErrorCard>

    <Popup ref="alert" class="lg">
      <template #title> Attenzione! </template>
      <template #text>
        <div v-if="form.leftAmount > 0" class="d-inline">
          Rimangono <b>{{(form.leftAmount).toLocaleString('de', {minimumFractionDigits: 2})}} €</b> da scadenzare.
        </div>
        <div v-else class="d-inline">
          C'è una differenza di <b>{{form.leftAmount.toLocaleString('de', {minimumFractionDigits: 2})}} €</b> tra gli importi inseriti.
        </div>
      </template>
      <template #button>
        <div class="row">
          <div class="col-6">
            <custom-button
              :isLoading="loading"
              label="Abbuona"
              class="w-100 mt-4 opaque"
              @click.prevent.native="onSave"
            />
          </div>
          <div class="col-6">
            <custom-button
              label="Modifica scadenze"
              class="w-100 mt-4"
              @click.prevent.native="$refs.alert.togglePopup()"
            />
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import UpsertInvoice1 from '@/views/components/Forms/Invoices/UpsertInvoice1.vue';
import UpsertInvoice2 from '@/views/components/Forms/Invoices/UpsertInvoice2.vue';
import Button from '@/views/components/Button.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import Popup from '@/views/components/Popup.vue';

export default {
  props: {
    banks: Array,
    companyId: String,
    invoice: Object,
    confirmInvoice: {
      type: Boolean,
      default: false,
    },
    fatturePassive: Boolean,
  },
  components: {
    UpsertInvoice1,
    UpsertInvoice2,
    'custom-button': Button,
    ErrorCard,
    Popup,
  },
  data () {
    return {
      isFirstPage: true,
      loading: false,
      errorMessage: '',

      sign: this.fatturePassive ? 'out' : 'in',
      id: null,
      // Form
      form: {
        number: null,
        date: null,
        name: '',
        vat: '',
        cf: '',
        address: '',
        cap: null,
        municipality: '',
        province: '',
        state: '',
        taxBase: null,
        iva: null,
        invoiceAmount: null, // calcolato automaticamente
        withholdingTax: null,
        withholdingDate: this.getWithholdingDate(),
        withholdingIsPaid: null,
        bank2: null,
        amount: null, // calcolato automaticamente
        rate: [],
        leftAmount: null,
      },
    };
  },
  methods: {
    getBankById (bankId) {
      return this.banks.find(bank => bank.id === bankId);
    },
    getWithholdingDate (invoiceDate) {
      const date = invoiceDate ? new Date(invoiceDate) : new Date();
      // const month = date.getDate() < 16 ? date.getMonth() : date.getMonth() + 1;
      return new Date(date.getFullYear(), date.getMonth() + 1, 16);
    },
    getPaymentWithholding () {
      const today = new Date();
      today.setHours(0, 0, 0);
      return new Date(this.invoice.withholdingDate) < today;
    },
    updatePage (goBack = false) {
      this.isFirstPage = goBack;
    },
    updateData () {
      if (!this.invoice) return;

      this.id = this.invoice.id ? this.invoice.id : null;

      this.form.number = this.invoice.number;
      this.form.date = this.invoice.date;
      this.form.name = this.invoice.denomination;
      this.form.vat = this.invoice.vat; // partita iva
      this.form.cf = this.invoice.fiscalCode;
      this.form.address = this.invoice.address;
      this.form.cap = this.invoice.cap;
      this.form.municipality = this.invoice.city;
      this.form.province = this.invoice.province;
      this.form.state = this.invoice.state;
      this.form.iva = this.invoice.iva; // importo iva
      this.form.taxBase = this.invoice.imponibile;
      this.form.invoiceAmount = this.invoice.total ? this.invoice.total : null;
      this.form.withholdingTax = this.invoice.ritenuta ? this.invoice.ritenuta : null;
      this.form.withholdingDate = this.invoice.ritenuta ? (this.invoice.dataRitenuta ? this.invoice.dataRitenuta : this.getWithholdingDate(this.invoice.date)) : null;
      this.form.withholdingIsPaid = this.invoice.ritenuta ? (this.invoice.ritenutaPagata ? this.invoice.ritenutaPagata : this.getPaymentWithholding()) : false;
      this.form.bank2 = this.invoice.ritenutaBankId ? this.getBankById(this.invoice.ritenutaBankId) : null;
      this.form.rate = this.invoice.rate ? this.invoice.rate : [];
      this.form.leftAmount = this.invoice.leftAmount ? this.invoice.leftAmount : null;
    },
    onSave () {
      this.$log.debug('save');
      this.errorMessage = '';
      this.loading = true;

      this.$log.debug(this.form.rate);
      /*
      const rate = this.form.rate.map(rata => {
        const amount = parseFloat(rata.amount);
        return { ...rata, amount: amount };
      });
      */

      this.$api.createInvoice(
        this.companyId,
        this.form.number, // number,
        this.form.name, // denomination,
        this.form.vat, // vat,
        this.form.cf, // fiscalCode,
        this.form.address, // address,
        this.form.municipality, // city,
        this.form.cap, // cap,
        this.form.province, // province,
        this.form.state, // state,
        this.form.taxBase, // taxable / imponibile
        this.form.iva, // iva,
        this.form.invoiceAmount, // total,
        this.form.date, // date,
        this.form.withholdingTax, // withholding,
        this.form.bank2 ? this.form.bank2.id : null, // withholdingBankId,
        this.sign, // sign in = attiva, out = passiva
        this.form.rate, // rate,
        this.form.leftAmount, // leftAmount
        this.id,
      )
        .then((res) => {
          this.$log.debug(res.data);

          if (this.confirmInvoice) {
            this.id = res.data.id;
            this.$emit('done', 'confirm');
          } else {
            this.$emit('done');
          }
        })
        .catch((e) => {
          this.$refs.alert.togglePopup();
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openAlert (amount = 0) {
      this.form.leftAmount = amount;
      this.$refs.alert.togglePopup();
    },
  },
  mounted () {
    this.updateData();
  },
};
</script>
