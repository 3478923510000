<template>
  <div class="form-widget" :class="{ 'form-error': markAsFault }">
    <FormInputLabel :label="label" :labelIsFake="labelIsFake" :hideLabel="hideLabel" :notRequired="notRequired" :id="inputId">
      <template #label="{ label, notRequired }">
        <slot name="label" :label="label" :notRequired="notRequired" />
      </template>
    </FormInputLabel>

    <div v-if="isGroup" class="input-group">
      <div v-if="hasPrepend" class="input-group-prepend" :class="{ 'input-group-prepend-with-action': prependAction }" @click.prevent="clickOnPrepend">
        <slot name="prepend">
          <div v-if="hasPrependText || hasPrependContent" class="input-group-text">
            <slot name="prepend-content">
              {{ prependText }}
            </slot>
          </div>
        </slot>
      </div>
      <slot :inputId="inputId" />
      <div v-if="hasAppend" class="input-group-append" :class="{ 'input-group-append-with-action': appendAction }" @click.prevent="clickOnAppend">
        <slot name="append">
          <div v-if="hasAppendText || hasAppendContent" class="input-group-text">
            <slot name="append-content">
              {{ appendText }}
            </slot>
          </div>
        </slot>
      </div>
    </div>
    <slot v-else :inputId="inputId" />

    <FormInputFeedback v-if="errorMsg">{{ errorMsg }}</FormInputFeedback>
  </div>
</template>

<script>

import formInputWidgetMixin from '../mixins/formInputWidget';

export default {
  name: 'form-form-input-widget',
  mixins: [formInputWidgetMixin],
  components: {
    FormInputLabel: () => import('../../FormLabel'),
    FormInputFeedback: () => import('../../FormFeedback'),
  },
  computed: {
    hasPrependText () {
      return this.prependText !== null;
    },
    hasPrependContent () {
      return !!this.hasPrependContentSlot;
    },
    hasPrepend () {
      return !!this.hasPrependSlot || this.hasPrependContent || this.hasPrependText;
    },
    hasAppendText () {
      return this.appendText !== null;
    },
    hasAppendContent () {
      return !!this.hasAppendContentSlot;
    },
    hasAppend () {
      return !!this.hasAppendSlot || this.hasAppendContent || this.hasAppendText;
    },
    isGroup () {
      return this.hasPrepend || this.hasAppend;
    },
  },
  methods: {
    clickOnPrepend () {
      if (!this.prependAction) {
        return;
      }

      this.$emit(this.prependAction);
    },
    clickOnAppend () {
      if (!this.appendAction) {
        return;
      }

      this.$emit(this.appendAction);
    },
  },
};

</script>

<style lang="scss">

.input-group {
  .input-group-prepend.input-group-prepend-with-action {
    cursor: pointer;
  }

  .input-group-append.input-group-append-with-action {
    cursor: pointer;
  }
}

</style>
