<template>
  <div class="recurring-payments" ref="activeInvoices">
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid p-0 w-auto m-4">
      <div class="row d-flex justify-content-between align-items-center mb-4">
        <div class="col">
          <h4>Pagamenti ricorrenti</h4>
        </div>
        <div class="col d-flex justify-content-end">
          <button class="btn btn-fin mr-3 btn-primary" @click="togglePopup('upsertTransfer')">Aggiungi pagamento</button>
          <FiltersRecurringPayments id="recurring-transfers-filters"/>
        </div>
      </div>

      <div class="table-wrapper my-4">
        <b-table
          id="invoicesTable"
          ref="invoicesTable"
          :items="transfers"
          :fields="fields"
          no-local-sorting
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          hover
          show-empty
        >
          <template #cell(date)="row">
            {{ toFormattedDate(row.item.date) }}
          </template>
          <template #cell(bank)="row">
            <div class="d-flex align-items-center">
              <img v-if="getLogo(row.item.bank.id)" :src="getLogo(row.item.bank.id)" class="bank-logo md mr-2">
              <div :class="`text-bank-variant-${row.item.bank.colour ? row.item.bank.colour : 0}`" class="font-weight-600">
                {{ row.item.bank.alias ? row.item.bank.alias : row.item.bank.name }}
              </div>
            </div>
          </template>
          <template #cell(frequency)="row">
            {{getFrequency(row.item.frequency)}}
          </template>
          <template #cell(amount)="row">
            <CellContent
              :value="parseFloat(row.item.amount)"
              class="d-flex justify-content-end m-0 p-0 border-0"
            />
          </template>
          <template #cell(nextExpirationDate)="row">
            {{ toFormattedDate(row.item.nextExpirationDate) }}
          </template>
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
              variant="link"
              toggle-class="text-decoration-none"
            >
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item
                @click="editRow(row.item)"
                >Modifica</b-dropdown-item
              >
              <b-dropdown-item
                @click="deleteRow(row.item)"
                class="red"
                >Elimina</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template #empty>
            <div class="d-flex justify-content-center align-items-center py-2">
              Nessuna fattura trovata
            </div>
          </template>
        </b-table>
      </div>

      <div class="row d-flex justify-content-between">
        <div class="col-4 d-flex align-items-center">
          <b-form-group
            label="Elementi per pagina"
            label-for="perPageOptions"
            class="page-items d-flex align-items-center mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageOptions"
              class="form-control ml-3"
              :options="perPageOptions"
            />
          </b-form-group>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-center">
          <b-pagination
            v-model="currentPage"
            aria-controls="table"
            :per-page="perPage2"
            :total-rows="totalItems"
            class="mb-0"
            first-number
             last-number
          >
            <template #prev-text><i class="fas fa-chevron-left"></i></template>
            <template #next-text><i class="fas fa-chevron-right"></i></template>
          </b-pagination>
        </div>
        <div class="col-4">
        </div>
      </div>
    </div>

    <Popup ref="upsertTransfer" class="md up" @beforeClosing="selectedRow = null">
      <template #fullContent>
        <BonificoRicorrente :transfer="selectedRow" :banks="banks" :companyId="companyId" @save="onSave" @toggleSnackbar="toggleSnackbar(msg)" />
      </template>
    </Popup>

    <Popup ref="deleteTransfer" class="md up" @beforeClosing="selectedRow = null">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
        Tutti gli impegni futuri del pagamento ricorrente <b v-if="selectedRow.causale">"{{ selectedRow.causale }}"</b> di <b>{{(selectedRow.amount).toLocaleString('de')}} €</b> con beneficiario {{ selectedRow.counterpart }} associati al conto {{selectedRow.bank.alias}} verranno eliminati.
      </template>
      <template #button>
        <custom-button
          :isLoading="loading"
          label="Elimina"
          class="w-100 mt-4"
          @click.prevent.native="onDelete"
        />
      </template>
    </Popup>

    <Snackbar :text="snackbarText" ref="snackbar" class="up"/>
  </div>
</template>

<script>
import formatMovementTypeFilter from '@/filters/formatMovementType';
import capitalizeFirstLetter from '@/filters/capitalizeFirstLetter';
import {
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormSelect,
  BPagination,
  BTable,
} from 'bootstrap-vue';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';
// to do handle broken img links

import { toFormattedDate } from '@/helpers/dates.js';

import Button from '@/views/components/Button.vue';
import BonificoRicorrente from '@/views/components/Forms/Movements/Forms/BonificoRicorrente.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
// import ErrorCard from '@/views/components/ErrorCard.vue';
import FiltersRecurringPayments from '@/views/components/FiltersRecurringPayments.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Popup from '@/views/components/Popup.vue';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  components: {
    BFormGroup,
    BFormSelect,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    'custom-button': Button,
    BonificoRicorrente,
    CellContent,
    FiltersRecurringPayments,
    Loader,
    Popup,
    Snackbar,
  },
  filters: {
    movement: formatMovementTypeFilter,
    capitalizeFirstLetter: capitalizeFirstLetter,
  },
  data () {
    return {
      banks: [],
      companyId: this.$oauth2.me.companies[0].id || null,
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
      fields: [
        { key: 'bank', label: 'Banca' },
        { key: 'counterpart', label: 'Beneficiario', sortable: false },
        { key: 'description', label: 'Causale', sortable: false, class: 'text-left' },
        { key: 'frequency', label: 'Frequenza', sortable: true, class: 'text-left' },
        { key: 'amount', label: 'Importo', sortable: true, class: 'text-right' },
        { key: 'nextExpirationDate', label: 'Prossima scadenza', sortable: true, class: 'text-right' },
        { key: 'actions', label: '', sortable: false },
      ],
      transfers: [],

      errorMessage: '',
      showPopup: true,
      snackbarText: '',
      loading: false,
      loading2: false,
      isLoadingPage: true,
      uploadingData: false,
      defaultView: true,

      rowColour: null,
      textColour: null,

      startDate: this.$route.query.startDate || null,
      endDate: this.$route.query.endDate || null,

      // Table
      isBusy: false,
      sortDesc: Boolean(this.$route.query.sortDesc) || true,
      sortBy: this.$route.query.sortBy || null, // default: ultime aggiunte (non c'è filtro)
      currentPage: this.$route.query.page || 1,
      perPage: this.$route.query.perPage || 10,
      perPage2: null,
      totalItems: null,
      perPageOptions: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],

      selectedRow: null,
    };
  },
  computed: {
    bankId: function () {
      return this.$route.query.bankId;
    },
    watchedSorting () {
      return this.sortBy + this.sortDesc;
    },
  },
  watch: {
    $route: function () {
      this.getRecurringTransfers();
    },
    selectAll () {
      if (this.selectAll) {
        this.invoicesToConfirm = this.uploadedInvoices.map(invoice => invoice.id);
      } else {
        this.invoicesToConfirm = [];
      }
    },
    perPage: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            perPage: this.perPage,
          }),
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.scrollToTop();
        });
    },
    currentPage: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            page: this.currentPage,
          }),
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.scrollToTop();
        });
    },
    startDate: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            startDate: this.startDate,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
    endDate: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            endDate: this.endDate,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
    watchedSorting: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            sortDesc: this.sortDesc,
            sortBy: this.sortBy,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
  },
  methods: {
    deleteRow (obj) {
      this.selectedRow = obj;
      this.togglePopup('deleteTransfer');
    },
    editRow (obj) {
      this.selectedRow = obj;
      this.togglePopup('upsertTransfer');
    },
    getCompanyInfo () {
      this.$api
        .getCompany(this.companyId)
        .then((res) => {
          this.banks = res.data.banks;
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getFrequency (month) {
      if (month) {
        const frequency = ['Mensile', 'Bimestrale', 'Trimestrale', 'Quadrimestrale', null, 'Semestrale', null, null, null, null, null, null, 'Annuale'];
        return frequency[month - 1];
      }
      return null;
    },
    getLogo (bankId) {
      const bank = this.banks.find(bank => bank.id === bankId);
      return (bank && bank.logo) ? bank.logo : bankLogoDefault;
    },
    getRecurringTransfers () {
      this.isBusy = true;
      const filters = this.$route.query;
      this.$api
        .getRecurringTransfers(this.companyId, filters)
        .then((res) => {
          this.$log.debug(res.data.recurringTransfers);
          this.transfers = res.data.recurringTransfers || [];

          this.totalItems = res.data.metadata['total-items'];
          this.perPage2 = res.data.metadata['per-page'];
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.isBusy = false;
          this.isLoadingPage = false;
        });
    },
    onDelete () {
      this.$log.debug(this.selectedRow);
      this.loading = true;
      this.$api
        .deleteRecurringTransfer(this.selectedRow.id)
        .then(() => {
          this.togglePopup('deleteTransfer');
          this.snackbarText = 'Pagamento ricorrente eliminato correttamente';
          this.$refs.snackbar.openSnackbar();
          this.getRecurringTransfers();
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onEdit () {
      this.getInvoices();
      this.togglePopup('movementEditor');
      this.snackbarText = 'Modifiche salvate';
      this.$refs.snackbar.openSnackbar();
    },
    scrollToTop () {
      setTimeout(() => {
        this.$refs.movements.scrollTo({
          top: 250,
          left: 0,
          behavior: 'smooth',
        });
      }, 500);
    },
    searchInBanks () {
      return this.banks.find((bank) => bank.id === this.bankId);
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    toggleSnackbar (msg) {
      this.snackbarText = msg;
      this.$refs.snackbar.openSnackbar();
    },
    onSave (msg) {
      this.togglePopup('upsertTransfer');
      this.toggleSnackbar(msg);
      this.getRecurringTransfers();
    },
  },
  mounted () {
    this.getCompanyInfo();
    this.getRecurringTransfers();
  },
};
</script>

<style lang="scss">

.recurring-payments {
  background: $primary-lighter;

  // row settings
  .dropdown {
    button {
      // no caret
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
        outline: none;
      }
      .red > * {
        color: $danger !important;
      }
      .dropdown-item {
        color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
          outline: none;
        }
        &:active {
          background: $primary-light;
        }
      }
    }
  }

  .table-wrapper {
    border-radius: 10px;
    box-shadow: $box-shadow;
    // overflow: hidden;
    height: min-content;
  }

  table {
    background: white;
    text-overflow: ellipsis;
    font-size: 16px;
    margin: 0;
    border-radius: 10px;
    th {
      border: none;
      text-transform: uppercase;
      font-size: 14px;
      color: rgba($primary, 0.75);
      font-weight: 600;
      &:nth-last-child(2) {
        text-align: right;
      }
    }
    thead {
      border: none !important;
      background: $fin-white;
      border-radius: 10px;
      th {
        padding: 23px 15px;
      }
    }
  }
}
</style>
