<template>
  <div class="dashboard">
    <div v-if="loading">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid w-auto p-0 m-4">
      <Loader v-if="!dataLoaded" class="no-bg" />
      <div v-else class="table-wrapper h-100 overflow-auto" id="table-wrapper" ref="tableWrapper">
        <Table2 :items="defaultView ? saldi : impegni" :todayDate="todayDate" @scroll="scroll" @scrollX="scrollX" :defaultView="defaultView" @setDefaultView="setDefaultView" :isTutorial="showTutorial" id="table" ref="table" />

        <Tutorial ref="tutorialPopup" v-if="showTutorialPopup" @close="closeTutorial" @startTour="startTour"/>
        <Shepherd v-if="showTutorial" @unblockDropdown="unblockDropdown" :movementTutorial="movementTutorial" @tutorialCompleted="endTour"/>
      </div>
    </div>
  </div>
</template>

<script>
import Table2 from '@/views/components/Table/Table2.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Tutorial from '@/views/components/Tutorial.vue';
import Shepherd from '@/views/components/Shepherd.vue';

export default {
  components: {
    Table2,
    Loader,
    Tutorial,
    Shepherd,
  },
  props: {
    firstAccess: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      movementTutorial: null,
      saldiLoaded: false,
      impegniLoaded: false,
      companyLoaded: false,
      // showTutorialPopup: this.firstAccess || false,
      showTutorialPopup: false,
      showTutorial: false,
      defaultView: true,
      loading: true,
      companyId: '',
      saldi: null,
      impegni: null,
      todayDate: '',
    };
  },
  computed: {
    dataLoaded () {
      return this.saldiLoaded && this.impegniLoaded && this.companyLoaded;
    },
  },
  methods: {
    closeTutorial () {
      this.showTutorialPopup = false;
    },
    getSaldi () {
      this.$api.fetchSaldiDashboard(this.companyId)
        .then((res) => {
          this.todayDate = res.headers.date;
          this.saldi = res.data.items;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
          this.saldiLoaded = true;
        });
    },
    getImpegni () {
      this.$api.fetchImpegniDashboard(this.companyId)
        .then((res) => {
          this.todayDate = res.headers.date;
          this.impegni = res.data.items;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
          this.impegniLoaded = true;
        });
    },
    getCompanyInfo () {
      this.loading = true;
      this.$oauth2.getMe(true);
      const me = this.$oauth2.me;

      if (me.companies.length === 0) {
        this.$router.replace('setup');
      } else {
        // to do replace multiazienda
        this.companyId = me.companies[0]?.id;
      }
      this.loading = false;
      this.companyLoaded = true;
    },
    setDefaultView (isDefault = true) {
      this.defaultView = Boolean(isDefault);
    },
    scroll (rowIndex) {
      // to do fixare in mozilla
      setTimeout(() => {
        this.$refs.tableWrapper.scrollTo({ left: undefined, top: 44 * rowIndex, behavior: 'smooth' });
      }, 500);
    },
    scrollX (colIndex) {
      // to do fixare in mozilla
      setTimeout(() => {
        this.$refs.tableWrapper.scrollTo({ left: 120 * colIndex, behavior: 'smooth' });
      }, 300);
    },
    startTour () {
      this.showTutorialPopup = false;
      this.showTutorial = true;
      this.$root.$emit('tutorialStarted');
    },
    endTour () {
      this.showTutorialPopup = false;
      this.showTutorial = false;
      this.$root.$emit('tutorialCompleted');
    },
    unblockDropdown () {
      this.$root.$emit('unblockDropdown');
    },
  },
  created () {
    /*
    const me = this.$oauth2.me;
    if (me?.product?.expired) {
      return this.$router.replace('checkout');
    }
    // to do for non-admin
    if (me?.companies && me?.companies[0].product?.expired) {
      this.$log.debug('expired');
      return this.$router.replace('product-expired');
    }
    */
  },
  beforeMount () {
    this.getCompanyInfo();
    this.getSaldi();
    this.getImpegni();

    this.$root.$on('updateMovements', () => {
      this.getSaldi();
      this.getImpegni();
    });

    this.$root.$on('getTutorialMovement', (movement) => {
      this.movementTutorial = JSON.parse(JSON.stringify(movement));
    });
  },
};

</script>

<style lang="scss">
$t-header-color: $fin-white;

$width-first-child:  190px; // min 175px (tabs)
$width-second-child:  120px;
$width-third-child:  110px;
$width-fourth-child:  110px;
$width-last-child:  110px;

$height-sticky-row: 44px;

$thead-height: 100px;
$th-div-height: 60px;

$tbody-td-padding: 10px;
$tbody-main-row-padding: 10px 15px;
$tbody-details-row-padding: 8px 15px; // 10px 15px

$width-tag-today: 40px;
$width-tag-week: 93px;

.upper {
  z-index: 3000!important;
}

.dashboard {
  background: $background;

  .table-wrapper {
    height: auto;
    max-height: 890px;
    overflow-x: scroll;
    overflow-y: hidden!important;
    background: $primary-lighter;
    border-radius: 0px 260px 10px 10px / 0px 50px 10px 10px;
    padding-bottom: 20px;
    box-sizing: content-box;
  }

  .container-fluid {
    position: relative;
    border-image: 10px solid black;
    background: $primary-lighter;
    overflow-x: hidden;
    &::after {
      content: '\00a0';
      position: absolute;
      z-index: 1000;
      left: 0;
      background: none;
      pointer-events: none;
      // box-shadow: $box-shadow;
      width: 100%;
      height: calc(100% - ($thead-height - $th-div-height));
      top: calc($thead-height - $th-div-height);
      border-radius: 10px;
    }
  }

  .dashboard-table {
    background: transparent;
    font-size: 16px;
    position: relative;

    thead {
        height: $thead-height;
        text-transform: uppercase;
        font-size: 14px;
        border: none;
        background: transparent;
        // Necessary for sticky rows
        position: sticky;
        top: 0;
        z-index: 7;
        th {
          background: none;
          position: relative;
          vertical-align: bottom;
        }
        td, th {
            padding: 0!important;
            div {
                position: relative;
                bottom: 0px!important;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $t-header-color;
                height: $th-div-height;
                padding: 10px;
                z-index: 0;
                border-left: none ;
                border-right: none;
            }
        }
    }

    tbody {
        background: transparent;
        td {
          text-align: right;
          padding: $tbody-td-padding;
          left: 0;
          transition: all 0.5s;
          min-width: 120px;
          .bank-logo {
            position: relative;
            left: -5px;
          }
        }
    }

    // STICKY ROWS ON BOTTOM
    .sticky-row {
        position: sticky;
        z-index: 7;

        // line count from bottom
        &.sticky-row-5 {
            bottom: $height-sticky-row*4;
            font-weight: 600;
            // shadow
            &::before {
                content: '';
                pointer-events: none;
                position: absolute;
                background: linear-gradient(transparent, rgba($primary, 0.02));
                top: -25px;
                z-index: 20;
                height: 25px;
                width: 100%;
            }
        }
        &.sticky-row-4 {
            bottom: $height-sticky-row*3;
        }
        &.sticky-row-3 {
            font-weight: 600;
            bottom: $height-sticky-row*2;
        }
        &.sticky-row-2 {
            bottom: $height-sticky-row;
        }
        &.sticky-row-1 {
            font-weight: 600;
            bottom: 0;
        }
    }

    // background of th
    th {
        position: relative;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0px;
            z-index: 0;
            height: 100%;
            width: 100%;
            background: $primary-lighter;
        }
    }

    // Shadow on last sticky column
    td.sticky-column, th.sticky-column div {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            right: -25px;
            top: 0px;
            z-index: 20;
            height: 100%;
            width: 25px;
            background: linear-gradient(90deg, rgba(39, 36, 83, 0.04), transparent);
        }
    }

    // STICKY COLUMNS
    td.sticky-column {
      text-align: right;
    }

    td.sticky-column, th.sticky-column {
        position: sticky;
        z-index: 6;
        padding: 0px $tbody-td-padding;
        text-align: right;
        div {
            background: $fin-white;
        }
        &.child-1 {
            left: 0;
            white-space: nowrap;
            text-align: left;
            // text-overflow: ellipsis;
            max-width: $width-first-child;
            min-width: $width-first-child;
            padding-left: 15px;
            div {
                padding-left: 15px;
                justify-content: left;
            }
            &.row-title {
                padding: $tbody-main-row-padding;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &.row-description {
                padding: $tbody-details-row-padding;
                position: sticky;
                right: 0;
                // width: calc($width-first-child + $width-second-child + $width-third-child + $width-fourth-child);
                width: calc($width-first-child + $width-third-child + $width-fourth-child);
                // min-width: calc($width-first-child + $width-second-child + $width-third-child + $width-fourth-child);
                min-width: calc($width-first-child + $width-third-child + $width-fourth-child);
                overflow: visible;
                text-align: right!important;
            }
        }
        &.child-2 {
            display: none;
            /*
            left: $width-first-child;
            min-width: $width-second-child;
            max-width: $width-second-child;
            */
        }
        &.child-3 {
          display: none;
          /*
            left: calc($width-first-child + $width-second-child);
            max-width: $width-third-child;
            min-width: $width-third-child;
          */
        }
        &.child-4 {
          /*
            left: calc($width-first-child + $width-second-child + $width-third-child);
            left: calc($width-first-child + $width-second-child); // senza fido
          */
            left: calc($width-first-child);
            max-width: $width-third-child;
            min-width: $width-third-child;
        }
        &.child-5 {
          /*
            left: calc($width-first-child + $width-second-child + $width-third-child + $width-fourth-child);
            left: calc($width-first-child + $width-second-child + $width-third-child); // senza fido
          */
          left: calc($width-first-child + $width-third-child); // senza fido
          max-width: $width-third-child;
          min-width: $width-third-child;
        }
    }

    // per aggiungere colonna modificare colspan = 5 in TableBody

    th.sticky-column {
      z-index: 8;
    }

    /* ---------------------------------------------------------------------------------------------- */

    // Today column highlighted
    td.today, th.today div {
        font-weight: 700;
        color: $primary;
    }

    .date {
      position: relative;
      .day {
        height: fit-content;
        position: absolute;
        &::after {
          content: attr(data-attribute);
          font-size: 12px;
          color: rgba($primary, 0.6);
          position: absolute;
          bottom: 2px;
        }
        &::before {
          display: none;
        }
      }
    }

    // Rounded border
    th:first-child {
        & div {
            border-radius: 10px 0px 0px 0px;
        }
    }

    // Tabs
    .tab {
        position: relative;
        border: none;
        border-radius: 10px 10px 0px 0px;
        text-transform: uppercase;
        padding: 6px 12px;
        font-weight: 700;
        background: $primary-lighter;
        color: $primary-mid;
        overflow: visible;
        z-index: 10;
        &.active, &.selected  {
          color: $primary;
          background: $fin-white;
          border: none;
        }
    }

    // Tags
    .floating-tag {
        white-space: nowrap;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
        width: 100%;
        cursor: pointer;
        &::before {
            font-weight: 700;
            position: absolute;
            font-size: 12px;
            padding: 3px 4px;
            border-radius: 5px;
            background: $primary;
            color: white;
            top: 0px;
            text-transform: uppercase;
            margin: auto;
            box-shadow: $box-shadow;
        }
        &.today::before {
            background: rgba($primary, 0.7)!important;
            width: $width-tag-today;
            left: calc(50% - ($width-tag-today / 2));
            content: 'oggi';
        }
        &.one-week::before {
            width: $width-tag-week;
            left: calc(50% - ($width-tag-week / 2));
            content: '+1 settimana';
        }
        &.two-weeks::before {
            width: $width-tag-week;
            left: calc(50% - ($width-tag-week / 2));
            content: '+2 settimane';
        }
        &.three-weeks::before {
            width: $width-tag-week;
            left: calc(50% - ($width-tag-week / 2));
            content: '+3 settimane';
        }
        &.four-weeks::before {
            width: $width-tag-week;
            left: calc(50% - ($width-tag-week / 2));
            content: '+4 settimane';
        }
        &.five-weeks::before {
            width: $width-tag-week;
            left: calc(50% - ($width-tag-week / 2));
            content: '+5 settimane';
        }
        &.six-weeks::before {
            width: $width-tag-week;
            left: calc(50% - ($width-tag-week / 2));
            content: '+6 settimane';
        }
        &.seven-weeks::before {
            width: $width-tag-week;
            left: calc(50% - ($width-tag-week / 2));
            content: '+7 settimane';
        }
        &.eight-weeks::before {
            width: $width-tag-week;
            left: calc(50% - ($width-tag-week / 2));
            content: '+8 settimane';
        }
        &.nine-weeks::before {
            width: $width-tag-week;
            left: calc(50% - ($width-tag-week / 2));
            content: '+9 settimane';
        }
        &.ten-weeks::before {
            width: $width-tag-week;
            left: calc(50% - ($width-tag-week / 2));
            content: '+10 settimane';
        }
        &.eleven-weeks::before {
            width: $width-tag-week;
            left: calc(50% - ($width-tag-week / 2));
            content: '+11 settimane';
        }
        &.twelve-weeks::before {
            width: $width-tag-week;
            left: calc(50% - ($width-tag-week / 2));
            content: '+12 settimane';
        }

    }

    // Hover
    tbody {
      // hover on main line (bank)
      tr:first-child:hover {
          filter: brightness(97%) saturate(1.1);
      }
      // hover on details row
      tr:nth-child(n+2) {
          td:first-child {
              cursor: default;
          }
          &:hover {
              filter: brightness(98%) saturate(1.1)!important;
              & td:first-child {
                  font-weight: 600;
              }
          }
      }
    }

    // Darker columns
    th:nth-child(2n+6) div,
    tr:nth-child(n+2) td:nth-child(2n),
    tr:first-child td:nth-child(2n+6) {
      &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 7;
        left: 0;
        top: 0;
        display: block;
        background: rgba(0, 0, 0, 0.02);
      }
    }

    tr:nth-child(n+2) td:nth-child(2n),
    tr:first-child td:nth-child(2n+6) {
      filter: brightness(98%) saturate(1.1);
    }
}

// cursore pointer su hover riga scadenzario attivo
.only-values-clickable {
  td + .selectable {
    cursor: pointer;
  }
}

}
</style>
