<template>
  <FormContainer v-if="success !== true" class="row px-0" @submitForm="changePassword">
    <div class="col-12 mb-2">
      <router-link :to="{ name: 'home' }">
        Vai alla Home
      </router-link>
    </div>
    <div class="col-auto">
      <p class="h4">Resetta la Password</p>
    </div>
    <FormInputTextWidget
      class="col-12"
      label="Password"
      v-model="password"
      :errorMsg="errorHash.password"
      type="password"
      @input="removeError('password')"
    />
    <FormInputTextWidget
      class="col-12"
      label="Conferma Password"
      v-model="confirmPassword"
      :errorMsg="errorHash.confirmPassword"
      type="password"
      @input="removeError('confirmPassword')"
    />
    <div class="col-12 mt-4">
      <button class="btn btn-primary" type="submit">Reset</button>
    </div>
    <div v-if="success === false" class="col-12 mt-2">
      <FormErrors>
        Si è verificato un errore non previsto, si prega di riprovare
      </FormErrors>
    </div>
  </FormContainer>
  <div v-else>
    <div class="alert alert-success">
      <h4 class="alert-heading">Password modificata con successo</h4>
      <p class="h6">Se non vieni reindirizzato al Login entro breve clicca il link sottostante.</p>
      <hr>
      <p>
        <router-link :to="{ name: 'login' }">Vai al login</router-link>
      </p>

    </div>
  </div>
</template>

<script>
import validateMixin from '../../../libs/Form/mixins/validate';

export default {
  name: 'password-change',
  data () {
    return {
      password: null,
      confirmPassword: null,
      success: null,
    };
  },
  components: {
    FormInputTextWidget: () => import('../../../libs/Form/components/Inputs/Widgets/FormInputTextWidget'),
    FormContainer: () => import('../../../libs/Form/components/FormContainer'),
    FormErrors: () => import('../../../libs/Form/components/FormErrors'),
  },
  mixins: [validateMixin],
  methods: {
    showFinalMessage () {
      this.success = true;
      setTimeout(() => {
        this.$router.replace({ name: 'login' });
      }, 2000);
    },
    notifyError () {
      this.success = false;
    },
    changePassword () {
      if (!this.isValid()) {
        return;
      }

      this.$api.changePasswordWithToken(
        this.$route.params.email,
        this.$route.params.token,
        this.password,
      ).then(this.showFinalMessage, this.notifyError).catch(this.notifyError);
    },
    isValid () {
      if (!this.password) {
        this.addError('Missing Password', 'password');
      } else if (this.password.length < 8 || !this.password.match(/\d|[^\w\s]/)) {
        this.addError('La password deve avere almeno 8 caratteri, compreso un numero o un simbolo.', 'password');
      } else if (!this.confirmPassword) {
        this.addError('Inserire la password di conferma', 'confirmPassword');
      } else if (this.password !== this.confirmPassword) {
        this.addError('La password di conferma deve combaciare con la password inserita', 'confirmPassword');
      }
      return !this.hasErrors;
    },
  },
};

</script>

<style lang="scss"></style>
