import { init as $oauth2ApiInit } from '../services/api';
import $oauth2 from '../services/auth';

export default (Vue, options) => {
  $oauth2ApiInit();

  Vue.oauth2 = Vue.observable($oauth2);
  Object.defineProperties(Vue.prototype, {
    $oauth2: {
      get: () => Vue.oauth2,
    },
  });

  $oauth2.setConfig(options.config);
  (options.providers || []).forEach(provider => $oauth2.addProvider(provider, (options.configProviders || {})[provider.name] || {}));
};
