<template>
  <div class="invoices" ref="activeInvoices">
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid p-0 w-auto m-4">
      <div class="row d-flex justify-content-between align-items-center mb-4">
        <div class="col">
          <h4>
            {{ fattureAttive ? 'Fatture attive' : 'Fatture passive'}}
          </h4>

          <button
            v-if="userInStage"
            variant="warning"
            squared
            @click="downloadFattureAttiveDemo()"
            >Demo
          </button>

        </div>
        <div class="col d-flex justify-content-end">
          <button class="btn btn-fin btn-primary mr-3" @click="selectedRow = null; togglePopup('upsertInvoice')">
            Aggiungi fattura
          </button>

          <div class="position-relative">
            <div class="notification-dot" v-if="uploadedInvoices.length > 0">
              {{uploadedInvoices.length}}
            </div>
            <button class="btn btn-fin btn-primary h-100 mr-3" @click="selectedRow = null; togglePopup('importInvoice')">Importa</button>
          </div>

          <FiltersInvoices />
        </div>
      </div>

      <div class="table-wrapper my-4">
        <b-table
          id="invoicesTable"
          ref="invoicesTable"
          :items="invoices"
          :fields="fields"
          no-local-sorting
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :key="`invoices_${update}`"
          hover
          show-empty
        >
          <template #head(denomination)>
            {{ fattureAttive ? 'Cliente' : 'Fornitore' }}
          </template>
          <template #head(total)>
            {{ fattureAttive ? 'Importo' : 'Totale fattura' }}
          </template>
          <template #cell(date)="row">
            {{ toFormattedDate(row.item.date) }}
          </template>
          <template #cell(bank)="row">
            <div class="d-flex align-items-center">
              <img
                v-if="getLogo(row.item?.bank)"
                :src="getLogo(row.item?.bank)"
                class="bank-logo md mr-2"
              />
              <div
                :class="`text-bank-variant-${
                  row.item.bank.colour ? row.item.bank.colour : 0
                }`"
                class="font-weight-600"
              >
                {{
                  row.item.bank.alias ? row.item.bank.alias : row.item.bank.name
                }}
              </div>
            </div>
          </template>
          <template #cell(scadenze)="row">
            <div v-if="isPaid(row.item)" class="paid ml-auto">
              Pagata
            </div>
            <div
              v-for="(scadenza, i) in row.item.scadenze"
              :key="`row_${row.index}_date_${i}`"
              class="font-weight-bolder"
            >
              <div :class="{ 'd-none': scadenza.isPaid }">
                {{ scadenza.date ? toFormattedDate(scadenza.date) : "" }}
              </div>
            </div>
          </template>
          <template #cell(total)="row">
            <CellContent
              :value="parseFloat(row.item.total)"
              class="d-flex justify-content-end m-0 p-0 border-0"
            />
          </template>
          <template #cell(residuo)="row">
            <CellContent
              :value="parseFloat(row.item.residuo)"
              class="d-flex justify-content-end m-0 p-0 border-0"
            />
          </template>
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
              variant="link"
              toggle-class="text-decoration-none"
            >
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item
                v-if="row.item.isImported"
                @click="downloadPdfInvoice(row.item)"
                >Scarica fattura</b-dropdown-item
              >
              <b-dropdown-item
                v-if="!row.item.isMatched"
                @click="
                  togglePopup('markAsPaid');
                  selectedRow = row.item;
                "
                >Modifica scadenze</b-dropdown-item
              >
              <b-dropdown-item
                v-if="!row.item.isMatched"
                @click="
                  editInvoice(row.item);
                  selectedRow = row.item;
                "
                >Modifica</b-dropdown-item
              >
              <b-dropdown-item
                v-if="!row.item.isImported"
                @click="
                  togglePopup('deleteInvoice');
                  selectedRow = row.item;
                "
                class="red"
                >Elimina</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template #empty>
            <div class="d-flex justify-content-center align-items-center py-2">
              Nessuna fattura trovata
            </div>
          </template>
        </b-table>
      </div>

      <div class="row d-flex justify-content-between">
        <div class="col-4 d-flex align-items-center">
          <b-form-group
            label="Elementi per pagina"
            label-for="perPageOptions"
            class="page-items d-flex align-items-center mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageOptions"
              class="form-control ml-3"
              :options="perPageOptions"
            />
          </b-form-group>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-center">
          <b-pagination
            v-model="currentPage"
            aria-controls="table"
            :per-page="perPage2"
            :total-rows="totalItems"
            class="mb-0"
            first-number
            last-number
          >
            <template #prev-text><i class="fas fa-chevron-left"></i></template>
            <template #next-text><i class="fas fa-chevron-right"></i></template>
          </b-pagination>
        </div>
        <div class="col-4">
        </div>
      </div>
    </div>

    <Popup @beforeClosing="confirmInvoice = false;" ref="upsertInvoice" class="xl up">
      <template #fullContent>
        <UpsertInvoiceWrapper :banks="banks" :companyId="companyId" :invoice="selectedRow" :fatturePassive="!fattureAttive" :confirmInvoice="confirmInvoice" @toggleSnackbar="toggleSnackbar(msg)" @done="onSavedInvoice"/>
      </template>
    </Popup>

    <Popup @beforeClosing="error = ''" ref="deleteInvoice" class="md up" :errorMessage="error">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
        La fattura n. <b>{{ selectedRow.number }}</b> di {{ (selectedRow.total).toLocaleString('de', { minimumFractionDigits: 2 }) }} € emessa da {{selectedRow.denomination}} verrà eliminata.
      </template>
      <template #button>
        <custom-button
          :isLoading="loading"
          label="Elimina"
          class="w-100 mt-4"
          @click.prevent.native="onDelete"
        />
      </template>
    </Popup>

    <Popup @beforeClosing="selectedRow = ''; error = '';"  ref="markAsPaid" class="md up" :errorMessage="error">
      <template #fullContent>
        <MarkExpirationAsPaid :invoice="selectedRow" @onSavedExpiration="onSavedExpiration" @setError="setError"/>
      </template>
    </Popup>

    <!-- Drag n drop invoice -->
    <Popup ref="importInvoice" class="xl import-invoice-popup" noBody @beforeClosing="errorMessage = '';" fixedOnTop :key="`import`">
      <template #fullContent>
        <h4 class="mb-4">Importa fatture {{ fattureAttive ? 'attive' : 'passive'}}</h4>
        <div class="row mb-3">
          <div class="col-12">
            <div class="selector" id="selector">
              <div class="row">
                <div class="col-6">
                  <button class="btn w-100 h-100 option" @click="defaultView = true;" :class="{ active: defaultView === true }">
                    Importa
                  </button>
                </div>
                <div class="col-6">
                  <div class="notification-dot" v-if="uploadedInvoices.length > 0">
                    {{uploadedInvoices.length}}
                  </div>
                  <button class="btn w-100 h-100 option" @click="defaultView = false; errorMessage = '';" :class="{ active: defaultView !== true }">
                    In attesa di conferma
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="defaultView">
          <DragAndDrop v-if="!uploadingData" @uploadFile="uploadFile"/>
          <div v-if="uploadingData">
            <div class="d-flex justify-content-center align-items-center p-5">
              <i class="fas fa-spinner spinning mr-2"></i>
              Loading
            </div>
          </div>

          <ErrorCard v-if="errorMessage" class="mb-3">
            <template #message >
            {{ errorMessage }}
            </template>
          </ErrorCard>
        </div>

        <div v-else>
          <ErrorCard v-if="errorMessage" class="mb-3">
            <template #message >
            {{ errorMessage }}
            </template>
          </ErrorCard>

          <div v-if="uploadedInvoices.length === 0" class="text-center">
            Non ci sono fatture da confermare
          </div>
          <div v-else>
            <b-table
              id="uploadedInvoices"
              ref="uploadedInvoices"
              :items="uploadedInvoices"
              :fields="fields2"
              :sort-by="'date'"
              :sort-desc="true"
            >
              <template #head(select)>
                <b-form-checkbox :id="`checkbox-head`" v-model="selectAll"></b-form-checkbox>
              </template>
              <template #cell(select)="row">
                <b-form-checkbox :id="`checkbox-${row.item.id}`" :value="row.item.id" v-model="invoicesToConfirm"></b-form-checkbox>
              </template>
              <template #cell(bankName)="row">
                <div :title="row.item.bankName">
                  {{ row.item.bankName }}
                </div>
              </template>
              <template #cell(date)="row">
                {{ row.item.date ? toFormattedDate(row.item.date) : null }}
              </template>
              <template #cell(type)="row">
                {{ row.item.type | movement | capitalizeFirstLetter }}
              </template>
              <template #cell(total)="row">
                <CellContent
                  :value="parseFloat(row.item.total)"
                  class="d-flex justify-content-end m-0 p-0 border-0"
                />
              </template>
              <template #cell(actions)="row">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-fin-sm p-0" @click="selectedRow = row.item; confirmInvoice = true; togglePopup('editInvoice')"><i class="fas fa-pen btn-fin"></i></button>
                  <button class="btn btn-fin-sm p-0" @click="selectedRow = row.item; togglePopup('deleteInvoice')"><i class="fas fa-trash btn-fin-red"></i></button>
                </div>
              </template>
            </b-table>

            <custom-button
              :isLoading="loading2"
              :label="`Conferma selezionate (${invoicesToConfirm.length})`"
              class="mt-4 w-100"
              @click.prevent.native="onConfirm"
            />

          </div>
        </div>

      </template>
    </Popup>

    <Popup
      ref="editInvoice"
      class="xl import-invoice-popup"
      noBody
      @beforeClosing="errorMessage = ''; selectedRow = null;"
    >
      <template #fullContent>
        <EditInvoice
          :propInvoice="selectedRow"
          :confirmSubmit="confirmSubmit"
          :confirmInvoice="confirmInvoice"
          :banks="banks"
          :companyId="companyId"
          :fatturePassive="!fattureAttive"
          @setError="setError"
          @toggleAlert="toggleAlert"
          @save="onEditInvoice"
        />
      </template>
    </Popup>

    <Popup ref="alert" class="lg upper">
      <template #title>
        Attenzione!
      </template>
      <template #text>
        <div v-if="leftAmount > 0" class="d-inline">
          Rimangono <b>{{ leftAmount ? (leftAmount).toLocaleString('de', {minimumFractionDigits: 2}) : leftAmount }} €</b> da scadenzare.
        </div>
        <div v-else class="d-inline">
          C'è una differenza di <b>{{ leftAmount ? (leftAmount).toLocaleString('de', {minimumFractionDigits: 2}) : leftAmount }} €</b> tra gli importi inseriti.
        </div>
      </template>
      <template #button>
        <div class="row">
          <div class="col-6">
            <custom-button
              :isLoading="loading"
              label="Abbuona"
              class="w-100 mt-4 opaque"
              @click.prevent.native="abbuona"
            />
          </div>
          <div class="col-6">
            <custom-button
              label="Modifica scadenze"
              class="w-100 mt-4"
              @click.prevent.native="togglePopup('alert')"
            />
          </div>
        </div>
      </template>
    </Popup>

    <Snackbar :text="snackbarText" ref="snackbar" class="up"/>
  </div>
</template>

<script>
import formatMovementTypeFilter from '@/filters/formatMovementType';
import capitalizeFirstLetter from '@/filters/capitalizeFirstLetter';
import { getFileExtension } from '@/helpers/files.js';
import {
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormSelect,
  BPagination,
  BTable,
} from 'bootstrap-vue';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';
// to do handle broken img links

import { toFormattedDate } from '@/helpers/dates.js';

import Button from '@/views/components/Button.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import DragAndDrop from '@/views/components/DragAndDrop.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import FiltersInvoices from '@/views/components/FiltersInvoices.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import MarkExpirationAsPaid from '@/views/components/Forms/Invoices/MarkExpirationAsPaid.vue';
import Popup from '@/views/components/Popup.vue';
import Snackbar from '@/views/components/Snackbar.vue';
import UpsertInvoiceWrapper from '@/views/components/Forms/Invoices/UpsertInvoiceWrapper.vue';
import EditInvoice from '@/views/components/Forms/Invoices/EditInvoice.vue';

export default {
  components: {
    BFormGroup,
    BFormSelect,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    'custom-button': Button,
    CellContent,
    DragAndDrop,
    ErrorCard,
    EditInvoice,
    FiltersInvoices,
    Loader,
    MarkExpirationAsPaid,
    Popup,
    Snackbar,
    UpsertInvoiceWrapper,
  },
  filters: {
    movement: formatMovementTypeFilter,
    capitalizeFirstLetter: capitalizeFirstLetter,
  },
  data () {
    return {
      banks: [],
      companyId: this.$oauth2.me.companies[0].id || null,
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
      fields: [
        { key: 'number', label: 'Fattura', sortable: false },
        // { key: 'bank', label: 'banca principale', sortable: false },
        {
          key: 'date',
          label: 'Data',
          class: 'text-nowrap',
          sortable: true,
        },
        { key: 'denomination', label: 'Cliente', sortable: false },
        // IMPORTO / TOTALE FATTURA
        { key: 'total', label: 'Importo', sortable: false, class: 'text-right' },
        { key: 'residuo', label: 'Al saldo', sortable: false, class: 'text-right' },
        { key: 'scadenze', label: 'Scadenze', sortable: true, class: 'text-right' },
        { key: 'actions', label: '', sortable: false },
      ],
      fields2: [
        { key: 'select', label: '', sortable: false },
        // aggiungere banca ?
        {
          key: 'date',
          label: 'Data',
          class: 'text-nowrap',
          sortable: true,
        },
        { key: 'number', label: 'N. fattura', sortable: true },
        { key: 'denomination', label: 'Controparte', sortable: true },
        // IMPORTO / TOTALE FATTURA
        { key: 'total', label: 'Importo', sortable: true, class: 'text-right' },
        { key: 'actions', label: '', sortable: false },
      ],

      invoices: [],
      // Uploaded Files
      uploadedInvoices: [],
      invoicesToConfirm: [],
      confirmInvoice: false,
      selectAll: false,
      selectedInvoice: null,

      defaultView: true,
      error: '',
      errorMessage: '',
      isLoadingPage: true,
      loading: false,
      loading2: false,
      showPopup: true,
      snackbarText: '',
      uploadingData: false,
      update: 0,

      rowColour: null,
      textColour: null,

      startDate: this.$route.query.startDate || null,
      endDate: this.$route.query.endDate || null,

      // Table
      isBusy: false,
      sortDesc: Boolean(this.$route.query.sortDesc) || true,
      sortBy: this.$route.query.sortBy || null, // default: ultime aggiunte (non c'è filtro)
      currentPage: this.$route.query.page || 1,
      perPage: this.$route.query.perPage || 10,
      perPage2: null,
      totalItems: null,
      perPageOptions: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],

      selectedRow: null,
      leftAmount: null,
      confirmSubmit: 0,
    };
  },
  computed: {
    fattureAttive () {
      return this.$route.meta.fattureAttive;
    },
    userInStage () {
      const me = this.$oauth2.me;
      return (
        me !== undefined &&
        me.email !== undefined &&
        me.demo !== undefined &&
        me.demo
      );
    },
    bankId: function () {
      return this.$route.query.bankId;
    },
    watchedSorting () {
      return this.sortBy + this.sortDesc;
    },
  },
  watch: {
    $route: function () {
      this.getInvoices();
    },
    fattureAttive () {
      this.openImportPopup();
    },
    selectAll () {
      if (this.selectAll) {
        this.invoicesToConfirm = this.uploadedInvoices.map(invoice => invoice.id);
      } else {
        this.invoicesToConfirm = [];
      }
    },
    perPage: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            perPage: this.perPage,
          }),
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.scrollToTop();
        });
    },
    currentPage: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            page: this.currentPage,
          }),
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.scrollToTop();
        });
    },
    selectedBank: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            bankId: this.selectedBank.id,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
    startDate: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            startDate: this.startDate,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
    endDate: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            endDate: this.endDate,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
    watchedSorting: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            sortDesc: this.sortDesc,
            sortBy: this.sortBy,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
    toggleSnackbar (msg) {
      this.snackbarText = msg;
      this.$refs.snackbar.openSnackbar();
    },
    uploadedInvoices () {
      this.$root.$emit('updateNotifications');
    },
  },
  methods: {
    downloadPdfInvoice (item) {
      this.$api.downloadPdfInvoice(item.id)
        .then((res) => {
          // create pdf
          window.open(URL.createObjectURL(res.data));
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        });
    },
    abbuona () {
      this.togglePopup('alert');
      this.$log.debug(this.$refs);
      this.confirmSubmit++;
    },
    editInvoice (item) {
      if (item.isImported) {
        this.togglePopup('editInvoice');
      } else {
        this.togglePopup('upsertInvoice');
      }
    },
    getCompanyInfo () {
      this.$api
        .getCompany(this.companyId)
        .then((res) => {
          this.banks = res.data.banks;
          this.selectedBank = this.searchInBanks(this.bankId);
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getInvoices () {
      this.isBusy = true;
      const filters = this.$route.query;

      const type = this.fattureAttive ? 'in' : 'out';
      this.$api
        .getListInvoices(this.companyId, filters, type)
        .then((res) => {
          this.$log.debug(res.data);
          this.totalItems = res.data.metadata['total-items'];
          this.perPage2 = res.data.metadata['per-page'];

          this.invoices = res.data.fatture || [];
          this.uploadedInvoices = res.data.notConfirmed || [];
          this.update++;
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.isBusy = false;
          this.isLoadingPage = false;
        });
    },
    onConfirm () {
      this.loading2 = true;
      this.$log.debug(this.invoicesToConfirm.length + ' CHECKED invoicesToConfirm');
      this.$log.debug(this.invoicesToConfirm);

      const promises = this.invoicesToConfirm.map(id => this.$api.confirmInvoice(id));
      this.$log.debug(promises);

      Promise.all(promises)
        .then((res) => {
          const invoicesToConfirm = this.invoicesToConfirm.length;
          this.$log.debug('invoicesToConfirm: ' + this.invoicesToConfirm.length);
          this.$log.debug(this.invoicesToConfirm);

          res.map(el => {
            const i = this.invoicesToConfirm.indexOf(el.data.id);
            this.invoicesToConfirm.splice(i, 1);
          });

          const invoicesThatFailedConfirmation = this.invoicesToConfirm.length;
          this.$log.debug('invoicesThatFailedConfirmation' + this.invoicesToConfirm.length);

          const invoicesEffectivelyConfirmed = invoicesToConfirm - invoicesThatFailedConfirmation;
          const fatturaMsg = invoicesEffectivelyConfirmed === 1 ? 'fattura è stata aggiunta' : 'fatture sono state aggiunte';
          this.$log.debug('invoicesEffectivelyConfirmed: ' + invoicesEffectivelyConfirmed);

          const invoicesUploadedToConfirm = this.uploadedInvoices?.length - invoicesEffectivelyConfirmed;
          this.$log.debug('uploaded to confirm yet: ' + invoicesUploadedToConfirm);
          if (invoicesUploadedToConfirm === 0) {
            this.togglePopup('importInvoice');
          }

          this.snackbarText = `${invoicesEffectivelyConfirmed} ${fatturaMsg}`;
          this.$refs.snackbar.openSnackbar();
          this.$root.$emit('updateNotifications');
          this.getInvoices();
        })
        .catch((e) => {
          this.errorMessage = "Qualcosa è andato storto, riprova più tardi o contatta l'assistenza se il problema persiste";
          this.$log.error(e);
        })
        .finally(() => {
          this.loading2 = false;
        });
    },
    onDelete () {
      this.loading = true;
      this.$api
        .deleteInvoice(this.selectedRow.id)
        .then(() => {
          this.togglePopup('deleteInvoice');
          this.snackbarText = 'Fattura eliminata correttamente';
          this.$refs.snackbar.openSnackbar();
          this.getInvoices();
          this.uploadedInvoices = this.uploadedInvoices.filter(file => file.id !== this.selectedRow.id);
          this.$root.$emit('updateNotifications');
        })
        .catch((e) => {
          this.error = "Qualcosa è andato storto, riprova più tardi o contatta l'assistenza se il problema persiste";
          this.$log.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onEdit () {
      this.getInvoices();
      this.togglePopup('movementEditor');
      this.snackbarText = 'Modifiche salvate';
      this.$refs.snackbar.openSnackbar();
    },
    onSavedInvoice (type = 'edit') {
      if (type === 'confirm') {
        this.snackbarText = 'Fattura confermata correttamente';
      } else if (type === 'save') {
        this.snackbarText = 'Fattura aggiunta correttamente';
      } else if (type === 'edit') {
        this.snackbarText = 'Fattura modificata correttamente';
      }
      this.togglePopup('upsertInvoice');
      this.getInvoices();
      this.$refs.snackbar.openSnackbar();
      // to do edit
      this.invoicesToConfirm = [];
    },
    isPaid (invoice) {
      return !invoice.scadenze.some(obj => !obj.isPaid);
    },
    onEditInvoice (type = 'edit') {
      if (type === 'confirm') {
        this.snackbarText = 'Fattura confermata correttamente';
        // elimina da selezione
        this.invoicesToConfirm = this.invoicesToConfirm.filter((id) => id !== this.selectedRow.id);
      } else {
        this.snackbarText = 'Fattura modificata correttamente';
      }
      this.$refs.snackbar.openSnackbar();
      this.getInvoices();
      this.togglePopup('editInvoice');
    },
    onSavedExpiration () {
      this.getInvoices();
      this.snackbarText = 'Scadenze modificate correttamente';
      this.$refs.snackbar.openSnackbar();
    },
    setError (msg) {
      this.error = msg;
    },
    scrollToTop () {
      setTimeout(() => {
        this.$refs.movements.scrollTo({
          top: 250,
          left: 0,
          behavior: 'smooth',
        });
      }, 500);
    },
    searchInBanks () {
      return this.banks.find((bank) => bank.id === this.bankId);
    },
    toggleAlert (amount = 0) {
      this.$log.debug(amount);
      this.leftAmount = amount;
      this.togglePopup('alert');
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    uploadFile (file) {
      this.errorMessage = '';
      const extension = getFileExtension(file.name);

      if (extension === 'xml' || extension === 'p7m') {
        this.uploadXml(file);
      } else if (extension === 'zip') {
        this.uploadZip(file);
      } else {
        this.errorMessage = 'File non supportato';
      }
    },
    uploadXml (file) {
      this.uploadingData = true;
      const type = this.fattureAttive ? 'in' : 'out';
      this.$api.uploadXmlInvoice(this.companyId, file, type)
        .then((res) => {
          this.$log.debug(res);
          if (res.data.msg) {
            this.errorMessage = res.data.msg;
          } else {
            this.uploadedInvoices.unshift(Object.assign(res.data));
            this.defaultView = false;

            this.confirmInvoice = true;
            this.selectedRow = res.data;
            this.togglePopup('editInvoice');
          }
          this.$root.$emit('updateNotifications');
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.uploadingData = false;
        });
    },
    uploadZip (file) {
      this.uploadingData = true;
      const formData = new FormData();
      formData.append('fatture', file);
      const type = this.fattureAttive ? 'in' : 'out';

      this.$api.uploadZipInvoice(this.companyId, formData, type)
        .then((res) => {
          this.$log.debug(res);
          const allInvoicesLength = res.data.fatture.length + res.data.messages.length;
          const importedInvoices = res.data.fatture;
          const notImported = res.data.messages.length;
          if (notImported > 0) {
            const fattura = notImported === 1 ? 'fattura' : 'fatture';
            this.errorMessage = `Il caricamento di ${notImported} ${fattura} (di ${allInvoicesLength}) non è andato a buon fine.`;
            this.$log.debug(this.errorMessage);
          }
          if (importedInvoices.length > 0) {
            this.defaultView = false;
            importedInvoices.map(fattura => {
              this.uploadedInvoices.unshift(fattura);
            });
          }
          this.$root.$emit('updateNotifications');
        })
        .catch((e) => {
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla la partita IVA nei files o contatta l'assistenza se il problema persiste";
          this.$log.error(e);
        })
        .finally(() => {
          this.uploadingData = false;
        });
    },
    downloadFattureAttiveDemo () {
      const type = this.fattureAttive ? 'in' : 'out';
      this.$api.downloadFattureDemo(this.companyId, type).then(() => {
        this.getCompanyInfo();
        this.getInvoices();
      });
    },
    openImportPopup () {
      setTimeout(() => {
        if (this.uploadedInvoices.length > 0) {
          this.defaultView = false;
          this.togglePopup('importInvoice');
        }
      }, '400');
    },
  },
  created () {
    // to do da saldare di default
    // this.$router.replace({ query: Object.assign({}, this.$route.query, { 'types[]': ['da saldare'] }) })
    //   .catch(() => {});
    this.getCompanyInfo();
    this.getInvoices();
    this.openImportPopup();
  },
};
</script>

<style lang="scss">
.no-bg {
  background: transparent !important;
}

.invoices {
  background: $primary-lighter;

  .paid {
      background: $primary-light;
      color: $primary;
      opacity: 0.75;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      padding: 1px 7px;
      margin: 0;
      gap: 10px;
      border-radius: 5px;
      width: fit-content;
  }

  .up {
    z-index: 30;
  }

  .spinning {
    font-size: 18px;
  }

  // selected bank box
  .box {
    border: none;
    // background: $primary-light;
    border-radius: 10px;
    padding: 15px;
    transition: all 0.5s;
  }

  // row settings
  .dropdown {
    button {
      // no caret
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
        outline: none;
      }
      .red > * {
        color: $danger !important;
      }
      .dropdown-item {
        color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
          outline: none;
        }
        &:active {
          background: $primary-light;
        }
      }
    }
  }

  .bank-logo {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .table-wrapper {
    border-radius: 10px;
    box-shadow: $box-shadow;
    // overflow: hidden;
    height: min-content;
  }

  table {
    background: white;
    text-overflow: ellipsis;
    font-size: 16px;
    margin: 0;
    border-radius: 10px;
    th {
      border: none;
      text-transform: uppercase;
      font-size: 14px;
      color: rgba($primary, 0.75);
      font-weight: 600;
      &:nth-last-child(2) {
        text-align: right;
      }
    }
    thead {
      border: none !important;
      background: $fin-white;
      border-radius: 10px;
      th {
        padding: 23px 15px;
      }
    }
  }
}
</style>
