<template>
  <div class="setup-bank-accounts" ref="step-2">
    <div v-if="isLoadingPage" class="text-center m-5">
      <Loader2 />
    </div>
    <div v-else>
      <Alert status="primary" fontSize="lg">
        Per sfruttare al meglio NinaFin, imposta un colore e un alias diverso per ogni conto aggiunto.
      </Alert>
      <h5>I tuoi conti</h5>
      <div class="row mb-4 g-2">
        <div class="col-4 py-3">
          <div
            type="button"
            class="add-button"
            :class="{invalid: showErrorBankAccounts && lengthOfArray < 1}"
            @click="
              togglePopup('upsertPopup');
              selectedItem = null;
            "
          >
            <i class="fas fa-plus-circle pb-2"></i>
            <span>Aggiungi conto</span>
          </div>
        </div>

        <div
          v-for="(account, i) in form.bankAccounts"
          :key="`account_${i}_${account.alias}_${lengthOfArray}_${account.isMain}_${update}`"
          class="col-4 py-3"
        >
          <div
            class="account-card text-center"
            :class="[`bank-variant-${account.colour}-light`, {'flag-auto': account.fabrickId !== null}]"
          >
            <img :src="getLogo(account.bankDetail?.id)" class="bank-logo md mb-2">
            <h6
              class="text-center mb-3"
              :class="`text-bank-variant-${account.colour}`"
            >
              {{ account.alias }}
            </h6>
            <span class="iban-tag" :class="`bank-variant-${account.colour}`"
              >IBAN</span
            >
            <div class="iban mt-1">{{ account.iban }}</div>
            <div class="row icons d-flex justify-content-between">
              <div class="col-auto">
                <button @click="setMainBank(account)" class="star">
                  <i
                    v-if="account.isMain === true"
                    class="fas fa-star"
                    :class="`text-bank-variant-${account.colour}`"
                  ></i>
                  <i
                    v-else
                    class="far fa-star"
                    :class="`text-bank-variant-${account.colour}`"
                  ></i>
                </button>
              </div>
              <div class="col-auto">
                <button
                  @click="
                    togglePopup('upsertPopup');
                    selectedItem = account;
                    index = i;
                  "
                >
                  <i
                    class="fas fa-pen"
                    :class="`text-bank-variant-${account.colour}`"
                  ></i>
                </button>
                <button
                  @click="
                    togglePopup('deletePopup');
                    selectedItem = account;
                  "
                >
                  <i
                    class="fas fa-trash"
                    :class="`text-bank-variant-${account.colour}`"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h5 v-if="fabrickAccounts?.length > 0">Conti da configurare</h5>
      <div class="row mb-4 g-2">
        <div
          v-for="(account, i) in fabrickAccounts"
          :key="`account_${i}_${account.alias}_${lengthOfArray}_${account.isMain}`"
          class="col-4 py-3"
        >
          <div
            class="account-card to-configure-card text-center"
            :class="[`bank-variant-${account.colour}-light`]"
          >
            <span class="to-configure">da configurare</span>

            <img :src="getLogo(account.bankDetail?.id)" class="bank-logo md mb-2">

            <h6
              class="text-center mb-3"
              :class="`text-bank-variant-${account.colour}`"
            >
              {{ account.alias }}
            </h6>
            <span class="iban-tag" :class="`bank-variant-${account.colour}`"
              >IBAN</span
            >
            <div class="iban mt-1">{{ account.iban }}</div>

            <div class="row icons d-flex justify-content-between">
              <div class="col-auto">
              </div>
              <div class="col-auto">
                <button
                  @click="
                    togglePopup('upsertPopup');
                    selectedItem = account;
                    index = i;
                  "
                >
                  <i
                    class="fas fa-pen"
                    :class="`text-bank-variant-${account.colour}`"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popup @beforeClosing="togglePopup('upsertPopup'); selectedItem = null; index = null; errorMessage = '';" ref="upsertPopup" :errorMessage="errorMessage">
        <template #fullContent>
          <UpsertBankAccount
            :banks="banks"
            :bankAccounts="bankAccounts"
            :settings="true"
            @addBankAccount="addBankAccount"
            @editBankAccount="editBankAccount"
            :account="selectedItem"
            :index="index"
          />
        </template>
      </Popup>

      <Popup
        @closePopup="togglePopup('deletePopup')"
        ref="deletePopup"
        class="md"
      >
        <template #title> Sei sicuro di voler eliminare? </template>
        <template #text>
          Il conto <b>{{ selectedItem.alias }}</b> con iban <b>{{ selectedItem.iban }}</b> verrà rimosso dall'elenco
        </template>
        <template #button>
          <custom-button
            label="Elimina"
            class="w-100 mt-4"
            @click.prevent.native="deleteBankAccount(selectedItem)"
          />
        </template>
      </Popup>

    </div>
  </div>
</template>

<script>
import Button from '@/views/components/Button.vue';
import Popup from '@/views/components/Popup.vue';
import UpsertBankAccount from '@/views/components/Setup/UpsertBankAccount.vue';
import Loader2 from '@/views/components/Layout/Spinner.vue';
import Alert from '@/libs/Feedback/components/Alert.vue';

import bankLogoDefault from '@/assets/images/bank-logo.svg';

export default {
  props: {
    companyId: String,
  },
  components: {
    'custom-button': Button,
    Popup,
    UpsertBankAccount,
    Loader2,
    Alert,
  },
  data () {
    return {
      banks: null,
      bankLogoDefault: bankLogoDefault,

      loading: false,
      isLoadingPage: true,
      showErrorBankAccounts: false,
      errorMessage: '',
      update: 0,

      selectedItem: null,
      index: null,

      oldBankAccounts: {},
      form: {},
      fabrickAccounts: {},
    };
  },
  computed: {
    bankAccounts () {
      return this.form.bankAccounts ? this.form.bankAccounts : [];
    },
    isValidForm () {
      return this.lengthOfArray > 0;
    },
    lengthOfArray () {
      return this.form.bankAccounts ? this.form.bankAccounts.length : 0;
    },
  },
  methods: {
    getBanks () {
      this.$api.fetchBanksList()
        .then((res) => {
          this.$log.debug(res.data);
          this.banks = res.data.bankDetails;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        });
    },
    getFabrickAccounts () {
      this.$api.getOpenBankingListAccounts(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          this.fabrickAccounts = res.data.accounts;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        });
    },
    getCompanyInfo () {
      this.$api.getCompany(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          const company = res.data;
          this.companyVAT = company.vat;
          this.form.bankAccounts = company.banks.filter(obj => !obj.isCash);
          this.oldBankAccounts = this.form.bankAccounts;
          this.update++;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
    getSettings () {
      this.$api.getBanksSettings(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          this.form.bankAccounts = res.data.data;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        });
    },
    togglePopup (ref) {
      return this.$refs[ref].togglePopup();
    },
    addBankAccount (account) {
      // loading upsert
      this.$emit('setError', '');
      this.$log.debug(account);
      this.$api.createBank(this.companyId, account.iban, account.alias, account.accountBalance, account.fido, account.colour, account.mainBank, account.transferFees, account.bankDetail.id, account.fabrickId, account.consentExpireAt)
        .then((res) => {
          this.$log.debug(res);
          this.$refs.upsertPopup.close();
          this.getCompanyInfo();
          this.getFabrickAccounts();
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i campi inseriti o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          // loading upsert stop
          this.update++;
        });
    },
    editBankAccount (account, setAsMain = false) {
      // this.isLoadingPage = true;
      this.$emit('setError', '');
      this.$api.updateBank(account.id, account.iban, account.alias, account.accountBalance, account.fido, account.colour, setAsMain ? true : (account.mainBank || account.isMain), account.transferFees)
        .then((res) => {
          this.$log.debug(res);
          this.snackbarText = 'Informazioni aggiornate';
          this.$emit('openSnackbar', this.snackbarText);
          this.$refs.upsertPopup.close();
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.getCompanyInfo();
        });
    },
    deleteBankAccount (account) {
      this.$emit('setError', '');
      this.isLoadingPage = true;
      this.$log.debug(account);
      this.$api.deleteBankAccount(account.id)
        .then((res) => {
          this.$log.debug(res);
          this.snackbarText = 'Informazioni aggiornate';
          this.$emit('openSnackbar', this.snackbarText);
          this.togglePopup('upsertPopup');
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.getCompanyInfo();
        });
    },
    getLogo (bankId) {
      const bank = this.banks?.find(bank => bank.id === bankId);
      return (bank && bank.logo) ? bank.logo : bankLogoDefault;
    },
    setMainBank (account) {
      this.editBankAccount(account, true);
    },
    onSubmit () {
      if (this.isValidForm) {
        this.loading = true;
        this.saveData();
      } else {
        this.loading = true;
        if (this.lengthOfArray <= 0) {
          this.showErrorBankAccounts = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
  },
  mounted () {
    this.getBanks(); // banche
    this.getCompanyInfo(); // conti
    this.getFabrickAccounts();
    // to do aggiungere chiamata conti eliminati
  },
};
</script>

<style lang="scss">
$height-of-row: 220px;
$icon-attention: url('~@/assets/images/triangle-exclamation-solid.svg');

.setup-bank-accounts {
  .add-button {
    max-width: 350px;
    margin: auto;
    background: $primary-lighter;
    color: $primary;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    border-radius: 10px;
    padding: auto;
    min-height: $height-of-row;
    min-width: 150px;
    i {
      font-size: 52px;
    }
    &:hover {
      background: rgba($primary-light, 0.6);
    }
    &:active {
      background: rgba($primary-light, 0.8);
    }
    &.invalid {
      color: $danger;
      background: (rgba($danger, 0.1));
    }
  }
  .account-card {
    min-height: $height-of-row;
    max-width: 350px;
    margin: auto;
    height: 100%;
    border-radius: 10px;
    padding: 15px;
    position: relative;
    .iban {
      font-weight: 500;
      font-size: 13px;
    }
    button {
      border: none;
      background: none;
    }
    .icons {
      position: absolute;
      bottom: 10px;
      width: 100%;
    }
  }
  .to-configure-card {
    border: 2px solid red;
    .to-configure {
      background: red;
      position: absolute;
      bottom: -1px;
      display: block;
      padding: 0px 5px;
      border-radius: 5px 5px 0px 0px;
      color: $fin-white;
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: auto;
      &::before {
        content: $icon-attention;
        line-height: 100%;
        display: inline-block;
        height: 12px;
        width: 12px;
        margin: 0;
        margin-right: 5px;
      }
    }
  }
  .star {
    background: white!important;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flag-auto::after {
    content: 'collegato';
    position: absolute;
    top: 10px;
    left: 10px;
    display: block;
    height: 20px;
    color: white;
    padding: 2px 4px;
    width: fit-content;
    height: fit-content;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    background: $primary-mid;
    opacity: 0.7;
  }
}
</style>
