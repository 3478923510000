<template>
  <form
    @submit.prevent="onSubmit"
    class="text-start"
    novalidate
    >

    <h3 class="mb-4">
        {{ employee ? "Modifica dipendente" : "Aggiungi dipendente" }}
    </h3>

    <div class="row d-flex flex-column">
        <div class="col">
          <b-form-group
            class="mb-3"
            id="input-group-name"
            label="Nome e cognome"
            label-for="input-name"
            :class="{ invalid: !isValidName && showErrorName }"
          >
            <b-form-input
              id="input-name"
              v-model="name"
              type="text"
              @blur="showErrorName = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidName || !showErrorName }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un nome ed un cognome validi
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group
            class="mb-3"
            id="input-group-months"
            label="Numero mensilità"
            label-for="input-months"
            :class="{ invalid: !isValidNumberOfMonths && showErrorNumberOfMonths }"
          >
            <b-form-select
              id="input-months"
              v-model="numberOfMonths"
              @blur="showErrorNumberOfMonths = true"
              :options="[12, 13, 14]"
            ></b-form-select>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidNumberOfMonths || !showErrorNumberOfMonths }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un numero di mesi valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group
            class="mb-3"
            id="input-group-salary"
            label="Stipendio netto"
            label-for="input-salary"
            :class="{ invalid: !isValidSalary && showErrorSalary }"
          >
            <CurrencyInput id="input-salary" v-model="salary" @blur="showErrorSalary = true"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidSalary || !showErrorSalary }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci uno stipendio valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
    </div>
    <custom-button
      :label="employee ? 'Salva' : 'Aggiungi'"
      :class="{disabled: !isValidForm}"
      class="w-100 mt-4"
      @click.prevent.native="onSubmit"
    />
  </form>
</template>

<script>
import { BFormGroup, BFormInput, BFormInvalidFeedback, BFormSelect } from 'bootstrap-vue';
import { isFilled, isOnlyLettersAndSpaces, isCurrency } from '@/helpers/formValidation.js';

import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';

export default {
  props: {
    employee: Object,
  },
  components: {
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    'custom-button': Button,
    CurrencyInput,
  },
  data () {
    return {
      showErrorName: false,
      showErrorNumberOfMonths: false,
      showErrorSalary: false,

      name: this.employee?.name || '',
      numberOfMonths: this.employee?.months || null,
      salary: parseFloat(this.employee?.salary) || null,
    };
  },
  computed: {
    isValidForm () {
      return this.isValidName && this.isValidNumberOfMonths && this.isValidSalary;
    },
    isValidName () {
      return isFilled(this.name) && isOnlyLettersAndSpaces(this.name);
    },
    isValidNumberOfMonths () {
      return isFilled(this.numberOfMonths);
    },
    isValidSalary () {
      return isFilled(this.salary) && isCurrency(this.salary);
    },
  },
  methods: {
    onSubmit () {
      if (this.isValidForm) {
        this.$emit('save', Object.assign({}, { name: this.name, months: this.numberOfMonths, salary: this.salary }));
      } else {
        if (!this.isValidName) {
          this.showErrorName = true;
        } else if (!this.isValidNumberOfMonths) {
          this.showErrorNumberOfMonths = true;
        } else if (!this.isValidSalary) {
          this.showErrorSalary = true;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.custom-select {
  border-radius: 10px;
}
</style>
