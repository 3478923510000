<template>
  <DatePicker ref="daterangepicker" id="input-date" v-model="range" is-range color="indigo" class="daterangepicker" @input="setValues" :locale="'ita'" trim-weeks :attributes="attributes">
    <template v-slot="{ inputValue, inputEvents, togglePopover }">
      <div class="flex items-center">
        <b-input-group>
          <b-input-group-prepend>
            <button class="prepend form-control" @click.prevent="togglePopover()">
              <i class="far fa-calendar"></i>
            </button>
          </b-input-group-prepend>
          <input
            @blur="$emit('blur')"
            :value="`${inputValue.start} - ${inputValue.end}`"
            class="form-control custom-border"
            v-on="inputEvents"
          />
        </b-input-group>
      </div>
    </template>
    <template v-slot:footer>
      <div class="row header p-1">
        <div v-if="lastMonthButtons" class="col-3 p-1">
          <button class="btn btn-fin btn-fin-sm w-100 h-100" @click.prevent="setLastWeek">Settimana scorsa</button>
        </div>
        <div v-if="lastMonthButtons" class="col-3 p-1">
          <button class="btn btn-fin btn-fin-sm w-100 h-100" @click.prevent="setLastMonth">Mese scorso</button>
        </div>
        <div class="col-3 p-1">
          <button class="btn btn-fin btn-fin-sm w-100 h-100" @click.prevent="setThisWeek">Questa settimana</button>
        </div>
        <div class="col-3 p-1">
          <button class="btn btn-fin btn-fin-sm w-100 h-100" @click.prevent="setThisMonth">Questo mese</button>
        </div>
        <div v-if="!lastMonthButtons" class="col-3 p-1">
          <button button class="btn btn-fin btn-fin-sm w-100 h-100" @click.prevent="setNextWeek">Prossima settimana</button>
        </div>
        <div v-if="!lastMonthButtons" class="col-3 p-1">
          <button class="btn btn-fin btn-fin-sm w-100 h-100" @click.prevent="setNextMonth">Prossimo mese</button>
        </div>
      </div>
    </template>
  </DatePicker>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { BInputGroup, BInputGroupPrepend } from 'bootstrap-vue';
import { toStandardDate } from '@/helpers/dates.js';

export default {
  props: {
    dates: Object,
    lastMonthButtons: Boolean,
    startDate: [Date, String],
    endDate: [Date, String],
  },
  components: {
    DatePicker,
    BInputGroup,
    BInputGroupPrepend,
  },
  computed: {
    isValidDate () {
      return true;
    },
  },
  data () {
    return {
      range: {
        start: this.startDate ? new Date(this.startDate) : new Date(),
        end: this.endDate ? new Date(this.endDate) : new Date(),
      },
      attributes: [
        { dot: { style: { backgroundColor: '#161F5F' } }, dates: new Date() },
      ],
    };
  },
  methods: {
    setValues (values) {
      this.$emit('updateValues', toStandardDate(this.range.start), toStandardDate(this.range.end));
    },
    setLastWeek () {
      const start = new Date();
      const end = new Date();
      start.setDate(start.getDate() - 8);
      end.setDate(end.getDate() - 1);
      this.range = Object.assign(this.range, { start: start, end: end });
      this.$refs.daterangepicker.updateValue(this.range);
      this.$refs.daterangepicker.move(end);
    },
    setThisWeek () {
      const start = new Date();
      const end = new Date();
      end.setDate(end.getDate() + 7);
      this.range = Object.assign(this.range, { start: start, end: end });
      this.$refs.daterangepicker.updateValue(this.range);
      this.$refs.daterangepicker.move(end);
    },
    setNextWeek () {
      const start = new Date();
      const end = new Date();
      start.setDate(start.getDate() + 7);
      end.setDate(end.getDate() + 14);
      this.range = Object.assign(this.range, { start: start, end: end });
      this.$refs.daterangepicker.updateValue(this.range);
      this.$refs.daterangepicker.move(end);
    },
    setLastMonth () {
      const date = new Date();
      const start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      const end = new Date(date.getFullYear(), date.getMonth(), 0);
      Object.assign(this.range, { start: start, end: end });
      this.$refs.daterangepicker.updateValue(this.range);
      this.$refs.daterangepicker.move(end);
    },
    setThisMonth () {
      const start = new Date();
      start.setDate(1);
      const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
      Object.assign(this.range, { start: start, end: end });
      this.$refs.daterangepicker.updateValue(this.range);
      this.$refs.daterangepicker.move(end);
    },
    setNextMonth () {
      const today = new Date();
      const start = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      const end = new Date(today.getFullYear(), today.getMonth() + 2, 0);
      Object.assign(this.range, { start: start, end: end });
      this.$refs.daterangepicker.updateValue(this.range);
      this.$refs.daterangepicker.move(end);
    },
    toStandardDate (s) {
      return toStandardDate(s);
    },
  },
  mounted () {
  },
};
</script>

<style lang="scss">
$height-of-row: 55px;

.vc-indigo {
  // --indigo-100: $primary;
  --indigo-200: #d3d6e381; // primary-light custom
  // --indigo-300: rgb(109, 109, 109);
  // --indigo-400: rgb(85, 85, 85);
  // --indigo-500: rgb(75, 75, 75);
  --indigo-600: #2C3576; // primary
  // --indigo-700: $primary;
  // --indigo-800: $primary;
  // --indigo-900: $primary;
}

.daterangepicker {
  position: relative;
  .vc-container {
    width: 350px!important;
    overflow: hidden!important;
    font-family: $font-family-sans-serif!important;
    color: $body-color;

    .vc-title {
      color: $primary;
    }

    .vc-weekday, .vc-arrow {
      color: $primary-mid;
    }

    .vc-arrow {
      border-radius: 100%;

      &:hover {
        background: $primary-lighter;
      }
    }

    .vc-dots {
      display: none;
    }
  }
  .vc-pane-container {
    margin-top: $height-of-row;
    overflow: visible!important;
  }
  .prepend {
    border-radius: 10px 0px 0px 10px!important;
    z-index: +1;
    &:active, &.selected {
      background: rgba($primary-mid, 0.6)!important;
    }
  }
  .bar.style {
    background: red!important;
  }
  .custom-border {
    border-radius: 0px 10px 10px 0px!important;
  }
  .header {
    position: absolute;
    top: -$height-of-row!important;
    left: 0;
    right: 0;
    margin: auto;
    .btn {
      min-height: 40px;
    }
  }
}
</style>
