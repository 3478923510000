<template>
  <div class="invoice" ref="summary">
    <form
      @submit.prevent
      class="text-start"
      novalidate
      >
      <h5 class="mb-4" v-if="fatturePassive">Riepilogo fattura</h5>
      <div class="row mb-4" v-if="fatturePassive">
        <div class="col-auto mr-4 d-flex flex-column">
          <label class="pretty-label">Numero fattura</label>
          <div class="font-weight-bolder">
            {{invoice.number}}
          </div>
        </div>
        <div class="col-auto mr-4 d-flex flex-column">
          <label class="pretty-label">Data</label>
          <div class="font-weight-bolder">
            {{ toFormattedDate(invoice.date) }}
          </div>
        </div>
        <div class="col-auto mr-4 d-flex flex-column">
          <label class="pretty-label">Fornitore</label>
          <div class="font-weight-bolder">
            {{invoice.name}}
          </div>
        </div>
        <div class="col-auto mr-4 d-flex flex-column">
          <label class="pretty-label">Totale fattura</label>
          <div class="font-weight-bolder">
            {{ invoice.invoiceAmount ? (invoice.invoiceAmount).toLocaleString('de') : null }}
          </div>
        </div>
      </div>

      <h5 class="mb-4">Piano delle scadenze</h5>
      <div class="row mb-4">
        <div class="col-auto mr-4 d-flex flex-column">
          <label class="pretty-label">Scadenzato</label>
          <div class="font-weight-bolder">
            {{ scadenzato ? scadenzato.toLocaleString('de') : scadenzato }}
          </div>
        </div>
        <div class="col-auto mr-4 d-flex flex-column">
          <label class="pretty-label">Da scadenzare</label>
          <div class="font-weight-bolder">
            {{ daScadenzare ? daScadenzare.toLocaleString('de') : daScadenzare }}
          </div>
        </div>
      </div>
      <div class="counter">
        <div v-for="(rata, index) in invoice.rate" :key="`rata_${index}`">
          <div class="light-card mb-4 enum">
            <button class="btn-delete-round" @click="deleteRow(index)" tabindex="1"><i class="fas fa-trash"></i></button>
            <div class="row">
              <div class="col p-0">
                <b-form-group
                    class="mb-0 box"
                    :id="`input-group-amount-${index}`"
                    label="Importo"
                    label-for="input-amounts"
                    :class="{ invalid: (!isValidAmount(rata.amount) && showErrors) || !isCurrency(rata.amount) }"
                >
                <CurrencyInput :key="`invoiceAmount_${invoice?.invoiceAmount}`" v-model="rata.amount"/>
                </b-form-group>
              </div>
              <div class="col p-0">
                <b-form-group
                  class="mb-0 box"
                  id="input-group-date"
                  label="Scadenza"
                  :label-for="`input-date-${index}`"
                  :class="{ invalid: !isValidDate(rata.date) && showErrors }"
                >
                  <DatePicker :id="`input-date_${index}`" :value="rata.date" @setValue="setDate($event, index)" @blur="showErrorDate = true" :key="`updateDate_${rata.date}`"/>
                </b-form-group>
              </div>
              <div class="col-3 p-0">
                <b-form-group
                  class="mb-0 box"
                  id="input-group-payment"
                  label="Modalità di pagamento"
                  label-for="input-payment"
                  :class="{ invalid: !isValidPayment(rata.paymentType) && showErrors }"
                >
                  <b-form-select
                    :class="{ invalid: !isValidPayment(rata.paymentType) && showErrors }"
                    id="input-payment"
                    :key="`${index}_${rata.paymentType}`"
                    v-model="rata.paymentType"
                    :options="options"
                  >
                  </b-form-select>
                </b-form-group>
                  </div>
              <div class="col-4 p-0">
                <b-form-group
                    class="mb-0"
                    id="input-group-bank"
                    :class="{ invalid: !isValidBank(rata.bankId) && showErrors }"
                    :key="`bank_${rata.bankId}`"
                >
                    <BankSelect class="mb-0" ref="bankSelect" :banks="banks" :selectedItem="getBankById(rata.bankId)" @update="updateBank($event, index)"/>
                </b-form-group>
              </div>
              <div class="col-1 ml-2 d-flex justify-content-center align-items-center p-0 mr-3">
                <b-form-checkbox
                  v-model="rata.paid"
                  name="input-paid"
                  :value="true"
                  :unchecked-value="false"
                >
                  Pagata
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end align-items-center mb-5">
        <button class="btn btn-fin" @click="addRow">Aggiungi scadenza</button>
      </div>

      <div class="row">
        <div class="col-4">
          <custom-button
            label="Indietro"
            class="w-100 btn-back"
            @click.prevent.native="goBack"
          />
        </div>
        <div class="col">
          <custom-button
            :isLoading="loading"
            :label="confirmInvoice ? 'Conferma' : 'Salva'"
            class="w-100"
            @click.prevent.native="onSubmit"
          />
        </div>
      </div>

      <ErrorCard v-if="errorMessage">
        <template #message >
          {{ errorMessage }}
        </template>
      </ErrorCard>
    </form>
  </div>
</template>

<script>
import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import DatePicker from '@/views/components/DatePicker.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import { BFormGroup } from 'bootstrap-vue';
import { isFilled, isCurrency, isDate, isValidYear } from '@/helpers/formValidation.js';
import { toFormattedDate } from '@/helpers/dates.js';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    banks: Array,
    propInvoice: Object,
    confirmInvoice: Boolean,
    fatturePassive: Boolean,
  },
  components: {
    BankSelect,
    'custom-button': Button,
    CurrencyInput,
    DatePicker,
    ErrorCard,
    BFormGroup,
  },
  data () {
    return {
      invoice: this.propInvoice,
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      showErrors: false,

      loading: false,
      errorMessage: '',

      options: [
        { value: 'MP01', text: 'MP01 - Contanti' },
        { value: 'MP02', text: 'MP02 - Assegno' },
        { value: 'MP03', text: 'MP03 - Assegno circolare' },
        { value: 'MP04', text: 'MP04 - Contanti presso Tesoreria' },
        { value: 'MP05', text: 'MP05 - Bonifico' },
        { value: 'MP06', text: 'MP06 - Vaglia cambiario' },
        { value: 'MP07', text: 'MP07 - Bollettino bancario' },
        { value: 'MP08', text: 'MP08 - Carta di pagamento' },
        { value: 'MP09', text: 'MP09 - RID' },
        { value: 'MP10', text: 'MP10 - RID utenze' },
        { value: 'MP11', text: 'MP11 - RID veloce' },
        { value: 'MP12', text: 'MP12 - RIBA' },
        { value: 'MP13', text: 'MP13 - MAV' },
        { value: 'MP14', text: 'MP14 - Quietanza erario' },
        { value: 'MP15', text: 'MP15 - Giroconto su conti di contabilità speciale' },
        { value: 'MP16', text: 'MP16 - Domiciliazione bancaria' },
        { value: 'MP17', text: 'MP17 - Domiciliazione postale' },
        { value: 'MP18', text: 'MP18 - Bollettino di c/c postale' },
        { value: 'MP19', text: 'MP19 - SEPA Direct Debit' },
        { value: 'MP20', text: 'MP20 - SEPA Direct Debit CORE' },
        { value: 'MP21', text: 'MP21 - SEPA Direct Debit B2B' },
        { value: 'MP22', text: 'MP22 - Trattenuta su somme già riscosse' },
        { value: 'MP23', text: 'MP23 - PagoPA' },
      ],
    };
  },
  computed: {
    scadenzato () {
      // Somma Importi delle scadenze
      let total = 0;
      if (this.invoice.rate) {
        this.invoice.rate.map(rata => {
          if (rata.amount) {
            total += parseFloat(rata.amount);
          }
        });
        return (parseFloat(total));
      } else {
        return total;
      }
    },
    daScadenzare () {
      // Totale da Pagare – Totale Scadenzato
      return this.invoice.amount - this.scadenzato;
    },
    isValidAmountToSchedule () {
      return this.daScadenzare === 0;
    },
  },
  methods: {
    addRow () {
      this.showErrors = false;
      const row = Object.assign({}, { amount: null, date: null, bankId: null, paymentType: null, paid: false });
      this.invoice.rate.push(row);
      // to do scroll down smooth
    },
    deleteRow (index) {
      this.$log.debug(index);
      this.$log.debug(this.invoice.rate[index]);
      this.invoice.rate.splice(index, 1);
    },
    getBankById (bankId) {
      return this.banks.find(bank => bank.id === bankId);
    },
    goBack () {
      this.$emit('updatePage', true);
    },
    isValidForm () {
      const invalid = this.invoice.rate.filter(rata => {
        if (!this.isValidAmount(rata.amount)) return true;
        if (!this.isValidDate(rata.date)) return true;
        if (!this.isValidPayment(rata.paymentType)) return true;
        if (!this.isValidBank(rata.bankId)) return true;

        return false;
      });
      return invalid.length === 0;
    },
    isCurrency (amount) {
      return !isFilled(amount) || isCurrency(amount);
    },
    isValidAmount (amount) {
      return isFilled(amount) && isCurrency(amount);
    },
    isValidDate (date) {
      return isFilled(date) && isDate(date) && isValidYear(date, 2000, 2100);
    },
    isValidPayment (payment) {
      return isFilled(payment);
    },
    isValidBank (bank) {
      return isFilled(bank);
    },
    onSubmit: function () {
      this.errorMessage = '';

      if (this.isValidForm()) {
        this.$log.debug(this.isValidAmountToSchedule);
        this.$log.debug(this.daScadenzare);

        if (this.isValidAmountToSchedule) {
          this.loading = true;
          this.$emit('save');
        } else {
          this.$emit('openAlert', this.daScadenzare);
        }
      } else {
        this.loading = true;
        this.showErrors = true;
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    updateBank (selectedBank, index) {
      this.invoice.rate[index].bankId = selectedBank.id;
    },
    setDate (newDate, index) {
      this.invoice.rate[index].date = newDate;
    },
  },
  mounted () {
    if (this.invoice && JSON.stringify(this.invoice.rate) === '[]') {
      this.addRow();
    }
  },
};
</script>

<style lang="scss">
.btn-back {
  opacity: 0.5;
}

.btn-delete-round {
  position: absolute;
  top: -10px;
  right: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  background: $danger;
  color: white;
  i {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }
}

.counter {
  counter-reset: counter;
  .enum {
    counter-increment: counter;
    &::after {
      content: counter(counter)!important;
      display: block;
      width: 25px;
      height: 25px;
      background: $primary;
      color: white;
      position: absolute;
      top: -5px;
      left: -5px;
      font-weight: 600;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
