<template>
  <form class="form-container" @submit.prevent="submit" novalidate>
    <slot />
  </form>
</template>

<script>

export default {
  methods: {
    submit () {
      this.$emit('submitForm');
    },
  },
};

</script>

<style lang="scss">

$form-error-color: $danger !default;
$font-family-titles: sans-serif !default;

.form-container {
  .form-widget {
    margin-bottom: 20px;
  }

  .info-required-fields {
    padding-left: 1.25rem;
    margin: 1.5rem 0 2rem;

    span {
      text-decoration: underline;
    }
  }

  .form-label {
    font-family: $font-family-titles;
    font-weight: 400;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 16px;
  }

  .form-control::placeholder {
    color: #D0D0D0;
  }

  .v-select.dropdown {
    .dropdown-toggle {
      border-radius: 0;
      padding: 4px 0;

      .form-control {
        border: none !important;
      }
    }
  }

  .services {
    .service {
      font-weight: bold;

      @include media-breakpoint-down("sm") {
        &:nth-child(2n+1) {
          padding-right: 3px;
        }
        &:nth-child(2n) {
          padding-left: 3px;
        }

        .form-check {
          padding-left: 1rem;

          .form-check-input {
            margin-left: -1rem;
          }
        }
      }
    }
  }

  .form-feedback-message {
    font-size: .75rem;
    margin-top: 4px;
    margin-bottom: 0;
  }

  .form-error {
    .form-label,
    .form-feedback-message {
      color: $form-error-color !important;
    }

    .form-control,
    .dropdown-toggle {
      color: $form-error-color !important;
      border-color: $form-error-color !important;
    }
  }
}

</style>
