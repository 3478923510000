<template>
  <div class="loan">
    <h4 class="mb-4">
      {{ loan ? 'Modifica finanziamento' : 'Aggiungi finanziamento'}}
    </h4>
    <div v-if="!loan || setup" class="row mb-5">
      <div class="col-12">
        <div class="selector" id="selector">
          <div class="row">
            <div class="col-4">
              <button class="btn w-100 h-100 option" @click="view = 1;" :class="{ active: (view === 1) }">
                Compilazione automatica finanziamento
              </button>
            </div>
            <div class="col-4">
              <button class="btn w-100 h-100 option" @click="view = 2;" :class="{ active: (view === 2) }">
                Compilazione automatica leasing
              </button>
            </div>
            <div class="col-4">
              <button class="btn w-100 h-100 option" @click="view = 3;" :class="{ active: (view === 3) }">
                Piano manuale
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- to do form reattivo condiviso tra componenti e POI sostituire v-show con v-if -->
    <CalculatedLoan v-if="view === 1 || !loan" v-show="view === 1" :setup="setup" :banks="banksList" :companyId="companyId" :loan="loan" @save="onSave"/>
    <CalculatedLeasing v-if="view === 2 || !loan" v-show="view === 2" :setup="setup" :banks="banksList" :companyId="companyId" :loan="loan" @save="onSave"/>
    <ManualLoan v-if="view === 3 || !loan" v-show="view === 3" :setup="setup" :banks="banksList" :companyId="companyId" :loan="loan" :propInstalments="propInstalments" :instalmentToEdit="instalmentToEdit" :instalmentToDelete="instalmentToDelete" @save="onSave" @openEditInstalmentPopup="triggerEmitEdit" @openDeleteInstalmentPopup="triggerEmitDelete"/>
  </div>
</template>

<script>
import CalculatedLoan from '@/views/components/Forms/Loans/CalculatedLoan.vue';
import CalculatedLeasing from '@/views/components/Forms/Loans/CalculatedLeasing.vue';
import ManualLoan from '@/views/components/Forms/Loans/ManualLoan.vue';

export default {
  props: {
    banks: Array,
    companyId: String,
    instalments: Array,
    instalmentToEdit: Object,
    instalmentToDelete: Object,
    loan: Object,
    tipoPianoId: Number,
    setup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CalculatedLoan,
    CalculatedLeasing,
    ManualLoan,
  },
  data () {
    return {
      view: this.tipoPianoId ? this.tipoPianoId : 1,
    };
  },
  computed: {
    banksList () {
      return this.banks ? this.banks.filter(bank => !bank.isCash) : [];
    },
    propInstalments () {
      return this.instalments;
    },
  },
  methods: {
    onSave (loan) {
      this.$emit('save', loan);
    },
    triggerEmitEdit (item) {
      this.$emit('openEditInstalmentPopup', item);
    },
    triggerEmitDelete (item) {
      this.$emit('openDeleteInstalmentPopup', item);
    },
    selectView () {
      if (this.loan) {
        if (this.loan.piano.tipoPianoId === 1 || this.loan.piano.tipoPianoId === 2) {
          this.view = 1;
        } else if (this.loan.piano.tipoPianoId === 3) {
          this.view = 2;
        } else if (this.loan.piano.tipoPianoId === 0 || this.loan.piano.tipoPianoId === null) {
          this.view = 3;
        }
      }
    },
  },
  beforeMount () {
    if (!this.tipoPianoId) {
      this.selectView();
    }
  },
};
</script>

<style lang="scss">
</style>
