import axios from 'axios';

import $config from '../../Config/services/config';
import $oauth2 from './auth';

const AxiosInstance = axios.create({
  baseURL: $config.get('apiBaseUrl'),
  withCredentials: false,
});

const axiosPublic = axios.create({
  baseURL: $config.get('apiBaseUrl'),
  withCredentials: false,
});

// Automatically add auth bearer to requests
AxiosInstance.interceptors.request.use(
  config => {
    const accessToken = $oauth2.tokens.access.get();

    if (!accessToken) {
      return config;
    }

    config.headers.Authorization = 'Bearer ' + accessToken;

    return config;
  },
  err => Promise.reject(err),
);

// Intercepts errors and try to refresh token
AxiosInstance.interceptors.response.use(
  response => response,
  error => {
    const { config, response: { status } } = error;
    const originalRequest = config;

    if (status === 401 && $oauth2.tokens.access.get()) {
      $oauth2.refresh();

      return new Promise((resolve, reject) => $oauth2.refreshingToken
        .subscribe(
          token => {
            originalRequest.headers.Authorization = 'Bearer ' + token;

            resolve(AxiosInstance(originalRequest));
          },
          () => reject(error),
        ),
      ).catch(err => $oauth2.logout().then(() => err));
    }

    return Promise.reject(error);
  },
);

AxiosInstance.OAuth2Instance = null;
AxiosInstance.getOAuth2TokenInstance = () => {
  if (AxiosInstance.OAuth2Instance === null) {
    AxiosInstance.OAuth2Instance = axios.create({
      baseURL: $config.get('apiBaseUrl'),
      withCredentials: false,
    });
  }

  return AxiosInstance.OAuth2Instance;
};

export default AxiosInstance;
export { axiosPublic };
