var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"employees",staticClass:"employees"},[(_vm.isLoadingPage)?_c('div',[_c('Loader',{staticClass:"no-bg"})],1):_c('div',{staticClass:"container-fluid p-0 w-auto m-4"},[_c('h4',{staticClass:"mb-4"},[_vm._v("Gestione personale")]),_c('div',{staticClass:"pretty-card"},[_c('h5',[_vm._v("F24 Personale")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 box"},[_c('label',{attrs:{"for":"expected-amount"}},[_vm._v("Importo atteso")]),_c('CellContent',{staticClass:"py-2",attrs:{"id":"expected-amount","value":_vm.expectedAmountF24 ? parseFloat(_vm.expectedAmountF24) : null}})],1),_c('div',{staticClass:"col-5 box"},[_c('b-form-group',{staticClass:"mb-3",class:{
              invalid: !_vm.isValidNewAmountF24 && _vm.showErrorNewAmountF24,
            },attrs:{"id":"input-group-amount-f24","label":`Importo F24 ${_vm.f24Date}`,"label-for":"input-amount-f24"}},[_c('CurrencyInput',{key:`f24-input-${_vm.loading}`,class:{
                invalid: !_vm.isValidNewAmountF24 && _vm.showErrorNewAmountF24,
              },attrs:{"id":"input-amount-f24"},on:{"blur":function($event){_vm.showErrorNewAmountF24 = true}},model:{value:(_vm.newAmountF24),callback:function ($$v) {_vm.newAmountF24=$$v},expression:"newAmountF24"}}),_c('b-form-invalid-feedback',{class:{
                invisible: _vm.isValidNewAmountF24 || !_vm.showErrorNewAmountF24,
              },attrs:{"state":false}},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" Inserisci un importo valido ")])],1)],1),_c('div',{staticClass:"col-5"},[_c('BankSelect',{class:{ invalid: !_vm.isValidBankF24 && _vm.showErrorBankF24 },attrs:{"banks":_vm.banks,"selectedItem":_vm.newBankF24},on:{"search:blur":function($event){_vm.showErrorBankF24 = true},"update":_vm.updateBankF24}}),_c('b-form-invalid-feedback',{class:{ invisible: _vm.isValidBankF24 || !_vm.showErrorBankF24 },attrs:{"state":false}},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" Inserisci una banca ")])],1)]),_c('h5',{staticClass:"mb-4"},[_vm._v("Stipendi personale")]),_c('div',{staticClass:"row"},[(_vm.cumulativeAmount)?_c('div',{staticClass:"col-2 box"},[_c('label',{attrs:{"for":"expected-amount-salary"}},[_vm._v("Importo atteso")]),_c('CellContent',{staticClass:"py-2",attrs:{"id":"expected-amount","value":_vm.expectedAmountSalary ? parseFloat(_vm.expectedAmountSalary) : null}})],1):_vm._e(),(_vm.cumulativeAmount)?_c('div',{staticClass:"col-5 box"},[_c('b-form-group',{staticClass:"mb-3",class:{
              invalid: !_vm.isValidNewAmountSalary && _vm.showErrorNewAmountSalary,
            },attrs:{"id":"input-group-amount-Salary","label":`Importo stipendi ${_vm.salaryDate}`,"label-for":"input-amount-Salary"}},[_c('CurrencyInput',{key:`salary-input-${_vm.loading}`,class:{
                invalid: !_vm.isValidNewAmountSalary && _vm.showErrorNewAmountSalary,
              },attrs:{"id":"input-amount-Salary"},on:{"blur":function($event){_vm.showErrorNewAmountSalary = true}},model:{value:(_vm.newAmountSalary),callback:function ($$v) {_vm.newAmountSalary=$$v},expression:"newAmountSalary"}}),_c('b-form-invalid-feedback',{class:{
                invisible:
                  _vm.isValidNewAmountSalary || !_vm.showErrorNewAmountSalary,
              },attrs:{"state":false}},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" Inserisci un importo valido ")])],1)],1):_vm._e(),(!_vm.cumulativeAmount)?_c('div',{staticClass:"col-7"},[(_vm.employees.length > 0)?_c('div',{staticClass:"scrollable"},_vm._l((_vm.employees),function(employee,i){return _c('div',{key:`employee_${i}`,staticClass:"box primary mb-3 pb-0"},[_c('h5',{staticClass:"mb-3"},[_vm._v(_vm._s(employee.name))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('label',{attrs:{"for":"expected-amount-salary"}},[_vm._v("Importo atteso")]),_c('CellContent',{staticClass:"py-2",attrs:{"id":"expected-amount","value":employee.salary ? parseFloat(employee.salary) : null}})],1),_c('div',{staticClass:"col-8"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":`input-group-amount-employee-${i}`,"label":`Importo stipendi ${_vm.getStringDate(
                      employee?.nextPayment
                    )}`,"label-for":`input-amount-employee-${i}`}},[_c('CurrencyInput',{key:`currency-input-${i}-${_vm.isUpdating}`,attrs:{"id":`input-amount-employee-${i}`},on:{"input":function($event){return _vm.setEmployeeSalary($event, i)}}})],1)],1)])])}),0):_c('div',[_c('div',{staticClass:"mb-4"},[_vm._v(" Devi aggiungere dei dipendenti per poter usare questa funzionalità. ")]),_c('router-link',{staticClass:"btn btn-fin",attrs:{"to":{
                name: 'settings',
                params: { page: 'SettingsEmployees' },
              },"tag":"button"}},[_vm._v(" Configura ")])],1)]):_vm._e(),_c('div',{staticClass:"col-5"},[_c('BankSelect',{class:{ invalid: !_vm.isValidBankSalary && _vm.showErrorBankSalary },attrs:{"banks":_vm.banks,"selectedItem":_vm.bankSalary},on:{"search:blur":function($event){_vm.showErrorBankSalary = true},"update":_vm.updateBankSalary}}),_c('b-form-invalid-feedback',{class:{ invisible: _vm.isValidBankSalary || !_vm.showErrorBankSalary },attrs:{"state":false}},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" Inserisci una banca ")])],1)]),_c('div',{staticClass:"row d-flex justify-content-end"},[_c('div',{staticClass:"col-4"},[_c('custom-button',{staticClass:"w-100 mt-4",attrs:{"isLoading":_vm.loading,"disabled":!_vm.changes,"label":"Salva"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}})],1)]),(_vm.errorMessage)?_c('ErrorCard',{staticClass:"mb-3",scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]},proxy:true}],null,false,935106760)}):_vm._e()],1),_c('Snackbar',{ref:"snackbar",attrs:{"text":_vm.snackbarText}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }