<template>
    <div class="outer-border" :class="[isSelected ? `border-bank-variant-${promise.bankColour} bank-variant-${promise.bankColour}-lighter`: 'hidden-border']" :tabindex="0">
        <div class="pretty-card" :class="[promise.bankColour ? `border-bank-variant-${promise.bankColour}-light` : 'default-border']">
            <div class="row mb-2">
            <div class="col-3">
                <label class="pretty-label mb-0 w-100">Banca</label>
                <div class="font-weight-bolder" :class="`text-bank-variant-${promise.bankColour}`">
                <img :src="promise.bank.logo || bankLogoDefault" class="bank-logo sm mr-1">
                {{promise.bank.alias}}
                </div>
            </div>
            <div class="col-3">
                <label class="pretty-label mb-0 w-100">Scadenza</label>
                {{promise.date ? toFormattedDate(promise.date) : null}}
            </div>
            <div class="col-3">
                <label class="pretty-label mb-0 w-100">Tipo</label>
                <div class="capitalize">{{promise.type | movement}}</div>
            </div>
            <div class="col-3">
                <label class="pretty-label mb-0 w-100 text-right">Importo</label>
                    <CellContent
                    :value="promise.amount ? parseFloat(promise.amount) : null"
                    class="d-flex justify-content-end  border-0"
                    />
            </div>
            </div>
            <div class="row">
            <div class="col">
                <label class="pretty-label mb-0 w-100">Controparte</label>
                {{promise.counterpart}}
            </div>
            <div class="col">
                <label class="pretty-label mb-0 w-100">Descrizione</label>
                {{promise.description}}
            </div>
            </div>

          <div class="suggested" v-if="level" :class="`text-bank-variant-${promise.bankColour} bank-variant-${promise.bankColour}-light`">
            Suggerito &#x2022; Livello affinità {{level}}/3
          </div>
        </div>
    </div>
</template>

<script>
import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

import CellContent from '@/views/components/Table/CellContent.vue';
import { toFormattedDate } from '@/helpers/dates.js';
import formatMovementTypeFilter from '@/filters/formatMovementType';

export default {
  props: {
    item: Object,
    isSelected: Boolean,
    level: Number,
  },
  components: {
    CellContent,
  },
  filters: {
    movement: formatMovementTypeFilter,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
    };
  },
  computed: {
    promise () {
      return this.item;
    },
  },
  methods: {
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
  },
};
</script>

<style lang="scss">
.matching {
  .outer-border {
    box-sizing: border-box;
    padding: 6px;
    border-radius: 20px;
    border-style: solid;
    border-width: 3px;
    &.hidden-border {
      border: 3px solid transparent;
    }
    &.hidden-bg {
      background: transparent;
    }
  }

  .pretty-label {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    color: $body;
  }

  .pretty-card {
    position: relative;
    opacity: 1;
    padding: 15px;
    font-size: 14px;
    min-height: 180px;
    border-style: solid;
    border-width: 2px;
    border-radius: 12px!important;
    margin: 0;
    box-shadow: none;
    cursor: pointer;
    &.default-border {
      border-color: $dodger-blue-light;
    }
  }

  .suggested {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
    padding: 1px 5px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.05em;
  }
}
</style>
