<template>
  <div class="spinner" />
</template>

<script>

export default {
};

</script>

<style lang="scss">

$primary: blue !default;

.spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba($primary, .18);
  border-radius: 50%;
  border-top-color: $primary;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

</style>
