import $oauth2 from '@/libs/OAuth2/services/auth';
import LoginWrapper from '@/views/pages/Public/LoginWrapper.vue';

export default [
  /**
   * ROOT
   * la root del progetto non esiste
   * per questo si limita a smistare l'utente in base al ruolo nelle 2 macro sezioni
   * management e operative
   */
  {
    path: '/',
    name: 'dashboard',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
    },
    props: true,
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/pages/Private/Dashboard.vue'),
  },
  {
    path: '/profilo',
    name: 'profile',
    meta: {
      layout: 'dashboard',
      auth: true,
      disableScroll: true,
    },
    component: () => import(/* webpackChunkName: "matching" */ '@/views/pages/Private/Profile.vue'),
  },
  {
    path: '/checkout/response',
    name: 'checkout-response',
    meta: {
      auth: true,
    },
    props: true,
    component: () => import(/* webpackChunkName: "checkout-response" */ '@/views/components/Stripe/ResponseFromServerCard.vue'),
  },
  {
    path: '/checkout',
    name: 'checkout',
    meta: {
      auth: true,
      noVisitor: true,
      noManager: true,
    },
    component: () => import(/* webpackChunkName: "checkout" */ '@/views/pages/Private/CheckoutPayment.vue'),
  },
  {
    path: '/licenza-scaduta',
    name: 'product-expired',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "checkout" */ '@/views/pages/Private/ProductExpired.vue'),
  },
  {
    path: '/gestione-movimenti',
    name: 'movements',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
    },
    component: () => import(/* webpackChunkName: "movements" */ '@/views/pages/Private/MovementsManager.vue'),
  },
  {
    path: '/gestione-personale',
    name: 'employees',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
    },
    component: () => import(/* webpackChunkName: "employees" */ '@/views/pages/Private/EmployeesManager.vue'),
  },
  {
    path: '/gestione-aziende',
    name: 'companies',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
    },
    component: () => import(/* webpackChunkName: "employees" */ '@/views/pages/Private/EmployeesManager.vue'),
  },
  {
    path: '/prima-nota',
    name: 'primeEntry',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    component: () => import(/* webpackChunkName: "primeentry" */ '@/views/pages/Private/PrimeEntry.vue'),
  },
  {
    path: '/riconciliazione-manuale',
    name: 'manualMatching',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
    },
    component: () => import(/* webpackChunkName: "matching" */ '@/views/pages/Private/ManualMatching.vue'),
  },
  {
    path: '/riconciliazione-automatica',
    name: 'automaticMatching',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
    },
    component: () => import(/* webpackChunkName: "matching" */ '@/views/pages/Private/AutomaticMatching.vue'),
  },
  {
    path: '/finanziamenti',
    name: 'loans',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
    },
    component: () => import(/* webpackChunkName: "loans" */ '@/views/pages/Private/Loans.vue'),
  },
  {
    path: '/finanziamenti/:id',
    name: 'loanDetails',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
    },
    props: true,
    component: () => import(/* webpackChunkName: "loans" */ '@/views/pages/Private/LoanDetails.vue'),
  },
  {
    path: '/pagamenti-ricorrenti',
    name: 'recurringPayments',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
    },
    component: () => import(/* webpackChunkName: "payments" */ '@/views/pages/Private/RecurringPayments.vue'),
  },
  {
    path: '/fatture-passive',
    name: 'passiveInvoices',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
      fattureAttive: false,
    },
    // component: () => import(/* webpackChunkName: "invoices" */ '@/views/pages/Private/Invoices.vue'),
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/pages/Private/Invoices.vue'),
  },
  {
    path: '/fatture-attive',
    name: 'activeInvoices',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
      fattureAttive: true,
    },
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/pages/Private/Invoices.vue'),
  },
  {
    path: '/scadenzario-attivo',
    name: 'scadenzarioAttivo',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
      scadenzarioAttivo: true,
    },
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/pages/Private/Scadenzario.vue'),
  },
  {
    path: '/scadenzario-passivo',
    name: 'scadenzarioPassivo',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
      scadenzarioAttivo: false,
    },
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/pages/Private/Scadenzario.vue'),
  },
  {
    path: '/setup',
    name: 'setup',
    meta: {
      auth: true,
      noVisitor: true,
    },
    component: () => import(/* webpackChunkName: "setup" */ '@/views/pages/Public/SetupWrapper.vue'),
  },
  {
    path: '/impostazioni',
    name: 'settings',
    meta: {
      layout: 'dashboard',
      auth: true,
      disableScroll: true,
    },
    props: true,
    component: () => import(/* webpackChunkName: "settings" */ '@/views/pages/Private/SettingsWrapper.vue'),
  },
  {
    path: '/assistenza',
    name: 'help',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    props: true,
    component: () => import(/* webpackChunkName: "help" */ '@/views/pages/Private/Help.vue'),
  },
  {
    path: '/archivio',
    name: 'archive',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    props: true,
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/pages/Private/Archive.vue'),
  },
  {
    path: '/archivio/notifica/:id',
    name: 'notificationDetails',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    props: true,
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/pages/Private/NotificationDetails.vue'),
  },
  {
    path: '/grafici',
    name: 'charts',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    props: true,
    component: () => import(/* webpackChunkName: "charts" */ '@/views/pages/Private/FullCharts.vue'),
  },
  {
    path: '/iva',
    name: 'iva',
    meta: {
      layout: 'dashboard',
      auth: true,
      noVisitor: true,
      fattureAttive: true,
    },
    component: () => import(/* webpackChunkName: "iva" */ '@/views/pages/Private/IVAManager.vue'),
  },
  /**
   * ACCOUNT & LOGIN
   * Pagina di Login Utente
   * Pagina di Richiesta reset password
   * Pagina di Cambio Password dopo la conferma reset password tramite email
   * Pagina di Attivazione dell'utente registrato
   * */
  {
    path: '/login',
    name: 'login',
    meta: {
      auth: false,
      layout: 'login',
    },
    component: LoginWrapper,
  },
  {
    path: '/register',
    name: 'registration-form',
    meta: {
      auth: false,
    },
    component: () => import(/* webpackChunkName: "registration" */ '@/views/pages/Public/RegistrationForm'),
  },
  {
    path: '/profile/:email/signup/:token',
    name: 'confirm-user-subscription',
    meta: {
      auth: false,
    },
    component: () => import(/* webpackChunkName: "confirmUserSubscription" */ '@/views/pages/Public/ConfirmUserSubscription'),
  },
  {
    path: '/owner/profile/:email/signup/:token',
    name: 'confirm-owner-subscription',
    meta: {
      auth: false,
    },
    component: () => import(/* webpackChunkName: "confirmUserSubscription" */ '@/views/pages/Public/ConfirmOwnerSubscription'),
  },
  {
    path: '/password-reset/:email?',
    name: 'password-reset',
    meta: {
      auth: false,
      layout: 'login',
    },
    component: LoginWrapper,
  },
  {
    path: '/password-reset/:email/change-password/:token',
    name: 'password-reset-change-password',
    meta: {
      layout: 'login',
      auth: false,
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/pages/Public/ResetPasswordChange'),
  },

  /**
   * LOGOUT
   * Rotta di logout
   * Sprovvista di componente
   * effettua il redirect su login con successo o su root se fallimento
   * */
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: async (to, from, next) => {
      try {
        if (!await $oauth2.getMe()) {
          throw new Error('User not logged in');
        }
        await $oauth2.logout();
      } catch (error) {}

      next({ name: 'login', replace: true });
    },
  },
  /** CATCHALL **/
  {
    path: '*',
    redirect: { name: 'dashboard' },
  },
];
