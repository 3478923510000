<template>
  <div class="invoice" ref="summary">
    <form
      @submit.prevent
      class="text-start"
      novalidate
      v-if="invoice"
      >
      <h4 class="mb-4">Modifica fattura</h4>
      <div class="row mb-4">
        <div class="col-auto mr-4 d-flex flex-column">
          <label class="pretty-label">Numero fattura</label>
          <div class="font-weight-bolder">
            {{invoice.number}}
          </div>
        </div>
        <div class="col-auto mr-4 d-flex flex-column">
          <label class="pretty-label">Data</label>
          <div class="font-weight-bolder">
            {{ toFormattedDate(invoice.date) }}
          </div>
        </div>
      </div>

      <h5 class="mb-4">{{ fatturePassive ? 'Fornitore' : 'Cliente' }}</h5>

      <div class="row mb-4">
        <div class="col-auto mr-4 mb-4 d-flex flex-column">
          <label class="pretty-label">Ragione Sociale</label>
          <div class="font-weight-bolder">
            {{invoice.name}}
          </div>
        </div>
        <div class="col-auto mr-4 d-flex flex-column">
          <label class="pretty-label">Partita Iva</label>
          <div class="font-weight-bolder">
            {{ invoice.vat }}
          </div>
        </div>
        <div class="col-auto mr-4 d-flex flex-column">
          <label class="pretty-label">Codice Fiscale</label>
          <div class="font-weight-bolder">
            {{invoice.cf}}
          </div>
        </div>
        <div class="col-auto mr-4 d-flex flex-column">
          <label class="pretty-label">Indirizzo</label>
          <div class="font-weight-bolder">
            {{invoice.address}} {{invoice.municipality}} {{invoice.cap}} {{invoice.province}} {{invoice.state}}
          </div>
        </div>
      </div>

      <h5 class="mb-4">Fattura</h5>

    <div class="row mb-4">
        <div class="col-auto mr-4 d-flex flex-column">
            <label class="pretty-label">Imponibile</label>
            <div class="font-weight-bolder">
            {{ invoice.taxBase ? (invoice.taxBase).toLocaleString('de', {minimumFractionDigits: 2}) : null }}
            </div>
        </div>
        <div class="col-auto mr-4 d-flex flex-column">
            <label class="pretty-label">Importo IVA</label>
            <div class="font-weight-bolder">
            {{ invoice.iva ? (invoice.iva).toLocaleString('de', {minimumFractionDigits: 2}) : null }}
            </div>
        </div>
        <div class="col-auto mr-4 d-flex flex-column">
            <label class="pretty-label">Totale fattura</label>
            <div class="font-weight-bolder">
            {{ invoice.invoiceAmount ? (invoice.invoiceAmount).toLocaleString('de', {minimumFractionDigits: 2}) : null }}
            </div>
        </div>
        <div class="col-auto mr-4 d-flex flex-column" v-if="fatturePassive && withholdingNotNull">
            <label class="pretty-label">Importo ritenuta</label>
            <div class="font-weight-bolder">
            {{ invoice.withholdingTax ? (invoice.withholdingTax).toLocaleString('de', {minimumFractionDigits: 2}) : null}}
            </div>
        </div>
        <div class="col-auto mr-4 d-flex flex-column" v-if="fatturePassive && withholdingNotNull">
          <label class="pretty-label">Data pagamento ritenuta</label>
          <div class="font-weight-bolder">
          {{ invoice.withholdingDate ? toFormattedDate(invoice.withholdingDate) : null }}
            </div>
        </div>
    </div>

    <div class="row mb-4" v-if="fatturePassive && withholdingNotNull">
        <div class="col-6">
            <BankSelect ref="bankSelect2" label="Banca pagamento ritenuta" :banks="banks" :selectedItem="invoice.bank2" @update="updateBank2"/>
        </div>
        <div class="col-5 d-flex align-items-center justify-content-left">
          <b-form-checkbox v-model="invoice.withholdingIsPaid" name="input-withholding-paid">
            Pagata
          </b-form-checkbox>
        </div>
    </div>

      <h5 class="mb-4">Piano delle scadenze</h5>
      <div class="row mb-4">
        <div class="col-auto mr-4 d-flex flex-column">
          <label class="pretty-label">Scadenzato</label>
          <div class="font-weight-bolder">
            {{ scadenzato ? scadenzato.toLocaleString('de', {minimumFractionDigits: 2}) : scadenzato }}
          </div>
        </div>
        <div class="col-auto mr-4 d-flex flex-column">
          <label class="pretty-label">Da scadenzare</label>
          <div class="font-weight-bolder">
            {{ daScadenzare ? daScadenzare.toLocaleString('de', {minimumFractionDigits: 2}) : daScadenzare }}
          </div>
        </div>
      </div>
      <div class="counter">
        <div v-for="(rata, index) in invoice.rate" :key="`rata_${index}`">
          <div class="light-card mb-4 enum">

            <button v-if="!rata.isMatched" class="btn-delete-round" @click="deleteRow(index)" tabindex="1"><i class="fas fa-trash"></i></button>

            <div class="row">
              <div class="col p-0">
                <b-form-group
                    class="mb-0 box"
                    :id="`input-group-amount-${index}`"
                    label="Importo"
                    label-for="input-amounts"
                    :class="[{ invalid: (!isValidAmount(rata.amount) && showErrors) || !isCurrency(rata.amount) }, { disabled: rata.isMatched }]"
                >
                <CurrencyInput :key="`invoiceAmount_${invoice?.invoiceAmount}`" v-model="rata.amount" :disabled="rata.isMatched"/>
                </b-form-group>
              </div>
              <div class="col p-0">
                <b-form-group
                  class="mb-0 box"
                  id="input-group-date"
                  label="Scadenza"
                  :label-for="`input-date-${index}`"
                  :class="[{ invalid: !isValidDate(rata.date) && showErrors }, { disabled: rata.isMatched }]"
                >
                  <DatePicker :id="`input-date_${index}`" :value="rata.date" @setValue="setDate($event, index)" @blur="showErrorDate = true" :key="`updateDate_${rata.date}`"/>
                </b-form-group>
              </div>
              <div class="col-3 p-0">
                <b-form-group
                  class="mb-0 box"
                  id="input-group-payment"
                  label="Modalità di pagamento"
                  label-for="input-payment"
                  :class="[{ invalid: !isValidPayment(rata.paymentType) && showErrors }, { disabled: rata.isMatched }]"
                >
                  <b-form-select
                    :class="{ invalid: !isValidPayment(rata.paymentType) && showErrors }"
                    id="input-payment"
                    :key="`${index}_${rata.paymentType}`"
                    v-model="rata.paymentType"
                    :options="options"
                    :disabled="rata.isMatched"
                  >
                  </b-form-select>
                </b-form-group>
                  </div>
              <div class="col-4 p-0">
                <b-form-group
                    class="mb-0"
                    id="input-group-bank"
                    :class="[{ invalid: !isValidBank(rata.bankId) && showErrors }, { disabled: rata.isMatched }]"
                    :key="`bank_${rata.bankId}`"
                >
                  <BankSelect class="mb-0" ref="bankSelect" :banks="banks" :disabled="rata.isMatched" :selectedItem="getBankById(rata.bankId)" @update="updateBank($event, index)" :key="`bank_${update}`"/>
                </b-form-group>
              </div>
              <div class="col-1 ml-2 d-flex justify-content-center align-items-center p-0 mr-3">
                <b-form-checkbox
                  v-model="rata.paid"
                  name="input-paid"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="rata.isMatched"
                >
                  Pagata
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end align-items-center mb-5">
        <button class="btn btn-fin" :class="{disabled: invoice.isMatched}" :disabled="invoice.isMatched" @click="addRow">Aggiungi scadenza</button>
      </div>

      <div class="row">
        <div class="col">
          <custom-button
            :isLoading="loading"
            label="Salva"
            class="w-100"
            @click.prevent.native="onSubmit"
          />
        </div>
      </div>

      <ErrorCard v-if="errorMessage">
        <template #message >
          {{ errorMessage }}
        </template>
      </ErrorCard>
    </form>

  </div>
</template>

<script>
import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import DatePicker from '@/views/components/DatePicker.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import { BFormGroup, BFormCheckbox } from 'bootstrap-vue';
import { isFilled, isCurrency, isDate, isValidYear } from '@/helpers/formValidation.js';
import { toFormattedDate } from '@/helpers/dates.js';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    banks: Array,
    propInvoice: Object,
    companyId: String,
    confirmInvoice: Boolean,
    fatturePassive: Boolean,
    confirmSubmit: Number,
  },
  components: {
    BFormCheckbox,
    BFormGroup,
    BankSelect,
    'custom-button': Button,
    CurrencyInput,
    DatePicker,
    ErrorCard,
  },
  watch: {
    confirmSubmit () {
      if (this.confirmSubmit > 0) {
        this.upsertInvoice();
      }
    },
  },
  data () {
    return {
      update: 0,
      invoiceId: null,
      invoice: {
        number: null,
        date: null,
        name: null,
        vat: null,
        cf: null,
        address: null,
        cap: null,
        municipality: null,
        province: null,
        state: null,
        iva: null,
        taxBase: null,
        invoiceAmount: null,
        withholdingTax: null,
        withholdingDate: null,
        withholdingIsPaid: null,
        bank2: null,
        rate: null,
        leftAmount: null,
      },
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
      showErrors: false,

      loading: false,
      errorMessage: '',

      options: [
        { value: 'MP01', text: 'MP01 - Contanti' },
        { value: 'MP02', text: 'MP02 - Assegno' },
        { value: 'MP03', text: 'MP03 - Assegno circolare' },
        { value: 'MP04', text: 'MP04 - Contanti presso Tesoreria' },
        { value: 'MP05', text: 'MP05 - Bonifico' },
        { value: 'MP06', text: 'MP06 - Vaglia cambiario' },
        { value: 'MP07', text: 'MP07 - Bollettino bancario' },
        { value: 'MP08', text: 'MP08 - Carta di pagamento' },
        { value: 'MP09', text: 'MP09 - RID' },
        { value: 'MP10', text: 'MP10 - RID utenze' },
        { value: 'MP11', text: 'MP11 - RID veloce' },
        { value: 'MP12', text: 'MP12 - RIBA' },
        { value: 'MP13', text: 'MP13 - MAV' },
        { value: 'MP14', text: 'MP14 - Quietanza erario' },
        { value: 'MP15', text: 'MP15 - Giroconto su conti di contabilità speciale' },
        { value: 'MP16', text: 'MP16 - Domiciliazione bancaria' },
        { value: 'MP17', text: 'MP17 - Domiciliazione postale' },
        { value: 'MP18', text: 'MP18 - Bollettino di c/c postale' },
        { value: 'MP19', text: 'MP19 - SEPA Direct Debit' },
        { value: 'MP20', text: 'MP20 - SEPA Direct Debit CORE' },
        { value: 'MP21', text: 'MP21 - SEPA Direct Debit B2B' },
        { value: 'MP22', text: 'MP22 - Trattenuta su somme già riscosse' },
        { value: 'MP23', text: 'MP23 - PagoPA' },
      ],
    };
  },
  computed: {
    scadenzato () {
      // Somma Importi delle scadenze
      let total = 0;
      if (this.invoice.rate) {
        this.invoice.rate.map(rata => {
          if (rata.amount) {
            total += parseFloat(rata.amount);
          }
        });
        return (parseFloat(total));
      } else {
        return total;
      }
    },
    withholdingNotNull () {
      return Boolean(this.invoice.withholdingTax);
    },
    daScadenzare () {
      // Totale da Pagare – Totale Scadenzato
      return this.invoice.invoiceAmount - this.scadenzato;
    },
    isValidAmountToSchedule () {
      return this.daScadenzare === 0;
    },
  },
  methods: {
    addRow () {
      this.showErrors = false;
      const row = Object.assign({}, { amount: null, date: null, bankId: null, paymentType: null, paid: false });
      this.invoice.rate.push(row);
      // to do scroll down smooth
    },
    deleteRow (index) {
      this.$log.debug(index);
      this.$log.debug(this.invoice.rate[index]);
      this.invoice.rate.splice(index, 1);
    },
    getBankById (bankId) {
      return this.banks.find(bank => bank.id === bankId);
    },
    getMainBank () {
      return this.banks.find(bank => bank.isMain);
    },
    isValidForm () {
      const invalid = this.invoice.rate.filter(rata => {
        if (!this.isValidAmount(rata.amount)) return true;
        if (!this.isValidDate(rata.date)) return true;
        if (!this.isValidPayment(rata.paymentType)) return true;
        if (!this.isValidBank(rata.bankId)) return true;

        return false;
      });
      return invalid.length === 0;
    },
    isCurrency (amount) {
      return !isFilled(amount) || isCurrency(amount);
    },
    isValidAmount (amount) {
      return isFilled(amount) && isCurrency(amount);
    },
    isValidDate (date) {
      return isFilled(date) && isDate(date) && isValidYear(date, 2000, 2100);
    },
    isValidPayment (payment) {
      return isFilled(payment);
    },
    isValidBank (bank) {
      return isFilled(bank);
    },
    onConfirm () {
      this.upsertInvoice();
    },
    onSubmit: function () {
      this.errorMessage = '';

      if (this.isValidForm()) {
        if (this.isValidAmountToSchedule) {
          this.loading = true;
          this.upsertInvoice();
        } else {
          this.$emit('toggleAlert', this.daScadenzare);
        }
      } else {
        this.loading = true;
        this.showErrors = true;
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    updateBank (selectedBank, index) {
      this.invoice.rate[index].bankId = selectedBank.id;
      this.update++;
    },
    updateBank2 (bank) {
      this.invoice.bank2 = bank;
    },
    upsertInvoice () {
      this.$api.createInvoice(
        this.companyId,
        this.invoice.number, // number,
        this.invoice.name, // denomination,
        this.invoice.vat, // vat,
        this.invoice.cf, // fiscalCode,
        this.invoice.address, // address,
        this.invoice.municipality, // city,
        this.invoice.cap, // cap,
        this.invoice.province, // province,
        this.invoice.state, // state,
        this.invoice.taxBase, // taxable / imponibile
        this.invoice.iva, // iva,
        this.invoice.invoiceAmount, // total,
        this.invoice.date, // date,
        this.invoice.withholdingTax, // withholding,
        this.invoice.bank2 ? this.invoice.bank2.id : null, // withholdingBankId,
        this.fatturePassive ? 'out' : 'in', // sign in = attiva, out = passiva
        this.invoice.rate, // rate,
        this.daScadenzare, // leftAmount
        this.invoiceId,
      )
        .then((res) => {
          this.$log.debug(res.data);
          const type = this.confirmInvoice ? 'confirm' : 'edit';
          this.$emit('save', type);
        })
        .catch((e) => {
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setDate (newDate, index) {
      this.invoice.rate[index].date = newDate;
    },
    setValues () {
      if (this.propInvoice) {
        this.invoiceId = this.propInvoice.id || null;
        this.invoice.number = this.propInvoice.number;
        this.invoice.date = this.propInvoice.date;
        this.invoice.name = this.propInvoice.denomination;
        this.invoice.vat = this.propInvoice.vat; // partita iva
        this.invoice.cf = this.propInvoice.fiscalCode;
        this.invoice.address = this.propInvoice.address;
        this.invoice.cap = this.propInvoice.cap;
        this.invoice.municipality = this.propInvoice.city;
        this.invoice.province = this.propInvoice.province;
        this.invoice.state = this.propInvoice.state;
        this.invoice.iva = this.propInvoice.iva;
        this.invoice.taxBase = this.propInvoice.imponibile ? this.propInvoice.imponibile : null;
        this.invoice.invoiceAmount = this.propInvoice.total ? this.propInvoice.total : null;
        this.invoice.withholdingTax = this.propInvoice.ritenuta ? this.propInvoice.ritenuta : null;
        this.invoice.withholdingDate = this.propInvoice.dataRitenuta || null;
        this.invoice.withholdingIsPaid = this.propInvoice.ritenutaPagata || false;
        this.invoice.bank2 = this.propInvoice.ritenutaBankId ? this.getBankById(this.propInvoice.ritenutaBankId) : null;
        this.invoice.rate = this.propInvoice.rate ? this.propInvoice.rate : [];
        this.invoice.rate.map((rata) => {
          if (rata.bank === null || rata.bankId === null) {
            const newBank = this.getMainBank();
            rata.bankId = newBank.id;
          }
        });
        this.invoice.leftAmount = this.propInvoice.leftAmount ? this.propInvoice.leftAmount : null;
      }
    },
  },
  beforeMount () {
    this.setValues();
  },
  mounted () {
    if (this.invoice && JSON.stringify(this.invoice.rate) === '[]') {
      this.addRow();
    }
  },
};
</script>

<style lang="scss">
.btn-back {
  opacity: 0.5;
}

.btn-delete-round {
  position: absolute;
  top: -10px;
  right: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  background: $danger;
  color: white;
  i {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }
}

.counter {
  counter-reset: counter;
  .enum {
    counter-increment: counter;
    &::after {
      content: counter(counter)!important;
      display: block;
      width: 25px;
      height: 25px;
      background: $primary;
      color: white;
      position: absolute;
      top: -5px;
      left: -5px;
      font-weight: 600;
      font-size: 14px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.upper {
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto;
    min-height: 100vh;
    z-index: 100;
    overflow: hidden;
}
</style>
