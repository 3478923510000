<template>
  <div class="alert alert-danger" :class="[footer ? 'mt-4 mb-0' : 'mt-0 mb-4']" role="alert">
    <i class="fas fa-exclamation-triangle mr-2"></i>
    <span class="font-weight-bold">
      <slot name="title" class="title">Attenzione!</slot>
    </span>
    <slot name="message">
      Qualcosa è andato storto, si prega di riprovare.
    </slot>
  </div>
</template>

<script>

export default {
  props: {
    footer: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  border-radius: 10px;
  border: 0;
  color: $danger;
  background: rgba($danger, .1);
  font-size: 14px;
}
</style>
