export const installLibrary = (url, id, myDocument = document) => new Promise(resolve => {
  if (myDocument.getElementById(id)) {
    resolve();

    return;
  }

  const js = myDocument.createElement('script');
  js.id = id;
  js.src = url;
  js.onreadystatechange = js.onload = () => {
    if (!js.readyState || /loaded|compvare/.test(js.readyState)) {
      setTimeout(() => {
        resolve();
      }, 500);
    }
  };

  const fjs = myDocument.getElementsByTagName('script')[0];
  fjs.parentNode.insertBefore(js, fjs);
});
