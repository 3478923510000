<template>
    <div class="text-center">
        <img :src="img" alt="" class="mb-4">
        <h4 class="text-center mb-4">Complimenti!</h4>
        <p class="text-center mb-4">
        Hai completato il setup. Ora puoi utilizzare l'applicazione.
        </p>
        <router-link :to="{ name: 'dashboard', params: { firstAccess: true } }" tag="button" class="btn btn-fin">
          Vai alla dashboard
        </router-link>
    </div>
</template>

<script>
import img from '@/assets/images/illustration.svg';

export default {
  data () {
    return {
      img: img,
    };
  },
};
</script>
