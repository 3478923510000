<template>
  <div class="setup-bank-accounts" ref="step-2">
    <div>
      <h5 class="mb-3">Conti correnti</h5>
      <Alert status="primary" fontSize="lg">
        Per sfruttare al meglio NinaFin, imposta un colore e un alias diverso per ogni conto aggiunto.
      </Alert>
      <div class="row mb-4 g-2" >
        <div
          v-for="(account, i) in form.bankAccounts"
          :key="`account_${i}_${account.alias}_${lengthOfBanks}`"
          class="col-4 py-3"
        >
          <div
            class="account-card text-center"
            :class="[`bank-variant-${account.colour}-light`]"
          >
            <img :src="getLogo(account?.bankId)" class="bank-logo md mb-2">
            <h6
              class="text-center mb-3"
              :class="`text-bank-variant-${account.colour}`"
            >
              {{ account.alias }}
            </h6>
            <span class="iban-tag" :class="`bank-variant-${account.colour}`"
              >IBAN</span
            >
            <div class="iban mt-1">{{ account.iban }}</div>
            <div class="row icons d-flex justify-content-between">
              <div class="col-auto">
                <button @click="setMainBank(account)" :title="account.mainBank === true ? 'Banca principale' : 'Rendi banca principale'">
                  <i
                    :class="account.mainBank === true ? `fas fa-star text-bank-variant-${account.colour}` : `far fa-star text-bank-variant-${account.colour}`"
                  ></i>
                </button>
              </div>
              <div class="col-auto">
                <button
                  @click="
                    togglePopup('upsertPopup');
                    selectedItem = account;
                    index = i;
                  "
                >
                  <i
                    class="fas fa-pen"
                    :class="`text-bank-variant-${account.colour}`"
                  ></i>
                </button>
                <button
                  @click="
                    togglePopup('deletePopup');
                    selectedItem = account;
                  "
                >
                  <i
                    class="fas fa-trash"
                    :class="`text-bank-variant-${account.colour}`"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
        <div v-if="fabrickAccountHasBeenLoaded === null"></div>
        <div class="col-4 py-3" v-if="fabrickAccountHasBeenLoaded === false">
          <span class="add-button">
            <Loader2 />
          </span>
        </div>
        <div v-else>
          <div
            v-for="(account, i) in form.fabrickAccounts"
            :key="`account_${i}_${account.fabrickId}_${lengthOfFabrickBanks}_${updating}`"
            class="col-4 py-3"
          >
            <div
              class="account-card text-center"
              :class="[`bank-variant-${account.colour}-light`]"
            >

              <img :src="getLogo(account.bankDetail?.id)" class="bank-logo md mb-2">

              <h6
                class="text-center mb-3"
                :class="`text-bank-variant-${account.colour}`"
              >
                {{ account.alias }}
              </h6>
              <span class="iban-tag" :class="`bank-variant-${account.colour}`"
                >IBAN</span
              >
              <div class="iban mt-1">{{ account.iban }}</div>
              <div class="row icons d-flex justify-content-between">
                <div class="col-auto">
                  <button @click="setMainBank(account)" :title="account.mainBank === true ? 'Banca principale' : 'Rendi banca principale'">
                    <i
                      :class="account.mainBank === true ? `fas fa-star text-bank-variant-${account.colour}` : `far fa-star text-bank-variant-${account.colour}`"
                    ></i>
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    @click="
                      togglePopup('upsertPopup');
                      selectedItem = account;
                      index = i;
                    "
                  >
                    <i
                      class="fas fa-pen"
                      :class="`text-bank-variant-${account.colour}`"
                    ></i>
                  </button>
                  <button
                    @click="
                      togglePopup('deletePopup');
                      selectedItem = account;
                    "
                  >
                    <i
                      class="fas fa-trash"
                      :class="`text-bank-variant-${account.colour}`"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4 py-3">
          <div
            type="button"
            class="add-button"
            :class="{invalid: showErrorBankAccounts && lengthOfArray < 1}"
            @click="
              togglePopup('upsertPopup');
              selectedItem = null;
            "
          >
            <i class="fas fa-plus-circle pb-2"></i>
            <span>Aggiungi conto</span>
          </div>
        </div>
        <div class="col-12 red" v-if="showErrorBankAccounts && lengthOfArray < 1">
          <span>Inserisci almeno un conto corrente</span>
        </div>
      </div>
      <h5 class="mb-3">Cassa</h5>
      <div class="row">
        <div class="col-6">
          <b-form-group
            class="m-0"
            id="input-group-cash"
            label="Saldo della cassa*"
            label-for="input-cash"
            :class="{ invalid: !isValidCash && showErrorCash }"
          >
            <CurrencyInput id="input-cash" v-model="form.cash" @blur="showErrorCash = true" />
            <b-form-invalid-feedback
              :state="false"
              :class="{ invisible: isValidCash || !showErrorCash }"
            >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo
              valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-6">
        <custom-button
          label="Indietro"
          class="w-100 btn-secondary"
          @click.prevent.native="$emit('changePage', -1)"
        />
        </div>
        <div class="col-6">
          <custom-button
            :isLoading="loading"
            :class="{disabled: !isValidForm}"
            label="Avanti"
            class="w-100"
            @click.prevent.native="onSubmit"
          />
        </div>
      </div>

      <Popup @closePopup="togglePopup('upsertPopup'); index = null; selectedItem = null;" ref="upsertPopup" fixedOnTop>
        <template #fullContent>
          <UpsertBankAccount
            :banks="banks"
            :bankAccounts="bankAccounts"
            @addBankAccount="addBankAccount"
            @editBankAccount="editBankAccount"
            :account="selectedItem"
            :index="index"
          />
        </template>
      </Popup>

      <Popup
        @closePopup="togglePopup('deletePopup')"
        ref="deletePopup"
        class="md"
      >
        <template #title> Sei sicuro di voler eliminare? </template>
        <template #text>
          Il conto <b>{{ selectedItem.alias }}</b> con iban <b>{{ selectedItem.iban }}</b> verrà rimosso dall'elenco
        </template>
        <template #button>
          <custom-button
            label="Elimina"
            class="w-100 mt-4"
            @click.prevent.native="selectedItem?.fabrickId !== null ? deleteFabrickBankAccount(selectedItem) : deleteBankAccount(selectedItem)"
          />
        </template>
      </Popup>

    </div>
  </div>
</template>

<script>
import Alert from '@/libs/Feedback/components/Alert.vue';
import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import Popup from '@/views/components/Popup.vue';
import UpsertBankAccount from '@/views/components/Setup/UpsertBankAccount.vue';
import { BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isCurrency } from '@/helpers/formValidation.js';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';
import Loader2 from '@/views/components/Layout/Spinner.vue';

export default {
  props: {
    propForm: {
      type: Object,
      required: true,
    },
    banks: {
      type: Array,
      required: true,
    },
  },
  components: {
    Alert,
    BFormGroup,
    BFormInvalidFeedback,
    CurrencyInput,
    'custom-button': Button,
    Popup,
    Loader2,
    UpsertBankAccount,
  },
  watch: {
    $route: {
      handler () {
        if (this.$route.query.fabrickonboardingsuccess || this.$route.query.fabrickupdateaggregationsuccess) {
          this.$log.debug('onboarding');
          this.getFabrickAccounts();
        }
      },
      immediate: true,
    },
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      errorMessage: '',

      updating: 0,
      loading: false,
      showErrorBankAccounts: false,
      showErrorCash: false,
      selectedItem: null,
      index: null,

      fabrickAccountHasBeenLoaded: null,
      lengthOfFabrickBanks: 0,

      form: {},
    };
  },
  computed: {
    isValidForm () {
      return this.isValidCash && this.lengthOfArray > 0;
    },
    isValidCash () {
      return isFilled(this.form.cash) && isCurrency(this.form.cash);
    },
    lengthOfBanks () {
      return this.form.bankAccounts ? this.form.bankAccounts.length : 0;
    },
    lengthOfArray () {
      return this.lengthOfFabrickBanks + this.lengthOfBanks;
    },
    bankAccounts () {
      return this.form.bankAccounts ? this.form.bankAccounts : [];
    },
    cash2: {
      get () {
        return this.propForm.cash;
      },
      set (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    togglePopup (ref) {
      return this.$refs[ref].togglePopup();
    },
    addBankAccount (account) {
      if (this.form.bankAccounts === undefined) {
        this.form.bankAccounts = [];
      }
      this.form.bankAccounts.push(account);

      // FOR ONBOARDING ONLY (this.lengthOfArray === 0):
      if (account.mainBank || this.lengthOfArray === 0) {
        this.setMainBank(account);
      }
      this.togglePopup('upsertPopup');
    },
    editBankAccount (account, i) {
      this.$log.debug(account);
      this.$log.debug(i);
      if (account.fabrickId && this.form.fabrickAccounts) {
        // const i = this.form.fabrickAccounts.indexOf(this.selectedItem);
        this.form.fabrickAccounts.splice(i, 1, account);
      } else {
        const i = this.form.bankAccounts.indexOf(this.selectedItem);
        this.form.bankAccounts.splice(i, 1, account);
      }

      if (account.mainBank) {
        this.setMainBank(account);
      }
      this.checkMainBankExists();
      this.updating++;
      this.index = null;
      this.togglePopup('upsertPopup');
    },
    deleteBankAccount (account) {
      const i = this.form.bankAccounts.indexOf(account);
      this.form.bankAccounts.splice(i, 1);
      this.togglePopup('deletePopup');
    },
    deleteFabrickBankAccount (account) {
      if (this.form.fabrickAccounts) {
        const i = this.form.fabrickAccounts.indexOf(account);
        this.form.fabrickAccounts.splice(i, 1);
        this.lengthOfFabrickBanks = this.form.fabrickAccounts ? this.form.fabrickAccounts.length : 0;
        this.togglePopup('deletePopup');
      } else {
        this.deleteBankAccount(account);
      }
    },
    getFabrickAccounts () {
      this.fabrickAccountHasBeenLoaded = false;
      this.$api.getOpenBankingListAccounts()
        .then((res) => {
          this.form.fabrickAccounts = res.data.accounts;
          this.lengthOfFabrickBanks = this.form.fabrickAccounts ? this.form.fabrickAccounts.length : 0;
          // FOR ONBOARDING ONLY :
          if (this.lengthOfBanks === 0 && this.lengthOfFabrickBanks > 0) {
            this.setMainBank(this.form.fabrickAccounts[0]);
          }
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.fabrickAccountHasBeenLoaded = true;
        });
    },
    checkMainBankExists () {
      const fabrickBank = this.form.fabrickAccounts ? this.form.fabrickAccounts.find(bank => bank.isMain === true) : null;
      const userBank = this.form.bankAccounts ? this.form.bankAccounts.find(bank => bank.isMain === true) : null;

      if (fabrickBank || userBank) {
        return null;
      }

      if (this.lengthOfBanks > 0) {
        this.setMainBank(this.form.bankAccounts[0]);
        return null;
      }

      if (this.lengthOfFabrickBanks > 0) {
        this.setMainBank(this.form.fabrickAccounts[0]);
        return null;
      }
      return null;
    },
    mergeBanks () {
      this.form.bankAccounts = this.form.bankAccounts ? this.form.bankAccounts : [];
      this.form.fabrickAccounts = this.form.fabrickAccounts ? this.form.fabrickAccounts : [];
      this.form.bankAccounts.forEach((bankAccount, index) => {
        const sameBank = this.form.fabrickAccounts.find(bank => bank.iban === bankAccount.iban);
        if (sameBank) {
          this.form.bankAccounts.splice(index, 1);
        }
      });
      this.form.bankAccounts = [...this.form.bankAccounts, ...this.form.fabrickAccounts];
      if (this.form.fabrickAccounts || this.form.fabrickAccounts === []) {
        delete this.form.fabrickAccounts;
      }
    },
    getLogo (bankId) {
      const bank = this.banks.find(bank => bank.id === bankId);
      return (bank && bank.logo) ? bank.logo : bankLogoDefault;
    },
    setMainBank (account) {
      if (this.form.fabrickAccounts) {
        this.form.fabrickAccounts.forEach((fabrickAccount) => {
          fabrickAccount.mainBank = Boolean(fabrickAccount.iban === account.iban);
        });
        this.lengthOfFabrickBanks = this.form.fabrickAccounts ? this.form.fabrickAccounts.length : 0;
      };
      if (this.form.bankAccounts) {
        this.form.bankAccounts.forEach((bankAccount) => {
          bankAccount.mainBank = Boolean(bankAccount.iban === account.iban);
        });
      };
      this.lengthOfFabrickBanks = this.form.fabrickAccounts ? this.form.fabrickAccounts.length : 0;
      this.updating++;
    },
    onSubmit () {
      this.checkMainBankExists();
      this.mergeBanks();
      if (this.isValidForm) {
        this.$log.debug(this.form);
        this.$emit('saveData', this.form);
      } else {
        this.loading = true;
        if (this.lengthOfArray <= 0) {
          this.showErrorBankAccounts = true;
        } else if (!this.isValidCash) {
          this.showErrorCash = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
  },
  mounted () {
    this.form = JSON.parse(JSON.stringify(this.propForm));
    this.lengthOfFabrickBanks = this.form.fabrickAccounts ? this.form.fabrickAccounts.length : 0;
  },
};
</script>

<style lang="scss">
.setup-bank-accounts {
  .add-button {
    background: $primary-lighter;
    color: $primary;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    border-radius: 10px;
    padding: auto;
    min-height: 235px;
    min-width: 150px;
    i {
      font-size: 52px;
    }
    &:hover {
      background: rgba($primary-light, 0.6);
    }
    &:active {
      background: rgba($primary-light, 0.8);
    }
    &.invalid {
      color: $danger;
      background: (rgba($danger, 0.1));
    }
  }
  .account-card {
    min-height: 235px;
    height: 100%;
    border-radius: 10px;
    padding: 15px;
    position: relative;
    .iban {
      font-weight: 500;
      font-size: 13px;
    }
    .iban-tag {
      color: $fin-white;
      font-weight: 600;
      font-size: 13px;
      padding: 0px 4px;
      border-radius: 5px;
    }
    button {
      border: none;
      background: none;
    }
    .icons {
      position: absolute;
      bottom: 10px;
      width: 100%;
    }
  }
}
</style>
