<template>
  <div class="loan">
    <form
      @submit.prevent="onSubmit"
      class="text-start"
      novalidate
      >
      <div class="row">
        <div class="col-6">
            <b-form-group
                class="mb-3 box px-0"
                id="input-group-name"
                label="Nome*"
                label-for="input-name"
                :class="{ invalid: !isValidName && showErrorName }"
            >
                <b-form-input
                id="input-name"
                v-model="name"
                type="text"
                @blur="showErrorName = true"
                ></b-form-input>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidName || !showErrorName }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido
                </b-form-invalid-feedback>
            </b-form-group>
        </div>
        <div class="col-6">
          <BankSelect ref="bankSelect" key="bankSelect" :label="'Banca*'" :banks="banks" :selectedItem="bankAccount" @search:blur="showErrorBank = true" @update="updateBank" :class="{ invalid: !isValidBank && showErrorBank }"/>
          <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBank || !showErrorBank }" >
            <i class="fas fa-exclamation-circle"></i> Inserisci una banca
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="d-flex mb-3">
        <b-form-group>
            <b-form-radio-group
                id="radio-group-2"
                v-model="granted"
            >
                <b-form-radio :value="false">Da erogare</b-form-radio>
                <b-form-radio :value="true">Erogato</b-form-radio>
            </b-form-radio-group>
        </b-form-group>
      </div>

      <div class="d-flex row">
        <div class="col-6" v-if="!granted">
          <b-form-group
            class="mb-3"
            id="input-group-amount"
            label="Importo erogato*"
            label-for="input-amount"
            :class="{ invalid: !isValidAmount && showErrorAmount }"
          >
            <CurrencyInput id="input-amount" v-model="amount" @blur="showErrorAmount = true" />
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmount || !showErrorAmount }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group
            class="mb-3"
            id="input-group-release-date"
            :label="`Data erogazione${this.granted ? '' : '*'}`"
            label-for="input-release-date"
            :class="{ invalid: !isValidDate && showErrorDate }"
          >
            <DatePicker id="input-release-date" :value="releaseDate" @setValue="setDate" @blur="showErrorDate = true" :key="`releaseDate_${update}`"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidDate || !showErrorDate }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una data valida
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-instalment-frequency"
            label="Frequenza delle rate*"
            label-for="input-instalment-frequency"
            :class="{ invalid: !isValidInstalmentFrequency && showErrorInstalmentFrequency }"
          >
            <b-form-select
              id="input-instalment-frequency"
              v-model="instalmentFrequency"
              type="number"
              @blur="showErrorInstalmentFrequency = true"
              :class="{ invalid: !isValidInstalmentFrequency && showErrorInstalmentFrequency }"
            >
              <option :value="1">Mensile</option>
              <option :value="3">Trimestrale</option>
              <option :value="6">Semestrale</option>
              <option :value="12">Annuale</option>
            </b-form-select>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidInstalmentFrequency || !showErrorInstalmentFrequency }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci il numero di rate
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            id="input-group-instalments"
            label="Numero rate*"
            label-for="input-instalments"
            :class="{ invalid: !isValidNumberOfInstalments && showErrorInstalments }"
          >
            <b-form-input
              id="input-instalments"
              v-model="numberOfInstalments"
              type="number"
              @blur="showErrorInstalments = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidNumberOfInstalments || !showErrorInstalments }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un numero valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-expiration"
            label="Data prima scadenza*"
            label-for="input-expiration"
            :class="{ invalid: !isValidFirstExpiration && showErrorFirstExpiration }"
          >
            <DatePicker id="input-expiration" :value="firstExpiration" @setValue="setDate2" @blur="showErrorFirstExpiration = true" :key="`updateFirstExpiration_${update}_${firstExpiration}`"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidFirstExpiration || !showErrorFirstExpiration }" >
              <i class="fas fa-exclamation-circle"></i> {{ lastOfMonth && firstExpiration ? "Il giorno selezionato non è l'ultimo del mese." : 'Inserisci una data valida'}}
              <button v-if="lastOfMonth && firstExpiration" class="d-inline font-weight-bolder no-btn p-0" @click.prevent="setLastDayOfMonth()">Clicca qui per reimpostare</button>
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mb-4 justify-content-start">
        <div class="col-4">
          <b-form-checkbox v-model="lastOfMonth" name="input-last-of-month">
            Imposta scadenze a fine mese
          </b-form-checkbox>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-12">
          <CreateInstalmentsTable :key="`instalments_table_${update}`" :instalments="instalments" :loadingInstalments="loadingInstalments" :showErrors="showErrorTable" @openEditInstalmentPopup="triggerEmitEdit" @openDeleteInstalmentPopup="triggerEmitDelete" @backup="backupData" @validDates="setValidDates" @validTypes="setValidTypes" @validAmounts="setValidAmounts"/>
        </div>
      </div>
      <custom-button
          ref="submit"
          :isLoading="loading"
          :label="loan ? 'Salva' : 'Aggiungi'"
          class="w-100"
          @click.prevent.native="onSubmit"
        />

      <ErrorCard v-if="errorMessage">
        <template #message >
          {{ errorMessage }}
        </template>
      </ErrorCard>
    </form>

  </div>
</template>

<script>
import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import CreateInstalmentsTable from '@/views/components/CreateInstalmentsTable.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import DatePicker from '@/views/components/DatePicker.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';

import { BFormGroup, BFormRadioGroup, BFormRadio, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isCurrency, isDate, isNumber, isValidYear } from '@/helpers/formValidation.js';
import { toFormattedDate, toStandardDate } from '@/helpers/dates.js';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    banks: Array,
    companyId: String,
    propInstalments: Array,
    instalmentToEdit: Object,
    instalmentToDelete: Object,
    loan: Object,
    setup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BankSelect,
    'custom-button': Button,
    DatePicker,
    ErrorCard,
    CreateInstalmentsTable,
    CurrencyInput,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormRadio,
  },
  watch: {
    loan () {
      this.updateData();
    },
    propInstalments () {
      this.updateData();
    },
    watchedData: {
      handler () {
        if (this.isValidForm) {
          this.calculateInstalments();
        }
      },
      deep: true,
    },
    instalmentToEdit () {
      if (this.instalmentToEdit) {
        this.updateInstalment(this.instalmentToEdit);
      }
    },
    instalmentToDelete () {
      if (this.instalmentToDelete) {
        this.deleteInstalment(this.instalmentToDelete);
      }
    },
    lastOfMonth () {
      if (this.lastOfMonth) {
        this.setLastDayOfMonth();
        this.showErrorFirstExpiration = true;
      }
    },
    firstExpiration () {
      if (this.lastOfMonth) {
        this.showErrorFirstExpiration = true;
      }
    },
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
      update: 0,

      showErrorBank: false,
      showErrorName: false,
      showErrorAmount: false,
      showErrorDate: false,
      showErrorInstalmentFrequency: false,
      showErrorInstalments: false,
      showErrorFirstExpiration: false,
      showErrorTable: false,

      loading: false,
      loadingInstalments: false,
      errorMessage: '',

      // Form
      bankAccount: null,
      name: '',
      granted: false,
      amount: null,
      releaseDate: null,
      instalmentFrequency: null,
      numberOfInstalments: null,
      firstExpiration: null,
      lastOfMonth: false,

      // Table
      instalments: [],
      oldInstalments: [],

      allDatesAreValid: true,
      allTypesAreValid: true,
      allAmountsAreValid: false,
    };
  },
  computed: {
    isValidData () {
      return this.isValidForm && this.allDatesAreValid && this.allTypesAreValid && this.allAmountsAreValid;
    },
    watchedData () {
      return [this.granted, this.amount, this.releaseDate, this.instalmentFrequency, this.numberOfInstalments, this.firstExpiration, this.lastOfMonth];
    },
    isValidForm () {
      return this.isValidBank && this.isValidName && (this.granted || (this.isValidAmount && this.isValidDate)) && this.isValidInstalmentFrequency && this.isValidNumberOfInstalments && this.isValidFirstExpiration;
    },
    isValidBank () {
      return isFilled(this.bankAccount);
    },
    isValidName () {
      return isFilled(this.name);
    },
    isValidAmount () {
      return isFilled(this.amount) && isCurrency(this.amount);
    },
    isValidDate () {
      return this.granted || isFilled(this.releaseDate);
    },
    isValidInstalmentFrequency () {
      return isFilled(this.instalmentFrequency);
    },
    isValidNumberOfInstalments () {
      return isFilled(this.numberOfInstalments) && isNumber(this.numberOfInstalments) && this.numberOfInstalments <= 350;
    },
    isValidFirstExpiration () {
      return isDate(this.firstExpiration) && isValidYear(this.firstExpiration, 2000, 2500) && this.isValidLastDay(this.firstExpiration);
    },
  },
  methods: {
    backupData () {
      this.oldInstalments = this.instalments.filter(obj => obj.amount !== null || obj.isPaid === true || obj.pre === true);
    },
    calculateInstalments () {
      this.loadingInstalments = true;

      const piano = {
        importo: this.amount,
        frequenzaRate: this.instalmentFrequency,
        tipoPianoId: 0,
        nbRate: this.numberOfInstalments,
        primaScadenza: this.firstExpiration,
        fineMese: this.lastOfMonth,
      };

      this.$api.generateInstalmentLoan(piano)
        .then((res) => {
          const newInstalments = res.data.rate;
          if ((JSON.stringify(this.instalments)) !== '[]') {
            newInstalments.map(obj => {
              const res = this.oldInstalments?.find(obj2 => obj2.nRata === obj.nRata);
              if (res) {
                obj.amount = res.amount;
                obj.isPaid = res.isPaid;
                obj.pre = res.pre;
              }
            });
            this.$log.debug(newInstalments);
          }

          this.instalments = newInstalments;
          this.update++;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loadingInstalments = false;
        });
    },
    getBankById (bankId) {
      return this.banks?.find(bank => bank.id === bankId);
    },
    onSubmit: function () {
      this.errorMessage = '';
      if (this.isValidData) {
        this.loading = true;
        if (this.granted) {
          this.amount = null;
          this.releaseDate = null;
        }
        this.instalments.map(obj => { obj.amount = parseFloat(obj.amount); });

        if (this.setup) {
          const piano = Object.assign({}, {
            frequenzaRate: this.instalmentFrequency,
            tasso: this.rate,
            tipoPianoId: 0,
            fineMese: this.lastOfMonth,
            nbRate: this.numberOfInstalments,
            primaScadenza: this.firstExpiration,
            nbRatePreammortamento: null,
            primaScadenzaPreammortamento: null,
            iva: null,
            maxiRataIniziale: null,
            maxiRataFinale: null,
            rate: this.instalments,
          });

          this.$log.debug(piano);
          this.$log.debug(Object.assign({}, piano));

          const loan = Object.assign({}, {
            bankAccount: this.bankAccount,
            label: this.name,
            isPaid: this.granted,
            isCalculated: false,
            importo: this.amount,
            paidDate: this.releaseDate,
            piano: Object.assign({}, piano),
          });

          this.$log.debug(loan);

          return this.$emit('save', loan);
        }

        this.$api.createLoan(
          this.companyId,
          this.name,
          this.bankAccount.id, // to do check id
          this.granted, // isPaid
          false, // isCalculated
          this.amount,
          this.releaseDate, // (opzionale) paidDate
          this.instalmentFrequency, // (opzionale) frequenzaRate
          null, // this.rate (tasso
          null, // tipoPianoId
          this.numberOfInstalments, // nbRate (ammortamento),
          this.firstExpiration, // primaScadenza (ammortamento),
          null, // nbRatePreammortamento
          null, // primaScadenzaPreammortamento,
          this.lastOfMonth,
          null, // iva
          null, // maxiRataIniziale o anticipo
          null, // maxiRataFinale o riscatto
          this.instalments, // rate (manuale)
          this.loan ? this.loan.id : null, // id
        )
          .then((res) => {
            this.$log.debug(res);
            const loanId = res.data.id;
            if (loanId) {
              this.$router.replace({ name: 'loanDetails', params: { id: loanId } });
            }
            this.$emit('save');
          })
          .catch((e) => {
            this.$log.error(e);
            this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = true;
        if (!this.isValidName) {
          this.showErrorName = true;
        } else if (!this.isValidBank) {
          this.showErrorBank = true;
        } else if (!this.isValidAmount && !this.granted) {
          this.showErrorAmount = true;
        } else if (!this.isValidDate && !this.granted) {
          this.showErrorDate = true;
        } else if (!this.isValidInstalmentFrequency) {
          this.showErrorInstalmentFrequency = true;
        } else if (!this.isValidNumberOfInstalments) {
          this.showErrorInstalments = true;
        } else if (!this.isValidFirstExpiration) {
          this.showErrorFirstExpiration = true;
        } else if (!this.isValidData) {
          this.showErrorTable = true;
        } else if (!this.isValidInstalmentFrequency) {
          this.showErrorInstalmentFrequency = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    setDate (newDate) {
      this.releaseDate = newDate;
    },
    setDate2 (newDate) {
      this.firstExpiration = newDate;
    },
    setValidDates (bool) {
      this.allDatesAreValid = bool;
    },
    setValidTypes (bool) {
      this.allTypesAreValid = bool;
    },
    setValidAmounts (bool) {
      this.allAmountsAreValid = bool;
    },
    toFormattedDate (date) {
      return toFormattedDate(date);
    },
    triggerEmitEdit (item) {
      this.$emit('openEditInstalmentPopup', item);
    },
    triggerEmitDelete (item) {
      this.$emit('openDeleteInstalmentPopup', item);
    },
    updateInstalment (item) {
      const i = item.nRata - 1;
      Object.assign(this.instalments[i], this.instalmentToEdit);
      if (i === 0) {
        this.firstExpiration = this.instalments[0].date; // updates form value
      }
      this.backupData();
    },
    deleteInstalment (item) {
      const newArray = this.instalments.filter(obj => obj.nRata !== item.nRata);
      let z = 1;
      for (const obj of newArray) {
        obj.nRata = z;
        z++;
      };

      this.instalments = newArray;
      this.numberOfInstalments = this.instalments.length; // updates form value
      this.backupData();
    },
    updateData () {
      // this.bank = this.getBankById(this.loan.bankId);
      this.bankAccount = this.loan?.bank ? this.loan?.bank : this.loan.bankAccount;
      this.name = this.loan.label;
      this.amount = this.loan.importo;
      this.releaseDate = this.loan.paidDate;
      this.granted = Boolean(!this.loan.importo);
      this.numberOfInstalments = this.loan.piano.nbRate;
      this.instalmentFrequency = this.loan.piano.frequenzaRate;
      this.firstExpiration = this.loan.piano.primaScadenza;
      this.instalments = this.propInstalments ? this.propInstalments : (this.loan.piano.rate ? this.loan.piano.rate : this.loan.rate);
      this.oldInstalments = this.propInstalments ? this.propInstalments : (this.loan.piano.rate ? this.loan.piano.rate : this.loan.rate);
      this.rate = this.loan.piano.tasso;
      this.lastOfMonth = this.loan.piano.fineMese;
      this.update++;
    },
    updateBank (bank) {
      this.bankAccount = bank;
    },
    setLastDayOfMonth () {
      if (this.firstExpiration) {
        const date = new Date(this.firstExpiration);
        const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.firstExpiration = toStandardDate(newDate);
        return ('0' + newDate.getDate()).slice(-2);
      }
    },
    toStandardDate (s) {
      return toStandardDate(s);
    },
    isValidLastDay (date) {
      if (this.lastOfMonth) {
        const newDate = new Date(date);
        const date2 = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 1);

        // this.$log.debug('is last day ' + newDate.getMonth() !== date2.getMonth());
        return newDate.getMonth() !== date2.getMonth();
      }
      return true;
    },
  },
  mounted () {
    if (this.loan) {
      this.updateData();
    }
  },
};
</script>

<style lang="scss">
</style>
