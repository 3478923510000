<template>
    <div>
        <form
          @submit.prevent="onSubmit"
          class="text-start"
          novalidate
          >
          <h2 class="h4 mb-4">{{ Boolean(user) ? 'Modifica' : 'Aggiungi'}} utente</h2>

          <div class="row">
            <div class="col-8">
              <b-form-group
                class="mb-3"
                id="input-group-email"
                label="E-mail"
                label-for="input-email"
                :class="{ invalid: !isValidEmail && showErrorEmail,  disabled: Boolean(user)}"
              >
                <b-form-input
                  id="input-email"
                  v-model="email"
                  type="text"
                  @blur="showErrorEmail = true"
                  :disabled="Boolean(user)"
                ></b-form-input>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidEmail || !showErrorEmail }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un'email valida
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group
                class="mb-3"
                id="input-group-role"
                label="Ruolo"
                label-for="input-role"
                :class="{ invalid: !isValidRole && showErrorRole }"
              >
                <b-form-select
                v-model="role"
                :options="options"
                ></b-form-select>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidRole || !showErrorRole }" >
                    <i class="fas fa-exclamation-circle"></i> Seleziona il ruolo
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>

          <custom-button
            :isLoading="loading"
            :label="user ? 'Salva' : 'Aggiungi'"
            :disabled="!isValidForm"
            class="w-100"
            @click.prevent.native="onSubmit"
          />

          <ErrorCard v-if="errorMessage">
            <template #message >
              {{ errorMessage }}
            </template>
          </ErrorCard>
        </form>
    </div>
</template>

<script>
import Button from '@/views/components/Button.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';

import { BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isEmail } from '@/helpers/formValidation.js';

export default {
  props: {
    user: Object,
    options: Array,
    companyId: String,
  },
  components: {
    'custom-button': Button,
    ErrorCard,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
  },
  data () {
    return {
      loading: false,
      errorMessage: '',

      showErrorEmail: false,
      showErrorRole: false,

      // Form
      email: '',
      role: null,
      password: '',
      password2: '',
    };
  },
  computed: {
    isValidForm () {
      return this.isValidEmail && this.isValidRole;
    },
    isValidEmail () {
      return isFilled(this.email) && isEmail(this.email);
    },
    isValidRole () {
      return isFilled(this.role);
    },
  },
  methods: {
    onSubmit: function () {
      this.errorMessage = '';
      if (this.isValidForm) {
        this.loading = true;
        this.errorMessage = '';
        const user = Object.assign({}, { email: this.email, role: this.role, id: this.user?.id ? this.user?.id : null, companyId: this.companyId });
        this.$api.reserveUser(user)
          .then((res) => {
            this.$log.debug(res);
            const msg = this.user ? 'Utente aggiornato correttamente' : 'Utente creato correttamente';
            this.$emit('onSaved', msg);
          })
          .catch((e) => {
            this.$log.error(e);
            this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = true;
        if (!this.isValidName) {
          this.showErrorName = true;
        } else if (!this.isValidSurname) {
          this.showErrorSurname = true;
        } else if (!this.isValidEmail) {
          this.showErrorEmail = true;
        } else if (!this.isValidRole) {
          this.showErrorRole = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    setValues () {
      this.name = this.user.name || '';
      this.surname = this.user.surname || '';
      this.email = this.user.email || '';
      this.role = this.user.role || null;
    },
  },
  mounted () {
    if (this.user) {
      this.setValues();
    }
  },
};
</script>
