var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"step-2",staticClass:"setup-bank-accounts"},[(_vm.isLoadingPage)?_c('div',{staticClass:"text-center m-5"},[_c('Loader2')],1):_c('div',[_c('Alert',{attrs:{"status":"primary","fontSize":"lg"}},[_vm._v(" Per sfruttare al meglio NinaFin, imposta un colore e un alias diverso per ogni conto aggiunto. ")]),_c('h5',[_vm._v("I tuoi conti")]),_c('div',{staticClass:"row mb-4 g-2"},[_c('div',{staticClass:"col-4 py-3"},[_c('div',{staticClass:"add-button",class:{invalid: _vm.showErrorBankAccounts && _vm.lengthOfArray < 1},attrs:{"type":"button"},on:{"click":function($event){_vm.togglePopup('upsertPopup');
            _vm.selectedItem = null;}}},[_c('i',{staticClass:"fas fa-plus-circle pb-2"}),_c('span',[_vm._v("Aggiungi conto")])])]),_vm._l((_vm.form.bankAccounts),function(account,i){return _c('div',{key:`account_${i}_${account.alias}_${_vm.lengthOfArray}_${account.isMain}_${_vm.update}`,staticClass:"col-4 py-3"},[_c('div',{staticClass:"account-card text-center",class:[`bank-variant-${account.colour}-light`, {'flag-auto': account.fabrickId !== null}]},[_c('img',{staticClass:"bank-logo md mb-2",attrs:{"src":_vm.getLogo(account.bankDetail?.id)}}),_c('h6',{staticClass:"text-center mb-3",class:`text-bank-variant-${account.colour}`},[_vm._v(" "+_vm._s(account.alias)+" ")]),_c('span',{staticClass:"iban-tag",class:`bank-variant-${account.colour}`},[_vm._v("IBAN")]),_c('div',{staticClass:"iban mt-1"},[_vm._v(_vm._s(account.iban))]),_c('div',{staticClass:"row icons d-flex justify-content-between"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"star",on:{"click":function($event){return _vm.setMainBank(account)}}},[(account.isMain === true)?_c('i',{staticClass:"fas fa-star",class:`text-bank-variant-${account.colour}`}):_c('i',{staticClass:"far fa-star",class:`text-bank-variant-${account.colour}`})])]),_c('div',{staticClass:"col-auto"},[_c('button',{on:{"click":function($event){_vm.togglePopup('upsertPopup');
                  _vm.selectedItem = account;
                  _vm.index = i;}}},[_c('i',{staticClass:"fas fa-pen",class:`text-bank-variant-${account.colour}`})]),_c('button',{on:{"click":function($event){_vm.togglePopup('deletePopup');
                  _vm.selectedItem = account;}}},[_c('i',{staticClass:"fas fa-trash",class:`text-bank-variant-${account.colour}`})])])])])])})],2),(_vm.fabrickAccounts?.length > 0)?_c('h5',[_vm._v("Conti da configurare")]):_vm._e(),_c('div',{staticClass:"row mb-4 g-2"},_vm._l((_vm.fabrickAccounts),function(account,i){return _c('div',{key:`account_${i}_${account.alias}_${_vm.lengthOfArray}_${account.isMain}`,staticClass:"col-4 py-3"},[_c('div',{staticClass:"account-card to-configure-card text-center",class:[`bank-variant-${account.colour}-light`]},[_c('span',{staticClass:"to-configure"},[_vm._v("da configurare")]),_c('img',{staticClass:"bank-logo md mb-2",attrs:{"src":_vm.getLogo(account.bankDetail?.id)}}),_c('h6',{staticClass:"text-center mb-3",class:`text-bank-variant-${account.colour}`},[_vm._v(" "+_vm._s(account.alias)+" ")]),_c('span',{staticClass:"iban-tag",class:`bank-variant-${account.colour}`},[_vm._v("IBAN")]),_c('div',{staticClass:"iban mt-1"},[_vm._v(_vm._s(account.iban))]),_c('div',{staticClass:"row icons d-flex justify-content-between"},[_c('div',{staticClass:"col-auto"}),_c('div',{staticClass:"col-auto"},[_c('button',{on:{"click":function($event){_vm.togglePopup('upsertPopup');
                  _vm.selectedItem = account;
                  _vm.index = i;}}},[_c('i',{staticClass:"fas fa-pen",class:`text-bank-variant-${account.colour}`})])])])])])}),0),_c('Popup',{ref:"upsertPopup",attrs:{"errorMessage":_vm.errorMessage},on:{"beforeClosing":function($event){_vm.togglePopup('upsertPopup'); _vm.selectedItem = null; _vm.index = null; _vm.errorMessage = '';}},scopedSlots:_vm._u([{key:"fullContent",fn:function(){return [_c('UpsertBankAccount',{attrs:{"banks":_vm.banks,"bankAccounts":_vm.bankAccounts,"settings":true,"account":_vm.selectedItem,"index":_vm.index},on:{"addBankAccount":_vm.addBankAccount,"editBankAccount":_vm.editBankAccount}})]},proxy:true}])}),_c('Popup',{ref:"deletePopup",staticClass:"md",on:{"closePopup":function($event){return _vm.togglePopup('deletePopup')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Sei sicuro di voler eliminare? ")]},proxy:true},{key:"text",fn:function(){return [_vm._v(" Il conto "),_c('b',[_vm._v(_vm._s(_vm.selectedItem.alias))]),_vm._v(" con iban "),_c('b',[_vm._v(_vm._s(_vm.selectedItem.iban))]),_vm._v(" verrà rimosso dall'elenco ")]},proxy:true},{key:"button",fn:function(){return [_c('custom-button',{staticClass:"w-100 mt-4",attrs:{"label":"Elimina"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.deleteBankAccount(_vm.selectedItem)}}})]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }