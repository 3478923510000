<template>
  <div class="layout-basic">
    <div class="d-flex h-100">
      <router-view class="flex-grow-1 overflow-auto" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'LayoutBasic',
  data () {
    return {

    };
  },
};
</script>

<style lang="scss">
</style>
