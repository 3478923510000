<template>
  <div class="bg-fin" ref="help">
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid w-auto m-4">

      <h4 class="mb-4">Assistenza</h4>
      <div class="pretty-card">
        <h5 class="mb-4">Richiesta di Assistenza</h5>

        <div class="row">
          <div class="col-6">
            <b-form-group
                class="mb-4"
                id="input-group-priority"
                label="Priorità"
                label-for="input-priority"
                :class="{ invalid: !isValidSubject && showErrorSubject }"
            >
                <b-form-select
                id="input-priority"
                :options="['Alta', 'Media', 'Bassa']"
                v-model="priority"
                ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
                class="mb-4"
                id="input-group-company"
                label="Azienda"
                label-for="input-company"
            >
                <vue-select id="input-company" :value="company" :options="companies" label="name" :clearable="false">
                  <template v-slot:no-options>
                      <template>
                      <div class="text-center py-2">
                          Nessuna azienda trovata
                      </div>
                      </template>
                  </template>
                </vue-select>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group
                class="mb-3"
                id="input-group-subject"
                label="Oggetto"
                label-for="input-subject"
                :class="{ invalid: !isValidSubject && showErrorSubject }"
            >
                <b-form-input
                id="input-subject"
                v-model="subject"
                type="text"
                @blur="showErrorSubject = true"
                ></b-form-input>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidSubject || !showErrorSubject }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido
                </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group
                id="input-group-description"
                label="Descrizione del problema"
                label-for="input-description"
                :class="{ invalid: !isValidDescription && showErrorDescription }"
            >
                <b-form-textarea
                id="input-description"
                v-model="description"
                @blur="showErrorDescription = true"
                ></b-form-textarea>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidDescription || !showErrorDescription }" >
                    <i class="fas fa-exclamation-circle"></i> Puoi usare al massimo 500 caratteri
                </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <div class="row d-flex justify-content-end">
          <div class="col-4">
            <custom-button
              :isLoading="loading"
              :disabled="!isValidForm"
              label="Invia"
              class="w-100 mt-4"
              @click.prevent.native="onSubmit"
            />
          </div>
        </div>

        <ErrorCard v-if="errorMessage">
            <template #message >
            {{ errorMessage }}
            </template>
        </ErrorCard>
      </div>

      <Snackbar ref="snackbarHelp" :text="snackbarText" />
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BFormTextarea, BFormSelect, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled } from '@/helpers/formValidation.js';

import Button from '@/views/components/Button.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Select from 'vue-select';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormInvalidFeedback,
    'custom-button': Button,
    ErrorCard,
    Loader,
    Snackbar,
    'vue-select': Select,
  },
  data () {
    return {
      companies: [],
      isLoadingPage: true,
      loading: false,
      snackbarText: '',
      errorMessage: '',

      // Form
      company: null,
      priority: 'Media',
      subject: '',
      description: '',

      showErrorSubject: false,
      showErrorDescription: false,
    };
  },
  computed: {
    isValidForm () {
      return this.isValidSubject && this.isValidDescription;
    },
    isValidSubject () {
      return isFilled(this.subject);
    },
    isValidDescription () {
      return isFilled(this.subject) && this.subject.length < 501;
    },
  },
  methods: {
    getMe () {
      const me = this.$oauth2.me;
      this.companies = me.companies || [];
      this.company = me.companies[0];
      this.isLoadingPage = false;
    },
    onSubmit () {
      this.loading = true;
      this.$log.debug('submit');
      /*
      this.$api.updateSalary(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          this.snackbarText = 'Richiesta di assistenza inoltrata correttamente';
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
          this.resetData();
        });
      */
      this.loading = false;
    },
    resetData () {
      this.priority = 'Media';
      this.subject = '';
      this.description = '';
    },
  },
  mounted () {
    this.getMe();
  },
};
</script>

<style lang="scss">
.employees {
  background: $primary-lighter;
  .box.primary {
    background: $primary-lighter;
  }
}
</style>
