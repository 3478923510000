<template>
  <DatePicker ref="datepicker" id="input-date" v-model="date" color="indigo" class="datepicker" @input="setValue" :locale="'ita'" trim-weeks :attributes="attributes" :typeable="true">
    <template v-slot="{ inputValue, togglePopover, inputEvents }">
      <div class="flex items-center">
        <b-input-group>
          <b-input-group-prepend>
            <button class="prepend form-control" @click.prevent="togglePopover(); $emit('input')">
              <i class="far fa-calendar"></i>
            </button>
          </b-input-group-prepend>
          <input
            @blur="$emit('blur')"
            :value="inputValue"
            v-on="inputEvents"
            class="form-control custom-border"
          />
        </b-input-group>
      </div>
    </template>
    <template v-slot:footer>
      <div class="row header p-1">
        <div v-if="lastMonthButtons" class="col-3 p-1">
          <button button class="btn btn-fin btn-fin-sm w-100 h-100" @click.prevent="moveToLastWeek">Settimana scorsa</button>
        </div>
        <div v-if="lastMonthButtons" class="col-3 p-1">
          <button button class="btn btn-fin btn-fin-sm w-100 h-100" @click.prevent="moveToLastMonth">Mese scorso</button>
        </div>
        <div class="col-3 p-1">
          <button class="btn btn-fin btn-fin-sm w-100 h-100" @click.prevent="moveToToday">Oggi</button>
        </div>
        <div class="col-3 p-1">
          <button button class="btn btn-fin btn-fin-sm w-100 h-100" @click.prevent="moveToTomorrow">Domani</button>
        </div>
        <div v-if="!lastMonthButtons" class="col-3 p-1">
          <button button class="btn btn-fin btn-fin-sm w-100 h-100" @click.prevent="moveToNextWeek">+1 Settimana</button>
        </div>
        <div v-if="!lastMonthButtons" class="col-3 p-1">
          <button button class="btn btn-fin btn-fin-sm w-100 h-100" @click.prevent="moveToNextMonth">+1 Mese</button>
        </div>
      </div>
    </template>
  </DatePicker>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { BInputGroup, BInputGroupPrepend } from 'bootstrap-vue';
import { toStandardDate } from '@/helpers/dates.js';

export default {
  props: {
    value: [Date, String],
    lastMonthButtons: Boolean,
  },
  components: {
    DatePicker,
    BInputGroup,
    BInputGroupPrepend,
  },
  computed: {
    isValidDate () {
      return true;
    },
  },
  data () {
    return {
      date: this.value ? new Date(this.value) : null,
      attributes: [
        { dot: { style: { backgroundColor: '#161F5F' } }, dates: new Date() },
      ],
    };
  },
  methods: {
    setValue (value) {
      this.$emit('change');
      this.$emit('setValue', toStandardDate(this.date));
    },
    moveToToday () {
      this.date = new Date();
      this.$refs.datepicker.move(this.date);
    },
    moveToTomorrow () {
      const date = new Date();
      this.date = date.setDate(date.getDate() + 1);
      this.$refs.datepicker.move(date);
    },
    moveToNextWeek () {
      const date = new Date();
      this.date = date.setDate(date.getDate() + 7);
      this.$refs.datepicker.move(date);
    },
    moveToNextMonth () {
      const date = new Date();
      // if last day of month move to last day of next month?
      this.date = date.setMonth(date.getMonth() + 1);
      this.$refs.datepicker.move(date);
    },
    moveToLastWeek () {
      const date = new Date();
      this.date = date.setDate(date.getDate() - 7);
      this.$refs.datepicker.move(date);
    },
    moveToLastMonth () {
      const date = new Date();
      this.date = date.setMonth(date.getMonth() - 1);
      this.$refs.datepicker.move(date);
    },
    toStandardDate (s) {
      return toStandardDate(s);
    },
  },
};
</script>

<style lang="scss">
$height-of-row: 55px;
$font-family-titles: sans-serif !default;

.datepicker {
  position: relative;
  .vc-container {
    width: 350px!important;
    overflow: hidden!important;
    font-family: $font-family-sans-serif!important;
    color: $body-color;

    .vc-title {
      color: $primary;
    }

    .vc-weekday, .vc-arrow {
      color: $primary-mid;
    }

    .vc-arrow {
      border-radius: 100%;

      &:hover {
        background: $primary-lighter;
      }
    }

    .vc-dots {
      display: none;
    }
  }
  .vc-pane-container {
    margin-top: $height-of-row;
    overflow: visible!important;
  }
  .vc-highlight {
    background: $primary!important;
  }
  .prepend {
    border-radius: 10px 0px 0px 10px!important;
    z-index: +1;
    &:active, &.selected {
      background: rgba($primary-mid, 0.6)!important;
    }
  }
  .bar.style {
    background: red!important;
  }
  .custom-border {
    border-radius: 0px 10px 10px 0px!important;
  }
  .header {
    position: absolute;
    top: -$height-of-row!important;
    left: 0;
    right: 0;
    margin: auto;
    .btn {
      min-height: 40px;
    }
  }
}
</style>
