<template>
  <div ref="profile">

    <h5 class="mb-4">Profilo</h5>
    <div class="row mb-5">
      <div class="col-3">
        <label for="expected-amount">Utente</label>
        <div>{{`${me?.name} ${me?.surname}`}}</div>
      </div>
      <div class="col-3">
        <label for="expected-amount">Email</label>
        <div>{{`${me?.email}`}}</div>
      </div>
      <div class="col-3">
        <label for="expected-amount">Data di registrazione</label>
        <div>{{toFullHumanDate(me?.createdAt)}}</div>
      </div>
    </div>

    <h5 class="mb-4">Dettagli abbonamento</h5>
    <div class="row">
      <div class="col-6">
        <div class="row mb-5">
          <div class="col">
            <label for="expected-amount">Prossima scadenza</label>
            <div>{{ expirationDate ? toFullHumanDate(expirationDate) : '' }}</div>
          </div>
          <div class="col d-flex align-items-center">
            <button class="btn btn-fin" @click="goToCheckout" >Rinnova abbonamento</button>
          </div>
        </div>
      </div>
      <div disabled class="col-6" v-if="renewalEnabled">
        <label for="autorenewal">Rinnovo automatico</label>
        <b-form-checkbox :disabled="!renewalEnabled" v-model="autoRenewal" name="check-button" switch @change="editAutorenewal" id="autorenewals">
          <b>{{ autoRenewal ? 'Attivo' : 'Non attivo' }}</b>
        </b-form-checkbox>
        <template v-if="renewalEnabled && autoRenewal">
        <label for="periodrenewal">Periodo</label>
        <b-form-checkbox v-model="periodRenewal" name="check-button" switch @change="editPeriodRenewal" id="periodRenewal" :disabled="!(renewalEnabled && autoRenewal)">
          <b>{{ periodRenewal ? 'Annuale' : 'Mensile' }}</b>
        </b-form-checkbox>
        </template>
        </div>
    </div>

    <h5 class="mb-4">Gestione consensi</h5>
    <div v-if="banks?.length > 0" class="row mb-5">
      <div v-for="(bank, i) in banks" :key="`bank_${i}`" class="col-4 mb-4">
        <div class="box primary h-100">

          <div class="row gap mb-4">
            <div class="col-12">
                <div class="row">
                  <div class="col d-flex align-items-center">
                    <h6 class="m-0">
                      {{bank.alias}}
                    </h6>
                  </div>
                  <div class="col-12">
                    <small class="mb-4">{{bank.iban}}</small>
                  </div>
                </div>
            </div>

            <div class="col-12">
              <div class="pretty-label">
                Scadenza
              </div>
                <div>
                  {{toFullHumanDate(bank.consentExpireAt)}}
                </div>
            </div>

            <div class="col-12">
              <div v-if="getDaysTo(bank.consentExpireAt) > 0">
                <div class="pretty-label">
                Rinnova entro
                </div>
                <div class="tag">
                  {{getDaysTo(bank.consentExpireAt)}}
                </div>
                giorni
              </div>
              <div v-else class="text-danger">
                <div class="text-danger pretty-label">
                  Scaduto
                </div>
                <div class="tag red">
                  {{Math.abs(getDaysTo(bank.consentExpireAt))}}
                </div>
                giorni fa
              </div>
            </div>
          </div>

          <div>
            <div class="text-center">
              <button class="btn btn-fin" :class="{'tag red' :getDaysTo(bank.consentExpireAt) < 0}" @click="goToFabrick" >Rinnova consenso</button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div v-else class="p-3 mb-4 text-center">
      Non ci sono conti correnti associati a Fabrick
    </div>

  </div>
</template>

<script>
import { toFullHumanDate } from '@/helpers/dates.js';

export default {
  props: {
    banks: Array,
  },
  data () {
    return {
      me: {},
      companyId: this.$oauth2.me.companies[0].id || null,
      snackbarText: '',
      expirationDate: null,

      autoRenewal: false,
      oldAutoRenewal: false,
      periodRenewal: false,
      oldPeriodRenewal: false,
      errorMessage: '',

      renewalEnabled: false,
    };
  },
  methods: {
    getMeInfo () {
      this.me = this.$oauth2.me;
    },
    toFullHumanDate (s) {
      return toFullHumanDate(s);
    },
    getDaysTo (d) {
      const date = new Date(d);
      const today = new Date();
      const diff = (date - today) / (60 * 60 * 24 * 1000);
      return Math.floor(diff);
    },
    goToCheckout () {
      this.$router.push({ name: 'checkout' });
    },
    editAutorenewal: function () {
      this.errorMessage = '';
      this.$api.editBillingInformations(null, this.autoRenewal, this.companyId)
        .then((res) => {
          this.$emit('openSnackbar', 'Informazioni aggiornate correttamente');
          this.oldAutoRenewal = this.autoRenewal;
        })
        .catch((e) => {
          this.autoRenewal = !this.autoRenewal;
          this.$log.error(e);
          this.errorMessage = "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editPeriodRenewal: function () {
      this.errorMessage = '';
      this.$api.editBillingInformations(null, null, this.companyId, this.periodRenewal)
        .then((res) => {
          this.$emit('openSnackbar', 'Informazioni aggiornate correttamente');
          this.oldPeriodRenewal = this.periodRenewal;
        })
        .catch((e) => {
          this.periodRenewal = !this.periodRenewal;
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToFabrick () {
      this.loading = true;
      this.$api.fabrickUpdateAggregation(null)
        .then((res) => {
          window.location.href = res.data.redirect;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  beforeMount () {
    this.getMeInfo();
  },
  mounted () {
    this.$root.$on('setExpiration', (expiration) => {
      this.expirationDate = expiration;
    });
    this.$root.$on('setAutoRenewal', (autorenewal) => {
      this.autoRenewal = autorenewal;
    });
    this.$root.$on('setPeriodRenewal', (periodrenewal) => {
      this.periodRenewal = periodrenewal;
    });
    this.$root.$on('setRenewalEnabled', (renewalEnabled) => {
      this.renewalEnabled = renewalEnabled;
    });
  },
};
</script>

<style lang="scss">
.profile {
  background: $primary-lighter;
  .box.primary {
    background: $primary-lighter;
  }
}
</style>
