export default {
  props: {
    prependText: {
      type: String,
      default: null,
    },
    prependAction: {
      type: String,
      default: null,
    },
    appendText: {
      type: String,
      default: null,
    },
    appendAction: {
      type: String,
      default: null,
    },
  },
};
