<template>
  <div class="container-fluid w-auto p-0 m-4 alerts">
    <div v-for="(critical, i) in criticals" :key="`critical_${i}`" class="alert critical">
        <div class="row">
            <div class="col-auto d-flex align-items-center">
                <i class="fas fa-exclamation-triangle"></i>
            </div>
            <div class="col p-0 d-flex align-items-center">
                <p class="m-0">
                    <b>{{critical.title}}</b>
                    {{critical.body}}
                </p>
            </div>
            <div class="col-auto d-flex align-items-center">
                <a :href="critical?.actionLink" class="button">{{critical.actionTitle ? critical.actionTitle : 'Clicca qui'}}</a>
            </div>
        </div>
    </div>
    <div v-for="(warning, i) in warnings" :key="`warning_${i}`" class="alert warning">
        <div class="row">
            <div class="col-auto d-flex align-items-center">
                <i class="fas fa-exclamation-triangle"></i>
            </div>
            <div class="col p-0 d-flex align-items-center">
                <p class="m-0">
                    <b>{{warning.title}}</b>
                    {{warning.body}}
                </p>
            </div>
            <div class="col-auto d-flex align-items-center">
                <a :href="warning?.actionLink" class="button">{{warning.actionTitle ? warning.actionTitle : 'Clicca qui'}}</a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      warnings: [],
      criticals: [],
      ads: [],
    };
  },
  mounted () {
    this.$root.$on('otherNotifications', (warnings, criticals, ads) => {
      this.warnings = warnings;
      this.criticals = criticals;
      this.ads = ads;
    });
  },
};
</script>

<style lang="scss">
.alerts {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .alert {
        border-radius: 10px;
        color: white;
        padding: 15px;
        margin: 0;
        cursor: default;
        &.warning {
            background: $warning;
            .button {
                color: $warning!important;
            }
        }
        &.critical {
            background: $danger;
            .button {
                color: $danger!important;
            }
        }
    }
    .button {
        background: white;
        border: none;
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        padding: 8px 15px;
        text-decoration: none!important;
        &:hover {
            filter: brightness(97%);
        }
    }
}
</style>
