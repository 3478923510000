export const monthsShort = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
export const monthsLong = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

export const monthNumberToLabel = (monthNumber, labels = null) => {
  if (!Number.isInteger(monthNumber)) {
    return '';
  }

  if (monthNumber < 1 || monthNumber > 12) {
    return '';
  }

  if (labels === null) {
    labels = monthsShort;
  }

  return labels[monthNumber - 1];
};

// to DD/MM/YYYY format
export const toFormattedDate = (date) => {
  if ((typeof date === 'string' || typeof date === 'object') && date !== null) {
    const x = new Date(date);
    return (
      ('0' + x.getDate()).slice(-2) +
      '/' +
      ('0' + (x.getMonth() + 1)).slice(-2) +
      '/' +
      x.getFullYear().toString()
    );
  }

  return undefined;
};

// to YYYY-MM-DD format
export const toStandardDate = (date) => {
  if (typeof date === 'string' || typeof date === 'object') {
    const x = new Date(date);
    return (
      x.getFullYear().toString() +
      '-' +
      ('0' + (x.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + x.getDate()).slice(-2)
    );
  }

  return undefined;
};

export const toFullHumanDate = (date) => {
  if (typeof date === 'string' || typeof date === 'object') {
    const x = new Date(date);
    const day = x.getDate();
    const month = monthsLong[x.getMonth()];
    const year = x.getFullYear().toString();

    return `${day} ${month} ${year}`;
    // return day + month + year;
  }

  return undefined;
};
