<template>
    <div class="pagamento_bonifico" ref="form" id="form">
        <form
          @submit.prevent="onSubmit"
          class="text-start"
          novalidate
          v-if="update > 0"
          >
          <h2 class="h4 mb-4">{{transfer ? 'Modifica' : 'Aggiungi'}}
            pagamento bonifico ricorrente</h2>

          <BankSelect ref="bankSelect" :selectedItem="bank" :label="'Banca*'" :banks="formattedBanks" @update="updateBank" @search:blur="showErrorBank = true" :class="{ invalid: !isValidBank && showErrorBank }" :key="update"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBank || !showErrorBank }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una banca
            </b-form-invalid-feedback>

          <b-form-group
            class="mb-3"
            id="input-group-counterpart"
            label="Beneficiario*"
            label-for="input-counterpart"
            :class="{ invalid: !isValidCounterpart && showErrorCounterpart }"
          >
            <b-form-input
              id="input-counterpart"
              v-model="counterpart"
              type="text"
              @blur="showErrorCounterpart = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidCounterpart || !showErrorCounterpart }" >
                <i class="fas fa-exclamation-circle"></i> Inserisci il beneficiario
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="d-flex row">
            <div class="col-6">
              <b-form-group
                class="mb-3"
                id="input-group-amount"
                label="Importo*"
                label-for="input-amount"
                :class="{ invalid: !isValidAmount && showErrorAmount }"
              >
                <b-input-group class="prepend">
                  <template #prepend>
                    <i class="fas fa-minus-circle red" />
                  </template>
                  <CurrencyInput id="input-amount" v-model="amount" @blur="formatAmount" :class="in_out === 'in' ? 'in' : 'out'" />
                </b-input-group>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmount || !showErrorAmount }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                class="mb-3"
                id="input-group-frequency"
                label="Frequenza*"
                label-for="input-frequency"
                :class="{ invalid: !isValidFrequency && showErrorFrequency }"
              >
                <b-form-select
                  id="input-frequency"
                  v-model="frequency"
                  type="number"
                  @blur="showErrorFrequency = true"
                >
                  <option :value="1">Mensile</option>
                  <option :value="2">Bimestrale</option>
                  <option :value="3">Trimestrale</option>
                  <option :value="6">Semestrale</option>
                  <option :value="12">Annuale</option>
                </b-form-select>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidFrequency || !showErrorFrequency }" >
                  <i class="fas fa-exclamation-circle"></i> Seleziona la frequenza
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <b-form-group
                class="mb-0"
                id="input-group-expiration"
                label="Data prima scadenza*"
                label-for="input-expiration"
                :class="{ invalid: !isValidExpiration && showErrorExpiration }"
              >
                <DatePicker id="input-expiration" :value="expiration" @setValue="setDate" @blur="showErrorExpiration = true" :key="`${lastOfMonth}_${update}`"/>
                <b-form-invalid-feedback class="fixed-height-feedback-2" :state="false" :class="{ invisible: isValidExpiration || !showErrorExpiration }" >
                  <i class="fas fa-exclamation-circle"></i> {{ !expiration ? 'Inserisci una data' : (lastOfMonth && !isExpirationLastDayOfMonth) ? "Il giorno selezionato non è l'ultimo del mese" : (isBeforeToday(expiration) ? 'La data non può essere antecedente a oggi' : 'Inserisci una data valida')}}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                class="mb-0"
                id="input-group-last-expiration"
                label="Data ultima scadenza"
                label-for="input-last-expiration"
                :class="{ invalid: !isValidLastExpiration && showErrorLastExpiration }"
              >
                <DatePicker id="input-last-expiration" :value="lastExpiration" @setValue="setDate2" @blur="showErrorLastExpiration = true" :key="`${lastOfMonth}_${update}`"/>
                <b-form-invalid-feedback class="fixed-height-feedback-2" :state="false" :class="{ invisible: isValidLastExpiration || !showErrorLastExpiration }" >
                  <i class="fas fa-exclamation-circle"></i> {{ !lastExpiration ? 'Inserisci una data valida' : lastOfMonth && !isLastExpirationLastDayOfMonth ? "Il giorno selezionato non è l'ultimo del mese." : !isValidDates ? "Inserisci una data successiva alla prima scadenza" : 'Inserisci una data valida'}}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>

          <div class="row mb-4 justify-content-start">
            <div class="col-12">
              <b-form-checkbox v-model="lastOfMonth" name="input-last-of-month">
                Imposta scadenze a fine mese
              </b-form-checkbox>
            </div>
          </div>

          <b-form-group
            class="mb-3"
            id="input-group-description"
            label="Causale*"
            label-for="input-description"
            :class="{ invalid: !isValidDescription && showErrorDescription }"
          >
            <textarea
              id="input-description"
              class="form-control"
              rows="3"
              v-model="description"
              @blur="showErrorDescription = true"
            ></textarea>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidDescription || !showErrorDescription }" >
              <i class="fas fa-exclamation-circle"></i>{{ description.length > 250 ? 'Puoi inserire fino a 250 caratteri' : 'Inserisci una descrizione' }}
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="row mb-4 justify-content-start">
            <div class="col-12">
              <b-form-checkbox v-model="addMonthToDescription" name="input-last-of-month" :disabled="frequency !== 1">
                Aggiungi mese e anno alla causale
              </b-form-checkbox>
            </div>
          </div>

          <custom-button
              :isLoading="loading"
              :label="transfer ? 'Salva' : 'Aggiungi'"
              class="w-100 mt-2"
              @click.prevent.native="onSubmit"
            />

          <div ref="errorCard">
            <ErrorCard v-if="errorMessage">
              <template #message >
                {{ errorMessage }}
              </template>
            </ErrorCard>
          </div>

        </form>
    </div>
</template>

<script>
import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import DatePicker from '@/views/components/DatePicker.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';

import { BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isCurrency } from '@/helpers/formValidation.js';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    in_out: String,
    banks: Array,
    companyId: String,
    transfer: Object,
  },
  components: {
    BankSelect,
    'custom-button': Button,
    CurrencyInput,
    DatePicker,
    ErrorCard,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
  },
  data () {
    return {
      update: 0,
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      showErrorBank: false,
      showErrorCounterpart: false,
      showErrorAmount: false,
      showErrorExpiration: false,
      showErrorLastExpiration: false,
      showErrorFrequency: false,
      showErrorDescription: false,
      loading: false,
      errorMessage: '',

      // Form
      bank: null,
      counterpart: '',
      amount: null,
      frequency: null,
      expiration: null,
      lastExpiration: null,
      lastOfMonth: '',
      description: '',
      addMonthToDescription: false,
      // sign: 'outcome',
    };
  },
  watch: {
    lastOfMonth () {
      if (this.lastOfMonth) {
        if (this.expiration) {
          this.setLastDayOfMonth();
        }
        if (this.lastExpiration) {
          this.setLastDayOfMonth2();
        }
        this.showErrorExpiration = true;
        // this.showErrorLastExpiration = true;
      }
    },
    errorMessage () {
      const ref = this.$parent.$refs.movementsWrapper;
      if (ref) {
        // to do: improve
        ref.scrollIntoView({ behavior: 'smooth', top: document.body.scrollHeight });
      } else {
        this.$log.debug('ref not found');
      }
    },
    frequency () {
      if (this.frequency !== 1) {
        this.addMonthToDescription = false;
      }
    },
  },
  computed: {
    formattedBanks () {
      return this.banks.filter(obj => obj.isCash !== true);
    },
    bankInfo () {
      return this.banks.find(obj => obj.name === this.bank);
    },
    isValidForm () {
      return this.isValidBank && this.isValidCounterpart && this.isValidAmount && this.isValidFrequency && this.isValidExpiration && this.isValidLastExpiration && this.isValidDescription;
    },
    isValidBank () {
      return isFilled(this.bank);
    },
    isValidCounterpart () {
      return isFilled(this.counterpart);
    },
    isValidAmount () {
      return isFilled(this.amount) && isCurrency(this.amount);
    },
    isValidFrequency () {
      return isFilled(this.frequency);
    },
    isValidExpiration () {
      return isFilled(this.expiration) && (!this.lastOfMonth || this.isExpirationLastDayOfMonth) && !this.isBeforeToday(this.expiration);
    },
    isValidLastExpiration () {
      return !isFilled(this.lastExpiration) || (this.isValidDates && (!this.lastOfMonth || this.isLastExpirationLastDayOfMonth));
    },
    isValidDescription () {
      return isFilled(this.description) && this.description.length <= 250;
    },
    isExpirationLastDayOfMonth () {
      return this.isLastDayOfMonth(this.expiration);
    },
    isLastExpirationLastDayOfMonth () {
      return this.isLastDayOfMonth(this.lastExpiration);
    },
    isValidDates () {
      if (this.expiration && this.lastExpiration) {
        const date = new Date(this.expiration);
        const date2 = new Date(this.lastExpiration);
        date.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
        return date < date2;
      }
      return false;
    },
  },
  methods: {
    isBeforeToday (date) {
      const date2 = new Date(date);
      const today = new Date();
      date2.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      return date2 < today;
    },
    isLastDayOfMonth (date) {
      const date2 = new Date(date);
      const month = date2.getMonth();
      date2.setDate(date2.getDate() + 1);
      return month !== date2.getMonth();
    },
    updateBank (bank) {
      this.bank = bank;
    },
    formatAmount: function () {
      // if (this.sign === 'income') {
      this.amount = Math.abs(this.amount);
      // } else {
      //   this.amount = -1 * Math.abs(this.amount);
      // }
      // this.showErrorAmount = true;
    },
    onSubmit: function () {
      this.errorMessage = '';
      if (this.isValidForm) {
        this.loading = true;
        this.formatAmount();
        this.$api.createRecurringTransfer(this.companyId, this.counterpart, parseFloat(this.amount), this.expiration, this.lastExpiration, this.description, this.bank.id, this.frequency, this.lastOfMonth, this.addMonthToDescription, this.transfer?.id)
          .then((res) => {
            this.$log.debug(res);
            this.$root.$emit('updateMovements');
            const msg = this.transfer ? 'Pagamento ricorrente aggiornato correttamente' : 'Pagamento ricorrente creato correttamente';
            this.$emit('save', msg);
          })
          .catch((e) => {
            this.$log.error(e);
            this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = true;
        if (!this.isValidBank) {
          this.showErrorBank = true;
        } else if (!this.isValidCounterpart) {
          this.showErrorCounterpart = true;
        } else if (!this.isValidAmount) {
          this.showErrorAmount = true;
        } else if (!this.isValidFrequency) {
          this.showErrorFrequency = true;
        } else if (!this.isValidExpiration) {
          this.showErrorExpiration = true;
        } else if (!this.isValidLastExpiration) {
          this.showErrorLastExpiration = true;
        } else if (!this.isValidDescription) {
          this.showErrorDescription = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    setDate (newDate) {
      this.showErrorExpiration = true;
      this.expiration = newDate;
    },
    setDate2 (newDate) {
      this.showErrorLastExpiration = true;
      this.lastExpiration = new Date(newDate);
    },
    setLastDayOfMonth () {
      const date = new Date(this.expiration);
      const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.expiration = newDate;
      this.update++;
    },
    setLastDayOfMonth2 () {
      const date = new Date(this.lastExpiration);
      const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.lastExpiration = newDate;
      this.update++;
    },
    setValues () {
      this.bank = this.transfer.bank;
      this.counterpart = this.transfer.counterpart;
      this.amount = this.transfer.amount;
      this.frequency = this.transfer.frequency;
      this.expiration = this.transfer.firstExpirationDate;
      this.lastExpiration = this.transfer.lastExpirationDate;
      this.lastOfMonth = this.transfer.lastOfMonth;
      this.description = this.transfer.description;
      this.addMonthToDescription = this.transfer.addMonthToDescription;
      this.update++;
    },
  },
  mounted () {
    if (this.transfer) {
      this.setValues();
    } else this.update++;
  },
};
</script>
