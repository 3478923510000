<template>
  <Loading v-if="isLoading" class="wrapper centered" />
  <span v-else>
    <component  :is="layout" class="wrapper app">
      <router-view />
    </component>
    <div class="screen-alert">
      <div class="mobile-modal">
        <h5>L'applicazione supporta solo monitor con risoluzione 1024x768 (XGA) o superiori.</h5>
      </div>
    </div>
    <b-alert
      v-model="updateExists"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000;"
      variant="warning"
    >
    <div class="d-flex justify-content-between">
      <div class="my-auto">
        È stato rilasciato un aggiornamento, aggiorna ora NinaFin!
      </div>
      <custom-button
        label="Aggiorna"
        @click.prevent.native="refreshApp"
        class="my-auto ml-2"
      />
    </div>
    </b-alert>
  </span>

</template>

<script>

// import LayoutPrivate from './components/Layout/LayoutPrivate/Layout';
// import LayoutModal from './components/Layout/LayoutModal/Layout';
// import SidebarEffectorMixin from '../libs/Sidebar/mixins/sidebarEffector';
import Loading from './components/Layout/Loading';
import update from '@/mixins/update';
import Button from '@/views/components/Button.vue';
// layouts
import LayoutLogin from '@/views/layouts/LayoutLogin.vue';
import LayoutDashboard from '@/views/layouts/LayoutDashboard.vue';
import LayoutBasic from '@/views/layouts/LayoutBasic.vue';

export default {
  name: 'app',
  components: {
    Loading,
    'custom-button': Button,
  },
  mixins: [update],
  /*
  mixins: [
    SidebarEffectorMixin,
  ],
  */
  data () {
    return {
      loading: true,
      feedback: true,
    };
  },
  computed: {
    isLoading () {
      return this.loading || !this.$route?.name;
    },
    isLogged () {
      return this.$oauth2.me;
    },
    layoutName () {
      return this.$route?.meta?.layout || null;
    },
    layout () {
      if (this.layoutName === 'login') {
        return LayoutLogin;
      }
      if (this.layoutName === 'dashboard') {
        return LayoutDashboard;
      }

      return LayoutBasic;
    },
  },

  mounted () {
    this.$oauth2.getMe()
      .finally(() => {
        this.loading = false;
      });
  },
};

</script>

<style lang="scss">

$background-image: "~@/assets/images/fin-bg.png";

$min-width: 240px !default;
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: $min-width;
}

main {
  overflow: auto;
}

body.show-mobile-menu {
  overflow: hidden;
}

.truncated-text {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fab-container {
  bottom: 5%;
  right: 5%;
  position: fixed;
}
.screen-alert {
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-image: url(#{$background-image});
  background-position: center;
  background-color: $primary-lighter;
  top: 0;
  left: 0;
  }

.mobile-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.screen-alert {
  visibility: hidden;
}

/* @media(max-width: 1023px), (max-height: 767px) {
  .app {
    visibility: hidden;
  }
  .screen-alert {
    visibility: visible;
  }
  html {
    overflow: hidden;
  }
} */
</style>
