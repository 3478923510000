<template>
<div>
  <form
      @submit.prevent="onSubmit"
      class="expirations"
      novalidate
    >
    <h4>Modifica scadenze</h4>
    <h6 class="mb-4">Fattura {{invoice.number}} di {{invoice.denomination}}</h6>
        <b-table
          id="invoicesTable"
          ref="invoicesTable"
          :items="scadenze"
          :fields="fields"
        >
          <template #cell(date)="row">
            {{ toFormattedDate(row.item.date) }}
          </template>
          <template #cell(isPaid)="row">
            <div v-if="row.item.isPaid" class="m-auto tag paid custom">Pagata</div>
            <button v-else class="tag to-pay" @click.prevent="markAsPaid(row.item.nRata)">Segna come pagata</button>
          </template>
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
              variant="link"
              toggle-class="text-decoration-none"
              v-if="row.item.isPaid && !row.item.isMatched"
            >
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item
                @click="
                  markAsPaid(row.item.nRata, false);
                "
                >Segna come non pagata</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template #empty>
            <div class="d-flex justify-content-center align-items-center py-2">
              Nessuna fattura trovata
            </div>
          </template>
        </b-table>
  </form>
    <Snackbar :text="snackbarText" ref="snackbar" class="up"/>
</div>
</template>

<script>
import { toFormattedDate } from '@/helpers/dates.js';
import {
  BDropdown,
  BDropdownItem,
  BTable,
} from 'bootstrap-vue';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  components: {
    BTable,
    BDropdown,
    BDropdownItem,
    Snackbar,
  },
  data () {
    return {
      snackbarText: '',
      loading: false,
      scadenze: JSON.parse(JSON.stringify(this.invoice?.scadenze)) || [],
      fields: [
        { key: 'nRata', label: 'n.', sortable: false },
        {
          key: 'date',
          label: 'Data scadenza',
          class: 'text-nowrap text-left',
        },
        { key: 'isPaid', label: 'Pagata', sortable: false, class: 'text-center' },
        { key: 'actions', label: '', sortable: false },
      ],
    };
  },
  methods: {
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    markAsPaid (nRata, isPaid = true) {
      this.$log.debug(this.invoice.id, nRata, isPaid);
      this.$api.setInstalmentInvoiceAsPaid(this.invoice.id, nRata, isPaid)
        .then((res) => {
          this.snackbarText = 'Scadenza aggiornata correttamente';
          this.scadenze = res.data.scadenze;
          this.$emit('onSavedExpiration');
        })
        .catch((e) => {
          const msg = "Qualcosa è andato storto, riprova più tardi o contatta l'assistenza se il problema persiste";
          this.$emit('setError', msg);
          this.$log.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.expirations {
  .tag {
    border: none;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    padding: 1px 7px;
    margin: 0;
    gap: 10px;
    border-radius: 5px;
    width: fit-content;
  }
  .paid.custom {
    background: $primary-lighter;
    color: $primary;
    opacity: 1;
    cursor: default;
  }
  .to-pay {
    border: none;
    white-space: nowrap;
    background: $primary;
    color: white;
    cursor: pointer;
  }
}
</style>
