<template>
  <div class="loan">
    <form
      @submit.prevent="onSubmit"
      class="text-start"
      novalidate
      >
      <div class="row">
        <div class="col-6">
            <b-form-group
                class="mb-3 box px-0"
                id="input-group-name"
                label="Nome*"
                label-for="input-name"
                :class="{ invalid: !isValidName && showErrorName }"
            >
                <b-form-input
                id="input-name"
                v-model="name"
                type="text"
                @blur="showErrorName = true"
                ></b-form-input>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidName || !showErrorName }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido
                </b-form-invalid-feedback>
            </b-form-group>
        </div>
        <div class="col-6">
          <BankSelect ref="bankSelect" :label="'Banca*'" :key="`bankSelectLoan_${update}`" :banks="banks" :selectedItem="bankAccount" @search:blur="showErrorBank = true" @update="updateBank" :class="{ invalid: !isValidBank && showErrorBank }"/>
          <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBank || !showErrorBank }" >
            <i class="fas fa-exclamation-circle"></i> Inserisci una banca
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="d-flex mb-3">
        <b-form-group>
            <b-form-radio-group
                id="radio-group-2"
                v-model="granted"
            >
                <b-form-radio :value="false">Da erogare</b-form-radio>
                <b-form-radio :value="true">Erogato</b-form-radio>
            </b-form-radio-group>
        </b-form-group>
      </div>

      <div class="d-flex row">
        <div class="col-6">
          <b-form-group
            class="mb-3"
            id="input-group-amount"
            :label="granted ? 'Debito residuo*' : 'Importo del finanziamento*'"
            label-for="input-amount"
            :class="{ invalid: !isValidAmount && showErrorAmount }"
          >
            <CurrencyInput id="input-amount" v-model="amount" @blur="showErrorAmount = true" />
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmount || !showErrorAmount }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group
            class="mb-3"
            id="input-group-release"
            :label="`Data erogazione${this.granted ? '' : '*'}`"
            label-for="input-release"
            :class="{ invalid: !isValidDate && showErrorDate }"
          >
            <DatePicker id="input-release" :value="releaseDate" @setValue="setDate" @blur="showErrorDate = true" :key="`updateReleaseDate_${update}`"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidDate || !showErrorDate }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una data valida
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-instalment-frequency"
            label="Frequenza delle rate*"
            label-for="input-instalment-frequency"
            :class="{ invalid: !isValidInstalmentFrequency && showErrorInstalmentFrequency }"
          >
            <b-form-select
              id="input-instalment-frequency"
              v-model="instalmentFrequency"
              type="number"
              @blur="showErrorInstalmentFrequency = true"
            >
              <option :value="1">Mensile</option>
              <option :value="3">Trimestrale</option>
              <option :value="6">Semestrale</option>
              <option :value="12">Annuale</option>
            </b-form-select>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidInstalmentFrequency || !showErrorInstalmentFrequency }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci il numero di rate
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-plan"
            label="Tipo di piano*"
            label-for="input-plan"
            :class="{ invalid: !isValidPlan && showErrorPlan }"
          >
            <b-form-select
              id="input-plan"
              v-model="plan"
              @blur="showErrorPlan = true"
            >
            <option :value="1">Francese - a rata costante</option>
            <option :value="2">Italiano - a quota capitale costante</option>
            </b-form-select>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidPlan || !showErrorPlan }" >
              <i class="fas fa-exclamation-circle"></i> Seleziona un piano
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-rate"
            label="Tasso* (%)"
            label-for="input-rate"
            :class="{ invalid: !isValidRate && showErrorRate }"
          >
            <CurrencyInput id="input-rate" v-model="rate" @blur="showErrorRate = true" />
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidRate || !showErrorRate }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una percentuale valida
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mb-4 justify-content-start">
        <div class="col-4">
          <b-form-checkbox v-model="lastOfMonth" name="input-last-of-month">
            Imposta scadenze a fine mese
          </b-form-checkbox>
        </div>
      </div>

      <div class="row mb-5">
        <!-- preammortamento -->
        <div class="col-6">
          <div class="simple-card">
            <div class="title w-100">
              Preammortamento
            </div>
            <div class="row content">
              <div class="col-5">
                <b-form-group
                  class="mb-0 mt-2"
                  id="input-group-preamortization-instalments"
                  :label="granted ? 'Rate residue' : 'Numero rate'"
                  label-for="input-preamortization-instalments"
                  :class="{ invalid: !isValidPreamortizationInstalments && showErrorPreamortizationInstalments }"
                >
                  <b-form-input
                    id="input-preamortization-instalments"
                    v-model="preamortization_instalments"
                    type="number"
                    @blur="showErrorPreamortizationInstalments = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidPreamortizationInstalments || !showErrorPreamortizationInstalments }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un numero valido
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="col-7">
                <b-form-group
                  class="mb-0 mt-2"
                  id="input-group-preamortization-expiration"
                  :label="granted ? 'Data prossima scadenza' : 'Data prima scadenza'"
                  label-for="input-preamortization-expiration"
                  :class="{ invalid: !isValidPreamortizationExpiration && showErrorPreamortizationExpiration }"
                >
                  <DatePicker id="input-preamortization-expiration" :value="preamortization_expiration" @setValue="setDate2" @blur="showErrorPreamortizationExpiration = true"  @change="showErrorPreamortizationExpiration = true" :key="`updatePremortization_${update}_${lastOfMonth}`"/>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidPreamortizationExpiration || !showErrorPreamortizationExpiration }" >
                    <i class="fas fa-exclamation-circle"></i> {{ lastOfMonth && preamortization_expiration ? "Il giorno selezionato non è l'ultimo del mese." : (!isAfterDeliveryDate(preamortization_expiration) ? 'La data deve essere successiva alla data di erogazione' : 'Inserisci una data valida')}}
                    <button v-if="lastOfMonth && preamortization_expiration " class="d-inline font-weight-bolder no-btn p-0" @click.prevent="setLastDayOfMonth(true)">Clicca qui per reimpostare</button>
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <!-- ammortamento -->
        <div class="col-6">
          <div class="simple-card">
            <div class="title w-100">
              Ammortamento*
            </div>
            <div class="row content">
              <div class="col-5">
                <b-form-group
                  class="mb-0 mt-2"
                  id="input-group-amortization-instalments"
                  :label="granted ? 'Rate residue*' : 'Numero rate*'"
                  label-for="input-amortization-instalments"
                  :class="{ invalid: !isValidAmortizationInstalments && showErrorAmortizationInstalments }"
                >
                  <b-form-input
                    id="input-amortization-instalments"
                    v-model="amortization_instalments"
                    type="number"
                    @blur="showErrorAmortizationInstalments = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmortizationInstalments || !showErrorAmortizationInstalments }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un numero valido
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="col-7">
                <b-form-group
                  class="mb-0 mt-2"
                  id="input-group-amortization-expiration"
                  :label="granted ? 'Data prossima scadenza*' : 'Data prima scadenza*'"
                  label-for="input-amortization-expiration"
                  :class="{ invalid: !isValidAmortizationExpiration && showErrorAmortizationExpiration }"
                >
                  <DatePicker id="input-amortization-expiration" :value="amortization_expiration" @setValue="setDate3" @blur="showErrorAmortizationExpiration = true" @change="showErrorAmortizationExpiration = true" :key="`updateAmortization_${update}_${preamortization_expiration}_${lastOfMonth}`"/>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmortizationExpiration || !showErrorAmortizationExpiration }" >
                    <i class="fas fa-exclamation-circle"></i> {{ lastOfMonth && amortization_expiration ? "Il giorno selezionato non è l'ultimo del mese." : (!isAfterDeliveryDate(amortization_expiration) ? 'La data deve essere successiva alla data di erogazione' : 'Inserisci una data valida')}}
                    <button v-if="lastOfMonth  && amortization_expiration" class="d-inline font-weight-bolder no-btn p-0" @click.prevent="setLastDayOfMonth()">Clicca qui per reimpostare</button>
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <custom-button
          :isLoading="loading"
          :label="loan ? 'Salva' : 'Aggiungi'"
          class="w-100"
          @click.prevent.native="onSubmit"
        />

      <ErrorCard v-if="errorMessage">
        <template #message >
          {{ errorMessage }}
        </template>
      </ErrorCard>
    </form>
  </div>
</template>

<script>
import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import DatePicker from '@/views/components/DatePicker.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';

import { BFormGroup, BFormRadioGroup, BFormRadio, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isCurrency, isDate, isNumber } from '@/helpers/formValidation.js';
import { toStandardDate } from '@/helpers/dates.js';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    banks: Array,
    companyId: String,
    loan: Object,
    setup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormRadio,
    'custom-button': Button,
    BankSelect,
    CurrencyInput,
    DatePicker,
    ErrorCard,
  },
  watch: {
    loan () {
      this.updateData();
    },
    lastOfMonth () {
      if (this.lastOfMonth && this.amortization_expiration) {
        this.setLastDayOfMonth(true);
        this.setLastDayOfMonth();
        this.showErrorAmortizationExpiration = true;
        this.showErrorPreamortizationExpiration = true;
      }
    },
    amortization_expiration () {
      if (this.lastOfMonth) {
        // this.setLastDayOfMonth();
        this.showErrorAmortizationExpiration = true;
      }
    },
    preamortization_expiration () {
      if (this.lastOfMonth) {
        this.showErrorPreamortizationExpiration = true;
      }

      if (this.isValidPreamortizationExpiration) return this.setAmortizationDate();
    },
    preamortization_instalments () {
      if (this.preamortization_instalments) return this.setAmortizationDate();
    },
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
      update: 0,

      showErrorBank: false,
      showErrorName: false,
      showErrorAmount: false,
      showErrorDate: false,
      showErrorInstalmentFrequency: false,
      showErrorPlan: false,
      showErrorRate: false,
      showErrorExpiration: false,
      showErrorPreamortizationInstalments: false,
      showErrorPreamortizationExpiration: false,
      showErrorAmortizationInstalments: false,
      showErrorAmortizationExpiration: false,
      showErrorFirstInstalment: false,
      showErrorLastInstalment: false,

      loading: false,
      errorMessage: '',

      // Form
      bankAccount: null,
      name: '',
      granted: false,
      amount: null,
      releaseDate: null,
      instalmentFrequency: null,
      plan: null,
      rate: null,
      lastOfMonth: false,
      preamortization_instalments: null,
      preamortization_expiration: null,
      amortization_instalments: null,
      amortization_expiration: null,
    };
  },
  computed: {
    isValidForm () {
      return this.isValidName && this.isValidBank && this.isValidAmount && this.isValidDate && this.isValidInstalmentFrequency && this.isValidPlan && this.isValidRate && this.isValidPreamortizationInstalments && this.isValidPreamortizationExpiration && this.isValidAmortizationInstalments && this.isValidAmortizationExpiration;
    },
    isValidBank () {
      return isFilled(this.bankAccount);
    },
    isValidName () {
      return isFilled(this.name);
    },
    isValidAmount () {
      return isFilled(this.amount) && isCurrency(this.amount);
    },
    isValidDate () {
      return this.granted || isFilled(this.releaseDate);
    },
    isValidInstalmentFrequency () {
      return isFilled(this.instalmentFrequency);
    },
    isValidPlan () {
      return isFilled(this.plan);
    },
    isValidRate () {
      return isFilled(this.rate);
    },
    isValidPreamortizationInstalments () {
      return !isFilled(this.preamortization_instalments) || (isNumber(this.preamortization_instalments) && this.preamortization_instalments > 0);
    },
    isValidPreamortizationExpiration () {
      return !isFilled(this.preamortization_expiration) || (isDate(this.preamortization_expiration) && this.isValidLastDay(this.preamortization_expiration) && this.isAfterDeliveryDate(this.preamortization_expiration));
    },
    isValidAmortizationInstalments () {
      return isFilled(this.amortization_instalments) && isNumber(this.amortization_instalments) && this.amortization_instalments > 0;
    },
    isValidAmortizationExpiration () {
      return isDate(this.amortization_expiration) && this.isValidLastDay(this.amortization_expiration) && this.isAfterDeliveryDate(this.amortization_expiration);
    },
  },
  methods: {
    isLastDayOfMonth (date) {
      const date2 = new Date(date);
      const month = date2.getMonth();
      date2.setDate(date2.getDate() + 1);
      this.$log.debug(month === date2.getMonth());
      return month === date2.getMonth();
    },
    isAfterDeliveryDate (date) {
      const inputDate = new Date(date);
      const releaseDate = new Date(this.releaseDate);
      return releaseDate < inputDate;
    },
    onSubmit: function () {
      this.errorMessage = '';
      if (this.isValidForm) {
        if (this.setup) {
          const piano = Object.assign({}, {
            frequenzaRate: this.instalmentFrequency,
            tasso: this.rate,
            tipoPianoId: this.plan, // 1 francese, 2 italiano
            fineMese: this.lastOfMonth,
            nbRate: this.amortization_instalments,
            primaScadenza: this.amortization_expiration,
            nbRatePreammortamento: this.preamortization_instalments,
            primaScadenzaPreammortamento: this.preamortization_expiration,
            iva: null,
            maxiRataIniziale: null,
            maxiRataFinale: null,
            rate: null,
          });

          const loan = Object.assign({}, {
            bankAccount: this.bankAccount,
            label: this.name,
            isPaid: this.granted,
            isCalculated: true,
            importo: this.amount,
            paidDate: this.releaseDate,
            piano: piano,
          });

          return this.$emit('save', loan);
        } else {
          // CREATE LOAN (no setup)
          this.loading = true;
          this.$api.createLoan(
            this.companyId,
            this.name,
            this.bankAccount.id, // to do check id
            this.granted, // isPaid
            true, // isCalculated
            this.amount,
            this.releaseDate, // paidDate
            this.instalmentFrequency, // frequenzaRate
            this.rate, // tasso
            this.plan, // tipoPianoId 1 francese, 2 italiano
            this.amortization_instalments, // nbRate (ammortamento),
            this.amortization_expiration, // primaScadenza (ammortamento),
            this.preamortization_instalments, // nbRatePreammortamento
            this.preamortization_expiration, // primaScadenzaPreammortamento,
            this.lastOfMonth,
            null, // iva
            null, // maxiRataIniziale
            null, // maxiRataFinale
            [], // rate (manuale)
            this.loan ? this.loan.id : null, // id
          )
            .then((res) => {
              if (this.loan && res.data.id) {
                this.$router.replace({ name: 'loanDetails', params: { id: res.data.id } });
              }
              this.$emit('save');
            })
            .catch((e) => {
              this.$log.error(e);
              this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } else {
        this.loading = true;
        if (!this.isValidName) {
          this.showErrorName = true;
        } else if (!this.isValidBank) {
          this.showErrorBank = true;
        } else if (!this.isValidAmount) {
          this.showErrorAmount = true;
        } else if (!this.isValidDate) {
          this.showErrorDate = true;
        } else if (!this.isValidInstalmentFrequency) {
          this.showErrorInstalmentFrequency = true;
        } else if (!this.isValidPlan) {
          this.showErrorPlan = true;
        } else if (!this.isValidRate) {
          this.showErrorRate = true;
        } else if (!this.isValidPreamortizationInstalments) {
          this.showErrorPreamortizationInstalments = true;
        } else if (!this.isValidPreamortizationExpiration) {
          this.showErrorPreamortizationExpiration = true;
        } else if (!this.isValidAmortizationInstalments) {
          this.showErrorAmortizationInstalments = true;
        } else if (!this.isValidAmortizationExpiration) {
          this.showErrorAmortizationExpiration = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    updateData () {
      this.bankAccount = this.loan?.bank ? this.loan?.bank : this.loan.bankAccount;
      this.name = this.loan.label;
      this.amount = this.loan.importo;
      this.releaseDate = this.loan.paidDate;
      this.granted = !this.loan.paidDate;
      this.instalmentFrequency = this.loan.piano.frequenzaRate;
      this.plan = this.loan.piano.tipoPianoId;
      this.rate = this.loan.piano.tasso;
      this.lastOfMonth = this.loan.piano.fineMese;
      this.preamortization_instalments = this.loan.piano.nbRatePreammortamento === 0 ? null : this.loan.piano.nbRatePreammortamento;
      this.preamortization_expiration = this.loan.piano.primaScadenzaPreammortamento;
      this.amortization_instalments = this.loan.piano.nbRate === 0 ? null : this.loan.piano.nbRate;
      this.amortization_expiration = this.loan.piano.primaScadenza;
      this.update++;
    },
    updateBank (bank) {
      this.bankAccount = bank;
    },
    setAmortizationDate () {
      const extraMonths = this.preamortization_instalments ? parseInt(this.preamortization_instalments) : 1;
      if (this.preamortization_expiration) {
        const date = new Date(this.preamortization_expiration);
        const year = date.getFullYear();
        const month = date.getMonth() + extraMonths + (this.lastOfMonth ? 1 : 0);
        const day = this.lastOfMonth ? 0 : date.getDate();

        const newDate = new Date(year, month, day);
        this.amortization_expiration = toStandardDate(newDate);
        this.update++;
      }
    },
    setLastDayOfMonth (isPreamortization = false) {
      if (isPreamortization && this.preamortization_expiration) {
        const date = new Date(this.preamortization_expiration);
        const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.preamortization_expiration = toStandardDate(newDate);
        return ('0' + newDate.getDate()).slice(-2);
      } else if (this.amortization_expiration) {
        const date = new Date(this.amortization_expiration);
        const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.amortization_expiration = toStandardDate(newDate);
        return ('0' + newDate.getDate()).slice(-2);
      } else {
        return null;
      }
    },
    toStandardDate (s) {
      return toStandardDate(s);
    },
    isValidLastDay (date) {
      if (this.lastOfMonth) {
        const newDate = new Date(date);
        const date2 = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 1);

        return newDate.getMonth() !== date2.getMonth();
      }
      return true;
    },
    setDate (newDate) {
      this.releaseDate = newDate;
    },
    setDate2 (newDate) {
      this.preamortization_expiration = newDate;
    },
    setDate3 (newDate) {
      this.amortization_expiration = newDate;
    },
  },
  mounted () {
    if (this.loan) {
      const promise = new Promise((resolve) => {
        this.updateData();
      });

      promise.then(this.update++);
    } else {
      this.update++;
    }
  },
};
</script>

<style lang="scss">
.no-btn {
  background: transparent;
  border: none;
  color: inherit;
  text-decoration: underline;
}

.simple-card {
  background: $fin-white;
  border-radius: 10px;
  overflow: visible!important;
  .title {
    border-radius: 10px 10px 0px 0px;
    background: $primary-lighter;
    color: $primary;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 5px 10px;
  }
  .content {
    padding: 5px 15px;
  }
  label.custom-control-label {
    font-weight: 400;
  }
}
</style>
