const api = {};

export const init = (apidef = {}) => {
  for (const name in apidef) {
    addEndpoint(name, apidef[name]);
  }
};

export const addEndpoint = (name, endpoint) => {
  api[name] = endpoint;
};

export default api;
