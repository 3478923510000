<template>
  <div ref="sidebar" id="sidebar">
    <nav class="sidebar d-flex flex-column justify-content-between" :class="{full: showFullSidebar}">

      <!-- top -->
      <div>
        <div class="logo-wrapper clickable">
          <div class="d-flex justify-content-center p-3 position-relative">
            <div @click="goTo('dashboard')">
              <div class="custom-height" v-show="isLoading"></div>
              <img :src="customLogo ? customLogo : logo" :class="{hidden: showFullSidebar}" width="46" height="46" class="fade-in" v-show="!isLoading" />
              <img :src="customBigLogo ? customBigLogo : bigLogo" :class="{hidden: !showFullSidebar}" height="46" class="fade-in" v-show="!isLoading"/>
            </div>

            <button class="btn toggle-sidebar" @click.prevent="toggleSidebar()" :data-content="!showFullSidebar ? 'Espandi pannello' : 'Comprimi pannello'" :class="{'collapsed' : !showFullSidebar}">
              <i class="fa fa-angle-double-right" :class="{rotated: showFullSidebar}"></i>
            </button>
          </div>
        </div>

        <div>
          <ul v-if="!visitor" class="d-flex flex-column justify-content-center p-0 " :class="{'no-title' : showFullSidebar}" id="ul">
            <router-link :to="{ name: 'dashboard'}" tag="li" data-content="Panoramica" :class="{'disabled': isCritical}" :disabled="isCritical" tabindex="0" role="button">
              <span class="ninafin-icon sidebar-icon icon-dashboard"><span class="path1"></span><span class="path2"></span></span>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Panoramica
              </div>
            </router-link>
            <router-link :to="{ name: 'charts'}" tag="li" data-content="Grafici" :class="{'disabled': isCritical}" :disabled="isCritical" tabindex="0" role="button">
              <i class="sidebar-icon fas fa-chart-line"></i>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Reports
              </div>
            </router-link>
            <router-link :to="{ name: 'employees'}" title="Gestione personale" tag="li" data-content="Gestione personale" :class="{'disabled': isCritical}" :disabled="isCritical" tabindex="0" role="button">
                <span class="ninafin-icon sidebar-icon icon-employees"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                <div class="name w-100" :class="{hidden: !showFullSidebar}">
                  Gestione personale
                </div>
            </router-link>
            <router-link :to="{ name: 'recurringPayments'}" title="Pagamenti ricorrenti" tag="li" data-content="Pagamenti ricorrenti"  :class="{'disabled': isCritical}" :disabled="isCritical" tabindex="0" role="button">
                <span class="ninafin-icon sidebar-icon icon-recurring-payments"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
                <div class="name w-100" :class="{hidden: !showFullSidebar}">
                  Pagamenti ricorrenti
                </div>
            </router-link>
            <router-link :to="{ name: 'loans'}" tag="li" data-content="Gestione finanziamenti"  :class="{'disabled': isCritical}" :disabled="isCritical" tabindex="0" role="button">
              <span class="ninafin-icon sidebar-icon icon-fundings"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Gestione finanziamenti
              </div>
            </router-link>

            <li @click="showSubmenu3 = !showSubmenu3" class="has-submenu"  data-content="Fatture"  :class="{'disabled': isCritical}"  tabindex="0" role="button">
              <div class="sidebar-icon">
                <div class="notification-sidebar" v-if="notificationsPassiveInvoices > 0 || notificationsActiveInvoices > 0">
                  {{notificationsPassiveInvoices + notificationsActiveInvoices}}
                </div>
                  <span class="ninafin-icon icon-invoices"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
              </div>
              <div class="name w-auto" :class="{hidden: !showFullSidebar}">
                Fatture
              </div>
              <i class="fas fa-angle-down ml-auto p-4" :class="[{rotated: showSubmenu3}, {hidden: !showFullSidebar}]"></i>

              <!--Visualizzato di lato-->
              <ul :class="{hidden: showFullSidebar}">
                <router-link :to="{ name: 'activeInvoices'}" tag="li" :class="[{'mr-4': notificationsActiveInvoices > 0}, {'disabled': isCritical}]" :disabled="isCritical">
                  <div class="notification-sidebar-secondary" v-if="notificationsActiveInvoices > 0">
                    {{notificationsActiveInvoices}}
                  </div>
                  Fatture attive
                </router-link>
                <router-link :to="{ name: 'passiveInvoices'}" tag="li" :class="[{'mr-4': notificationsPassiveInvoices > 0}, {'disabled': isCritical}]" :disabled="isCritical">
                  <div class="notification-sidebar-secondary" v-if="notificationsPassiveInvoices > 0">
                    {{notificationsPassiveInvoices}}
                  </div>
                  Fatture passive
                </router-link>
              </ul>
            </li>

            <!--Visualizzato sotto-->
            <ul class="submenu" v-if="showSubmenu3" :class="{hidden: !showFullSidebar}">
              <router-link :to="{ name: 'activeInvoices'}" tag="li"  :class="{'disabled': isCritical}" :disabled="isCritical" tabindex="0" role="button">
                <div class="notification-sidebar-secondary mr-5" v-if="notificationsActiveInvoices > 0">
                  {{notificationsActiveInvoices}}
                </div>
                Attive
              </router-link>
              <router-link :to="{ name: 'passiveInvoices'}" tag="li"  :class="{'disabled': isCritical}" :disabled="isCritical" tabindex="0" role="button">
                <div class="notification-sidebar-secondary mr-5" v-if="notificationsPassiveInvoices > 0">
                  {{notificationsPassiveInvoices}}
                </div>
                Passive
              </router-link>
            </ul>

            <li @click="showSubmenu2 = !showSubmenu2" class="has-submenu"  data-content="Scadenzario"  :class="{'disabled': isCritical}" tabindex="0" role="button">
              <span class="ninafin-icon sidebar-icon icon-schedule"><span class="path1"></span><span class="path2"></span></span>
              <div class="name w-auto" :class="{hidden: !showFullSidebar}">
                Scadenzario
              </div>
              <i class="fas fa-angle-down ml-auto p-4" :class="[{rotated: showSubmenu2}, {hidden: !showFullSidebar}]"></i>

              <!--Visualizzato di lato-->
              <ul :class="{hidden: showFullSidebar}">
                <router-link :to="{ name: 'scadenzarioAttivo'}" tag="li"  :class="{'disabled': isCritical}" :disabled="isCritical" tabindex="0" role="button">
                  Scadenzario attivo
              </router-link>
                <router-link :to="{ name: 'scadenzarioPassivo'}" tag="li"  :class="{'disabled': isCritical}" :disabled="isCritical" tabindex="0" role="button">
                  Scadenzario passivo
                </router-link>
              </ul>
            </li>

            <!--Visualizzato sotto-->
            <ul class="submenu" v-if="showSubmenu2" :class="{hidden: !showFullSidebar}">
              <router-link :to="{ name: 'scadenzarioAttivo'}" tag="li"  :class="{'disabled': isCritical}" :disabled="isCritical" tabindex="0" role="button">
                Attivo
              </router-link>
              <router-link :to="{ name: 'scadenzarioPassivo'}" tag="li"  :class="{'disabled': isCritical}" :disabled="isCritical" tabindex="0" role="button">
                Passivo
              </router-link>
            </ul>

            <li @click="showSubmenu = !showSubmenu" class="has-submenu"  data-content="Riconciliazione"  :class="{'disabled': isCritical}" tabindex="0" role="button">
              <div class="sidebar-icon">
                <div class="notification-sidebar" v-if="notificationsMovements > 0">
                  {{notificationsMovements}}
                </div>
                <span class="ninafin-icon icon-matching"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
              </div>
              <div class="name w-auto" :class="{hidden: !showFullSidebar}">
                Riconciliazione
              </div>
              <i class="fas fa-angle-down ml-auto p-4" :class="[{rotated: showSubmenu}, {hidden: !showFullSidebar}]"></i>

              <!--Visualizzato di lato-->
              <ul :class="{hidden: showFullSidebar}">
                <router-link :to="{ name: 'automaticMatching'}" tag="li" :class="{'mr-4': notificationsMovements > 0}">
                  <div class="notification-sidebar-secondary" v-if="notificationsMovements > 0">
                    {{notificationsMovements}}
                  </div>
                  Riconciliazione automatica
                </router-link>
                <router-link :to="{ name: 'manualMatching'}" tag="li" tabindex="0" role="button">
                  Riconciliazione manuale
                </router-link>
              </ul>
            </li>

            <!--Visualizzato sotto-->
            <ul class="submenu" v-if="showSubmenu" :class="{hidden: !showFullSidebar}">
              <router-link :to="{ name: 'automaticMatching'}" tag="li" tabindex="0" role="button">
                <div class="notification-sidebar-secondary mr-5" v-if="notificationsMovements > 0">
                  {{notificationsMovements}}
                </div>
                Automatica
              </router-link>
              <router-link :to="{ name: 'manualMatching'}" tag="li" tabindex="0" role="button">
                Manuale
              </router-link>
            </ul>

            <router-link class="prime-entry" :to="{ name: 'primeEntry'}" tag="li" data-content="Prima nota" tabindex="0" role="button">
              <span class="sidebar-icon ninafin-icon icon-prime-entry"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Prima nota
              </div>
            </router-link>

            <router-link class="prime-entry" :to="{ name: 'iva'}" tag="li" data-content="Gestione IVA" tabindex="0" role="button">
              <span class="sidebar-icon ninafin-icon icon-prime-entry"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Gestione IVA
              </div>
            </router-link>

            <li v-if="userInStage" @click="showSubmenuDemo = !showSubmenuDemo" class="has-submenu bg-warning"  data-content="Demo">
              <div class="sidebar-icon">
                  <i class="fas fa-hat-wizard"></i>
              </div>
              <div class="name w-auto" :class="{hidden: !showFullSidebar}">
                Demo
              </div>
              <i class="fas fa-angle-down ml-auto p-4" :class="[{rotated: showSubmenuDemo}, {hidden: !showFullSidebar}]"></i>

              <!--Visualizzato di lato-->
              <ul :class="{hidden: showFullSidebar}">
                <button
                  variant="danger"
                  squared
                  @click="resetCompany()"
                  >Reset
                </button>
                <button
                  variant="success"
                  squared
                  @click="startDemo()"
                  >Start
                </button>
              </ul>
            </li>

            <!--Visualizzato sotto-->
            <div v-if="showSubmenuDemo && userInStage" class="submenu" :class="{hidden: !showFullSidebar}">
              <button
                variant="danger"
                squared
                @click="resetCompany()"
                >Reset
              </button>
              <button button
                variant="success"
                squared
                @click="startDemo()"
                >Start
              </button>
            </div>
          </ul>

          <ul v-if="visitor" class="d-flex flex-column justify-content-center p-0" :class="{'no-title' : showFullSidebar}">
            <router-link :to="{ name: 'primeEntry'}" tag="li" data-content="Prima nota" tabindex="0" role="button">
              <div class="icon">
                <i class="fas fa-book"></i>
              </div>
              <div class="name w-100" :class="{hidden: !showFullSidebar}">
                Prima nota
              </div>
            </router-link>
          </ul>
        </div>

        <div class="btn-add-movement d-flex align-items-center justify-content-center" v-if="!visitor">
          <b-dropdown
            ref="dropdown-sidebar"
            class="w-100"
            :class="[{sm: !showFullSidebar}, {'disabled': isCritical}]"
            size="md"
            id="dropdown-1"
            dropright
            variant="primary"
            toggle-class="text-decoration-none"
            offset="-200, 10"
            :disabled="isCritical"
          >
            <template #button-content>
              <span id="add-movement">
                <div :class="{hidden: showFullSidebar}">
                  <i class="fas fa-plus"></i>
                </div>
                <div class="name w-100" :class="{hidden: !showFullSidebar}">
                  Aggiungi movimento
                </div>
              </span>
            </template>
            <span id="categories">
              <b-dropdown-item @click="togglePopup('addMovement', $event); movement = 0;" id="first" class="add-movement">Accredito/addebito</b-dropdown-item>
              <b-dropdown-item @click="togglePopup('addMovement'); movement = 1;">Bonifico</b-dropdown-item>
              <b-dropdown-item @click="togglePopup('addMovement'); movement = 7;">Bonifico ricorrente</b-dropdown-item>
              <b-dropdown-item @click="togglePopup('addMovement'); movement = 2;">Pagamento fiscale</b-dropdown-item>
              <b-dropdown-item @click="togglePopup('addMovement'); movement = 3;">Competenze e oneri bancari</b-dropdown-item>
              <b-dropdown-item @click="togglePopup('addMovement'); movement = 4;">Girofondo</b-dropdown-item>
              <b-dropdown-item @click="togglePopup('addMovement'); movement = 5;">Ri.Ba o titolo di credito</b-dropdown-item>
              <b-dropdown-item @click="togglePopup('addMovement'); movement = 6;">Versamento/prelievo</b-dropdown-item>
            </span>
          </b-dropdown>
        </div>
      </div>

      <!-- bottom -->
      <div>
        <ul class="d-flex flex-column justify-content-center p-0 m-0" :class="{'no-title' : showFullSidebar}">
          <!--router-link class="bottom has-submenu" :to="{ name: 'settings', params: { page: 0 } }" tag="li">
            <div class="icon">
              <i class="fas fa-cog"></i>
            </div>
            <div class="name" :class="{hidden: !showFullSidebar}">
                Impostazioni
            </div>
            <ul :class="{hidden: showFullSidebar}">
              <router-link :to="{ name: 'settings', params: { page: 0 } }" tag="li">
                Azienda
              </router-link>
              <router-link :to="{ name: 'settings', params: { page: 1 } }" tag="li">
                Conti correnti
              </router-link>
              <router-link :to="{ name: 'settings', params: { page: 2 } }" tag="li">
                Cassa
              </router-link>
              <router-link :to="{ name: 'settings', params: { page: 3 } }" tag="li">
                Dipendenti
              </router-link>
            </ul>
          </router-link-->
          <router-link class="bottom" :to="{ name: 'help' }" tag="li" data-content="Assistenza" v-if="false" tabindex="0" role="button">
            <div class="icon">
              <i class="fas fa-cog"></i>
            </div>
            <div class="name" :class="{hidden: !showFullSidebar}">
              Assistenza
            </div>
          </router-link>

          <router-link class="bottom" :to="{ name: 'settings', params: { page: 0 } }" tag="li" data-content="Impostazioni" v-if="!visitor" tabindex="0" role="button" :class="{'disabled': isCritical}" :disabled="isCritical">
            <span class="ninafin-icon sidebar-icon icon-settings"></span>
            <div class="name" :class="{hidden: !showFullSidebar}">
              Impostazioni
            </div>
          </router-link>

          <li class="bottom" @click="togglePopup('logout')" data-content="Log out" tabindex="0" role="button">
            <span class="ninafin-icon sidebar-icon icon-log-out"></span>
            <div class="name" :class="{hidden: !showFullSidebar}">
              Log out
            </div>
          </li>

        <NotificationsPopup v-if="showNotifications" v-click-outside="hidePopup" :notificationsArray="notifications" @getMore="getMoreNotifications" @closeNotificationsPopup="toggleNotifications" :class="{ 'move-to-right': showFullSidebar}" id="notifications-popup" />

        <div class="company d-flex name w-100 align-items-center justify-content-left">
          <i class="fas fa-building" :class="{hidden: showFullSidebar}"></i>
          <div :class="{hidden: !showFullSidebar}" class="d-flex name w-100 flex-column justify-content-center">
            <div class="company-owner">{{ companyName }}</div>
            <div class="vat">P. IVA {{ companyVAT }}</div>
          </div>
        </div>

        <div class="row m-3 d-flex justify-content-center align-items-center">
          <div class="col-10 p-0 d-flex" :class="showFullSidebar ? 'justify-content-left' : 'justify-content-center'">
            <button class="btn user-wrapper" @click="goTo('profile')" @keydown.enter="$router.push({ name: 'profile'})" tabindex="0" role="button"  :class="{'disabled': isCritical}" :disabled="isCritical">
              <div class="circle-wrapper">
                  <div class="circle">{{name[0]}}{{surname[0]}}</div>
              </div>
              <div class="user-name" v-if="showFullSidebar">
                {{ name }} {{ surname }}
              </div>
            </button>
          </div>
          <div class="col-2 d-flex justify-content-center align-items-center" :class="{'mt-3': !showFullSidebar}" >
            <button class="btn notification-box" id="notifications-button" @click="toggleNotifications">
              <div v-if="numberOfNotifications > 0" class="number-of-notifications">{{numberOfNotifications}}</div>
              <span class="ninafin-icon icon-notifications"></span>
            </button>
          </div>
        </div>

        </ul>
      </div>

    </nav>

    <Popup @closePopup="togglePopup('logout')" ref="logout" class="md">
      <template #title> Sei sicuro di voler uscire? </template>
      <template #text>Il tuo accont verrà disconnesso.</template>
      <template #button>
        <custom-button
          label="Log out"
          class="name w-100 mt-4"
          @click.prevent.native="logout"
        />
      </template>
    </Popup>

    <Popup @closePopup="togglePopup('selectMovement')" ref="selectMovement" class="md">
      <template #fullContent>
        <SelectMovement :banks="banksList" :companyId="companyId" @save="savedMovement"/>
      </template>
      <!-- to do feedback movimento aggiunto -->
    </Popup>

    <Popup @closePopup="togglePopup('addMovement')" ref="addMovement" class="md" id="add-movement-popup">
      <template #fullContent>
        <MovementsWrapper :banks="banksList" :companyId="companyId" :movement="movement" @save="savedMovement" id="add-movement-popup-content" :tutorial="tutorial"/>
      </template>
    </Popup>

    <Snackbar :text="snackbarText" ref="snackbar_sidebar"/>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue';

import logo from '@/assets/images/NinaFin_Fin.svg';
import bigLogo from '@/assets/images/nina-fin-logo-new.svg';
import Popup from '@/views/components/Popup.vue';
import SelectMovement from '@/views/components/Forms/Movements/SelectMovement.vue';
import Snackbar from '@/views/components/Snackbar.vue';
import Button from '@/views/components/Button.vue';
import MovementsWrapper from '@/views/components/Forms/Movements/MovementsWrapper.vue';
import NotificationsPopup from '@/views/components/NotificationsPopup.vue';

export default {
  name: 'Sidebar',
  components: {
    BDropdown,
    BDropdownItem,
    SelectMovement,
    'custom-button': Button,
    MovementsWrapper,
    NotificationsPopup,
    Popup,
    Snackbar,
  },
  directives: {
    'click-outside': {
      bind (el, binding, vnode) {
        const button = document.getElementById('notifications-button');

        el.clickOutsideEvent = function (event) {
          // here we check if the click event is outside the element and it's children
          if (!(el === event.target || el.contains(event.target) || button.contains(event.target) || button === event.target)) {
            // if clicked outside, call the provided method
            vnode.context[binding.expression](event);
          }
        };
        // register click and touch events
        document.body.addEventListener('click', el.clickOutsideEvent);
        document.body.addEventListener('touchstart', el.clickOutsideEvent);
      },
      unbind (el) {
        // unregister click and touch events before the element is unmounted
        document.body.removeEventListener('click', el.clickOutsideEvent);
        document.body.removeEventListener('touchstart', el.clickOutsideEvent);
      },
    },
  },
  data () {
    return {
      tutorial: false,
      blockDropdown: false,

      showNotifications: false,

      isLoading: true,
      customBigLogo: null,
      customLogo: null,

      logo: logo,
      bigLogo: bigLogo,
      showFullSidebar: true,
      snackbarText: '',
      showSubmenu: false,
      showSubmenu2: false,
      showSubmenu3: false,
      showSubmenuDemo: false,
      movement: null,

      visitor: false,
      admin: false,

      page: 1,
      totalPages: 1,

      notifications: [],
      importantNotifications: [],
      warnings: [],
      criticals: [],
      ads: [],

      name: '',
      surname: '',
      companyId: '',
      companyName: '',
      companyVAT: '',
      banksList: null,

      notificationsPassiveInvoices: 0,
      notificationsActiveInvoices: 0,
      notificationsMovements: 0,
    };
  },
  watch: {
    showSubmenu () {
      if (this.showSubmenu === true) {
        this.showSubmenu2 = false;
        this.showSubmenu3 = false;
        this.showSubmenuDemo = false;
      }
    },
    showSubmenu2 () {
      if (this.showSubmenu2 === true) {
        this.showSubmenu = false;
        this.showSubmenu3 = false;
        this.showSubmenuDemo = false;
      }
    },
    showSubmenu3 () {
      if (this.showSubmenu3 === true) {
        this.showSubmenu = false;
        this.showSubmenu2 = false;
        this.showSubmenuDemo = false;
      }
    },
    showSubmenuDemo () {
      if (this.showSubmenuDemo === true) {
        this.showSubmenu = false;
        this.showSubmenu2 = false;
        this.showSubmenu3 = false;
      }
    },
    showNotifications () {
      if (this.showNotifications) {
        // to do
        // enables event listener
      } else {
        // removes event listener
      }
    },
  },
  computed: {
    userInStage () {
      const me = this.$oauth2.me;
      return (
        me !== undefined &&
        me.email !== undefined &&
        me.demo !== undefined &&
        me.demo
      );
    },
    isActive () {
      return this.$router.path;
    },
    numberOfNotifications () {
      const notifications = this.notifications.filter((obj) => !obj.hasBeenRead);
      const importantNotifications = this.importantNotifications.filter((obj) => !obj.hasBeenRead);
      return notifications?.length + importantNotifications.length;
    },
    isCritical () {
      return JSON.stringify(this.criticals) !== '[]';
    },
  },
  methods: {
    hidePopup () {
      this.showNotifications = false;
    },
    goTo (name) {
      this.$router.push({ name: name })
        .catch(() => {});
    },
    getCompanyInfo () {
      this.$api.getCompany(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          const company = res.data;
          this.companyVAT = company.vat;
          this.banksList = company.banks;
          this.notificationsPassiveInvoices = company.notifications.fattureOut;
          this.notificationsActiveInvoices = company.notifications.fattureIn;
          this.notificationsMovements = company.notifications.movements;

          this.customLogo = company.theme?.logoUrl;
          this.customBigLogo = company.theme?.bigLogoUrl;
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getNotifications () {
      this.$api.getNotifications(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          this.totalPages = res.data.metadata['total-pages'] || 1;

          this.notifications = res.data.notifications;
          this.warnings = res.data.warning || [];
          this.criticals = res.data.critical || [];
          this.ads = res.data.adv || [];

          if (JSON.stringify(this.criticals) !== '[]') {
            this.$router.push({ name: 'primeEntry' });
          }

          this.$root.$emit('otherNotifications', this.warnings, this.criticals, this.ads);
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getMoreNotifications () {
      if (this.page < this.totalPages) {
        this.page++;
        this.$log.debug(`get more, page: ${this.page}`);
        const filters = { page: this.page };

        this.$api.getNotifications(this.companyId, filters)
          .then((res) => {
            this.$log.debug(res.data);

            const newImportantNotifications = res.data.notifications.filter((obj) => obj.category === 'n' && obj.isImportant);
            const newNotifications = res.data.notifications.filter((obj) => obj.category === 'n' && !obj.isImportant);

            this.importantNotifications = this.importantNotifications.concat(newImportantNotifications);
            this.notifications = this.notifications.concat(newNotifications);
          })
          .catch((e) => {
            this.$log.error(e);
          });
      }
    },
    getRoles () {
      const role = this.$oauth2.me.companies[0]?.role;
      if (role === 'owner') {
        this.admin = true;
      } else if (role === 'visitor') {
        this.visitor = true;
      }
    },
    getMeInfo () {
      const me = this.$oauth2.me;
      this.name = me.name || '';
      this.surname = me.surname || '';
      this.companyName = me.companies[0]?.name;
      this.companyId = me.companies[0]?.id;
    },
    logout () {
      this.$router.replace({ name: 'logout' }).catch(() => {});
    },
    toggleNotifications () {
      this.showNotifications = !this.showNotifications;
    },
    togglePopup (ref, $event) {
      this.$log.debug($event);
      this.$refs[ref].togglePopup();
    },
    toggleSidebar () {
      this.showFullSidebar = !this.showFullSidebar;
    },
    savedMovement () {
      this.$refs.addMovement.close();
      this.snackbarText = 'Movimento aggiunto correttamente';
      this.$refs.snackbar_sidebar.openSnackbar();
    },
    resetCompany () {
      this.$api.resetCompany(this.companyId).then(() => {
        this.getCompanyInfo();
      });
    },
    startDemo () {
      this.$api.downloadFattureDemo(this.companyId, 'in').then(() => {
        this.$api.downloadFattureDemo(this.companyId, 'out').then(() => {
          this.$api.downloadTransactionsDemo(this.companyId, 'out').then(() => {
            this.getCompanyInfo();
          });
        });
      });
    },
  },
  beforeMount () {
    this.getMeInfo();
    this.getCompanyInfo();
    this.getRoles();
    this.getNotifications();
  },
  mounted () {
    this.$root.$on('updateNotifications', () => {
      this.getCompanyInfo();
      this.getNotifications();
    });

    this.$root.$on('tutorialStarted', () => {
      this.tutorial = true;
      this.blockDropdown = true;
    });

    this.$root.$on('tutorialCompleted', () => {
      this.tutorial = false;
    });

    this.$root.$on('unblockDropdown', () => {
      this.blockDropdown = false;
    });

    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (this.blockDropdown) {
        bvEvent.preventDefault();
      }
    });
  },
};
</script>

<style lang="scss">
$sidebar-size: 80px;
$full-sidebar-size: 280px;

.sidebar {
  position: relative;
  height: 100%;
  width: $sidebar-size;
  background-color: white;
  z-index: 10;
  transition: all 0.5s ease-out;
  filter: drop-shadow($box-shadow);

  .custom-height {
    height: 46px;
  }

  .sidebar-icon {
    font-size: 18px;
    min-width: $sidebar-size;
    display: flex;
    justify-content: center;
    position: relative!important;
    opacity: 0.8;
  }
  .bottom .sidebar-icon {
    font-size: 14px!important;
  }

  &.full {
    width: $full-sidebar-size;
    transition: all 0.5s ease-out;
    .icon {
      transform: translateX(-5px);
    }
  }

  .logo-wrapper img {
    transition: all 0.5s ease-in-out;
  }

  .notification-section-title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 15px;
  }
  /*
  .toggle-sidebar {
    overflow: hidden;
    font-size: 16px;
    padding: 10px 10px;
    background: white;
    font-weight: 600;
    box-shadow: 20px 0px 24px -10px rgba($primary, 0.06);
    background: $primary-lighter;
    color: rgba($primary, 0.8);
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }
  */

  .toggle-sidebar {
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    cursor: pointer;
    border-radius: 100%;
    color: rgba($primary, .7);
    position: absolute;
    right: -18px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s ease-in;

    &::after {
      content: attr(data-content);
      display: block;
      position: absolute;
      top: 50%;
      left: 41px;
      font-size: 16px;
      padding: 3px 9px;
      background: white;
      font-weight: 600;
      color: rgba($primary, 0.8);
      border-radius: 10px;
      white-space: nowrap;
      box-shadow: 20px 0px 24px -10px rgba($primary, 0.06);
      transform: translateY(-50%);

      opacity: 0;
      transition: all 0.2s ease-in;
      pointer-events: none;
    }

    &:hover {
      background: lighten($primary-lighter, 2%);
      &::after {
        display: block;
        opacity: 1;
        pointer-events: all;
      }
    }

    &.collapsed {
      width: 30px;
      height: 30px;
      right: -15px;

      &::after {
        left: 35px;
      }
    }
  }

 /* NOTIFICATIONS */
  .upper-notification-row {
    border-bottom: 1px solid $primary-light;
    padding: 10px 15px;
  }

  .archive > * {
    background: transparent!important;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  /* Style for the notification count */
  .notification-count {
    position: relative;
    top: 0px;
    right: 0px;
    background-color: #f44336;
    color: #fff;
    font-size: 12px;
    padding: 5px;
    border-radius: 50%;
  }

  /* Container for the notifications popup */
  #notifications-popup {
    width: 500px;
    height: auto;
    position: absolute;
    bottom: 50px;
    left: calc(10px + $sidebar-size);
    background-color: #fff;
    border: 1px solid $primary-light;
    border-radius: 10px;
    z-index: 1;
    overflow: hidden;
    cursor: default;
    transition: all 0.5s ease-in-out;
    &.move-to-right {
      left: calc(10px + $full-sidebar-size);
      transition: all 0.5s ease-in-out;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.6;
  }

/* List  */
  ul {
    list-style: none;

    a {
      text-decoration: none;
    }
    .router-link-exact-active {
      background: $primary-lighter;
    }
    &.submenu {
      padding: 0px;
      z-index: +1;
      // overflow: hidden;
      li {
        padding-left: 70px;
        width: 100%;
        height: 40px;
        font-size: 14px;
      }
    }
    &.no-title li:hover::after {
      display: none;
    }

    .btn {}

    .custom-title {
      position: absolute;
      top: 0;
      left: $sidebar-size;
      font-size: 16px;
      padding: 14px 10px;
      background: white;
      font-weight: 600;
      color: rgba($primary, 0.8);
      border-radius: 0px 10px 10px 0px;
      box-shadow: 20px 0px 24px -10px rgba($primary, 0.06);
      white-space: nowrap;
      transition: all 0.5s ease-in;
    }

    .has-submenu {
      // hide default title in riconciliazione
      &:hover::after {
        display: none;
      }
    }

    li ul {
      padding: 0px;
      display: none;
      background: white;
      margin-left: $sidebar-size;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0px 10px 10px 0px;
      box-shadow: 20px 0px 24px -10px rgba($primary, 0.06);
      overflow: hidden;
      li {
        overflow: hidden;
        font-size: 16px;
        padding: 10px 10px;
        background: white;
        font-weight: 600;
        box-shadow: 20px 0px 24px -10px rgba($primary, 0.06);
        white-space: nowrap;
        &:hover {
          color: rgba($primary, 0.8)!important;
          &::after {
            display: none;
          }
        }
      }
      li::after {
        display: none;
      }
    }

    li:hover > ul{
      display: block;
      cursor: pointer;
    }

    li {
      position: relative;
      margin: auto;
      height: 52px;
      // padding: 18px 33px;
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      color: rgba($primary, 0.8)!important;
      font-weight: 600;
      font-size: 16px;
      &::after {
        content: attr(data-content);
        display: none;
        position: absolute;
        top: 0;
        left: $sidebar-size;
        font-size: 16px;
        padding: 14px 10px;
        background: white;
        font-weight: 600;
        color: rgba($primary, 0.8);
        border-radius: 0px 10px 10px 0px;
        white-space: nowrap;
        transition: all 0.5s ease-in;
        box-shadow: 20px 0px 24px -10px rgba($primary, 0.06);
      }

      &.no-title:hover::after {
        display: none;
      }
      &:hover::after {
        display: inline-block;
      }
      &:hover {
        background: $primary-lighter;
        cursor: pointer;
      }
      &.bottom {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        max-height: 45px;
        color: rgba($primary, 0.75);
        i {
          font-size: 16px;
        }
        &::after {
          text-transform: none;
          max-height: inherit;
          padding: 10px;
        }
      }

      .name {
        position: relative;
        left: -15px;
        overflow: hidden;
      }

      i {
        position: relative;
        color: $primary;
        opacity: 0.8;
        transition: all 0.5s ease-out;
      }

      div, a {
        white-space: nowrap;
        transition: all 0.5s ease-out;
        // padding-left: 10px;
      }
    }
  }

  .notification-sidebar {
    position: absolute;
    background: $danger;
    padding: 2px;
    color: white;
    font-size: 10px;
    height: 14px;
    min-width: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    top: 14px;
    bottom: 0;
    right: 20px;
    margin: auto;
    z-index: 1;
  }

  .notification-sidebar-secondary {
    background: $primary-mid;
    padding: 0!important;
    color: white;
    font-weight: 700;
    font-size: 12px;
    width: 18px;
    height: 18px;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    min-width: fit-content;
    top: 0px;
    bottom: 0;
    right: 10px;
    margin: auto;
  }

  .company {
    color: $primary;
    background: $primary-lighter;
    height: 100%;
    padding: 10px;
    white-space: nowrap;
    padding-left: 30px;
    overflow: hidden;
    i {
      transition: all 0.5s ease-out;
      font-size: 22px;
      position: absolute;
      margin: auto;
    }
    .company-owner {
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 16px;
      width: 85%;
      overflow: hidden;
    }
    .vat {
      font-weight: 500;
      font-size: 13px;
      opacity: 0.7;
    }
  }

  .user-wrapper {
    background: transparent;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    &:hover {
      background: $primary-lighter;
    }

    .circle-wrapper {
      display: flex;
      justify-content: center;
      .active {
        background: $primary-mid;
        border-radius: 18px;
      }
      .circle {
        width: 35px;
        height: 35px;
        border-radius: 18px;
        background: $primary-mid;
        opacity: 0.6;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: 600;
        color: white;
        cursor: pointer;
      }
    }

    .user-name {
      font-size: 14px;
      font-weight: 600;
      color: $primary;
      opacity: 0.75;
      white-space: nowrap;
      overflow: ellipsis;
      margin-left: 8px;
    }
  }

  .notification-box {
    background: transparent;
    padding: 8px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    &:hover {
      background: $primary-lighter;
    }
    .number-of-notifications {
      z-index: 10!important;
      position: absolute;
      text-align: center;
      min-width: 14px;
      top: -5px;
      right: 0;
      padding: 2px 3px;
      line-height: 10px;
      font-size: 10px;
      font-weight: 600;
      border-radius: 10px;
      background: $red!important;
      color: white;

      overflow: visible;
    }
  }

  .btn-container {
    margin: 15px;
    .btn-add {
      padding: 10px;
      background: $primary;
      color: white;
      position: relative;
      i {
        transition: all 0.5s ease-out;
        font-size: 18px;
      }
      div {
        transition: all 0.5s ease-out;
        font-size: 16px;
      }
    }
  }

  .dropdown {
    position: relative;
    width: 100%;
    margin: 0px 10px 0px 10px;
    .dropdown-toggle {
      width: 100%!important;
    }
    &.sm button {
      display: flex;
      justify-content: center;
    }
    button {
      overflow: hidden;
      position: absolute;
      left: 0px;
      right: 0px;
      // no caret
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
        outline: none;
      }
      .red > * {
        color: $danger !important;
      }
      .dropdown-item {
        color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
          outline: none;
        }
        &:active {
          background: $primary-light;
        }
      }
    }
  }

  .dropdown-menu li {
    &::after {
      display: none!important;
    }
  }

  // animations
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
    width: auto;
  }
  .rotated {
    transform: rotate(180deg);
    transition: all 0.3s ease-out;
  }
  .hidden {
    opacity: 0;
    width: 0px;
    transition: all 0.5s ease-out;
  }
}
</style>
