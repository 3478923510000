<template>
  <div class="promises-chart">
    <v-chart :options="lineChart" :autoresize="true" @legendselectchanged="onLegendSelected" class="line-chart w-100" :key="`promises_${update}`"/>
  </div>
</template>

<script>
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import { toFullHumanDate } from '@/helpers/dates.js';

export default {
  props: {
    banks: Array,
    series: Array,
    todayDate: String,
    rangeDays: Number,
  },
  components: {
    'v-chart': ECharts,
  },
  watch: {
    series: {
      handler () {
        this.lineChart.series = this.series;
        this.getXAxis(this.rangeDays);
        this.update++;
        this.lineChart.legend.selected = this.oldSelected;
      },
      deep: true,
    },
  },
  data () {
    return {
      items: [],
      xAxis: [],
      update: 0,
      oldSelected: null,

      lineChart: {
        color: [
          '#fe639b',
          '#885edb',
          '#5855ff',
          '#35a1ea',
          '#4cc0c0',
          '#b2c100',
          '#f3aa3b',
          '#ff9357',
          '#fd5050',
          '#b52469',
          '#d369d6',
          '#6988d6',
          '#46cf7d',
          '#00d26d',
          '#d7ce00',
          '#708080',
        ],
        backgroundColor: '#ffffff',
        /*
        title: {
          text: 'Saldi',
          textStyle: {
            color: '#2c3576',
          },
          subtextStyle: {
            color: '#2c3576',
          },
        },
        */
        line: {
          /*
          itemStyle: { borderWidth: 1 },
          lineStyle: {
            width: 2,
          },
          */
          symbolSize: 4,
          symbol: 'circle',
          smooth: false,
        },
        tooltip: {
          // className: 'custom-tooltip',
          trigger: 'axis',
          backgroundColor: '#fff',
          // shadowOffsetX: 1,
          // shadowColor: '#2C3576',
          // shadowOffsetY: 2,
          borderWidth: 1,
          borderRadius: 10,
          padding: 15,
          textStyle: {
            color: '#2C3576',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontSize: 14,
          },
          axisPointer: {
            type: 'line', // cross, shadow
            snap: true,
          },
          toolbox: {
            feature: {
              show: true,
              orient: 'horizontal',
              left: 'right',
              top: 'top',
              backgroundColor: 'transparent',
              borderColor: '#ccc',
              borderRadius: 0,
              borderWidth: 0,
              padding: 5,
              itemSize: 15,
              itemGap: 8,
              fontSize: 15,
              showTitle: true,
              saveAsImage: {},
            },
          },
        },
        legend: {
          // right: 0,
          align: 'auto',
          padding: [10, 10, 20, 20],
          data: [],
          // itemGap: 15,
          textStyle: {
            color: '#2C3576',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontSize: 14,
          },
          emphasis: {
            focus: 'series',
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.1)',
            },
          },
          selected: {
            'Totale banche': false,
            'Totale tesoreria': false,
            'Scadenzario Attivo': false,
            Saldo: false,
          },
        },
        grid: {
          left: '1%',
          right: '2%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false,
          },
        },
        series: [],
      },
    };
  },
  computed: {
    range () {
      return this.rangeDays;
    },
  },
  methods: {
    getDate (i) {
      const x = new Date();
      const y = new Date(x.setDate(x.getDate(this.todayDate) + i));
      return toFullHumanDate(y);
    },
    getXAxis () {
      const xAxis = [];
      for (let i = 0; i < this.range; i++) {
        xAxis.push(this.getDate(i));
      }
      this.lineChart.xAxis.data = xAxis;
    },
    onLegendSelected (event) {
      this.oldSelected = event.selected;
    },
  },
  mounted () {
    this.getXAxis();
    this.lineChart.legend.data = this.banks;
    this.lineChart.series = this.series;
  },
};
</script>
