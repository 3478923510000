<template>
  <div class="tutorial">
    <div
      ref="welcomeCard"
      class="pretty-card"
    >
      <h4 class="mb-4"> Benvenuto su NinaFin </h4>
      <p>
        L'introduzione dura soltanto qualche minuto, vuoi procedere?
      </p>
      <div class="row">
        <div class="col-6">
            <custom-button
            label="Salta"
            class="w-100 mt-4 btn-secondary"
            @click.prevent.native="closePopup"
            />
        </div>
        <div class="col-6">
            <custom-button
            label="Avanti"
            class="w-100 mt-4"
            @click.prevent.native="goForward"
            />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Button from '@/views/components/Button.vue';

export default {
  components: {
    'custom-button': Button,
  },
  data () {
    return {
      me: this.$oauth2.me,
    };
  },
  methods: {
    closePopup () {
      this.$emit('close');
    },
    goForward () {
      this.$emit('startTour');
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
  },
  mounted () {
    this.$log.debug();
  },
};
</script>

<style lang="scss">
/*
0: sidebar e table
1: sidebar e parte di table (dettaglio non visibile)
7: sidebar e parte di table senza banche
8: sidebar
10: Tutto coperto
*/
.tutorial {
    z-index: 100;
    background: rgba(27, 27, 27, 0.651);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    .pretty-card {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: fit-content;
        max-width: 600px;
        margin: auto;
    }
    /*
    .welcome-text {
        color: white;
        font-weight: 600;
    }
    */
}
</style>
