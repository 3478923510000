<template>
<div>
    <form
      @submit.prevent="onSubmit"
      class="text-start"
      novalidate
      >
      <div class="row">
        <div class="col-12">
          <BankSelect ref="bankSelect" noBox :banks="banks" :selectedItem="bank" @update="updateBank" @search:blur="showErrorBank = true" :class="{ invalid: !isValidBank && showErrorBank }"/>
          <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBank || !showErrorBank }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una banca
          </b-form-invalid-feedback>
        </div>

        <div class="col-12">
          <b-form-group
            class="mb-3"
            id="input-group-expiration"
            label="Data"
            label-for="input-expiration"
            :class="{ invalid: !isValidExpiration && showErrorExpiration }"
          >
            <DatePicker id="input-expiration" :value="expiration" @setValue="setDate" @blur="showErrorExpiration = true" @input="showErrorExpiration = true" :key="`updateExpiration`" lastMonthButtons/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidExpiration || !showErrorExpiration }" >
              <i class="fas fa-exclamation-circle"></i>
              {{ !expirationIsBeforeToday ? 'La data inserita non deve superare quella odierna' : 'Inserisci una data valida' }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-12">
          <b-form-group
            class="mb-3"
            id="input-group-type"
            label="Tipo di movimento"
            label-for="input-type"
            :class="{disabled: form}"
          >
            <b-form-radio-group
              id="radio-group-1"
              v-model="in_out"
              @change="type = null"
              name="radio-options"
              class="mt-3"
              :class="{disabled: form}"
            >
              <b-form-radio :class="{disabled: form}" value="in">Entrata</b-form-radio>
              <b-form-radio :class="{disabled: form}" value="out">Uscita</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <div class="col-12">
          <b-form-group
            class="mb-3"
            id="input-group-type"
            :class="{ invalid: !isValidType && showErrorType, disabled: form }"
          >
            <vue-select v-model="type" :options="formattedTypes" label="name" placeholder="Seleziona un tipo..." :disabled="Boolean(form)" :clearable="false">
              <template v-slot:no-options>
                  <div class="text-center py-2">
                    Nessun tipo trovato
                  </div>
              </template>
            </vue-select>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidType || !showErrorType }" >
                <i class="fas fa-exclamation-circle"></i> Seleziona un tipo di movimento
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            class="mb-3"
            id="input-group-amount"
            label="Importo"
            label-for="input-amount"
            :class="{ invalid: !isValidAmount && showErrorAmount }"
          >
            <b-input-group class="prepend">
              <template #prepend>
                <i v-if="in_out === 'in'" class="fas fa-plus-circle green" />
                <i v-if="in_out === 'out'" class="fas fa-minus-circle red" />
              </template>
              <CurrencyInput id="input-amount" :key="`update_${change}_${Boolean(form)}`" v-model="amount" @blur="showErrorAmount = true" :class="in_out === 'in' ? 'in' : 'out'" />
            </b-input-group>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmount || !showErrorAmount }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-12">
          <b-form-group
            class="mb-4"
            id="input-group-description"
            label="Descrizione"
            label-for="input-description"
            :class="{ invalid: !isValidDescription && showErrorDescription }"
          >
            <textarea
              id="input-description"
              class="form-control"
              rows="3"
              v-model="description"
              @blur="showErrorDescription = true"
            ></textarea>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidDescription || !showErrorDescription }" >
              <i class="fas fa-exclamation-circle"></i>{{ description.length > 250 ? 'Puoi inserire fino a 250 caratteri' : 'Inserisci una descrizione valida' }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-12">
          <custom-button
            :isLoading="loading"
            label="Contabilizza"
            class="w-100"
            @click.prevent.native="onSubmit"
          />
        </div>
      </div>

      <ErrorCard v-if="errorMessage">
        <template #message >
          {{ errorMessage }}
        </template>
      </ErrorCard>
    </form>
</div>
</template>

<script>
import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import DatePicker from '@/views/components/DatePicker.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import Select from 'vue-select';

import { BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isCurrency, isDate, isValidYear } from '@/helpers/formValidation.js';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    banks: Array,
    companyId: String,
    form: Object,
    rettificaAmount: Number,
  },
  components: {
    BankSelect,
    'custom-button': Button,
    CurrencyInput,
    DatePicker,
    ErrorCard,
    'vue-select': Select,
    BFormGroup,
    BFormInvalidFeedback,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      showErrorBank: false,
      showErrorExpiration: false,
      showErrorType: false,
      showErrorAmount: false,
      showErrorDescription: false,
      loading: false,
      errorMessage: '',

      change: false,

      types: [
        { type: 'anticipo_entrata', name: 'Accredito Anticipo', in_out: 'in' },
        { type: 'finanziamento_entrata', name: 'Accredito Finanziamento', in_out: 'in' },
        { type: 'girofondo_entrata', name: 'Accredito Girofondo', in_out: 'in' },
        { type: 'riba_attiva', name: 'Ri.Ba. o titolo di credito attivo', in_out: 'in' },
        { type: 'versamento', name: 'Versamento', in_out: 'in', cash: true },
        { type: 'accredito', name: 'Altro Accredito', in_out: 'in', cash: true },
        { type: 'scadenzario_attivo', name: 'Scadenzario Attivo', in_out: 'in', cash: true },
        { type: 'scadenzario_passivo', name: 'Scadenzario Passivo', in_out: 'out', cash: true },
        { type: 'anticipo_uscita', name: 'Estinzione Anticipo', in_out: 'out' },
        { type: 'finanziamento_uscita', name: 'Rata Finanziamento', in_out: 'out' },
        { type: 'girofondo_uscita', name: 'Addebito Girofondo', in_out: 'out' },
        { type: 'riba_passiva', name: 'Ri.Ba o titolo di credito passivo', in_out: 'out' },
        { type: 'prelievo', name: 'Prelievo', in_out: 'out', cash: true },
        { type: 'stipendio', name: 'Stipendio', in_out: 'out' },
        { type: 'competenza', name: 'Competenze e Oneri Bancari', in_out: 'out' },
        { type: 'pagamento_fiscale', name: 'Pagamento Fiscale', in_out: 'out' },
        { type: 'bonifico_uscita', name: 'Altro Bonifico Addebitato', in_out: 'out' },
        { type: 'addebito', name: 'Altro Addebito', in_out: 'out', cash: true },
      ],

      // Form
      id: null,
      bank: null,
      amount: null,
      in_out: 'in',
      type: null,
      expiration: new Date(),
      description: '',
    };
  },
  watch: {
    bank: function () {
      this.$emit('updateColours', this.bank?.colour);
    },
    form: {
      handler () {
        this.change = !this.change;
        if (this.form) {
          this.setForm();
        } else {
          this.resetForm();
        }
      },
      deep: true,
    },
  },
  computed: {
    formattedTypes () {
      if (this.bank?.isCash) {
        return this.types.filter(type => (type.in_out === this.in_out) && type.cash);
      } else {
        return this.types.filter(type => type.in_out === this.in_out);
      }
    },
    isValidForm () {
      return this.isValidBank && this.isValidExpiration && this.isValidType && this.isValidAmount && this.isValidDescription;
    },
    isValidBank () {
      return isFilled(this.bank);
    },
    isValidAmount () {
      return isFilled(this.amount) && isCurrency(this.amount);
    },
    isValidExpiration () {
      return isDate(this.expiration) && isValidYear(this.expiration, 2000, 2100) && this.expirationIsBeforeToday;
    },
    expirationIsBeforeToday () {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      this.expiration.setHours(0, 0, 0, 0);
      return this.expiration <= today;
    },
    isValidDescription () {
      return this.description.length <= 250;
    },
    isValidType () {
      return isFilled(this.type);
    },
  },
  methods: {
    confirm () {
      this.onSubmit(true);
    },
    failure () {
      this.loading = false;
      this.errorMessage = "Qualcosa è andato storto! Ti preghiamo di riprovare più tardi o di contattare l'assistenza se il problema persiste.";
    },
    onSubmit (confirm = false) {
      this.errorMessage = '';
      if (this.isValidForm) {
        if (!confirm) {
          this.loading = true;
        }
        const amount = this.in_out === 'in' ? this.amount : -this.amount;
        const movementToAccount = Object.assign({}, { bankId: this.bank.id, amount: amount, type: this.type.type, date: this.expiration, description: this.description, promiseId: this.id, rettificaAmount: this.rettificaAmount });
        this.$emit('accountMovement', movementToAccount);
      } else {
        this.loading = true;
        if (!this.isValidBank) {
          this.showErrorBank = true;
        } else if (!this.isValidExpiration) {
          this.showErrorExpiration = true;
        } else if (!this.isValidType) {
          this.showErrorType = true;
        } else if (!this.isValidAmount) {
          this.showErrorAmount = true;
        } else if (!this.isValidDescription) {
          this.showErrorDescription = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    searchBankById (bankId) {
      return this.banks.find(bank => bank.id === bankId) || null;
    },
    searchTypeByValue (typeValue) {
      return this.types.find(type => type.type === typeValue);
    },
    setDate (newDate) {
      this.expiration = newDate;
    },
    setForm () {
      this.errorMessage = '';
      if (this.form) {
        this.id = this.form.id;
        this.bank = this.form.bank?.fabrickId ? null : this.form.bank;
        this.amount = Math.abs(this.form.amount);
        this.type = this.searchTypeByValue(this.form.type) || null;
        this.in_out = this.type.in_out;
        // this.expiration = this.form.date;
        this.description = this.form?.description ? this.form?.description : '';
      }
    },
    /*
    setToday () {
      const today = new Date();
      return today.getFullYear().toString() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    },
    */
    success () {
      this.loading = false;
      this.resetForm();
    },
    resetForm () {
      this.id = null;
      this.bank = null;
      this.amount = null;
      this.in_out = 'in';
      this.type = null;
      this.expiration = new Date();
      this.description = '';

      this.showErrorBank = false;
      this.showErrorExpiration = true; // c'è data di default
      this.showErrorType = false;
      this.showErrorAmount = false;
      this.showErrorDescription = false;

      this.errorMessage = '';
    },
    stopLoading () {
      this.loading = false;
    },
    updateBank (bank) {
      this.bank = bank;
    },
  },
};
</script>
