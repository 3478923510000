<template>
    <b-form-group :class="[{box: !noBox}, noBox ? 'bg-none' : bgColour, textColour]">
        <div class="row">
            <div class="col">
                <label for="bank-selector">{{label}}</label>
            </div>
            <div v-if="selectedBank && selectedBank.id" class="col text-right">
                <BrainScore :brainScore="selectedBank.brainScore" :colour="selectedBank.colour"/>
            </div>
        </div>
        <vue-select
            v-model="selectedBank"
            id="bank-selector"
            :options="banks"
            :label="selectedBank?.alias ? 'alias' : 'name'"
            :clearable="false"
            placeholder="Seleziona una banca"
            @search:blur="$emit('search:blur')"
            :disabled="disabled"
        >
            <template #option="row">
              <div class="list-element">
                  <img
                  :src="(row.isCash || row.type === 'cash') ? cashLogoDefault : ( row.logo ? row.logo : (row.bankDetail?.logo ? row.bankDetail?.logo : bankLogoDefault))"
                  class="bank-logo mr-2"
                  />
                  {{ row.alias ? row.alias : row.name }}
              </div>
            </template>

            <template #selected-option="row">
              <div :class="textColour" class="font-weight-600">
                  <img
                  :src="(row.isCash || row.type === 'cash') ? cashLogoDefault : ( row.logo ? row.logo : (row.bankDetail?.logo ? row.bankDetail?.logo : bankLogoDefault))"
                  class="bank-logo mr-2"
                  />
                  {{ row.alias ? row.alias : row.name }}
              </div>
            </template>

            <template v-slot:no-options>
              <div class="text-center py-2">Nessuna banca trovata</div>
            </template>
        </vue-select>
    </b-form-group>
</template>

<script>

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';
import BrainScore from '@/views/components/BrainScore.vue';
import Select from 'vue-select';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Banca',
    },
    banks: Array,
    selectedItem: Object,
    noBox: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BrainScore,
    'vue-select': Select,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      selectedBank: this.selectedItem ? this.selectedItem : null,
    };
  },
  watch: {
    selectedBank () {
      return this.$emit('update', this.selectedBank);
    },
    selectedItem () {
      this.selectedBank = this.selectedItem;
    },
  },
  computed: {
    colour () {
      return this.selectedBank ? this.selectedBank.colour : 0;
    },
    bgColour () {
      return this.colour ? `bank-variant-${this.colour}-light` : 'primary-light';
    },
    textColour () {
      return this.colour ? `text-bank-variant-${this.colour}` : 'text-primary';
    },
  },
};
</script>
