<template>
  <div class="credit-card--container text-center text-md-left">
    <label class="subheading d-none" :for="cardId">Dettagli della carta</label>
    <div class="credit-card--info mx-auto mx-md-0" :id="cardId"></div>
  </div>
</template>

<script>

import * as stripeService from './stripe';
import $config from '@/libs/Config/services/config.js';

export default {
  props: {
    cardId: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    initializeStripeElements () {
      if (!this.stripe) {
        throw new Error('Missing Stripe initialization');
      }

      const style = {
        base: {
          fontSmoothing: 'antialiased',
        },
        invalid: {
          color: '#ff0000',
          iconColor: '#ff0000',
        },
      };

      const elements = this.stripe.elements();

      var card = elements.create('card', { style });
      card.mount('#card-element');

      card.on('change', event => {
        this.$emit('input', event.complete);
      });

      this.$emit('cardInit', { error: null, card });
    },
  },
  async mounted () {
    try {
      await stripeService.install();

      stripeService.init($config.get('stripe_token')).then(stripe => {
        this.stripe = stripe;
        this.initializeStripeElements();

        this.$log.debug(this.stripe);
        this.$emit('init', { error: null, stripe });
      }).catch(err => {
        this.$emit('init', { error: err, stripe: null });
        this.$log.error(err);
      });
    } catch (err) {
      this.$emit('init', { error: err, stripe: null });
    }
  },
};
</script>

<style lang="scss">
.credit-card {
  &--info {
    padding: 20px;
    border: 1px solid #DADADA;
    border-radius: 10px;

    @include media-breakpoint-up("sm") {
      min-width: 450px;
    }
  }
}
</style>
