import $oauth2 from '../libs/OAuth2/services/auth';

const getMe = async () => {
  try {
    const me = await $oauth2.getMe();
    if (!me) {
      return null;
    }

    return me;
  } catch (err) {
    return null;
  }
};

// se utente non ha effettuato setup, dopo il login viene reindirizzato a setup

export default async (to, from, next) => {
  const me = await getMe();

  if (to.meta.auth === false && me) {
    return next({ name: 'dashboard' });
  }

  if (to.meta.auth && !me) {
    return next({ name: 'login' });
  }

  // CONTROLLO LICENZA
  // to do aggiungere status licenza anche per manger e visitor
  /*
  if (me.product?.expired && me.companies[0]?.role === 'owner') {
    return next({ name: 'checkout' });
  }

  if (me.companies[0].product.isExpired && me.companies[0]?.role !== 'owner') {
    return next({ name: 'expired' });
  }

  if (to.name === 'expired' && ---not-expired---) {
    return next({ name: 'dashboard' })
  }
  */

  // CONTROLLO SETUP
  /*
  if (to.name === 'dashboard' && me.companies?.length === 0) {
    return next({ name: 'setup' });
  }
  */

  // CONTROLLO PERMESSI
  /* to do da rivedere con utente multicompany */
  if (to.meta.noVisitor && me.companies[0]?.role === 'visitor') {
    return next({ name: 'primeEntry' });
  }

  if (to.meta.noManager && me.companies[0]?.role === 'manager') {
    return next({ name: 'dashboard' });
  }

  return next();
};
