import formIdMixin from './formId';
import formInputWidgetPropsMixin from './formInputWidgetProps';
import formInputMixin from './formInput';
import formLabelMixin from './formLabel';
import formErrorMixin from './formError';

export default {
  mixins: [formIdMixin, formInputWidgetPropsMixin, formInputMixin, formLabelMixin, formErrorMixin],
  components: {
    FormInputWidget: () => import('../Widgets/FormInputWidget'),
  },
  computed: {
    hasPrependContentSlot () {
      return !!this.$scopedSlots['prepend-content'];
    },
    hasPrependSlot () {
      return !!this.$scopedSlots.prepend;
    },
    hasAppendContentSlot () {
      return !!this.$scopedSlots['append-content'];
    },
    hasAppendSlot () {
      return !!this.$scopedSlots.append;
    },
  },
  methods: {
    emitInput (newValue) {
      this.$emit('input', newValue);
    },
  },
};
