<template>
    <div class="notifications-popup">
        <div class="row upper-notification-row d-flex justify-content-between">
        <div class="col-auto title">
            Avvisi
        </div>
        <div class="col-auto archive" @click="$emit('closeNotificationsPopup')">
            <router-link tag="span" :to="{ name: 'archive' }">
            Archivio
            </router-link>
        </div>
        </div>
        <div class="notifications-box" id="notifications-box" v-scrolled-to-bottom="getMore">
            <span class="notification-section-title" v-if="importantNotifications?.length > 0">Importanti</span>

            <div class="row gap">
                <div v-for="(notification, i) in importantNotifications" :key="`vip_notification_${i}`" class="col-12">
                    <div class="notification d-flex" :class="notification?.hasBeenRead ? 'read' : 'to-read'" @click="goToDetails(notification)">
                    <img class="notification-icon" :src="notification?.icon ? notification?.icon : infoIcon">
                    <div class="notification-text">
                        {{notification?.body}}
                        <span class="notification-timestamp w-100">{{getTimeSince(notification?.timestamp)}}</span>
                    </div>
                    </div>
                </div>
            </div>

            <span class="notification-section-title" v-if="notifications?.length > 0" :class="{'mt-4': importantNotifications?.length > 0}">Altri</span>

            <div class="row gap">
                <div v-for="(notification, i) in notifications" :key="`vip_notification_${i}`" class="col-12">
                    <div class="notification d-flex" :class="notification?.hasBeenRead ? 'read' : 'to-read'" @click="goToDetails(notification)">
                    <img class="notification-icon" :src="notification?.icon ? notification?.icon : infoIcon">
                    <div class="notification-text mr-4">
                        {{notification?.body}}
                        <span class="notification-timestamp w-100">{{getTimeSince(notification?.createdAt)}}</span>
                    </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="emptyNotifications">
                <div class="col-12 d-flex justify-content-center align-items-center p-4">
                Non ci sono avvisi
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import infoIcon from '@/assets/images/info_icon.svg';

export default {
  props: {
    notificationsArray: Array,
  },
  data () {
    return {
      infoIcon: infoIcon,
      // notifications: [],
      // importantNotifications: [],
    };
  },
  directives: {
    'scrolled-to-bottom': {
      bind (el, binding, vnode) {
        let loading = false;
        const done = () => { loading = false; };

        el.scrollToBottom = function (event) {
          const distance = el.scrollTop;
          const scrollMax = el.scrollHeight - el.clientHeight;
          const trigger = distance > scrollMax - 5;

          if (trigger && !loading) {
            loading = true;
            // calls the method
            const result = vnode.context[binding.expression](event);
            if (result === undefined) done();
            else if (result.then && result.then.call) result.then(done, done);
            else done();
          }
        };

        el.addEventListener('scroll', el.scrollToBottom);
      },
      unbind (el) {
        el.removeEventListener('scroll', el.scrollToBottom);
      },
    },
  },
  computed: {
    emptyNotifications () {
      return this.notificationsArray?.length <= 0;
    },
    notifications () {
      return this.notificationsArray ? this.notificationsArray.filter((obj) => obj.category === 'n' && !obj.isImportant) : [];
    },
    importantNotifications () {
      return this.notificationsArray ? this.notificationsArray.filter((obj) => obj.category === 'n' && obj.isImportant) : [];
    },
  },
  methods: {
    getTimeSince (datetime) {
      const timestamp = new Date(datetime);
      const now = new Date();
      const secondsPast = (now.getTime() - timestamp.getTime()) / 1000;
      if (secondsPast < 60) {
        return parseInt(secondsPast) + ' secondi fa';
      }
      if (secondsPast < 3600) {
        return parseInt(secondsPast / 60) + ' minuti fa';
      }
      if (secondsPast <= 86400) {
        return parseInt(secondsPast / 3600) + ' ore fa';
      }
      if (secondsPast > 86400) {
        const day = timestamp.getDate();
        const month = timestamp.toDateString().match(/ [a-zA-Z]*/)[0].replace(' ', '');
        const year = timestamp.getFullYear() === now.getFullYear() ? '' : ' ' + timestamp.getFullYear();
        return day + ' ' + month + year;
      }
    },
    goToDetails (notification) {
      this.$router.push({ name: 'notificationDetails', params: { notification: notification, id: notification.notificationId } })
        .catch(() => {});
      this.$emit('closeNotificationsPopup');
    },
    getMore () {
      this.$emit('getMore');
    },
  },
};
</script>

<style lang="scss">
.notifications-popup {
    box-sizing: content-box;
    .notifications-box {
    display: flex;
    flex-direction: column;
    padding: 15px;
    max-height: 400px;
    overflow-y: scroll;
  }

  .upper-notification-row {
    border-bottom: 1px solid $primary-light;
    padding: 10px 15px;
  }

  .archive > * {
    background: transparent!important;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

    /* Style for the notification count */
  .notification-count {
    position: relative;
    top: 0px;
    right: 0px;
    background-color: #f44336;
    color: #fff;
    font-size: 12px;
    padding: 5px;
    border-radius: 50%;
  }
}
</style>
