<template>
  <div class="edit-movement">
    <form
      @submit.prevent="onSubmit"
      class="text-start"
      novalidate
      >
      <h3 class="mb-4 mr-3">Modifica {{ obj.type | movement}} </h3>
      <b-form-group
        class="mb-3"
        id="input-group-bank"
        label="Banca"
        label-for="input-bank"
        :class="{ invalid: !isValidBank && showErrorBank, disabled: descriptionOnly }"
      >
        <vue-select v-model="bank" @search:blur="showErrorBank = true" :options="banks" label="name" placeholder="Seleziona una banca..." :clearable="false" :disabled="descriptionOnly">
          <template #option="row">
            <div class="list-element" :title="row.name">
              <!--<img :src="row.logo" class="bank-logo mr-2" @error="replaceImg">-->
              <img :src="row.isCash ? cashLogoDefault : (row.logo ? row.logo : bankLogoDefault)" class="bank-logo mr-2">
              {{ row.name }}
            </div>
          </template>
          <template #selected-option="row">
            <div :title="row.name">
              <img
                :src="row.isCash ? cashLogoDefault : (row.logo ? row.logo : bankLogoDefault)"
                class="bank-logo mr-2"
              />
              {{ row.name }}
            </div>
          </template>
          <template v-slot:no-options>
            <template>
              <div class="text-center py-2">
                Nessuna banca trovata
              </div>
            </template>
          </template>
        </vue-select>
        <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBank || !showErrorBank }" >
          <i class="fas fa-exclamation-circle"></i> Inserisci una banca
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="d-flex row">
        <div class="col-6">
          <b-form-group
            class="mb-3 text-right"
            id="input-group-amount"
            label="Importo"
            label-for="input-amount"
            :class="{ invalid: !isValidAmount && showErrorAmount, disabled: descriptionOnly }"
          >
            <CurrencyInput id="input-amount" v-model="amount" @blur="showErrorAmount = true" class="p-3 text-right" :class="{ disabled: descriptionOnly}"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmount || !showErrorAmount }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group
            class="mb-3"
            id="input-group-expiration"
            label="Scadenza"
            label-for="input-expiration"
            :class="{ invalid: !isValidExpiration && showErrorExpiration, disabled: descriptionOnly }"
          >
            <b-form-input
              id="input-expiration"
              v-model="expiration"
              type="date"
              @blur="showErrorExpiration = true"
              :disabled="descriptionOnly"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidExpiration || !showErrorExpiration }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una scadenza valida
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <b-form-group
        class="mb-3"
        id="input-group-description"
        :label="descriptionOnly ? 'Descrizione' : 'Causale'"
        label-for="input-description"
        :class="{ invalid: !isValidDescription && showErrorDescription }"
      >
        <textarea
          id="input-description"
          class="form-control"
          rows="3"
          v-model="description"
          @blur="showErrorDescription = true"
        ></textarea>
        <b-form-invalid-feedback :state="false" :class="{ invisible: isValidDescription || !showErrorDescription }" >
          <i class="fas fa-exclamation-circle"></i> {{ description.length > 250 ? 'Puoi inserire fino a 250 caratteri' : 'Inserisci una descrizione valida' }}
        </b-form-invalid-feedback>
      </b-form-group>

      <custom-button
          :isLoading="loading"
          label="Salva"
          class="w-100 mt-4"
          @click.prevent.native="onSubmit"
          :class="{disabled: !changes}"
        />

      <ErrorCard v-if="errorMessage" class="mt-2">
        <template #message >
          {{ errorMessage }}
        </template>
      </ErrorCard>
    </form>
  </div>
</template>

<script>
import formatMovementTypeFilter from '@/filters/formatMovementType';

import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import Select from 'vue-select';

import { BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isCurrency, isDate, isValidYear, isSafe } from '@/helpers/formValidation.js';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  name: 'EditMovement',
  props: {
    banks: Array,
    obj: Object,
    close: {
      type: Function,
    },
    descriptionOnly: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    movement: formatMovementTypeFilter,
  },
  components: {
    'custom-button': Button,
    CurrencyInput,
    ErrorCard,
    'vue-select': Select,
    'b-form-group': BFormGroup,
    'b-form-input': BFormInput,
    'b-form-invalid-feedback': BFormInvalidFeedback,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      showErrorBank: false,
      showErrorAmount: false,
      showErrorExpiration: false,
      showErrorDescription: false,
      loading: false,
      errorMessage: '',
      submitted: false,

      // Form
      oldBank: this.obj.bankName || '',
      oldAmount: parseFloat(this.obj.amount) || null,
      oldExpiration: this.obj.date || null,
      oldDescription: this.obj.description || '',

      type: this.obj.type,
      bank: this.obj.bankName || '',
      amount: parseFloat(this.obj.amount) || null,
      expiration: this.obj.date || null,
      description: this.obj.description || '',
    };
  },
  computed: {
    changes () {
      return (this.oldBank !== this.bank) ||
              (this.oldAmount !== this.amount) ||
              (this.oldExpiration !== this.expiration) ||
              (this.oldDescription !== this.description);
    },
    isValidForm () {
      return this.isValidBank && this.isValidAmount && this.isValidExpiration && this.isValidDescription;
    },
    isValidBank () {
      return isFilled(this.bank);
    },
    isValidAmount () {
      return isFilled(this.amount) && isCurrency(this.amount);
    },
    isValidExpiration () {
      return isDate(this.expiration) && isValidYear(this.expiration, 2000, 2100);
    },
    isValidDescription () {
      return isSafe(this.description) && this.description.length <= 250;
    },
  },
  methods: {
    onSubmit: function () {
      this.errorMessage = '';
      if (this.descriptionOnly) {
        this.loading = true;
        this.$api.updateMovement(this.obj.id, null, null, this.description, null, null)
          .then((res) => {
            this.$log.debug(res.data);
            this.$emit('save');
          })
          .catch((e) => {
            this.$log.error(e);
            this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.isValidForm) {
        this.loading = true;
        this.$api.updatePromise(this.obj.id, parseFloat(this.amount), this.expiration, this.description, this.type, this.bank.id)
          .then((res) => {
            this.$log.debug(res.data);
            this.$emit('save');
          })
          .catch((e) => {
            this.$log.error(e);
            this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = true;
        if (!this.isValidBank) {
          this.showErrorBank = true;
        } else if (!this.isValidAmount) {
          this.showErrorAmount = true;
        } else if (!this.isValidExpiration) {
          this.showErrorExpiration = true;
        } else if (!this.isValidDescription) {
          this.showErrorDescription = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
  },
};
</script>
