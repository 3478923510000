<template>
  <div :class="alertClass" class="alert" role="alert">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'alert',
  props: {
    status: {
      type: String,
      validator: value => {
        return [
          'primary',
          'secondary',
          'success',
          'danger',
          'warning',
          'info',
          'light',
          'dark',
        ].indexOf(value) !== -1;
      },
      default: 'danger',
    },
    fontSize: {
      type: String,
      validator: value => {
        return [
          'sm',
          'lg',
        ].indexOf(value) !== -1;
      },
      default: 'sm',
    },
  },
  computed: {
    alertClass () {
      return `alert-${this.status} alert-${this.fontSize}`;
    },
  },
};

</script>

<style lang="scss" scoped>
.alert {
  border-radius: 10px;
  border: 0;

  &-warning {
    color: saturate(darken($warning, 8%), 10%);
    background: rgba($warning, .1);
  }

  &-danger {
    color: $danger;
    background: rgba($danger, .1);
  }

  &-success {
    color: $success;
    background: rgba($success, .1);
  }

  &-primary {
    color: $primary;
    background: rgba($primary, .1);
  }

  &-sm {
    font-size: 14px;
  }

  &-lg {
    font-size: 16px;
  }

}</style>
