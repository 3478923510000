<template>
  <b-form-input
  ref="inputRef"
  type="text"
  @blur="$emit('blur')"
  ></b-form-input>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input';
import { BFormInput } from 'bootstrap-vue';

export default {
  name: 'CurrencyInput',
  components: {
    BFormInput,
  },
  props: {
    value: Number,
    options: Object,
  },
  setup (props) {
    const options = {
      locale: 'de-DE',
      currency: 'EUR',
      currencyDisplay: 'hidden',
      valueRange: undefined,
      precision: 2,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: false,
      useGrouping: true,
    };

    const { inputRef } = useCurrencyInput(options);

    return { inputRef };
  },
};
</script>
