<template>
  <div class="invoice">
    <form
      @keydown.enter="onNext"
      @submit.prevent="onNext"
      class="text-start"
      novalidate
      >
      <div class="row">
        <div class="col-6">
          <b-form-group
              class="mb-3 box px-0"
              id="input-group-number"
              label="Numero fattura*"
              label-for="input-number"
              :class="{ invalid: !isValidNumber && showErrorNumber }"
          >
            <b-form-input
            id="input-number"
            v-model="invoice.number"
            type="text"
            @blur="showErrorNumber = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidNumber || !showErrorNumber }" >
                <i class="fas fa-exclamation-circle"></i> Inserisci un numero valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            class="mb-3 box px-0"
            id="input-group-date"
            label="Data fattura*"
            label-for="input-date"
            :class="{ invalid: !isValidDate && showErrorDate }"
          >
            <DatePicker id="input-date" :value="invoice.date" @setValue="setDate" @blur="showErrorDate = true" :key="`updateAmortization_${update}`"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidDate || !showErrorDate }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una data valida
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <h5 class="mb-4">{{ fatturePassive ? 'Fornitore' : 'Cliente' }}</h5>

      <div class="d-flex row">
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-name"
            label="Ragione sociale*"
            label-for="input-name"
            :class="{ invalid: !isValidName && showErrorName }"
          >
            <b-form-input
            id="input-name"
            v-model="invoice.name"
            type="text"
            @blur="showErrorName = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidName || !showErrorName }" >
                <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-vat"
            label="Partita IVA*"
            label-for="input-vat"
            :class="{ invalid: (!isValidVat && showErrorVat) || (!isValidVatOrFiscalCode && showErrorVatOrFiscalCode) }"
          >
            <b-form-input
            id="input-vat"
            v-model="invoice.vat"
            type="text"
            @blur="showErrorVat = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: (isValidVat || !showErrorVat) && (isValidVatOrFiscalCode || !showErrorVatOrFiscalCode) }" >
                <i class="fas fa-exclamation-circle"></i>
                {{ !isValidVatOrFiscalCode ? 'Inserisci Partita IVA o Codice Fiscale' : 'Inserisci una partita IVA valida' }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-1 d-flex justify-content-center align-items-center opaque mb-3">
          oppure
        </div>
        <div class="col-3">
          <b-form-group
            class="mb-3"
            id="input-group-cf"
            label="Codice Fiscale*"
            label-for="input-cf"
            :class="{ invalid: (!isValidCf && showErrorCf) || (!isValidVatOrFiscalCode && showErrorVatOrFiscalCode) }"
          >
            <b-form-input
            id="input-cf"
            v-model="invoice.cf"
            type="text"
            @blur="showErrorCf = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: (isValidCf || !showErrorCf) && (isValidVatOrFiscalCode || !showErrorVatOrFiscalCode) }" >
                <i class="fas fa-exclamation-circle"></i>
                {{ !isValidVatOrFiscalCode ? 'Inserisci Partita IVA o Codice Fiscale' : 'Inserisci un codice fiscale valido' }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-8">
          <b-form-group
            class="mb-3"
            id="input-group-address"
            label="Indirizzo*"
            label-for="input-address"
            :class="{ invalid: !isValidAddress && showErrorAddress }"
          >
            <b-form-input
            id="input-address"
            v-model="invoice.address"
            type="text"
            @blur="showErrorAddress = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAddress || !showErrorAddress }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un indirizzo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-cap"
            label="CAP"
            label-for="input-cap"
            :class="{ invalid: !isValidCap && showErrorCap }"
          >
            <b-form-input
            id="input-cap"
            v-model="invoice.cap"
            type="number"
            @blur="showErrorCap = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidCap || !showErrorCap }" >
                <i class="fas fa-exclamation-circle"></i> Inserisci un CAP valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-municipality"
            label="Comune*"
            label-for="input-municipality"
            :class="{ invalid: !isValidMunicipality && showErrorMunicipality }"
          >
            <b-form-input
            id="input-municipality"
            v-model="invoice.municipality"
            type="text"
            @blur="showErrorMunicipality = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidMunicipality || !showErrorMunicipality }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un comune valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-province"
            label="Provincia*"
            label-for="input-province"
            :class="{ invalid: !isValidProvince && showErrorProvince }"
          >
            <b-form-input
            id="input-province"
            v-model="invoice.province"
            type="text"
            @blur="showErrorProvince = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidProvince || !showErrorProvince }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una provincia valida
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-state"
            label="Stato*"
            label-for="input-state"
            :class="{ invalid: !isValidState && showErrorState }"
          >
            <b-form-input
            id="input-state"
            v-model="invoice.state"
            type="text"
            @blur="showErrorState = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidState || !showErrorState }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci uno stato valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <h5 class="mb-4">Fattura</h5>

      <div class="row">
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-tax-base"
            label="Imponibile*"
            label-for="input-tax-base"
            :class="{ invalid: !isValidTaxBase && showErrorTaxBase }"
          >
            <CurrencyInput id="input-tax-base" v-model="invoice.taxBase" @blur="showErrorTaxBase = true"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidTaxBase || !showErrorTaxBase }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-iva"
            label="Importo IVA*"
            label-for="input-iva"
            :class="{ invalid: !isValidIva && showErrorIva }"
          >
            <CurrencyInput id="input-iva" v-model="invoice.iva" @blur="showErrorIva = true"/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidIva || !showErrorIva }" >
              <i class="fas fa-exclamation-circle"></i> {{ invoice.iva >= 0 ? 'Inserisci un importo valido' : "L'importo deve essere positivo" }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            class="mb-0 disabled"
            id="input-group-total-amount"
            label="Totale fattura"
            label-for="input-total-amount"
            :class="{ invalid: !isValidInvoiceAmount && showErrorInvoiceAmount }"
          >
            <CurrencyInput id="input-total-amount" :key="`invoiceAmount_${invoice.invoiceAmount}`" v-model="invoice.invoiceAmount" @blur="showErrorInvoiceAmount = true" @input.prevent=""/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidInvoiceAmount || !showErrorInvoiceAmount }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-8" v-if="fatturePassive">
          <div class="simple-card">
            <div class="title w-100">
              ritenuta
            </div>
              <div class="row content">

                <div class="col-6">
                  <b-form-group
                    class="mb-0 box px-0"
                    id="input-group-withholding-tax"
                    label="Importo"
                    label-for="input-withholding-tax"
                  >
                    <CurrencyInput id="input-withholding-tax" v-model="invoice.withholdingTax"/>
                    <b-form-invalid-feedback :state="false" :class="{ invisible: isValidWithholdingTax }" >
                      <i class="fas fa-exclamation-circle"></i> {{ invoice.withholdingTax >= 0 ? 'Inserisci un importo valido' : "L'importo deve essere positivo" }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group
                    class="mb-0 box px-0"
                    id="input-group-withholding-date"
                    label="Data pagamento"
                    label-for="input-withholding-date"
                    :class="{ invalid: !isValidWithholdingDate && showErrorWithholdingDate }"
                  >
                    <DatePicker id="input-release-date" :value="invoice.withholdingDate" @setValue="setDate2" @blur="showErrorWithholdingDate = true" :key="`withholdingDate_${update}`"/>
                    <b-form-invalid-feedback :state="false" :class="{ invisible: isValidWithholdingDate || !showErrorWithholdingDate }" >
                      <i class="fas fa-exclamation-circle"></i> Inserisci una data valida
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-2 d-flex align-items-center justify-content-left">
                  <b-form-checkbox v-model="invoice.withholdingIsPaid" name="input-withholding-paid">
                    Pagata
                  </b-form-checkbox>
                </div>
                <div class="col-12">
                  <BankSelect ref="bankSelect2" label="Banca pagamento" :banks="banks" :selectedItem="invoice.bank2" @update="updateBank2"/>
                </div>
              </div>
          </div>
        </div>
        <div class="col-4">
          <b-form-group
            class="mb-3 box px-0 disabled"
            id="input-group-amount"
            label="Totale da pagare"
            label-for="input-amount"
            :class="{ invalid: !isValidAmount && showErrorAmount }"
          >
            <CurrencyInput id="input-amount" :key="`invoice_amount_${invoice.amount}`" v-model="invoice.amount" @blur="showErrorAmount = true" @input.prevent=""/>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAmount || !showErrorAmount }" >
              <i class="fas fa-exclamation-circle"></i> L'importo deve essere positivo
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <custom-button
          :isLoading="loading"
          label="Avanti"
          class="w-100 mt-4"
          @click.prevent.native="onNext"
        />

      <ErrorCard v-if="errorMessage">
        <template #message >
          {{ errorMessage }}
        </template>
      </ErrorCard>
    </form>
  </div>
</template>

<script>
import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import DatePicker from '@/views/components/DatePicker.vue';
import ErrorCard from '@/views/components/ErrorCard.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';

import { BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isCurrency, isIva, isFiscalCode, isCap } from '@/helpers/formValidation.js';

export default {
  props: {
    banks: Array,
    propInvoice: Object,
    fatturePassive: Boolean,
  },
  components: {
    BankSelect,
    'custom-button': Button,
    CurrencyInput,
    DatePicker,
    ErrorCard,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
  },
  watch: {
    watchedTaxBase () {
      this.updateTotalInvoice();
    },
    watchedIva () {
      this.updateTotalInvoice();
    },
    watchedWithholdingTax () {
      this.updateAmountToPay();
    },
    watchedTotalInvoice () {
      this.updateAmountToPay();
    },
  },
  data () {
    return {
      invoice: this.propInvoice,
      update: 0,

      showErrorNumber: false,
      showErrorDate: false,
      showErrorBank: false,
      showErrorName: false,
      showErrorVat: false,
      showErrorCf: false,
      showErrorVatOrFiscalCode: false,
      showErrorAddress: false,
      showErrorCap: false,
      showErrorMunicipality: false,
      showErrorProvince: false,
      showErrorState: false,
      showErrorTaxBase: false,
      showErrorIva: false,
      showErrorInvoiceAmount: false,
      showErrorAmount: true,
      showErrorWithholdingDate: false,

      loading: false,
      errorMessage: '',
    };
  },
  computed: {
    isValidForm () {
      return this.isValidNumber && this.isValidDate && this.isValidName && this.isValidVat && this.isValidVatOrFiscalCode && this.isValidCf && this.isValidAddress && this.isValidCap && this.isValidMunicipality && this.isValidProvince && this.isValidState && this.isValidTaxBase && this.isValidIva && this.isValidInvoiceAmount && (this.fatturePassive ? this.isValidWithholdingTax && this.isValidAmount && this.isValidWithholdingDate : true);
    },
    watchedTaxBase () {
      return this.invoice.taxBase;
    },
    watchedIva () {
      return this.invoice.iva;
    },
    watchedWithholdingTax () {
      return this.invoice.withholdingTax;
    },
    watchedTotalInvoice () {
      return this.invoice.invoiceAmount;
    },
    /* -----------------Campi obbligatori----------------- */
    isValidNumber () {
      return isFilled(this.invoice.number);
    },
    isValidDate () {
      return isFilled(this.invoice.date);
    },
    isValidName () {
      return isFilled(this.invoice.name);
    },
    isValidTaxBase () {
      return isFilled(this.invoice.taxBase) && isCurrency(this.invoice.taxBase);
    },
    isValidIva () {
      return isFilled(this.invoice.iva) && isCurrency(this.invoice.iva) && this.invoice.iva >= 0;
    },
    isValidVatOrFiscalCode () {
      return isFilled(this.invoice.vat) || isFilled(this.invoice.cf);
    },
    /* -----------------Campi opzionali----------------- */
    isValidVat () {
      return (!isFilled(this.invoice.vat) && isFilled(this.invoice.cf)) || isIva(this.invoice.vat);
    },
    isValidCf () {
      return (!isFilled(this.invoice.cf) && isFilled(this.invoice.vat)) || isFiscalCode(this.invoice.cf);
    },
    isValidAddress () {
      return isFilled(this.invoice.address);
    },
    isValidCap () {
      return isFilled(this.invoice.cap) ? isCap(this.invoice.cap) : true;
    },
    isValidMunicipality () {
      return isFilled(this.invoice.municipality);
    },
    isValidProvince () {
      return isFilled(this.invoice.province);
    },
    isValidState () {
      return isFilled(this.invoice.state);
    },
    isValidInvoiceAmount () {
      return isFilled(this.invoice.invoiceAmount) ? isCurrency(this.invoice.invoiceAmount) : true;
    },
    isValidWithholdingTax () {
      return isFilled(this.invoice.withholdingTax) ? (isCurrency(this.invoice.withholdingTax) && this.invoice.withholdingTax >= 0) : true;
    },
    isValidWithholdingDate () {
      return isFilled(this.invoice.withholdingDate) || !this.invoice.withholdingTax;
    },
    isValidBank2 () {
      return isFilled(this.invoice.bank2);
    },
    isValidAmount () {
      return isFilled(this.invoice.amount) ? (isCurrency(this.invoice.amount) && this.invoice.amount >= 0) : true;
    },
  },
  methods: {
    getBankById (bankId) {
      return this.banks.find(bank => bank.id === bankId);
    },
    onNext: function () {
      this.errorMessage = '';
      if (this.isValidForm) {
        this.$emit('updatePage');
      } else {
        this.loading = true;
        if (!this.isValidNumber) {
          this.showErrorNumber = true;
        } else if (!this.isValidDate) {
          this.showErrorDate = true;
        } else if (!this.isValidName) {
          this.showErrorName = true;
        } else if (!this.isValidVat) {
          this.showErrorVat = true;
        } else if (!this.isValidCf) {
          this.showErrorCf = true;
        } else if (!this.isValidVatOrFiscalCode) {
          this.showErrorVatOrFiscalCode = true;
        } else if (!this.isValidAddress) {
          this.showErrorAddress = true;
        } else if (!this.isValidCap) {
          this.showErrorCap = true;
        } else if (!this.isValidMunicipality) {
          this.showErrorMunicipality = true;
        } else if (!this.isValidProvince) {
          this.showErrorProvince = true;
        } else if (!this.isValidState) {
          this.showErrorState = true;
        } else if (!this.isValidTaxBase) {
          this.showErrorTaxBase = true;
        } else if (!this.isValidIva) {
          this.showErrorIva = true;
        } else if (!this.isValidInvoiceAmount) {
          this.showErrorInvoiceAmount = true;
        } else if (!this.isValidWithholdingTax) {
          this.showErrorWithholdingTax = true;
        } else if (!this.isValidWithholdingDate) {
          this.showErrorWithholdingDate = true;
        } else if (!this.isValidAmount) {
          this.showErrorAmount = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    updateAmountToPay () {
      const ritenuta = (this.invoice.withholdingTax && this.isValidWithholdingTax) ? parseFloat(this.invoice.withholdingTax) : 0;
      this.invoice.amount = (this.invoice.invoiceAmount - ritenuta);
    },
    updateBank2 (bank) {
      this.invoice.bank2 = bank;
    },
    updateTotalInvoice () {
      const imponibile = (this.invoice.taxBase && this.isValidTaxBase) ? (this.invoice.taxBase) : 0;
      const iva = (this.invoice.iva && this.isValidIva) ? (this.invoice.iva) : 0;
      this.invoice.invoiceAmount = (imponibile + iva);
    },
    updateWithholdingDate () {
      const date = new Date(this.invoice.date);
      // const month = date.getDate() < 16 ? date.getMonth() : date.getMonth() + 1;
      const withholdingDate = new Date(date.getFullYear(), date.getMonth() + 1, 16);
      this.setDate2(withholdingDate);
    },
    updatePaymentWithholding () {
      const today = new Date();
      today.setHours(0, 0, 0);
      this.invoice.withholdingIsPaid = new Date(this.invoice.withholdingDate) < today;
    },
    setDate (newDate) {
      this.invoice.date = newDate;
      this.updateWithholdingDate();
      this.update++;
    },
    setDate2 (newDate) {
      this.invoice.withholdingDate = newDate;
      this.updatePaymentWithholding();
      this.update++;
    },
  },
  mounted () {
    this.update++;
  },
};
</script>

<style lang="scss">
.simple-card {
  background: $fin-white;
  border-radius: 10px;
  overflow: visible!important;
  .title {
    border-radius: 10px 10px 0px 0px;
    background: $primary-lighter;
    color: $primary;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 5px 10px;
  }
  .content {
    padding: 5px 15px;
  }
  label.custom-control-label {
    font-weight: 400;
  }
}
</style>
