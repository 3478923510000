<template>
  <div ref="shepherd">
  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue';
import { useShepherd } from 'vue-shepherd';
require('shepherd.js/dist/css/shepherd.css');

const emit = defineEmits(['unblockDropdown', 'tutorialCompleted']);
const props = defineProps({ movementTutorial: Object });

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    classes: 'shadow-md bg-purple-dark p-2 rounded',
    scrollTo: true,
    modalOverlayOpeningRadius: 10,
  },
});

onMounted(() => {
  watch(() => props.movementTutorial, (newVal) => {
    if (newVal !== null) {
      // iban.value = props.value.movementTutorial?.bank?.iban;
      tour.next();
    }
  });

  function quitTutorial () {
    emit('tutorialCompleted');
    tour.cancel();
  }

  function step6Text () {
    const bank = props.movementTutorial.bankName;
    return 'Complimenti! Ora puoi visualizzare il movimento inserito nella tabella.<br/><br/>Proviamo ora a vedere il dettaglio dei movimenti.<br/><br/> <b>Clicca sulla riga <b/>' + bank;
  }

  tour.addStep({
    id: 'step-1',
    text: 'Quelli che vedi sono i saldi disponibili di ogni tuo conto corrente, giorno per giorno, al netto degli impegni previsti.<br/><br/>In pratica il saldo disponibile di ogni conto a fine giornata se tutti gli impegni previsti saranno onorati.',
    attachTo: { element: '#table-wrapper', on: 'bottom' },
    canClickTarget: false,
    modalOverlayOpeningPadding: 10,
    buttons: [
      {
        text: 'Salta tutorial',
        action: quitTutorial,
        classes: 'mr-auto exit',
      },
      {
        text: 'Avanti',
        action: tour.next,
        classes: 'btn btn-primary rounded',
      },
    ],
  });

  tour.addStep({
    id: 'step-2',
    text: 'Puoi vedere i movimenti attesi selezionando "impegni" <br/><br/> <b>Adesso proviamo ad inserire un movimento di prova.<b/>',
    attachTo: { element: '#tabs', on: 'bottom-start' },
    classes: 'mt-4',
    modalOverlayOpeningPadding: 10,
    buttons: [
      {
        text: 'Salta tutorial',
        action: quitTutorial,
        classes: 'mr-auto exit',
      },
      {
        text: 'Avanti',
        action: tour.next,
        classes: 'btn btn-primary rounded',
      },
    ],
  });

  tour.addStep({
    id: 'step-3',
    text: "Clicca su '<b>Aggiungi movimento</b>' e seleziona la categoria '<b>Accredito/Addebito</b>'.",
    attachTo: { element: '#dropdown-1__BV_toggle_', on: 'right' },
    modalOverlayOpeningPadding: 10,
    classes: 'ml-4',
    advanceOn: { selector: '#dropdown-1__BV_toggle_', event: 'click' },
    buttons: [
      {
        text: 'Salta tutorial',
        action: quitTutorial,
        classes: 'mr-auto exit',
      },
    ],
  });

  tour.addStep({
    id: 'step-4',
    text: "Seleziona '<b>Accredito/Addebito</b>'.",
    attachTo: { element: '.add-movement', on: 'top' },
    canClickTarget: true,
    advanceOn: { selector: '.add-movement', event: 'click' },
    buttons: [
      {
        text: 'Salta tutorial',
        action: quitTutorial,
        classes: 'mr-auto exit',
      },
      {
        text: 'Avanti',
        action: tour.next,
        classes: 'btn btn-primary rounded',
      },
    ],
  });

  tour.addStep({
    id: 'step-5',
    text: 'Prova a inserire un <b>addebito</b> di <b>100 €</b> dovuto a <b>Mario Rossi</b> previsto per <b>domani</b>.<br/><br/> Non preoccuparti, il movimento verrà eliminato durante il tutorial.',
    attachTo: { element: '#pretty-card', on: 'right' },
    highlightClass: 'custom',
    classes: 'ml-3',
    when: {
      show () {
        emit('unblockDropdown');
      },
    },
    buttons: [
      {
        text: 'Salta tutorial',
        action: quitTutorial,
        classes: 'mr-auto exit',
      },
    ],
  });

  tour.addStep({
    id: 'step-6',
    text: step6Text,
    attachTo: { element: '#table-wrapper', on: 'bottom' },
    when: {
      show () {
        const table = document.getElementById('table');
        table.addEventListener('click', function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          const bankRow = document.getElementById(props.movementTutorial.bank.iban);
          if ($event.target === bankRow || bankRow.contains($event.target)) {
            tour.next();
          }
        });
      },
      hide () {
        const table = document.getElementById('table');
        table.removeEventListener('click');
      },
    },
    buttons: [
      {
        text: 'Salta tutorial',
        action: quitTutorial,
        classes: 'mr-auto exit',
      },
    ],
  });

  tour.addStep({
    id: 'step-7',
    text: 'Clicca sulla riga Addebiti/Accrediti per visualizzare i movimenti.',
    attachTo: { element: '#pretty-card', on: 'right' },
    highlightClass: 'custom',
    classes: 'ml-3',
    when: {
      show () {
        emit('unblockDropdown');
      },
    },
    buttons: [
      {
        text: 'Salta tutorial',
        action: quitTutorial,
        classes: 'mr-auto exit',
      },
    ],
  });

  tour.start();
});
</script>

<style lang="scss">
.exit {
    color: $primary!important;
    opacity: 0.8;
    background: none!important;
    padding: 0!important;
    font-size: 16px;
    font-weight: 400;
    &:hover {
      text-decoration: underline;
      opacity: 1;
    }
}

.rounded {
  border-radius: 10px!important;
}

.shepherd-modal-overlay-container {
  z-index: 15!important;
}

.custom {
  .close-btn {
    pointer-events: none;
    opacity: 0.5!important;
  }
}
</style>
