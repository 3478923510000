<template>
  <transition name="fade">
    <div v-if="showPopup" class="popup-bg d-flex align-items-center">
        <div class="container-fluid my-auto p-5" :class="{'fixedOnTop': fixedOnTop}">
            <div class="card" id="pretty-card">
                  <div v-if="!hideCloseButton" type="button" class="close-btn pe-auto" @click="close">
                      <i class="fas fa-times"></i>
                  </div>
                  <slot name="fullContent">
                    <div>
                        <h2 class="h4 text-left">
                            <slot name="title">Lorem ipsum</slot>
                        </h2>

                        <p class="text-left mt-3" v-if="!noBody">
                            <slot name="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo asperiores porro lattuga.</slot>
                        </p>

                        <div :class="{'mt-4': slotHasData, 'mb-': slotHasData}">
                            <slot name="content"></slot>
                        </div>
                        <slot name="button">
                            <!-- <button class="btn w-100 mt-4" @click="$emit('onPrimaryAction')">
                                <slot name="button-text"></slot>
                            </button> -->
                        </slot>
                    </div>
                  </slot>
                  <ErrorCard v-if="errorMessage">
                      <template #message >
                      {{ errorMessage }}
                      </template>
                  </ErrorCard>
            </div>
        </div>
    </div>
  </transition>
</template>

<script>
import ErrorCard from '@/views/components/ErrorCard.vue';

export default {
  props: {
    onPrimaryAction: Function,
    beforeClosing: Function,
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    noBody: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    fixedOnTop: {
      type: Boolean,
    },
  },
  components: {
    ErrorCard,
  },
  data () {
    return {
      showPopup: false,
    };
  },
  computed: {
    slotHasData () {
      return this.$slots.content;
    },
  },
  methods: {
    togglePopup () {
      this.showPopup = !this.showPopup;
    },
    close () {
      this.$emit('beforeClosing');
      this.showPopup = false;
    },
  },
};
</script>

<style lang="scss">

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fixedOnTop {
  position: absolute!important;
  left: 0;
  right: 0;
  top: 10%;
}

.popup-bg {
  display: flex;
  align-items: center;
  z-index: 20;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(39, 36, 83, 0.4);
  backdrop-filter: blur(5px);
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &.md {
    .card {
      max-width: 500px!important;
    }
  }
  &.lg {
    .card {
      max-width: 800px!important;
    }
  }
  &.xl {
    .card {
      max-width: 1200px!important;
    }
  }
  .card {
    // max-width: fit-content;
    min-width: 400px;
    border-radius: 10px;
    margin: auto;
    position: relative;
    padding: 30px;
    margin: auto;
    border-width: 0;
    .close-btn {
      color: $primary;
      opacity: 0.75;
      position: absolute;
      right: 30px;
      font-size: 20px;
      svg {
        width: 16px;
        height: auto;
      }
    }
    h2 {
      margin-right: 20px;
    }
  }
}
</style>
