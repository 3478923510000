<template>
  <div class="setup-bank-accounts" ref="step-2">
    <div v-if="isLoadingPage" class="text-center m-5">
      <Loader2 />
    </div>
    <div v-else>
      <h5>Rettifica saldo cassa</h5>
      <p>Verrà creato un movimento di rettifica con la differenza tra il saldo nuovo e quello vecchio</p>
      <div class="row mt-4">
        <div class="col-6">
          <label>Saldo attuale</label>
          <CellContent
            :value="oldCash ? parseFloat(oldCash) : null"
            class="m-0 p-0 border-0 d-flex justify-content-start w-100"
          />
        </div>
        <div class="col-6">
          <b-form-group
            class="m-0"
            id="input-group-cash"
            label="Nuovo saldo"
            label-for="input-cash"
            :class="{ invalid: !isValidCash && showErrorCash }"
          >
            <CurrencyInput id="input-cash" v-model="cash" @blur="showErrorCash = true" :key="loading"/>
            <b-form-invalid-feedback
              :state="false"
              :class="{ invisible: isValidCash || !showErrorCash }"
            >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo
              valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

    <div class="row mt-5">
      <div class="col-6 ml-auto">
        <custom-button
          :isLoading="loading"
          :class="{disabled: !isValidCash}"
          label="Salva"
          class="w-100"
          @click.prevent.native="onSubmit"
        />
      </div>
    </div>

      <Popup @closePopup="togglePopup('upsertPopup')" ref="upsertPopup">
        <template #fullContent>
          <UpsertBankAccount
            :banks="banks"
            @addBankAccount="addBankAccount"
            @editBankAccount="editBankAccount"
            :account="selectedItem"
          />
        </template>
      </Popup>

      <Popup
        @closePopup="togglePopup('deletePopup')"
        ref="deletePopup"
        class="md"
      >
        <template #title> Sei sicuro di voler eliminare? </template>
        <template #text>
          Il conto <b>{{ selectedItem.alias }}</b> con iban <b>{{ selectedItem.iban }}</b> verrà rimosso dall'elenco
        </template>
        <template #button>
          <custom-button
            label="Elimina"
            class="w-100 mt-4"
            @click.prevent.native="deleteBankAccount(selectedItem)"
          />
        </template>
      </Popup>

    </div>

  </div>
</template>

<script>
import Button from '@/views/components/Button.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import Popup from '@/views/components/Popup.vue';
import UpsertBankAccount from '@/views/components/Setup/UpsertBankAccount.vue';
import Loader2 from '@/views/components/Layout/Spinner.vue';

import { BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled } from '@/helpers/formValidation.js';

export default {
  props: {
    companyId: String,
  },
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    CellContent,
    CurrencyInput,
    'custom-button': Button,
    Popup,
    UpsertBankAccount,
    Loader2,
  },
  data () {
    return {
      loading: false,
      isLoadingPage: true,
      showErrorCash: false,
      errorMessage: '',

      oldCash: null,
      cash: null,
    };
  },
  computed: {
    isValidCash () {
      return isFilled(this.cash) && this.cash !== this.oldCash && this.cash >= 0;
    },
  },
  methods: {
    getCompanyInfo () {
      this.$api.getCompany(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          const company = res.data;
          this.oldCash = company.banks.find(obj => obj.isCash).accountBalance;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
    togglePopup (ref) {
      return this.$refs[ref].togglePopup();
    },
    onSubmit () {
      this.$emit('setError', '');
      if (this.isValidCash) {
        this.saveData();
      } else {
        this.loading = true;
        if (this.lengthOfArray <= 0) {
          this.showErrorBankAccounts = true;
        } else if (!this.isValidCash) {
          this.showErrorCash = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    saveData () {
      this.loading = true;
      this.$api.updateCashSettings(this.companyId, this.cash)
        .then((res) => {
          this.$log.debug(res);
          this.snackbarText = 'Informazioni aggiornate';
          this.$emit('openSnackbar', this.snackbarText);
          this.cash = null;
          this.showErrorCash = false;
          this.getCompanyInfo();
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted () {
    this.getCompanyInfo();
  },
};
</script>

<style lang="scss">
.setup-bank-accounts {
  .add-button {
    background: $primary-lighter;
    color: $primary;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    border-radius: 10px;
    padding: auto;
    min-height: 235px;
    min-width: 150px;
    i {
      font-size: 52px;
    }
    &:hover {
      background: rgba($primary-light, 0.6);
    }
    &:active {
      background: rgba($primary-light, 0.8);
    }
    &.invalid {
      color: $danger;
      background: (rgba($danger, 0.1));
    }
  }
  .account-card {
    min-height: 235px;
    height: 100%;
    border-radius: 10px;
    padding: 15px;
    position: relative;
    .iban {
      font-weight: 500;
      font-size: 13px;
    }
    .iban-tag {
      color: $fin-white;
      font-weight: 600;
      font-size: 13px;
      padding: 0px 4px;
      border-radius: 5px;
    }
    button {
      border: none;
      background: none;
    }
    .icons {
      position: absolute;
      bottom: 10px;
      width: 100%;
    }
  }
}
</style>
