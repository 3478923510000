<template>
<div class="add-bank-account">
  <h2 class="h4 mb-4">{{ account ? 'Modifica conto' : 'Aggiungi conto' }}</h2>
    <div class="row mb-4" v-if="!account">
      <div class="col-12">
        <div class="selector" id="selector">
          <button type="button" class="btn w-50 option" @click="manualMode = false;" :class="{ active: !manualMode }">
            Assistita
          </button>
          <button type="button" class="btn w-50 option" @click="manualMode = true; errorMessage = '';" :class="{ active: manualMode }">
            Manuale
          </button>
        </div>
      </div>
    </div>

    <div v-if="manualMode">
      <form
        @submit.prevent="onSubmit"
        class="text-start"
        novalidate
      >
      <div class="row">
          <div class="col-6">
              <b-form-group
                  class="mb-2"
                  id="input-group-bank"
                  label-for="input-bank"
                  :class="[{ invalid: !isValidBank && showErrorBank }, {disabled: settingsEdit}]"
              >
                  <BankSelect ref="bankSelect" :banks="banks" :label="'Banca*'" :disabled="settingsEdit" :selectedItem="form.bankDetail" @update="updateBank" @search:blur="showErrorBank = true" :key="update"/>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBank || !showErrorBank }" >
                      <i class="fas fa-exclamation-circle"></i> Inserisci una banca
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
          <div class="col">
              <b-form-group
                  class="box"
                  id="input-group-alias"
                  label="Alias"
                  label-for="input-alias"
                  :class="{ invalid: !isValidAlias && showErrorAlias }"
              >
                  <b-form-input
                  id="input-alias"
                  v-model="form.alias"
                  type="text"
                  @blur="showErrorAlias = true"
                  placeholder="Inserisci un alias"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAlias || !showErrorAlias }" >
                      <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido (max 30 caratteri)
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
      </div>

      <div class="row">
          <div class="col">
              <b-form-group
                  class="mb-2"
                  id="input-group-iban"
                  label="IBAN*"
                  label-for="input-iban"
                  :class="{ invalid: !isValidIban && showErrorIban }"
              >
                  <b-form-input
                  id="input-iban"
                  v-model="form.iban"
                  type="text"
                  @blur="showErrorIban = true"
                  placeholder="Inserisci un iban"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidIban || !showErrorIban }" >
                      <i class="fas fa-exclamation-circle"></i>
                      {{!isUniqueIban(form.iban) ? 'Questo iban è già stato inserito' : 'Inserisci un IBAN valido' }}
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
      </div>

      <div class="row">
          <div class="col">
              <b-form-group
                  class="mb-2"
                  id="input-group-fido"
                  label="Fido"
                  label-for="input-fido"
                  :class="{ invalid: !isValidFido && showErrorFido }"
              >
                  <CurrencyInput id="input-fido" v-model="form.fido" @blur="showErrorFido = true"/>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidFido || !showErrorFido }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
          <div class="col" v-if="false">
              <b-form-group
                class="mb-2"
                id="input-group-anticipi"
                :class="[{ invalid: !isValidContoAnticipi && showErrorContoAnticipi && !form.anticipoRiba }, { disabled: form.anticipoRiba }]"
                :disabled="form.anticipoRiba"
              >
                <label>Conto anticipi</label>
                <CurrencyInput id="input-anticipi" v-model="form.contoAnticipi" @blur="showErrorContoAnticipi = true"/>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidContoAnticipi || !showErrorContoAnticipi || form.anticipoRiba }" >
                <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                </b-form-invalid-feedback>
              </b-form-group>
          </div>
          <div class="col">
              <b-form-group
                  class="mb-2"
                  id="input-group-fee"
                  label="Importo commissioni bonifico"
                  label-for="input-fee"
                  :class="{ invalid: !isValidFee && showErrorFee }"
              >
                  <CurrencyInput id="form-fee" v-model="form.transferFees" @blur="showErrorFee = true"/>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidFee || !showErrorFee }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
      </div>
      <div class="row">
          <div class="col">
              <b-form-group
                  class="mb-2"
                  id="input-group-account-balance"
                  label="Saldo contabile*"
                  label-for="input-account-balance"
                  :class="{ invalid: !isValidAccountBalance && showErrorAccountBalance }"
              >
                  <CurrencyInput id="input-account-balance" v-model="form.accountBalance" @blur="showErrorAccountBalance = true"/>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAccountBalance || !showErrorAccountBalance }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
          <div class="col">
              <b-form-group
                  class="mb-2 disabled"
                  id="input-group-available-balance"
                  label="Saldo disponibile"
                  label-for="input-available-balance"
              >
                <CurrencyInput id="input-available-balance" v-model="availableBalance" :key="availableBalance"/>
              </b-form-group>
          </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="colours">Colore</label>
          <div class="form-group mb-3">
            <div class="colours" id="colours">
                <div v-for="el in list" :key="`color_${el}`" class="circle d-block" :class="[`bank-variant-${el}`, `border-bank-variant-${el}`, {active: (form.colour === JSON.stringify(el))}]" @click="form.colour = JSON.stringify(el)" tabindex="0" @keydown.enter="form.colour = JSON.stringify(el)">
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-auto">
          <b-form-checkbox
            id="checkbox-1"
            v-model="form.mainBank"
            name="checkbox-1"
          >
            Banca principale
            <b-form-invalid-feedback :state="false">Puoi selezionare solo una banca principale</b-form-invalid-feedback>
          </b-form-checkbox>
        </div>
        <div class="col-auto" v-if="false">
          <b-form-checkbox
            id="checkbox-2"
            v-model="form.anticipoRiba"
            name="checkbox-2"
            @change="form.contoAnticipi = ''; showErrorContoAnticipi = false;"
          >
            Anticipo RiBa SBF in conto
          </b-form-checkbox>
        </div>
      </div>

      <custom-button
        type="submit"
        :isLoading="loading"
        label="Salva"
        class="w-100"
        @click.prevent.native="onSubmit"
      />
      </form>
    </div>
    <div v-else>
      <div v-if="onBoarded === null" class="custom-loader-container">
        <Loader2 class="custom-loader" />
      </div>
      <div v-if="onBoarded === true">
          <p class="my-4 text-center">
            Hai già eseguito l'onboarding su fabrick <br>
            Per autorizzare altri conti clicca sul tasto in basso.
          </p>
        <custom-button
          :isLoading="loading"
          label="Continua su Fabrick"
          class="w-100"
          @click.prevent.native="updateAggregation"
        />
      </div>

      <div id="onboarding-form" v-if="onBoarded === false">
        <form
        @submit.prevent="onOnboarding"
        class="text-start"
        novalidate
        >
        <div class="row">
          <div class="col-12">
            <p>
              Per collegare automaticamente i tuoi conti a NinfaFin, devi effettuare la registrazione su Fabrick. <br>
              <span class="font-weight-bolder">I movimenti verranno acquisiti alle 8:00 del giorno successivo.</span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
              <b-form-group
                  class="mb-3"
                  id="input-group-name"
                  label="Nome*"
                  label-for="input-name"
                  :class="{ invalid: !isValidName && showErrorName }"
              >
                  <b-form-input
                  id="input-name"
                  v-model="customerInfo.name"
                  type="text"
                  @blur="showErrorName = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidName || !showErrorName }" >
                      <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido
                  </b-form-invalid-feedback>
              </b-form-group>
            </div>
          <div class="col-6">
              <b-form-group
                  class="mb-3"
                  id="input-group-surname"
                  label="Cognome*"
                  label-for="input-surname"
                  :class="{ invalid: !isValidSurname && showErrorSurname }"
              >
                  <b-form-input
                  id="input-surname"
                  v-model="customerInfo.surname"
                  type="text"
                  @blur="showErrorSurname = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidSurname || !showErrorSurname }" >
                      <i class="fas fa-exclamation-circle"></i> Inserisci un cognome valido
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b-form-group
                class="mb-3"
                id="input-group-birth-place"
                label="Luogo di nascita*"
                label-for="input-birth-place"
                :class="{ invalid: !isValidBirthPlace && showErrorBirthPlace }"
            >
                <b-form-input
                id="input-birth-place"
                v-model="customerInfo.birthPlace"
                type="text"
                @blur="showErrorBirthPlace = true"
                ></b-form-input>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBirthPlace || !showErrorBirthPlace }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un luogo di nascita
                </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
                class="mb-3"
                id="input-group-birth-place"
                label="Data di nascita*"
                label-for="input-birth-place"
                :class="{ invalid: !isValidBirthDate && showErrorBirthDate }"
            >
              <DateOfBirthPicker id="input-birth-date" :value="customerInfo.birthDate" @setValue="setDate" @blur="showErrorBirthDate = true"/>
              <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBirthDate || !showErrorBirthDate }" >
                <i class="fas fa-exclamation-circle"></i> Inserisci una data valida
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-form-group
              class="mb-3"
              id="input-group-fiscal-code"
              label="Codice Fiscale*"
              label-for="input-fiscal-code"
              :class="{ invalid: !isValidFiscalCode && showErrorFiscalCode}"
            >
              <b-form-input
              id="input-fiscal-code"
              v-model="customerInfo.fiscalCode"
              type="text"
              @blur="showErrorFiscalCode = true"
              ></b-form-input>
              <b-form-invalid-feedback :state="false" :class="{ invisible: isValidFiscalCode || !showErrorFiscalCode }" >
                <i class="fas fa-exclamation-circle"></i> Inserisci un codice fiscale valido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <custom-button
          :isLoading="loading"
          :class="{disabled: !isValidCustomerInfo}"
          label="Continua su Fabrick"
          class="w-100"
          @click.prevent.native="onOnboarding"
        />
        </form>
      </div>
    </div>
    <ErrorCard v-if="errorMessage">
      <template #message >
        {{ errorMessage }}
      </template>
    </ErrorCard>
</div>
</template>

<script>
import ErrorCard from '@/views/components/ErrorCard.vue';
import Button from '@/views/components/Button.vue';
import BankSelect from '@/views/components/BankSelect.vue';
import { BFormGroup, BFormInput, BFormInvalidFeedback, BFormCheckbox } from 'bootstrap-vue';
import { isFilled, isCurrency, isItalianIban, isFiscalCode } from '@/helpers/formValidation.js';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import DateOfBirthPicker from '@/views/components/DateOfBirthPicker.vue';
import Loader2 from '@/views/components/Layout/Spinner.vue';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    banks: Array,
    bankAccounts: Array,
    account: Object,
    index: Number,
    settings: Boolean,
  },
  components: {
    BankSelect,
    ErrorCard,
    'custom-button': Button,
    CurrencyInput,
    DateOfBirthPicker,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    Loader2,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      update: 0,
      manualMode: Boolean(this.account),

      showErrorBank: false,
      showErrorAlias: false,
      showErrorIban: false,
      showErrorFido: false,
      showErrorContoAnticipi: false, // to do update
      showErrorFee: false,
      showErrorAccountBalance: false,

      showErrorName: false,
      showErrorSurname: false,
      showErrorFiscalCode: false,
      showErrorBirthPlace: false,
      showErrorBirthDate: false,

      loading: false,
      errorMessage: '',

      list: [14, 8, 5, 1, 4, 6, 2, 7, 3, 9, 11, 12, 13, 10, 15],

      // bankDetail: this.account?.bankDetail || null,
      onBoarded: null,
      // Form
      form: {
        id: this.account?.id || null,
        alias: this.account?.alias || '',
        iban: this.account?.iban || '',
        colour: this.account?.colour || null,
        fido: this.account?.fido || null,
        contoAnticipi: this.account?.contoAnticipi || null, // to do update
        transferFees: this.account?.transferFees || null,
        accountBalance: this.account?.accountBalance || null,
        mainBank: this.account?.mainBank || false,
        anticipoRiba: this.account ? this.account.anticipoRiba : false,
        bankDetail: this.account ? this.account.bankDetail : null,
        fabrickId: this.account ? this.account.fabrickId : null,
        consentExpireAt: this.account ? this.account.consentExpireAt : null,
      },

      customerInfo: {
        name: '',
        surname: '',
        birthPlace: '',
        birthDate: null,
        fiscalCode: '',
      },
    };
  },
  watch: {
    bankDetail: {
      handler () {
        if (Object.entries(this.form.bankDetail).length > 0) {
          this.form.colour = this.form.bankDetail.colour !== '' ? this.form.bankDetail.colour : '1';
          this.form.alias = (this.form.bankDetail.alias === null) ? this.form.bankDetail.alias : (this.form.bankDetail.shortName ? this.form.bankDetail.shortName : null);
        }
      },
      deep: true,
    },
  },
  computed: {
    availableBalance: {
      get () {
        if (this.form.anticipoRiba) {
          return ((parseFloat(this.form.fido * 100) + parseFloat(this.form.accountBalance * 100)) / 100);
        } else {
          return ((parseFloat(this.form.fido * 100) + parseFloat(this.form.contoAnticipi * 100) + parseFloat(this.form.accountBalance * 100)) / 100);
        }
      },
      set (newVal) {
        return newVal;
      },
    },
    bankDetail () {
      return this.form.bankDetail;
    },
    // Account
    isValidForm () {
      return this.isValidBank && this.isValidAlias && this.isValidIban && this.isValidFido && this.isValidContoAnticipi && this.isValidFee && this.isValidAccountBalance;
    },
    isValidBank () {
      return isFilled(this.form.bankDetail);
    },
    isValidAlias () {
      return this.form.alias?.length <= 30;
    },
    isValidIban () {
      return isFilled(this.form.iban) && isItalianIban(this.form.iban) && this.isUniqueIban(this.form.iban);
    },
    isValidFido () {
      return isCurrency(this.form.fido) || !isFilled(this.form.fido);
    },
    isValidContoAnticipi () {
      /*
      if (this.form.anticipoRiba) {
        return isCurrency(this.form.contoAnticipi) || !isFilled(this.form.contoAnticipi);
      } else {
        return isFilled(this.form.contoAnticipi) && isCurrency(this.form.contoAnticipi);
      }
      */
      return true;
    },
    isValidFee () {
      return isCurrency(this.form.transferFees) || !isFilled(this.form.transferFees);
    },
    isValidAccountBalance () {
      return isFilled(this.form.accountBalance) && isCurrency(this.form.accountBalance);
    },
    // CustomerInfo
    isValidCustomerInfo () {
      return this.isValidName && this.isValidSurname && this.isValidFiscalCode && this.isValidBirthPlace && this.isValidBirthDate;
    },
    isValidName () {
      return isFilled(this.customerInfo.name);
    },
    isValidSurname () {
      return isFilled(this.customerInfo.surname);
    },
    isValidFiscalCode () {
      return isFilled(this.customerInfo.fiscalCode) && isFiscalCode(this.customerInfo.fiscalCode);
    },
    isValidBirthPlace () {
      return isFilled(this.customerInfo.birthPlace);
    },
    isValidBirthDate () {
      return isFilled(this.customerInfo.birthDate);
    },
    settingsEdit () {
      return this.settings && this.account !== null;
    },
  },
  methods: {
    checkOnBoarded () {
      this.loading = true;
      this.$api.fabrickCheckOnBoarded()
        .then((res) => {
          this.onBoarded = res.data.onBoarded;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isUniqueIban (iban) {
      return !this.bankAccounts.find((obj, i) => {
        return obj.iban.replace(/\s/g, '') === iban.replace(/\s/g, '') && i !== this.index;
      });
    },
    updateAggregation () {
      this.loading = true;
      this.$api.fabrickUpdateAggregation(null)
        .then((res) => {
          window.location.href = res.data.redirect;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onOnboarding () {
      this.loading = true;
      this.$api.fabrickOnboarding(Object.assign({ customerInfo: this.customerInfo }))
        .then((res) => {
          this.$log.debug(res);
          window.location.href = res.data.redirect;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setDate (newDate) {
      this.customerInfo.birthDate = new Date(newDate);
    },
    onSubmit: function () {
      this.errorMessage = '';
      if (this.form.alias === '') {
        this.form.alias = this.form.bankDetail?.shortName;
      }

      if (this.isValidForm && !this.account) {
        this.$log.debug('1');
        this.$emit('addBankAccount', Object.assign(this.form, this.index));
      } else if (this.isValidForm && this.account && this.account.toConfigure) {
        this.$log.debug('2');
        this.$emit('addBankAccount', Object.assign(this.form, this.index));
      } else if (this.isValidForm && this.account && !this.account.toConfigure) {
        this.$log.debug('3');
        this.$emit('editBankAccount', Object.assign(this.form, this.index));
      } else {
        this.loading = true;
        if (!this.isValidBank) {
          this.showErrorBank = true;
        } else if (!this.isValidAlias) {
          this.showErrorAlias = true;
        } else if (!this.isValidIban) {
          this.showErrorIban = true;
        } else if (!this.isValidFido) {
          this.showErrorFido = true;
        } else if (!this.isValidContoAnticipi) {
          this.showErrorContoAnticipi = true;
        } else if (!this.isValidFee) {
          this.showErrorFee = true;
        } else if (!this.isValidAccountBalance) {
          this.showErrorAccountBalance = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    updateBank (bank) {
      this.form.bankDetail = bank;
      this.update++;
    },
  },
  mounted () {
    if (!this.account) {
      this.checkOnBoarded();
    }
  },
};
</script>

<style lang="scss">
.add-bank-account {
  .custom-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }
  .bank-logo {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .colours {
    display: inline-flex;
    gap: 10px;
  }
  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    &:hover {
      filter: brightness(95%);
    }
    &:focus {
      border: none;
    }
    &.active::after {
      content: url(~@/assets/images/check-icon.svg);
      transform: scale(0.8);
      position: absolute;
      height: inherit;
      width: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 7px;
    }
    &.active::before {
      content: '';
      height: inherit;
      width: inherit;
      position: absolute;
      border: 2px solid #000;
      border-radius: 100%;
      mix-blend-mode: overlay;
    }
  }
}
</style>
