<template>
    <div>
        <TaxBoxForm :setup="true" @completed="setStepAsCompleted" />
        <div class="row mt-4">
          <div class="col-6">
              <custom-button
              :isLoading="loading"
              label="Indietro"
              class="w-100 btn-secondary"
              @click.prevent.native="$emit('changePage', -1)"
              />
          </div>
          <div class="col-6">
              <custom-button
              :isLoading="loading"
              label="Avanti"
              class="w-100"
              @click.prevent.native="onSubmit"
              />
          </div>
        </div>
    </div>
</template>

<script>
import Button from '@/views/components/Button.vue';
import TaxBoxForm from '@/views/components/Settings/TaxBoxForm';

export default {
  components: {
    'custom-button': Button,
    TaxBoxForm,
  },
  data () {
    return {
      loading: false,
    };
  },
  methods: {
    onSubmit () {
      this.$emit('saveData', null);
    },
    setStepAsCompleted (isCompleted = false) {
      if (isCompleted) {
        this.$emit('setTaxFormAsCompleted', true);
      }
    }
  },
};
</script>

<style>

</style>
