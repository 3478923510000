<template>
    <div class="product-expired">
      <div class="container-fluid">
        <div class="row mt-5">
          <div class="col-12 d-flex justify-content-center">
            <div class="logo">
              <img :src="logo" alt="NinaFin logo">
            </div>
          </div>
        </div>
        <div class="pretty-card my-4 text-center">

          <div class="row mt-2">
            <div style="margin: auto;">
              <img :src="errorImg" alt="" class="mb-4">
              <h4 class="text-center mb-3">La licenza è scaduta</h4>
              <p class="text-center mb-4">
                Contatta l'amministratore dell'account
              </p>
              <router-link v-if="false" :to="{ name: 'checkout' }" tag="button" class="btn btn-fin">
                Vai al modulo di pagamento
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import logo from '@/assets/images/fin-big-logo.svg';
import errorImg from '@/assets/images/account-activation-error.svg';

export default {
  data () {
    return {
      logo: logo,
      errorImg: errorImg,
    };
  },
  created () {
    const me = this.$oauth2.me;
    // to do controllo per livello utente
    if (!me?.product || !me?.product?.expired) {
      this.$router.replace('dashboard');
    }
  },
};
</script>
<style lang="scss">
  .product-expired {
    background-image: url(~@/assets/images/fin-bg.png);
    background-position: center;
    background-color: $primary-lighter;
    align-items: center;
    .container-fluid {
      max-width: 968px;
    }
  }
</style>
