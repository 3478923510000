<template>
  <transition name="fade">
    <div v-if="showSnackbar" class="snackbar">
      <div class="snackbar-shadow">
          <div class="snackbar-body">
            <slot name="icon">
              <div class="icon" :class="type">
                <i class="fas" :class="(type === 'default') ?  'fa-check-circle' : 'fa-times-circle'"></i>
              </div>
            </slot>
            <div class="text">
              <slot name="content">
                {{ text }}
              </slot>
            </div>
            <div type="button" class="close-btn" @click="closeSnackbar()">
              <i class="fas fa-times"></i>
            </div>
          </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'snackbar',
  props: {
    text: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: 'default',
    },
  },
  data () {
    return {
      showSnackbar: false,
      type: this.variant,
    };
  },
  methods: {
    openSnackbar () {
      this.showSnackbar = true;
      setTimeout(() => {
        this.closeSnackbar();
        this.type = 'default';
      }, 5000);
    },
    openErrorSnackbar () {
      this.type = 'danger';
      this.openSnackbar();
    },
    closeSnackbar () {
      this.showSnackbar = false;
    },
  },
};
</script>

<style lang="scss">
.snackbar {
    position: absolute;
    bottom: 0;
    left: 0;

    z-index: 10;
    min-height: 100px;
    width: 100vw;
    max-width: 100vw;
    background: linear-gradient(360deg, #D3D6E3 0%, rgba(211, 214, 227, 0) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    .snackbar-body {
      background: white;
      width: fit-content;
      padding: 15px;
      font-weight: 600;
      font-size: 14px;
      border-radius: 10px;
      box-shadow: $box-shadow;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      .icon {
        margin-right: 10px;
        font-size: 20px;
        &.default {
          color: $green;
        }
        &.danger {
          color: $danger;
        }
      }
      .text {
        max-width: 460px;
      }
      .close-btn {
        color: $primary;
        opacity: 0.75;
        position: relative;
        font-size: 20px;
        margin-left: 15px;
        i {
          font-size: 20px;
        }
      }
    }
}
</style>
