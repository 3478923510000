export default {
  baseUrl: `${process.env.BASE_URL || '/api/v1'}`,
  apiBaseUrl: `${process.env.VUE_APP_API_BASE_URL || '/api/v1'}`,
  STORAGE_KEY: 'ninafin',
  tokenKey: 'ninafin-token',
  refreshKey: 'ninafin-refresh',
  client_id: `${process.env.VUE_APP_CLIENT_ID || 'rff'}`,
  client_secret: `${process.env.VUE_APP_CLIENT_SECRET || '9370eaaa0393381639545b72b38d5c58'}`,
  env: `${process.env.NODE_ENV || 'dev'}`,
  stripe_token: `${process.env.VUE_APP_STRIPE_PUBLIC_KEY || ''}`,
  recaptchav3_sitekey: `${process.env.VUE_APP_RECAPTCHAV3_SITE_KEY || ''}`,
};
